<template>
  <f7-page @page:beforein="beforein">
    <navbar
      :text="`${$t('Utilities_Title')} > ${$t(
        'LeftPanel_title_referencePlans'
      )}`"
    />

    <div class="reference-plans-group-list">
      <div
        v-for="(group, index) of groups"
        :key="`reference-plans-group-${index}`"
        class="reference-plans-group"
        @click="showListByGroup(group)"
      >
        <img
          v-if="hasImage(group)"
          :src="group.image"
          :alt="group.name"
          class="reference-plans-group-image"
        >
        <div
          v-else
          class="reference-plans-group-image"
        />
        <div>{{ group.name }}</div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import navbar from '../../../components/NavBar.vue';

import {
  CEREAL, LIMON_POMELO, OLIVO, HORTÍCOLA_INVERNADERO, HORTÍCOLA_AIRE_LIBRE,
  CULTIVOS_INDUSTRIALES, FRUTAL, HORTÍCOLA_FRUTO, FRUTO_SECO, NARANJA_MANDARINA,
  ALFALFA, MAÍZ, UVA_VINIFICACIÓN, PRADERA_FORRAJE,
} from '../../../js/constants';

export default {
  name: 'ReferencePlansPage',
  components: {
    navbar,
  },
  data() {
    return {
      environment: process.env.NODE_ENV,
    };
  },
  computed: {
    groups() {
      const images = [
        { name: 'Cereal', image: CEREAL },
        { name: 'Limon-Pomelo', image: LIMON_POMELO },
        { name: 'Olivo', image: OLIVO },
        { name: 'Hortícola invernadero', image: HORTÍCOLA_INVERNADERO },
        { name: 'Hortícola aire libre', image: HORTÍCOLA_AIRE_LIBRE },
        { name: 'Cultivos industriales', image: CULTIVOS_INDUSTRIALES },
        { name: 'Frutal', image: FRUTAL },
        { name: 'Hortícola fruto', image: HORTÍCOLA_FRUTO },
        { name: 'Fruto seco', image: FRUTO_SECO },
        { name: 'Naranja-Mandarina', image: NARANJA_MANDARINA },
        { name: 'Alfalfa', image: ALFALFA },
        { name: 'Maíz', image: MAÍZ },
        { name: 'Uva vinificación', image: UVA_VINIFICACIÓN },
        { name: 'Pradera Forraje', image: PRADERA_FORRAJE },
        { name: 'Otros' },
      ];

      return images.sort((a, b) => {
        const cpA = a.name.toUpperCase();
        const cpB = b.name.toUpperCase();
        // eslint-disable-next-line no-nested-ternary
        return (cpA < cpB) ? -1 : (cpA > cpB) ? 1 : 0;
      });
    },
  },
  methods: {
    beforein() {
      this.$fa.setScreenName('Utilidades > Planes de referencia');
    },
    hasImage(group) {
      return group?.image != null;
    },
    showListByGroup(group) {
      this.$f7router.navigate({
        name: 'reference-plans-group-list',
        params: {
          group: group.name,
        },
      });
    },
  },
};
</script>

<style>
.reference-plans-group-list {
  display: grid;
  /* grid-template-columns: repeat(8, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 10px;
  padding: 15px;
}

.reference-plans-group {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.reference-plans-group:hover {
  background-color: #f3f4f6;
  cursor: pointer;
  /* color: #007aff; */
}

/* .reference-plans-group:hover .reference-plans-group-image {
  object-fit: contain;
} */

.reference-plans-group-image {
  width: 100%;
  height: 160px;
  background-color: #e5e7eb;
  border-radius: 10px;
  margin-bottom: 10px;
  object-fit: cover;
}
</style>

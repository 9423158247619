<template>
  <div class="margin-bottom default-forms">
    <f7-row class="row-items-center">
      <f7-col width="40">
        <div class="dx-field">
          <div class="dx-field-label">
            <span>Unidad de aplicación</span>
          </div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="period.default_apply_unit"
              :items="applyUnit"
              display-expr="name"
              value-expr="id"
              :on-value-changed="onChangeApplyUnit"
              :read-only="readOnly"
            />
          </div>
        </div>
      </f7-col>
      <f7-col width="20" />
      <f7-col width="40">
        <div class="dx-field justify-end">
          <div
            class="dx-field-label align-right"
            style="width: 40% !important;"
          >
            <span>Velocidad de avance</span>
          </div>
          <div class="dx-field-value">
            <DxNumberBox
              v-model="treatmentPlan.forward_speed"
              :read-only="readOnly"
            />
          </div>
          <div class="margin-right margin-left">
            km/h
          </div>
        </div>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box';
import { mapState } from 'vuex';
import DxNumberBox from 'devextreme-vue/number-box';

export default {
  name: 'TreatmentStepTwoFormDefault',
  components: {
    DxSelectBox,
    DxNumberBox,
  },
  props: {
    period: {
      type: Object,
      default: () => {},
    },
    selectedIndex: Number,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      applyUnit: [
        {
          id: 'default',
          name: 'Kg-L / ha',
        },
        {
          id: 'Kg-L / planta',
          name: 'Kg-L / planta',
        },
      ],
    };
  },
  computed: {
    ...mapState('treatmentStepper', ['treatmentPlan']),
  },
  methods: {
    onChangeApplyUnit(e) {
      let index = 0;
      let count = this.selectedIndex;

      for (const period of this.treatmentPlan.periods) {
        const doseVolumeNotEmpty = period.products.some(
          ({ dose_volume, active }) => (dose_volume > 0 && active),
        );

        if (doseVolumeNotEmpty) {
          if (count === 0) {
            break;
          }

          count--;
        }
        index++;
      }

      this.treatmentPlan.default_apply_unit.splice(
        index,
        1,
        e.value,
      );
    },
  },
};
</script>

<style src="./TreatmentStepTwoForms.styles.scss" scoped lang="scss"></style>

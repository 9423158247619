<template>
  <section class="stepper-component">
    <div class="content-devx">
      <div class="card-header">
        {{ title }}
      </div>
      <div class="card-body">
        <section class="steps">
          <div
            v-for="(step, index) in treatmentSteps"
            :key="index"
            class="step"
            @click="setCurrentStep(index)"
          >
            <div class="dot-container">
              <span :class="'dot ' + step.statusClass">
                <i
                  v-if="step.statusClass && step.statusClass.includes('complete')"
                  class="dx-icon-check"
                />
              </span>
            </div>
            <span class="dot-label">{{ step.label }}</span>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { DxDateBox, DxCalendarOptions } from 'devextreme-vue/date-box';
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextBox from 'devextreme-vue/text-box';

export default {
  name: 'Stepper',
  components: {
    DxDateBox,
    DxCalendarOptions,
    DxSelectBox,
    DxTextBox,
  },
  props: [],
  data() {
    return {
      stepSelected: {},
      temporalFrameId: 0,
      planPeriods: [],
    };
  },
  computed: {
    title() {
      if (typeof this.treatmentPlan.id === 'undefined') {
        return this.$t('treatment.treatmentPlan.treatmentCreatePlan.title');
      }

      return this.$t('treatment.treatmentPlan.treatmentCreatePlan.editTitle');
    },
    ...mapGetters('treatmentStepper', ['treatmentSteps', 'currentStep', 'stepIndex', 'treatmentPlan']),
  },
  created() {
    this.updateClasses();
  },
  methods: {

    setCurrentStep(index) {
      this.setStep(index);
      this.$f7.views.main.router.navigate(this.currentStep.page,
        { reloadCurrent: true });
    },
    ...mapActions('treatmentStepper', ['next', 'back', 'findSelectedIndex', 'updateClasses', 'setStep']),
  },
  watch: {
  },
};

</script>
<style src="./stepper.scss" scoped lang="scss"></style>

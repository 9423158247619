<template>
  <section>
    <div class="content-devx no-margin margin-right-half">
      <div class="content-separatos">
        <section class="product-chart">
          <DxChart
            style="width: 100%;"
            palette="SoftPastel"
            :data-source="stackProduct"
            @legend-click="onLegendClick"
          >
            <DxSeriesTemplate name-field="productName" />
            <DxTooltip
              :enabled="true"
              :z-index="999999"
              :customize-tooltip="customizeTooltip"
            />
            <DxCommonSeriesSettings
              argument-field="monthWeek"
              value-field="value"
              type="stackedbar"
            />
            <DxLegend
              vertical-alignment="top"
              horizontal-alignment="center"
              item-text-position="right"
            />
            <DxSize
              :height="210"
            />
          </DxChart>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLegend,
  DxSeriesTemplate,
  DxTooltip,
  DxSize,
} from 'devextreme-vue/chart';
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import { mapActions, mapState } from 'vuex';
import ProductDataFormatter from '@/pages/wizardNutrition/report-formatter/product-data-formatter';

export default {
  name: 'ProductResume',
  components: {
    DxDataGrid,
    DxColumn,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLegend,
    DxSeriesTemplate,
    DxTooltip,
    DxSize,
  },
  props: ['fertilization'],
  data() {
    return {
      productResume: [],
      stackProduct: [],
      plantation: {},
    };
  },
  computed: {
    ...mapState('Nutrition', ['productsNotInNutritionPlan']),
  },
  async mounted() {
    this.setProductsNotInNutritionPlan([]);
    const formatter = new ProductDataFormatter(this.fertilization);
    try {
      await formatter.buildProductResumeData();
    } catch (e) {
      const notFoundNutritionProducts = JSON.parse(e.message);
      this.setProductsNotInNutritionPlan(notFoundNutritionProducts);
    }

    this.productResume = formatter.productResume;
    this.stackProduct = formatter.stackProduct;
    this.plantation = formatter.plantation;
  },
  methods: {
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    customizeTooltip(point) {
      return {
        html: `<div><div class='tooltip-header'>${
          point.argumentText
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('product')}: ${point.seriesName} </span>
          </div>
          <span class="bold"> ${this.$t('weather_parameter_value')}: ${
  point.value
} kg/ha</span> `,
      };
    },
    ...mapActions('Nutrition', ['setProductsNotInNutritionPlan']),
  },
};
</script>

import Request from './Request';
import Api from './Api';
import Helpers from '../js/helpers';

const fertilizationStatus = {
  ACTIVE: 'ACTIVO',
  CANCELLED: 'CANCELADO',
  PLANNED: 'PLANEADO',
};

const fertilizationPeriod = {
  MONTHLY: 'MENSUAL',
  WEEKLY: 'SEMANAL',
};

const fertilizationService = {

  async getAll(companyId, plantationId) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/fertilization?plantation=${plantationId}`,
      { data: {} },
      'GET', false,
      'application/json',
      Api.getHeaderAuth(),
      companyId);
    return JSON.parse(xhr.response);
  },
  async getById(id, companyId) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/fertilization/${id}`,
      { data: {} },
      'GET', false,
      'application/json',
      Api.getHeaderAuth(),
      companyId);
    return JSON.parse(xhr.response);
  },
  async getLast(companyId, plantationId) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/fertilization?plantation=${plantationId}&latest=true`,
      { data: {} },
      'GET', false,
      'application/json',
      Api.getHeaderAuth(),
      companyId);
    return JSON.parse(xhr.response);
  },
  async edit(id, dataPayload, companyId) {
    try {
      const xhr = await Request.async(`${Api.getBaseUrl()}/fertilization/${id}`,
        { data: JSON.stringify(dataPayload) },
        'PUT', false,
        'application/json',
        Api.getHeaderAuth(),
        companyId);
      return Promise.resolve().then(JSON.parse(xhr.response));
    } catch (error) {
      return Promise.reject(Helpers.getError(error));
    }
  },
  async delete(id, companyId) {
    return await Request.async(`${Api.getBaseUrl()}/fertilization/${id}`,
      { data: {} },
      'DELETE', false,
      'application/json',
      Api.getHeaderAuth(),
      companyId);
  },
  async save(dataPayload, companyId) {
    try {
      const xhr = await Request.async(`${Api.getBaseUrl()}/fertilization`,
        { data: JSON.stringify(dataPayload) },
        'POST', false,
        'application/json',
        Api.getHeaderAuth(),
        companyId);
      return Promise.resolve().then(JSON.parse(xhr.response));
    } catch (error) {
      return Promise.reject(Helpers.getError(error));
    }
  },

};

export default fertilizationService;

export { fertilizationService, fertilizationStatus, fertilizationPeriod };

<template>
  <section class="nutritional-needs">
    <div class="content-devx">
      <div class="card-header">
        {{ $t('nutritionalNeeds.title') }}
      </div>
      <div class="card-body">
        <div class="dx-field margin-top-half">
          <div class="dx-field-label no-padding">
            <span>{{ $t('nutritionalNeeds.productionInput') }}</span>
          </div>
          <div class="dx-field-value-static no-padding">
            <DxNumberBox
              v-model="stepData.expectedProduction"
              @keyUp="onKeyUpExpectedProduction"
            />
          </div>
        </div>
        <DxDataGrid
          class="data-grid-reference-value"
          :data-source="currentNutritionalNeeds"
          :show-borders="true"
          :on-editing-start="onEditingStart"
          @row-updated="cleanNutritionalBalanceData"
        >
          <DxEditing
            :allow-updating="true"
            mode="cell"
          />
          <DxColumn caption="">
            <DxColumn
              :caption="$t('nutritionalNeeds.productionInput')"
              data-field="production"
              data-type="number"
              :allow-editing="false"
              :allow-sorting="false"
            />
          </DxColumn>
          <DxColumn
            alignment="center"
            :caption="$t('nutrition_needs_info')"
          >
            <DxColumn
              :caption="$t('nutritionalNeeds.n')"
              data-field="n"
              data-type="number"
              :allow-sorting="false"
              :set-cell-value="setCellValue"
            />
            <DxColumn
              :caption="$t('nutritionalNeeds.p2o5')"
              data-field="p"
              data-type="number"
              :allow-sorting="false"
              :set-cell-value="setCellValue"
            />
            <DxColumn
              :caption="$t('nutritionalNeeds.k2o')"
              data-field="k"
              data-type="number"
              :allow-sorting="false"
              :set-cell-value="setCellValue"
            />
            <DxColumn
              :caption="$t('nutritionalNeeds.cao')"
              data-field="c"
              data-type="number"
              :allow-sorting="false"
              :set-cell-value="setCellValue"
            />
            <DxColumn
              :caption="$t('nutritionalNeeds.mgo')"
              data-field="m"
              data-type="number"
              :allow-sorting="false"
              :set-cell-value="setCellValue"
            />
            <DxColumn
              :caption="$t('nutritionalNeeds.so3')"
              data-field="s"
              data-type="number"
              :allow-sorting="false"
              :set-cell-value="setCellValue"
            />
          </DxColumn>
        </DxDataGrid>
      </div>
    </div>
  </section>
</template>
<script>
import { DxDataGrid, DxColumn, DxEditing } from 'devextreme-vue/data-grid';
import DxNumberBox from 'devextreme-vue/number-box';
import { mapState, mapActions } from 'vuex';
import Api from '../../../services/Api';

export default {
  name: 'NutritionalNeeds',
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxNumberBox,
  },
  props: [],
  data() {
    return {
      columns: [
        this.$t('nutritionalNeeds.productionInput'),
        this.$t('nutritionalNeeds.n'),
        this.$t('nutritionalNeeds.p2o5'),
        this.$t('nutritionalNeeds.k2o'),
        this.$t('nutritionalNeeds.cao'),
        this.$t('nutritionalNeeds.mgo'),
        this.$t('nutritionalNeeds.so3'),
      ],
      currentNutritionalNeeds: [],
    };
  },
  computed: {
    ...mapState('nutritionStepper', ['stepData']),
    ...mapState('referenceValueStore', ['referenceValues', 'nutritionalNeeds']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapState('Explotaciones', ['actualUser']),
  },
  async created() {
    this.$f7.preloader.show();
    await this.initData();
    this.currentNutritionalNeeds.push(this.stepData.nutritionalNeeds[1]);
    this.$f7.preloader.hide();
  },
  methods: {
    getReferenceValues() {
      if (!this.stepData.referenceValues[0].production) {
        return this.stepData.nutritionalNeedsReference;
      }
      const { production } = this.stepData.referenceValues[0];
      return {
        c: (this.stepData.referenceValues[0].c / production).toFixed(2) * 1,
        k: (this.stepData.referenceValues[0].k / production).toFixed(2) * 1,
        m: (this.stepData.referenceValues[0].m / production).toFixed(2) * 1,
        n: (this.stepData.referenceValues[0].n / production).toFixed(2) * 1,
        p: (this.stepData.referenceValues[0].p / production).toFixed(2) * 1,
        s: (this.stepData.referenceValues[0].s / production).toFixed(2) * 1,
        production,
      };
    },
    onEditingStart(e) {
      this.currentCellDataField = e.column.dataField;
    },
    setStepDataNutrionalNeeds(data, percentage, production) {
      this.stepData.nutritionalNeeds[0] = {
        n: (parseFloat(data.n) / percentage) / production,
        p: (parseFloat(data.p) / percentage) / production,
        k: (parseFloat(data.k) / percentage) / production,
        c: (parseFloat(data.c) / percentage) / production,
        m: (parseFloat(data.m) / percentage) / production,
        s: (parseFloat(data.s) / percentage) / production,
        expected_production: 1,
        production: 1,
      };
    },
    setCellValue(newData, value) {
      newData[this.currentCellDataField] = parseFloat(Math.abs(value).toFixed(1));

      const allReduction = this.stepData.dosageProduct.map((i) => i?.reduction_percentage ?? 0);
      const fertigationReduction = allReduction.length ? Math.max(...allReduction) : 0;

      const percentage = fertigationReduction > 0 ? (1 - fertigationReduction / 100) : 1;
      const data = { ...this.stepData.nutritionalNeeds[1], ...newData };
      const production = parseFloat(this.stepData.expectedProduction);

      this.setStepDataNutrionalNeeds(data, percentage, production);
    },
    async initData() {
      if (this.stepData.expectedProduction == null) {
        const xhr = await Api.getPlantationById(
          this.actualUser.id,
          this.actualPlantation.id,
        );
        const plantation = JSON.parse(xhr.response);

        this.stepData.expectedProduction = plantation != null
          ? parseFloat(plantation.expected_production ?? 1)
          : 1;
      }

      if (
        this.stepData.nutritionalNeeds
        && this.stepData.nutritionalNeeds.length > 0
      ) {
        this.stepData.expectedProduction = parseFloat(this.stepData.nutritionalNeeds[1].production);
        return;
      }

      // Si no hay un registro en el array de 'this.nutritionalNeeds',
      //   se dispara un error al añadir los datos al stepData.
      // El arreglo más sencillo es crear un objeto de nutrición vacío.
      let firstNeed = this.nutritionalNeeds[0];
      if (typeof firstNeed === 'undefined') {
        firstNeed = {
          n: 0,
          p: 0,
          k: 0,
          c: 0,
          m: 0,
          s: 0,
        };
      }

      // this.stepData.dosageProduct fertigationReduction

      const allReduction = this.stepData.dosageProduct.map((i) => i?.reduction_percentage ?? 0);
      const fertigationReduction = allReduction.length ? Math.max(...allReduction) : 0;

      const percentage = 1 - fertigationReduction / 100;

      this.stepData.nutritionalNeeds.push(firstNeed);
      this.stepData.nutritionalNeeds.push({
        id: null,
        production: this.stepData.expectedProduction,
        n: this.roundValue(firstNeed.n * this.stepData.expectedProduction * percentage),
        p: this.roundValue(firstNeed.p * this.stepData.expectedProduction * percentage),
        k: this.roundValue(firstNeed.k * this.stepData.expectedProduction * percentage),
        c: this.roundValue(firstNeed.c * this.stepData.expectedProduction * percentage),
        m: this.roundValue(firstNeed.m * this.stepData.expectedProduction * percentage),
        s: this.roundValue(firstNeed.s * this.stepData.expectedProduction * percentage),
      });
    },
    async onKeyUpExpectedProduction(e) {
      this.stepData.nutritionalBalanceData = [];
      this.stepData.nutritionalNeeds[0] = this.getReferenceValues();
      let value = parseFloat(parseFloat(e.event.target.value).toFixed(1));

      if (Number.isNaN(value)) {
        value = 0;
        this.stepData.expectedProduction = 0;
      }

      this.stepData.nutritionalNeeds[1].production = value;
      this.calculateValue(this.stepData.nutritionalNeeds, 'n', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 'p', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 'k', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 'c', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 'm', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 's', value);
    },
    cleanNutritionalBalanceData() {
      this.stepData.nutritionalBalanceData = [];
    },
    calculateValue(array, param, value) {
      const allReduction = this.stepData.dosageProduct.map((i) => i?.reduction_percentage ?? 0);
      const fertigationReduction = allReduction.length ? Math.max(...allReduction) : 0;

      const percentage = fertigationReduction > 0 ? 1 - fertigationReduction / 100 : 1;

      array[1][param] = this.roundValue(array[0][param] * value * percentage, 1);
    },

    roundValue(val, decimals = 2) {
      return parseFloat(val.toFixed(decimals));
    },
  },
};
</script>
<style src="./nutritionalNeeds.scss" scoped lang="scss"></style>

<template>
  <f7-page>
    <navbar
      :text="`${currentPlantation.company} >
        ${currentPlantation.explotation} >
        ${currentPlantation.parcel} >
        ${currentPlantation.sector} >
        ${currentPlantation.name} >
        ${$t('Utilities.nutrition')} >
        ${$t('copy')}`
      "
    />

    <PlantationsSelector
      v-if="loaded"
      :left-date-limit="startDate"
      :right-date-limit="endDate"
      :extra-params="extraParams"
      @updatePlantationsSelection="updatePlantationsSelection"
    />
    <div
      v-else
      class="content-devx placeholder-reference-plan-copy-table"
    >
      <Spinner />
    </div>

    <div class="footer-buttons-page">
      <div>
        <f7-button
          class="dx-btn-cancel btnBack"
          type="default"
          text-color="grey"
          styling-mode="outlined"
          @click="goBack"
        >
          {{ $t('Go_Back') }}
        </f7-button>
      </div>
      <div>
        <f7-button
          class="dx-btn-cancel btn-width-150 standard-blue-btn"
          raised
          type="success"
          styling-mode="contained"
          :disabled="
            copying || plantationsSelection.length === 0
          "
          @click="copyPlans"
        >
          <Spinner
            v-if="copying"
            size="30px"
            color="white"
          />
          <span v-else>{{ $t('continue') }}</span>
        </f7-button>
      </div>
    </div>
  </f7-page>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import PlantationsSelector from '../../../components/utilities/referencePlans/copy/PlantationsSelector.vue';
import Spinner from '../../../components/spinner.vue';
import Api from '../../../services/Api';

export default {
  name: 'NutritionPlansCopy',
  components: { navbar, PlantationsSelector, Spinner },
  props: {},
  data() {
    return {
      id: null,
      duration: null,
      startDate: null,
      endDate: null,
      plantationsSelection: [],
      loaded: false,
      copying: false,
      extraParams: {
        species: this.$f7route.query.species,
        status: ['ACTIVA', 'PENDIENTE'],
      }
    };
  },
  computed: {
    ...mapState('Plantation', ['plantations', 'currentPlantation']),
  },
  async mounted() {
    this.id = this.$f7route.params.id;
    this.duration = this.$f7route.query.duration;
    this.startDate = this.$f7route.query.date;
    this.endDate = moment(this.startDate)
      .add(this.duration, 'days')
      .format('YYYY-MM-DD');
    this.loaded = true;
  },
  methods: {
    updatePlantationsSelection(newPlantationsSelection) {
      this.plantationsSelection = newPlantationsSelection;
    },
    copyPlans() {
      this.copying = true;

      const data = {
        nutritional_plan_id: this.id,
        plantation_ids: this.plantationsSelection.map((plantation) => plantation.id),
        date_init: moment(this.startDate).format('YYYY-MM-DD'),
      };

      Api.copyNutritionalPlan(data)
        .then(() => {
          this.$notifyDX(
            {
              message: 'Nutrition plan copied successfully',
              width: 550,
            },
            'success',
            5000,
          );

          this.$f7router.navigate({
            name: 'utilities_nutrition_nutrition-detail',
            params: {
              id: this.id,
            },
          });
        })
        .catch((error) => {
          this.$notifyDX(
            {
              message: error,
              width: 550,
            },
            'error',
            5000,
          );
        })
        .finally(() => {
          this.copying = false;
        });
    },
    goBack() {
      this.$f7router.navigate({
        name: 'utilities_nutrition_nutrition-detail',
        params: {
          id: this.id,
        },
      });
    },
    ...mapActions('Plantation', ['fetchPlantations']),
  },
};
</script>

<style lang="scss">
.placeholder-reference-plan-copy-table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 456px;

  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>

<template>
  <section class="nutrition-detail">
    <PopupSelectObservationsReport
      v-if="showSelectObservationsReport"
      :hide-filter="!isNew"
      :type-action="typeAction"
      :irrigation-table="!showIrrigationTable"
      @close="closeSelectObservationsReport"
      @download="downloadReport"
      @share="shareReport"
    />
    <ReportNotify
      v-show="reportMessage.length > 0"
      :message="reportMessage"
    />
    <div
      class="content-devx margin-half"
      style="padding: 0"
    >
      <div style="display: flex; justify-content: space-between; padding: 4px">
        <div
          class="main-title-content"
          style="padding: 9px"
        >
          {{ $t('nutritionDetail.nutritionDetail.title') }}
        </div>
        <div>
          <Spinner
            v-if="creatingPlan"
            size="32px"
          />
          <contextMenu
            v-else
            :items="items"
          />
        </div>
      </div>

      <div style="width: 100%; height: 1px; background: #e4e6ed" />

      <div class="card-body main content-separatos">
        <div class="field">
          <span class="field-label">{{
            $t('nutritionDetail.nutritionDetail.description')
          }}</span>
          <span class="field-value">{{ fertilization.description }}</span>
        </div>
        <div class="field">
          <span class="field-label">{{
            $t('nutritionDetail.nutritionDetail.initDate')
          }}</span>
          <span class="field-value">{{
            formatingDate(fertilization.init_date)
          }}</span>
        </div>
        <div class="field">
          <span class="field-label">{{
            $t('nutritionDetail.nutritionDetail.endDate')
          }}</span>
          <span class="field-value">{{
            formatingDate(fertilization.end_date)
          }}</span>
        </div>
        <div class="field">
          <span class="field-label">{{
            $t('nutritionDetail.nutritionDetail.status')
          }}</span>
          <span class="field-value">
            <span :class="getClassStatus(fertilization.status)">{{
              getNameStatus(fertilization.status)
            }}</span>
          </span>
        </div>
        <div class="field">
          <span class="field-label">{{
            $t('nutritionDetail.nutritionDetail.expectedProduction')
          }}</span>
          <span
            class="field-value"
          >{{ fertilization.nutritional_needs.production }} t/ha</span>
        </div>
        <div class="field">
          <span
            class="field-label"
          >{{ $t('nutritionDetail.nutritionDetail.area') }}
          </span>
          <span
            class="field-value"
          >{{ formatNumber(fertilization.area) }} ha</span>
        </div>
        <div class="field">
          <span class="field-label">{{
            $t('nutritionDetail.nutritionDetail.textureSoil')
          }}</span>
          <span class="field-value">{{
            $helpers.getProperty(
              plantationProperty.farms[0].properties,
              'ground_texture'
            )
          }}</span>
        </div>
        <div class="field">
          <span class="field-label">{{
            $t('nutritionDetail.nutritionDetail.irrigationSystem')
          }}</span>
          <span class="field-value">{{
            $helpers.getProperty(
              plantationProperty.farms[0].properties,
              'irrigation_type'
            )
          }}</span>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import numeral from 'numeral';
import moment from 'moment';

import { mapActions, mapState } from 'vuex';
import { Device } from 'framework7/framework7-lite.esm.bundle';
import contextMenu from '@/components/contextMenu/index.vue';
import Spinner from '@/components/spinner.vue';
import PopupSelectObservationsReport from '@/components/reports/popupSelectObservationsReport.vue';
import { fertilizationStatus } from '@/services/fertilizationService';
import ProductDataFormatter from '@/pages/wizardNutrition/report-formatter/product-data-formatter';
import ReportNotify from '@/components/reportNotify/index.vue';
import PDFMaker from '../../../js/pdf-maker-plans.js';
import Api from '../../../services/Api';
import { getTimeValues } from '../../../js/utils/timeValuesUtils';

const numeralFormat = '0,0.00';

export default {
  name: 'NutritionDetail',
  components: {
    contextMenu,
    PopupSelectObservationsReport,
    ReportNotify,
    Spinner,
  },
  props: {
    fertilization: {
      type: Object,
      default: () => {},
    },
    plantationProperty: {
      type: Object,
      default: () => {},
    },
    hideReportButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productResume: [],
      showSelectObservationsReport: false,
      typeAction: 'download',
      pdfmaker: null,
      reportMessage: '',
      downloading: false,
      creatingPlan: false,
    };
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('nutritionStepper', ['isNew']),
    showIrrigationTable() {
      return (
        this.fertilization?.periods_irrigation &&
        this.fertilization.periods_irrigation.every(value => value === 0)
      ) ?? true;
    },
    items() {
      const isAdmin = this.user?.user?.contact?.admin ?? false;
      const copyAsReferencePlan = (isAdmin && this.isNew)
        ? [
          {
            icon: 'save',
            text: this.$t('ContextMenu.saveReferencePlan'),
            onItemClick: this.copyAsReferencePlan,
          },
        ]
        : [];

      if (this.hideReportButton) {
        return [
          {
            icon: 'copy',
            text: this.$t('ContextMenu.Copy'),
            // onItemClick: this.goToViewCopyNutritionPlan,
            onItemClick: () => {
              this.$emit('open-copy-modal');
            },
          },
          {
            icon: 'edit',
            text: this.$t('ContextMenu.Edit'),
            onItemClick: this.editItem,
          },
          ...copyAsReferencePlan,
        ];
      }

      return [
        {
          icon: 'share',
          text: this.$t('ContextMenu.Share'),
          onItemClick: this.viewReportShare,
        },
        {
          icon: 'download',
          text: this.$t('ContextMenu.Download'),
          onItemClick: this.viewReportDownload,
        },
        {
          icon: 'copy',
          text: this.$t('ContextMenu.Copy'),
          // onItemClick: this.goToViewCopyNutritionPlan,
          onItemClick: () => {
            this.$emit('open-copy-modal');
          },
        },
        {
          icon: 'edit',
          text: this.$t('ContextMenu.Edit'),
          onItemClick: this.editItem,
        },
        ...copyAsReferencePlan,
      ];
    },
    fertilizationBalance() {
      const object1 = this.getUfsTotal();
      const object2 = this.fertilization.nutritional_needs;

      return {
        n: object1.n - object2.n,
        p205: object1.p - object2.p205,
        k20: object1.k - object2.k20,
        ca0: object1.c - object2.ca0,
        mg0: object1.m - object2.mg0,
        s03: object1.s - object2.s03,
      };
    },
    calculateUfContributed() {
      const ufContributed = {
        n: 0,
        p: 0,
        k: 0,
        c: 0,
        m: 0,
        s: 0,
      };

      this.fertilization.contribution_products.forEach((item) => {
        const totalPeriods = item.periods.reduce((a, b) => a + b);
        ufContributed.n += this.calculateElementBalance(item.n, totalPeriods);
        ufContributed.p += this.calculateElementBalance(
          item.p205,
          totalPeriods,
        );
        ufContributed.k += this.calculateElementBalance(item.k20, totalPeriods);
        ufContributed.c += this.calculateElementBalance(item.ca0, totalPeriods);
        ufContributed.m += this.calculateElementBalance(item.mg0, totalPeriods);
        ufContributed.s += this.calculateElementBalance(item.s03, totalPeriods);
      });

      return ufContributed;
    },
    calculateFinalBalance() {
      const a = this.fertilizationBalance;
      const b = this.calculateUfContributed;

      return {
        n: parseFloat((a.n + b.n).toFixed(3)),
        p205: parseFloat((a.p205 + b.p).toFixed(3)),
        k20: parseFloat((a.k20 + b.k).toFixed(3)),
        ca0: parseFloat((a.ca0 + b.c).toFixed(3)),
        mg0: parseFloat((a.mg0 + b.m).toFixed(3)),
        s03: parseFloat((a.s03 + b.s).toFixed(3)),
      };
    },
  },
  async mounted() {
    const formatter = new ProductDataFormatter(this.fertilization);
    try {
      await formatter.buildProductResumeData();
    } catch (e) {
      const notFoundNutritionProducts = JSON.parse(e.message);
    }
    this.productResume = formatter.productResume;
  },
  methods: {
    ...mapActions('Plantation', ['setIsPlantationSelected']),
    ...mapActions('Nutrition', ['setEditingFromContextMenu']),
    ...mapActions('nutritionStepper', ['setIsEditing', 'setIsNew']),
    toIntnteger(value) {
      if (value != null) {
        return parseInt(value, 10);
      }

      return null;
    },
    getNameStatus(status) {
      return this.$t(status?.toUpperCase()) ?? '';
    },
    goToViewCopyNutritionPlan() {
      this.$f7.views.main.router.navigate('/viewCopyNutritionPlan/', {
        reloadCurrent: true,
      });
    },
    getClassStatus(status) {
      switch (status) {
        case fertilizationStatus.ACTIVE:
          return 'Activos';
        case fertilizationStatus.CANCELLED:
          return 'label-danger';
        default:
        case fertilizationStatus.PLANNED:
          return 'Planificado';
      }
    },
    formatNumber(number) {
      return numeral(number).format(numeralFormat);
    },
    formatingDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    viewReportDownload() {
      if (this.downloading) return;
      this.typeAction = 'download';
      this.openSelectObservationsReport();
    },
    viewReportShare() {
      if (this.downloading) return;
      this.typeAction = 'share';
      this.openSelectObservationsReport();
    },
    async generatePDF(selectedObservations) {
      const AtcName = `${this.user.user.contact.name} ${this.user.user.contact.surname}`;

      this.pdfmaker.insertReportTitle('PLAN DE NUTRICIÓN');

      this.pdfmaker.insertTableWithoutBorders([
        [
          {
            title: this.$t('DxClients_client'),
            text: this.currentPlantation.company,
          },
          {
            title: this.$t('searchEngine_farm'),
            text: this.currentPlantation.explotation,
          },
          {
            title: this.$t('Parcel'),
            text: this.currentPlantation.parcel,
          },
        ],
        [
          {
            title: this.$t('Sector'),
            text: this.currentPlantation.sector,
          },
          {
            title: this.$t('Plantation'),
            text: this.currentPlantation.name,
          },
          {
            title: this.$t('nutritionDetail.report.area'),
            text: `${this.fertilization.area.toFixed(2)} ha`,
          },
        ],
        [
          {
            title: this.$t('nutritionDetail.report.description'),
            text: this.fertilization.description,
          },
          {
            title: this.$t('nutritionDetail.report.cropType'),
            text: this.currentPlantation.species,
          },
          {
            title: this.$t('nutritionDetail.report.variety'),
            text: this.currentPlantation.variety,
          },
        ],
        [
          {
            title: this.$t('nutritionDetail.report.date'),
            text: `${moment(this.fertilization.init_date).format(
              'DD/MM/YYYY',
            )} - ${moment(this.fertilization.end_date).format('DD/MM/YYYY')}`,
          },
          {
            title: '',
            text: '',
          },
          {
            title: '',
            text: '',
          },
        ],
      ]);

      this.pdfmaker.insertTableGeneric('RESUMEN POR ELEMENTOS', [
        [
          this.$t('nutritionDetail.elementResume.uf'),
          this.$t('nutritionDetail.elementResume.n'),
          // this.$t('nutritionDetail.elementResume.p'),
          'P2O5',
          // this.$t('nutritionDetail.elementResume.k'),
          'K2O',
          this.$t('nutritionDetail.elementResume.c'),
          this.$t('nutritionDetail.elementResume.m'),
          // this.$t('nutritionDetail.elementResume.s'),
          'SO3',
        ],
        [
          this.$t('nutritionDetail.elementResume.necNutritional'),
          this.fertilization.nutritional_needs.n,
          this.fertilization.nutritional_needs.p205,
          this.fertilization.nutritional_needs.k20,
          this.fertilization.nutritional_needs.ca0,
          this.fertilization.nutritional_needs.mg0,
          this.fertilization.nutritional_needs.s03,
        ],
        [
          this.$t('nutritionDetail.elementResume.fertilizationBalance'),
          this.fertilizationBalance.n,
          this.fertilizationBalance.p205,
          this.fertilizationBalance.k20,
          this.fertilizationBalance.ca0,
          this.fertilizationBalance.mg0,
          this.fertilizationBalance.s03,
        ],
        [
          this.$t('nutritionDetail.elementResume.ufContributed'),
          this.calculateUfContributed.n,
          this.calculateUfContributed.p,
          this.calculateUfContributed.k,
          this.calculateUfContributed.c,
          this.calculateUfContributed.m,
          this.calculateUfContributed.s,
        ],
        [
          this.$t('nutritionDetail.elementResume.finalBalance'),
          this.calculateFinalBalance.n,
          this.calculateFinalBalance.p205,
          this.calculateFinalBalance.k20,
          this.calculateFinalBalance.ca0,
          this.calculateFinalBalance.mg0,
          this.calculateFinalBalance.s03,
        ],
      ]);

      const graph = document.querySelector('.product-chart');

      const productsResumeTable = [];

      this.productResume.forEach((product) => {
        productsResumeTable.push([
          product.product,
          product.dosageUnit,
          product.dosagePlant,
          product.price,
          product.costUnit,
          product.costPlant,
          product.dosageTotal,
          product.costTotal,
        ]);
      });

      await this.pdfmaker.insertTableAndGraph(
        'RESUMEN POR PRODUCTOS',
        [
          [
            this.$t('nutritionDetail.productResume.product'),
            this.$t('nutritionDetail.productResume.dosageUnit'),
            this.$t('nutritionDetail.productResume.dosagePlant'),
            this.$t('nutritionDetail.productResume.price'),
            this.$t('nutritionDetail.productResume.costUnit'),
            this.$t('nutritionDetail.productResume.costPlant'),
            this.$t('nutritionDetail.productResume.dosageTotal'),
            this.$t('nutritionDetail.productResume.costTotal'),
          ],
          ...productsResumeTable,
        ],
        graph,
      );

      if (this.fertilization?.contribution_products?.length > 0) {
        const productsNames = this.fertilization.contribution_products.map((product) => {
          const isLiquid = product.presentation === 'LIQUIDO';
          const unit = isLiquid ? 'l/ha' : 'kg/ha';
          return `${product.product_name} (${unit})`;
        });

        this.pdfmaker.insertTableGeneric(
          'APLICACIÓN DE PRODUCTOS EN CADA PERIODO KG-L/HA',
          [
            [
              'Productos fertilizantes',
              ...productsNames,
            ],
            ...(this.productsResumeTable()),
          ],
          false,
          '',
          true,
        );
      }

      const observations = await Promise.all(
        selectedObservations.map(async (item, index) => {
          const xhr = await Api.getObservationDetail(
            this.currentPlantation.companyId,
            item.id,
          );
          const observation = JSON.parse(xhr.response);

          const comment = [];

          observation.template_form.forEach((element) => {
            if (
              observation[element.field] !== null
              && observation[element.field]?.length > 0
            ) {
              comment.push([element.description, observation[element.field]]);
            }
          });

          return {
            id: observation.id,
            title: `Observación ${index + 1}:`,
            date: observation.date,
            multimedia: await Promise.all(
              observation.multimedia
                .filter(
                  (extension) => extension.extension === '.jpg'
                    || extension.extension === '.png'
                    || extension.extension === '.JPG'
                    || extension.extension === '.PNG',
                )
                .map(async (image) => {
                  image.base64 = await this.imageToBase64(image.attachment);
                  return image;
                }),
            ),
            comment,
          };
        }),
      );

      const productsObservations = this.fertilization.contribution_products
        .map((item) => ({
          name: item.product_name,
          annotation: item.observation ?? '',
        }))
        .filter((item) => item.annotation.length > 0);

      if (
        observations.length > 0
        || productsObservations.length > 0
        || (this.fertilization.observation !== ''
          && this.fertilization.observation != null)
      ) {
        this.pdfmaker.insertObservations(
          this.fertilization.observation,
          productsObservations,
          [...observations],
        );
      }

      this.pdfmaker.insertTableBottomSignature([
        [
          ['Nombre del ATC', AtcName],
          ['Fecha', moment().format('DD/MM/YYYY')],
          ['Firmado', ''],
        ],
      ]);
    },
    async generateNewPDF(selectedObservations, filters) {
      const AtcName = `${this.user.user.contact.name} ${this.user.user.contact.surname}`;

      this.pdfmaker.insertReportTitle('PLAN DE NUTRICIÓN NUEVO');

      this.pdfmaker.insertTableWithoutBorders([
        [
          {
            title: this.$t('DxClients_client'),
            text: this.currentPlantation.company,
          },
          {
            title: this.$t('searchEngine_farm'),
            text: this.currentPlantation.explotation,
          },
          {
            title: this.$t('Parcel'),
            text: this.currentPlantation.parcel,
          },
        ],
        [
          {
            title: this.$t('Sector'),
            text: this.currentPlantation.sector,
          },
          {
            title: this.$t('Plantation'),
            text: this.currentPlantation.name,
          },
          {
            title: this.$t('nutritionDetail.report.area'),
            text: `${this.fertilization.area.toFixed(2)} ha`,
          },
        ],
        [
          {
            title: this.$t('nutritionDetail.report.description'),
            text: this.fertilization.description,
          },
          {
            title: this.$t('nutritionDetail.report.cropType'),
            text: this.currentPlantation.species,
          },
          {
            title: this.$t('nutritionDetail.report.variety'),
            text: this.currentPlantation.variety,
          },
        ],
        [
          {
            title: this.$t('nutritionDetail.report.date'),
            text: `${moment(this.fertilization.init_date).format(
              'DD/MM/YYYY',
            )} - ${moment(this.fertilization.end_date).format('DD/MM/YYYY')}`,
          },
          {
            title: '',
            text: '',
          },
          {
            title: '',
            text: '',
          },
        ],
      ]);

      const resumePerElements = [
        [
          this.$t('nutritionDetail.elementResume.uf'),
          this.$t('nutritionDetail.elementResume.n'),
          { text: ['P', { text: '2', sub: {} }, 'O', { text: '5', sub: {} }] },
          { text: ['K', { text: '2', sub: {} }, 'O'] },
          this.$t('nutritionDetail.elementResume.c'),
          this.$t('nutritionDetail.elementResume.m'),
          { text: ['SO', { text: '3', sub: {} }] },
        ],
      ];

      if (filters.nutritionalNeeds) {
        resumePerElements.push([
          this.$t('nutritionDetail.elementResume.necNutritional'),
          this.toIntnteger(this.fertilization.nutritional_needs.n),
          this.toIntnteger(this.fertilization.nutritional_needs.p205),
          this.toIntnteger(this.fertilization.nutritional_needs.k20),
          this.toIntnteger(this.fertilization.nutritional_needs.ca0),
          this.toIntnteger(this.fertilization.nutritional_needs.mg0),
          this.toIntnteger(this.fertilization.nutritional_needs.s03),
        ]);
      }

      if (filters.previousBalance) {
        resumePerElements.push([
          this.$t('nutritionDetail.elementResume.fertilizationBalance'),
          this.toIntnteger(this.fertilizationBalance.n),
          this.toIntnteger(this.fertilizationBalance.p205),
          this.toIntnteger(this.fertilizationBalance.k20),
          this.toIntnteger(this.fertilizationBalance.ca0),
          this.toIntnteger(this.fertilizationBalance.mg0),
          this.toIntnteger(this.fertilizationBalance.s03),
        ]);
      }

      if (filters.ufTheoretical) {
        resumePerElements.push([
          'UF teóricas',
          this.toIntnteger(this.fertilization.uf_teoric.n),
          this.toIntnteger(this.fertilization.uf_teoric.p205),
          this.toIntnteger(this.fertilization.uf_teoric.k20),
          this.toIntnteger(this.fertilization.uf_teoric.ca0),
          this.toIntnteger(this.fertilization.uf_teoric.mg0),
          this.toIntnteger(this.fertilization.uf_teoric.s03),
        ]);
      }

      if (filters.ufEfficient) {
        resumePerElements.push([
          'UF eficientes aportadas',
          this.toIntnteger(this.fertilization.uf_efficiency_provided.n),
          this.toIntnteger(this.fertilization.uf_efficiency_provided.p205),
          this.toIntnteger(this.fertilization.uf_efficiency_provided.k20),
          this.toIntnteger(this.fertilization.uf_efficiency_provided.ca0),
          this.toIntnteger(this.fertilization.uf_efficiency_provided.mg0),
          this.toIntnteger(this.fertilization.uf_efficiency_provided.s03),
        ]);
      }

      if (filters.finalBalance) {
        resumePerElements.push([
          this.$t('nutritionDetail.elementResume.finalBalance'),
          this.toIntnteger(this.fertilization.final_balance.n),
          this.toIntnteger(this.fertilization.final_balance.p205),
          this.toIntnteger(this.fertilization.final_balance.k20),
          this.toIntnteger(this.fertilization.final_balance.ca0),
          this.toIntnteger(this.fertilization.final_balance.mg0),
          this.toIntnteger(this.fertilization.final_balance.s03),
        ]);
      }

      if (resumePerElements.length > 1) {
        this.pdfmaker.insertTableGeneric(
          'RESUMEN POR ELEMENTOS',
          resumePerElements,
        );
      }

      const productsResumeTable = [];

      const showPlant = this.plantationProperty.crop_type !== 'HERBÁCEO';

      const graph = document.querySelector('.product-chart');

      this.productResume.forEach((product) => {
        const rowData = [
          product.product,
          product.dosageUnitKg,
          product.dosageUnitL,
        ];

        if (showPlant) {
          if (filters.resumeProductsComplete) {
            rowData.push(
              product.dosagePlantKg,
              product.dosagePlantL,
              product.dosageTotalKg,
              product.dosageTotalL,
              product.priceKg,
              product.priceL,
              product.costUnit
            );
            if (!filters.resumeProductsNoCost) {
              rowData.push(product.costPlant, product.costTotal);
            }
          } else if (filters.resumeProductsNoCost) {
            rowData.push(
              product.dosagePlantKg,
              product.dosagePlantL,
              product.dosageTotalKg,
              product.dosageTotalL
            );
          }
        } else {
          if (filters.resumeProductsComplete) {
            rowData.push(
              product.dosageTotalKg,
              product.dosageTotalL,
              product.priceKg,
              product.priceL,
              product.costUnit
            );
            if (!filters.resumeProductsNoCost) {
              rowData.push(product.costTotal);
            }
          } else if (filters.resumeProductsNoCost) {
            rowData.push(product.dosageTotalKg, product.dosageTotalL);
          }
        }
        productsResumeTable.push(rowData);
      });

      if (this.fertilization?.contribution_products?.length > 0)  {
        this.pdfmaker.insertResumePerProduct(
          'RESUMEN POR PRODUCTOS',
          productsResumeTable,
          showPlant,
          filters.resumeProductsComplete,
          filters.resumeProductsNoCost
        );
      }

      if (filters.productsAplication && this.fertilization?.contribution_products?.length > 0) {
        const productsNames = this.fertilization.contribution_products.map((product) => {
        const isLiquid = product.presentation === 'LIQUIDO';
        const unit = isLiquid ? 'l/ha' : 'kg/ha';
        return `${product.product_name} (${unit})`;
        });

        this.pdfmaker.insertTableGeneric(
          'APLICACIÓN DE PRODUCTOS EN CADA PERIODO KG-L/HA',
          [
            [
              'Productos fertilizantes',
              ...productsNames,
            ],
            ...(this.productsResumeTable()),
          ],
          false,
          '',
          true
        );
      }

      if (filters.irrigationTable) {
        const title = 'TABLA DE RIEGO';
        // Obtener los datos para la tabla y los productos
        const { tableData, productNames, period } = this.productsIrrigationTable();
        
        this.pdfmaker.insertIrrigationTable(title, tableData, productNames, period);
      }

      if (filters.productsAplicationTotals && this.fertilization?.contribution_products?.length > 0) {
        const productsNamesTotals = this.fertilization.contribution_products.map((product) => {
          return product.presentation === 'LIQUIDO' ? `${product.product_name} (l)` : `${product.product_name} (kg)`;
        });

        this.pdfmaker.insertTableGeneric(
          'APLICACIÓN DE PRODUCTOS EN CADA PERIODO KG-L TOTALES',
          [
            [
              'Productos fertilizantes',
              ...productsNamesTotals,
            ],
            ...(this.productsResumeTableTotal()),
          ],
          false,
          '',
          true,
        );
      }

      if (filters.resumeProductsGraphics) {
        await this.pdfmaker.insertGenericChart('RESUMEN POR PRODUCTOS', graph);
      }

      if (filters.ufEfficient && filters.ufEfficientTable) {
        this.pdfmaker.insertTableGeneric(
          'APLICACIÓN DE UF EFICIENTES EN CADA PERIODO',
          [
            [
              'Uf eficientes (kg/ha)',
              'N',
              {
                text: [
                  'P',
                  { text: '2', sub: {} },
                  'O',
                  { text: '5', sub: {} },
                ],
              },
              { text: ['K', { text: '2', sub: {} }, 'O'] },
            ],
            ...(await this.applicationsOfEfficiencyPerPeriod()),
          ],
          false,
          '',
          true,
        );
      }

      if (filters.ufEfficientGraph) {
        const graphApplication = document.querySelector(
          '#application-efficiency-per-period-chart',
        );

        await this.pdfmaker.insertGenericChart(
          'APLICACIÓN DE UF EFICIENTES EN CADA PERIODO',
          graphApplication,
        );
      }

      const observations = await Promise.all(
        selectedObservations.map(async (item, index) => {
          const xhr = await Api.getObservationDetail(
            this.currentPlantation.companyId,
            item.id,
          );
          const observation = JSON.parse(xhr.response);

          const comment = [];

          observation.template_form.forEach((element) => {
            if (
              observation[element.field] !== null
              && observation[element.field]?.length > 0
            ) {
              comment.push([element.description, observation[element.field]]);
            }
          });

          return {
            id: observation.id,
            title: `Observación ${index + 1}:`,
            date: observation.date,
            multimedia: await Promise.all(
              observation.multimedia
                .filter(
                  (extension) => extension.extension === '.jpg'
                    || extension.extension === '.png'
                    || extension.extension === '.JPG'
                    || extension.extension === '.PNG',
                )
                .map(async (image) => {
                  image.base64 = await this.imageToBase64(image.attachment);
                  return image;
                }),
            ),
            comment,
          };
        }),
      );

      const productsObservations = this.fertilization.contribution_products
        .map((item) => ({
          name: item.product_name,
          annotation: item.observation ?? '',
        }))
        .filter((item) => item.annotation.length > 0);

      if (
        observations.length > 0
        || productsObservations.length > 0
        || (this.fertilization.observation !== ''
          && this.fertilization.observation != null)
      ) {
        this.pdfmaker.insertObservations(
          this.fertilization.observation,
          productsObservations,
          [...observations],
        );
      }

      this.pdfmaker.insertTableBottomSignature([
        [
          ['Nombre del ATC', AtcName],
          ['Fecha', moment().format('DD/MM/YYYY')],
          ['Firmado', ''],
        ],
      ]);
    },
    async downloadReport({ observations: selectedObservations, filters }) {
      this.closeSelectObservationsReport();
      this.downloading = true;
      this.reportMessage = this.$t('generatingPDF');

      this.pdfmaker = new PDFMaker();

      if (this.isNew) {
        await this.generateNewPDF(selectedObservations, filters);
      } else {
        await this.generatePDF(selectedObservations);
      }

      const random = moment().format('YYMMDDHHmmss');

      const title = `${this.currentPlantation.name} - ${
        this.fertilization.description
      } - ${moment().format('DD-MM-YYYY')} (${random})`;

      this.closeSelectObservationsReport();

      this.reportMessage = this.$t('downloadingPDF');

      if (Device.desktop) {
        await this.pdfmaker.buildAndDownload(title);
        this.reportMessage = '';
      } else {
        await new Promise((resolve) => {
          const pdfdata = this.pdfmaker.buildForMobile();

          pdfdata.getBase64((base64) => {
            const path = `${cordova.file.externalRootDirectory}Download/`;
            const fileExtension = '.pdf';

            this.savebase64AsPDF(
              path,
              title + fileExtension,
              base64,
              'application/pdf',
            );

            resolve();
          });
        });
      }

      // this.reportMessage = '';
      this.downloading = false;
    },
    async shareReport({ observations: selectedObservations, filters }) {
      this.closeSelectObservationsReport();
      this.downloading = true;

      this.reportMessage = this.$t('generatingPDF');

      this.pdfmaker = new PDFMaker();

      if (this.isNew) {
        await this.generateNewPDF(selectedObservations, filters);
      } else {
        await this.generatePDF(selectedObservations);
      }

      const random = moment().format('YYMMDDHHmmss');

      const title = `${this.currentPlantation.name} - ${
        this.fertilization.description
      } - ${moment().format('DD-MM-YYYY')} (${random})`;

      // this.closeSelectObservationsReport();

      this.$f7.dialog.confirm(this.$t('confirmSharePdf'), async () => {
        const buffer = await this.pdfmaker.getPdfAsBuffer();

        const pdf = new File([buffer], `${title}.pdf`, {
          type: 'application/pdf',
        });
        const files = [pdf];
        if (navigator.canShare && Device.desktop) {
            try {
              await navigator.share({
                files,
                title,
                text: '',
              });
              this.reportMessage = '';
            } catch (error) {
              this.reportMessage = '';
              this.$notifyDX(
                {
                  message: error.message,
                  width: 550,
                },
                'error',
                3000,
              );
            }
        } else if (Device.desktop && !navigator.canShare) {
            this.$notifyDX(
              {
                message: this.$t('nutritionDetail.nutritionDetail.shareError'),
                width: 550,
              },
              'error',
              3000,
            ); 
        } else {
            try {
                await new Promise((resolve) => {
                  const pdfdata = this.pdfmaker.buildForMobile();
                  pdfdata.getBase64((base64) => {
                    const path = `${cordova.file.externalRootDirectory}Download/`;
                    const fileExtension = '.pdf';
                    this.savebase64AsPDFAndShare(
                      path,
                      title + fileExtension,
                      base64,
                      'application/pdf',
                      title,
                    );

                    resolve();
                  });
                });
            } catch (error) {
              this.reportMessage = '';
              this.$notifyDX(
                {
                  message: error.message,
                  width: 550,
                },
                'error',
                3000,
              );
            }                 
          }
      });
      this.downloading = false;
      this.reportMessage = '';
    },
    editItem() {
      this.setIsEditing(true);
      this.setIsPlantationSelected(false);
      this.setEditingFromContextMenu(this.fertilization.id);
      // this.setIsNew(true);
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_nutrition_nutrition-step-one',
        params: { id: this.fertilization.id },
      });
      this.$f7.views.main.router.navigate(url, { reloadCurrent: true });

      setTimeout(() => {
        this.setIsNew(true);
      }, 1000);
    },
    copyAsReferencePlan() {
      this.creatingPlan = true;

      Api.createReferencePlan({ fertilization_plan: this.fertilization.id })
        .then(() => {
          this.$notifyDX(
            {
              message: 'Reference plan created',
              width: 550,
            },
            'success',
            5000,
          );

          // this.$f7router.navigate({
          //   name: 'reference-plans-detail',
          //   params: {
          //     id: `${this.id}`,
          //   },
          // });
        })
        .catch((error) => {
          this.$notifyDX(
            {
              message: error,
              width: 550,
            },
            'error',
            5000,
          );
        })
        .finally(() => {
          this.creatingPlan = false;
        });
    },
    openSelectObservationsReport() {
      this.showSelectObservationsReport = true;
    },
    closeSelectObservationsReport() {
      this.showSelectObservationsReport = false;
    },
    async imageToBase64(path) {
      const blob = await fetch(path).then((res) => res.blob());
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    savebase64AsPDF(folderpath, filename, content, contentType) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter(
            (fileWriter) => {
              fileWriter.write(DataBlob);
              this.reportMessage = this.$t('FileDownloadedInDownloads');

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
            () => {
              this.reportMessage = this.$t('SheetExport_sheet_export_error');

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
          );
        });
      });
    },
    savebase64AsPDFAndShare(
      folderpath,
      filename,
      content,
      contentType,
      message,
    ) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter(
            (fileWriter) => {
              fileWriter.write(DataBlob);
              const fileName = folderpath + filename;

              window.plugins.socialsharing.shareWithOptions({
                message,
                files: [fileName],
              });

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
            () => {
              this.reportMessage = this.$t('SheetExport_sheet_export_error');

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
          );
        });
      });
    },
    getUfsTotal() {
      return {
        n: this.sumUfs('n'),
        p: this.sumUfs('p205'),
        k: this.sumUfs('k20'),
        c: this.sumUfs('ca0'),
        m: this.sumUfs('mg0'),
        s: this.sumUfs('s03'),
      };
    },
    sumUfs(prop) {
      return (
        this.fertilization.uf_available_soil[prop]
        + this.fertilization.uf_irrigation_water[prop]
        + this.fertilization.uf_rest_harvest[prop]
      );
    },
    calculateElementBalance(value, price) {
      return Math.round((value / 100) * price);
    },
    async applicationsOfEfficiencyPerPeriod() {
      const ufs = {
        n: {},
        p205: {},
        k20: {},
      };

      const names = {
        n: 'N (kg/ha)',
        p205: 'P₂O₅ (kg/ha)',
        k20: 'K₂O (kg/ha)',
      };

      const FULL_EFFICIENCY = {
        n: 100,
        p: 100,
        k: 100,
        c: 100,
        m: 100,
        s: 100,
      };

      for (const product of this.fertilization.contribution_products) {
        const hasReduction = product.product_reduction_percentage > 0;
        let eficiencies = FULL_EFFICIENCY;

        if (!hasReduction) {
          eficiencies = await Api.getProductEfficiency({
            code: product?.product_code,
            tipology: product?.product_tipology,
            ph: this.fertilization.ph || 0,
          })
            .then((xhr) => {
              const data = JSON.parse(xhr.response);

              return {
                n: data.NT,
                p: data.P2015_Total,
                k: data.K2O,
                c: data.CaO,
                m: data.MgO,
                s: data.SO3,
              };
            })
            .catch(() => ({
              n: 0,
              p: 0,
              k: 0,
              c: 100,
              m: 100,
              s: 100,
            }));
        }

        product.eficiencies = eficiencies;
      }

      this.fertilization.contribution_products.forEach((product) => {
        product.periods.forEach((period, index) => {
          if (ufs.n[index] === undefined) {
            ufs.n[index] = 0;
            ufs.p205[index] = 0;
            ufs.k20[index] = 0;
          }

          ufs.n[index]
            += period * (product.n / 100) * (product.eficiencies.n / 100);
          ufs.p205[index]
            += period * (product.p205 / 100) * (product.eficiencies.p / 100);
          ufs.k20[index]
            += period * (product.k20 / 100) * (product.eficiencies.k / 100);
        });
      });

      const dataSource = Object.entries(ufs).map(([uf, values]) => ({
        element: names[uf],
        ...Object.values(values).map((value) => (value > 0 ? parseInt(value, 10) : null)),
      }));

      const invertedRowColumns = [];

      const len = Object.keys(dataSource[0]).length - 2;

      moment(this.fertilization.init_date)

      const { timeValues } = getTimeValues(
        moment(this.fertilization.init_date),
        moment(this.fertilization.end_date),
        this.fertilization.period,
        this.isReferencePlan
      );
     
      const columnNames = Object.keys(timeValues[0]);

      for (let i = 0; i <= len; i += 1) {
        const row = [];

        row.push(columnNames[i]);

        for (let j = 0; j < 3; j += 1) {
          row.push(dataSource[j][i]);
        }

        invertedRowColumns.push(row);
      }

      return invertedRowColumns;
    },
    productsResumeTable() {
      const products = [];

      this.fertilization.contribution_products.forEach((product) => {
        const isLiquid = product.presentation === 'LIQUIDO';
        const unit = isLiquid ? 'l/ha' : 'kg/ha';
        const name = `${product.product_name} (${unit})`;

        const productDose = { name };

        product.periods.forEach(async (period, index) => {
          productDose[index] = period != null ? period : 0;
        });
        products.push(productDose);
      });

      const invertedRowColumns = [];

      const len = Object.keys(products[0]).length - 1;

      const { timeValues } = getTimeValues(
        moment(this.fertilization.init_date),
        moment(this.fertilization.end_date),
        this.fertilization.period,
        this.isReferencePlan
      );

      const columnNames = Object.keys(timeValues[0]);

      for (let i = 0; i <= len; i += 1) {
        const row = [];

        row.push(columnNames[i]);

        for (const element of products) {
          row.push(element[i]);
        }

        invertedRowColumns.push(row);
      }

      return invertedRowColumns;
    },
    productsResumeTableTotal() {
      const products = [];
      const surfaceArea = this.fertilization?.area;

      this.fertilization.contribution_products.forEach((product) => {
        const name = `${product.product_name} (kg)`;

        const productDose = { name };

        product.periods.forEach(async (period, index) => {
          // Multiplicamos el valor del período por la superficie de la hectárea y redondeamos a 1 decimal si es distinto de 0.
          const calculatedValue = period != null ? (period * surfaceArea) : 0;
          const formattedValue = calculatedValue % 1 === 0 ? calculatedValue.toFixed(0) : calculatedValue.toFixed(1);

          // Verificar si el valor termina en '.0' y se quita el decimal, de lo contrario lo ponemos
          productDose[index] = formattedValue.includes('.0') ? formattedValue.replace('.0', '') : formattedValue;
        });

        products.push(productDose);
      });

      const invertedRowColumns = [];

      const len = Object.keys(products[0]).length - 1;

      const { timeValues } = getTimeValues(
        moment(this.fertilization.init_date),
        moment(this.fertilization.end_date),
        this.fertilization.period,
        this.isReferencePlan
      );

      const columnNames = Object.keys(timeValues[0]);

      for (let i = 0; i <= len; i += 1) {
        const row = [];

        row.push(columnNames[i]);

        for (const element of products) {
          row.push(element[i]);
        }

        invertedRowColumns.push(row);
      }

      return invertedRowColumns;
    },
    productsIrrigationTable() {   
      let dataSource = [];
      let productColumns = [];
      const contributionProducts = this.fertilization.contribution_products;
      const periodsIrrigation = this.fertilization.periods_irrigation;
      const periodsIrrigationTime = this.fertilization.periods_irrigation_time;
      const period = this.fertilization.period;

      // Incorporar los resultados de Object.entries(this.getTimeValues()) a cada objeto de dataSource

      const { timeValuesIrrigation } = getTimeValues(
        moment(this.fertilization.init_date),
        moment(this.fertilization.end_date),
        this.fertilization.period,
        this.isReferencePlan
      );

      const timeValuesData = Object.entries(timeValuesIrrigation[0]).map(([name, value]) => {
        return { name };
      });

      const periodData = timeValuesData;

      // Generar columnas para los nombres de productos
      contributionProducts.forEach((product, index) => {
        const column = {
          caption: product.product_name,
          alignment: 'left',
          name: product.presentation === 'LIQUIDO' ? 'l/Riego' : product.presentation === 'SOLIDO' ? 'kg/Riego' : 'l/Riego'
        };
        productColumns.push(column);

        // Asignar valores a las celdas correspondientes
        const periodsProductIrrigation = product.periods_product_irrigation;
        const periodsDose = product.periods_dose;

        periodsProductIrrigation.forEach((value, i) => {
          // Asegurarse de que la fila exista en dataSource
          if (!dataSource[i]) {
            dataSource[i] = {};
          }

          // Asignar valores a las columnas de NºRiegos y l/Riego
          dataSource[i][`periods_product_irrigation_${index}`] = periodsProductIrrigation[i];
          dataSource[i][`periods_dose_${index}`] = periodsDose[i];

          // Agregar valores de periodData al objeto de datos
          const period = periodData[i];
          Object.keys(period).forEach((key) => {
            const newDataField = period[key];
            dataSource[i][key] = newDataField;
          });
        });
      });

      // Agregar valores de periods_irrigation y periods_irrigation_time a dataSource
      periodsIrrigation.forEach((value, i) => {
        // Asegurarse de que la fila exista en dataSource
        if (!dataSource[i]) {
          dataSource[i] = {};
        }

        // Asignar valores a periods_irrigation y periods_irrigation_time
        dataSource[i]['periods_irrigation'] = value;
        dataSource[i]['periods_irrigation_time'] = periodsIrrigationTime[i];
      });

      return { tableData: dataSource, productNames: productColumns, period };
    },
  },
};
</script>
<style src="./nutritionDetail.scss" scoped lang="scss"></style>

<template>
  <section class="converter-nutritional-solutions">
    <div class="content-devx">
      <div class="card-header padding-left-half">
        {{ $t('Utilities.converterNutritionalSolutions.title') }}
      </div>
      <div class="card-body main">
        <div class="top margin-vertical padding-horizontal">
          <DxRadioGroup
            ref="rgUnit"
            class="radio-buttons"
            :data-source="itemUnitsNutritionalSolutions"
            display-expr="label"
            layout="horizontal"
            @valueChanged="onChangeUnit"
          />
        </div>
        <div class="card-header padding-left-half">
          <b>{{ $t('Utilities.converterNutritionalSolutions.bottomTitle') }}</b>
        </div>
        <div class="display-flex justify-content-space-between padding-horizontal align-items-center">
          <div class="dx-field-section justify-content-flex-start">
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('Utilities.converterNutritionalSolutions.element') }}
              </div>
              <div class="dx-field-value-static input-zoom">
                <DxNumberBox
                  ref="nbMeq"
                  v-model="unitSelected.element"
                  @keyUp="changeElement"
                />
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label text-align-right">
                {{ $t('Utilities.converterNutritionalSolutions.oxide') }}
              </div>
              <div class="dx-field-value-static input-zoom">
                <DxNumberBox
                  ref="nbMg"
                  :value="unitSelected.oxide"
                  @keyUp="changeOxide"
                />
              </div>
            </div>
          </div>
          <div class="padding-horizontal">
            <DxButton
              :text="$t('Utilities.converterNutritionalSolutions.reset')"
              type="default"
              @click="cleanData"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import DxRadioGroup from 'devextreme-vue/radio-group';
import DxNumberBox from 'devextreme-vue/number-box';
import DxButton from 'devextreme-vue/button';
import { mapState } from 'vuex';

export default {
  name: 'ConverterNutritionalSolutions',
  components: {
    DxRadioGroup,
    DxNumberBox,
    DxButton,
  },
  props: [],
  data() {
    return {
      unitSelected: { },
      unitStatic: { },
    };
  },
  computed: {
    ...mapState('Converter', ['itemUnitsNutritionalSolutions']),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.$refs.rgUnit.instance.option('value', this.itemUnitsNutritionalSolutions[0]);
      this.unitSelected = { ...this.itemUnitsNutritionalSolutions[0] };
      this.unitStatic = { ...this.itemUnitsNutritionalSolutions[0] };
    },
    cleanData() {
      this.initData();
    },
    onChangeUnit(e) {
      this.unitStatic = { ...e.value };
      this.unitSelected = { ...e.value };
    },
    changeElement(e) {
      const value = parseFloat(e.event.target.value);
      this.unitSelected.oxide = this.roundValue(this.getFactor() * value);
    },
    changeOxide(e) {
      const value = parseFloat(e.event.target.value);
      this.unitSelected.element = this.roundValue(value / this.getFactor());
    },
    getFactor() {
      return parseFloat(this.unitStatic.oxide / this.unitStatic.element);
    },
    roundValue(val) {
      return parseFloat(val.toFixed(5));
    },
  },
};

</script>
<style src="./converterNutritionalSolutions.scss" scoped lang="scss"></style>

<template>
  <div class="content-devx">
    <div
      class="main-title-content"
    >
      {{ $t('nutritionDetail.elementResume.title') }}
    </div>
    <div class="list-by-group-reference-plans content-separatos">
      <DxDataGrid
        class="grid-resume-per-elements"
        :data-source="tableData"
        :show-borders="true"
        :row-alternation-enabled="true"
        :show-row-lines="true"
        :show-column-lines="true"
      >
        <DxColumn
          v-for="table in tables"
          :key="table.dataField"
          :data-field="table.dataField"
          :caption="table.caption"
          :width="table.width"
          :min-width="table.minWidth"
          :fixed="table.fixed"
          :fixed-position="table.fixedPosition"
          :allow-sorting="false"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';

export default {
  name: 'ResumePerElements',
  components: {
    DxDataGrid,
    DxColumn,
  },
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableData: [],
      tables: [
        {
          dataField: 'uf',
          caption: '',
          minWidth: 180,
          fixed: true,
          fixedPosition: 'left',
        },
        {
          dataField: 'n',
          caption: 'N/ha',
          minWidth: 50,
        },
        {
          dataField: 'p205',
          caption: 'P₂O₅/ha',
          minWidth: 50,
        },
        {
          dataField: 'k20',
          caption: 'K₂O/ha',
          minWidth: 50,
        },
        {
          dataField: 'ca0',
          caption: 'CaO/ha',
          minWidth: 50,
        },
        {
          dataField: 'mg0',
          caption: 'MgO/ha',
          minWidth: 50,
        },
        {
          dataField: 's03',
          caption: 'SO₃/ha',
          minWidth: 50,
        },
      ],
    };
  },
  mounted() {
    const toIntnteger = (value) => {
      if (value != null) {
        return parseInt(value, 10);
      }

      return null;
    };

    this.tableData.push({
      uf: `${this.$t('nutritionalBalance.necNutritional')} (kg/ha)`,
      ...this.plan.nutritional_needs,
    });
    this.tableData.push({
      uf: 'UF Eficientes (kg/ha)',
      ...this.plan.uf_efficiency_provided,
    });

    this.tableData.map((item) => {
      item.n = toIntnteger(item.n);
      item.p205 = toIntnteger(item.p205);
      item.k20 = toIntnteger(item.k20);
      item.ca0 = toIntnteger(item.ca0);
      item.mg0 = toIntnteger(item.mg0);
      item.s03 = toIntnteger(item.s03);
      return item;
    });
  },
};
</script>
<style lang="scss" scoped>
@import './referencePlansDetail.styles.scss';
</style>

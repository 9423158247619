import pdfmake from 'pdfmake';
import { cloneDeep } from 'lodash';
import pdfImage from '../json/pdfImages.json';

export default class PDFMakerGis {
  constructor() {
    pdfmake.fonts = {
      Roboto: {
        normal:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    };

    this.pdfData = {
      footer(currentPage, pageCount) {
        return [
          {
            text: `Página ${currentPage.toString()}`,
            alignment: 'right',
            margin: [0, 20, 10, 0],
          },
        ];
      },
      header(currentPage, pageCount, pageSize) {
        return [
          {
            margin: [20, 10, 20, 0],
            columns: [
              {
                image: pdfImage.timac_image,
                width: 200,
              },
              {
                margin: [100, 0, 0, 0],
                image: pdfImage.contact_image,
                width: 160,
              },
              {
                margin: [110, 10, 0, 0],
                image: pdfImage.roullier_image,
                width: 30,
              },
              {
                margin: [125, 10, 0, 0],
                image: pdfImage.iso_image,
                width: 30,
              },
            ],
          },
        ];
      },
      content: [],
      pageSize: 'A4',
      pageMargins: [20, 80, 20, 60],
      styles: {
        h1: { fontSize: 18, bold: true, margin: [0, 14, 0, 8] },
        h2: {
          fontSize: 16,
          bold: true,
          margin: [0, 20, 0, 8],
          color: '#444',
        },
        h3: {
          fontSize: 14,
          bold: true,
          margin: [0, 22, 0, 8],
          color: '#666',
        },
        tableHeaderCell: {
          margin: [2, 2, 2, 2],
          fontSize: 7,
          fillColor: '#eee',
          bold: true,
        },
        tableNormalCell: {
          margin: [2, 2, 2, 2],
          fontSize: 8,
          fillColor: '#fff',
          bold: false,
        },
        blueContainer: {
          margin: [0, 14, 0, 14],
        },
        blueContainerTitle: {
          margin: [4, 4, 4, 4],
          fillColor: '#006FA8;',
          color: '#fff',
          bold: true,
        },
        reportTitleStyle: {
          margin: [0, 0, 0, 0],
          fontSize: 16,
          color: '#006FA8',
          bold: true,
        },
        noBordersCell: {
          margin: [8, 8, 8, 8],
          fontSize: 8,
          bold: false,
        },
        noBordersCellSmall: {
          margin: [1, 1, 1, 1],
          fontSize: 8,
          bold: false,
        },
        noBordersCellSignature: {
          margin: [8, 25, 8, 25],
          fontSize: 8,
          bold: false,
        },
        bigger: {
          fontSize: 15,
          italics: true,
        },
        commentStyleTitle: {
          fontSize: 10,
          italics: true,
          bold: true,
        },
        commentStyle: {
          fontSize: 10,
          italics: false,
        },
        normalStyle: {
          fontSize: 10,
        },
        normalStyle2: {
          fontSize: 12,
        },
        observationsTitle: {
          fontSize: 12,
          margin: [0, 8, 0, 4],
        },
        observationsList: {
          fontSize: 12,
          margin: [10, 0, 0, 0],
        },
        legalText: {
          margin: [8, 8, 8, 8],
          fontSize: 8,
        },
        footer: {
          margin: [10, 10, 10, 10],
        },
      },
    };
  }

  getBlueContainer(title, breakTabla = true) {
    let tableOptions = {};

    if (breakTabla) {
      tableOptions = {
        // headerRows: 1,
        keepWithHeaderRows: true,
      };
    }

    return {
      style: 'blueContainer',
      table: {
        ...tableOptions,
        headerRows: 1,
        // headerRows: 1,
        // keepWithHeaderRows: true,
        // dontBreakRows: true,
        // unbreakable: true,
        widths: ['*'],
        body: [[{ text: title, style: 'blueContainerTitle' }], []],
      },
    };
  }

  insertTableWithoutBorders(data) {
    const blueContainer = this.getBlueContainer('DATOS DE LA EXPLOTACIÓN');

    const table = {
      widths: Array(data[0].length).fill(
        `${(100 / data[0].length).toFixed(2)}%`,
      ),
      body: [],
    };

    data.forEach((row) => {
      const headers = row.map((cell) => {
        if (cell.title.length === 0 && cell.text.length === 0) {
          return { style: 'noBordersCellSmall', text: '' };
        }

        return {
          style: 'noBordersCellSmall',
          text: [
            { text: `${cell.title}: `, fontSize: 10, bold: false },
            { text: cell.text, fontSize: 10, bold: true },
          ],
        };
      });
      table.body.push(headers);
    });

    blueContainer.table.body[1].push({ table, layout: 'noBorders' });

    this.pdfData.content.push(blueContainer);
  }

  async inserTableSatelliteImages(data) {
    const table = {
      widths: Array(data[0].length).fill(
        `${(100 / data[0].length).toFixed(2)}%`,
      ),
      body: [],
    };

    for (const row of data) {
      const rows = await Promise.all(row.map(async (cell) => {
        if (!Array.isArray(cell)) {
          return [
            {
              alignment: 'center',
              image: cell.image,
              width: 180,
            },
            {
              alignment: 'center',
              image: cell.stack,
              height: 75,
              width: 200,
            },
          ];
        }

        return [];
      }));

      table.body.push(rows);
    }

    this.pdfData.content.push({ table, layout: 'noBorders' });
  }

  async insertChart(chart) {
    const pdfChart = {
      widths: ['*'],
      body: [],
    };
    pdfChart.body.push([
      {
        alignment: 'center',
        image: chart.image,
        width: 550,
      },
    ]);
  
    this.pdfData.content.push({
      table: pdfChart,
      layout: 'noBorders',
      margin: [0, 15, 0, 15],
    });
  }

  insertReportTitle(text) {
    this.pdfData.content.push({ text, style: 'reportTitleStyle' });
  }

  insertTableBottomSignature(data) {
    const blueContainer = this.getBlueContainer('INFORMACIÓN');

    const table = {
      widths: [],
      body: [],
    };

    const celldimension = (100 / data[0].length).toFixed(2);
    data[0].forEach((_) => {
      table.widths.push(`${celldimension}%`);
    });

    data.forEach((row) => {
      const headers = row.map((cell) => ({
        style: 'noBordersCellSignature',
        text: [
          { text: `${cell[0]}: `, fontSize: 10, bold: false },
          { text: cell[1], fontSize: 10, bold: true },
        ],
      }));
      table.body.push(headers);
    });

    blueContainer.table.body[1].push({ table, layout: 'noBorders' });

    this.pdfData.content.push(blueContainer);
  }
  // Funcion para insertar comentarios en el pdf
  insertTableBottomComments(data) {
    const blueContainer = this.getBlueContainer('COMENTARIOS');
  
    const table = {
      widths: [],
      body: [],
    };
  
    const celldimension = (100 / data[0].length).toFixed(2);
    data[0].forEach((_) => {
      table.widths.push(`${celldimension}%`);
    });
  
    data.forEach((row) => {
      const headers = row.map((cell) => ({
        style: 'noBordersCellSmall',
        text: { text: cell[0], fontSize: 10, bold: true },
      }));
      table.body.push(headers);
    });
  
    blueContainer.table.body[1].push({ table, layout: 'noBorders', alignment: 'left' });
  
    this.pdfData.content.push(blueContainer);
  }

  // Función para insertar las observaciones
  insertObservations(data) {
    const blueContainer = this.getBlueContainer('OBSERVACIONES', false);

    const table = {
      widths: ['100%'],
      body: [],
    };

    data.forEach((row) => {
      table.body.push([{ text: row.title, style: 'observationsTitle' }]);

      const comments = [];

      row.comment.forEach((comment) => {
        comments.push({ text: `${comment[0]}: `, style: 'commentStyleTitle' });
        comments.push({ text: `${comment[1]}. `, style: 'normalStyle' });
      });

      table.body.push([
        {
          style: 'observationsList',
          ul: [
            { text: `Fecha: ${row.date}`, style: 'normalStyle' },
            {
              text: comments,
            },
          ],
        },
      ]);

      for (const multimediaItem of row.multimedia) {
        
        table.body.push([
          {
            image: multimediaItem.base64,
            width: 250,
            alignment: 'center',
            margin: [0, 0, 0, 15],
          },
        ]);
      }
    });

    blueContainer.table.body[1].push({ table, layout: 'noBorders' });

    this.pdfData.content.push(blueContainer);
  }

  async getPdfAsBuffer() {
    const pdfDocGenerator = pdfmake.createPdf(cloneDeep(this.pdfData));
    return new Promise((resolve) => {
      pdfDocGenerator.getBuffer((buffer) => {
        resolve(buffer);
      });
    });
  }

  async buildAndDownload(filename) {
    return new Promise((resolve) => {
      pdfmake.createPdf(this.pdfData).download(filename, () => {
        resolve();
      });
    });
  }

  buildForMobile() {
    const pdfBuild = pdfmake.createPdf(cloneDeep(this.pdfData));
    return pdfBuild;
  }
}

<template>
  <f7-page class="nutrition-detail">
    <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.name} > ${$t('Utilities.nutrition')}`"/>

    <ModalCopyReferencePlan
      :id="id"
      :visible="ShowModal"
      type="nutrition"
      @close="ShowModal = false"
      @copy="copy"
    />

    <Placeholder
      v-if="!dataLoaded"
      :is-new="isNew"
    />

    <nutrition-detail-component
      v-if="dataLoaded"
      :fertilization="fertilization"
      :plantation-property="actualPlantation"
      @open-copy-modal="ShowModal = true"
    />
    <f7-row no-gap>
      <f7-col style="align-self: stretch">
        <element-resume-component
          v-if="!isNew && dataLoaded"
          class="element-resume"
          :fertilization="fertilization"
        />
        <ElementResumeComponentNew
          v-if="isNew && dataLoaded"
          class="element-resume"
          :fertilization="fertilization"
        />
      </f7-col>
      <f7-col>
        <product-resume-chart-component
          v-if="dataLoaded"
          :fertilization="fertilization"
        />
      </f7-col>
    </f7-row>

    <product-resume-component
      v-if="dataLoaded"
      :plantationData="actualPlantation"
      :fertilization="fertilization"
    />

    <ProductResumeTable
      v-if="dataLoaded"
      :fertilization="fertilization"
    />

    <IrrigationResumeTable
      v-if="dataLoaded && !showIrrigationTable"
      :fertilization="fertilization"
    />

    <ApplicationEfficiencyPerPeriod
      v-if="isNew && dataLoaded"
      :fertilization="fertilization"
    />

    <Observations
      v-if="dataLoaded"
      :fertilization="fertilization"
    />
    <section class="margin-half margin-bottom">
      <f7-button
        class="dx-btn-cancel btnBack"
        type="default"
        text-color="grey"
        styling-mode="outlined"
        name="ButtonCancel"
        @click="back"
      >
        {{ $t('Button_go_to_back') }}
      </f7-button>
    </section>
  </f7-page>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import nutritionDetailComponent from '../../../components/nutritionDetail/nutritionDetail/index.vue';
import elementResumeComponent from '../../../components/nutritionDetail/elementResume/index.vue';
import ElementResumeComponentNew from '../../../components/nutritionDetail/elementResume/new.vue';
import ProductResumeTable from '../../../components/nutritionDetail/productResumeTable/index.vue';
import IrrigationResumeTable from '../../../components/nutritionDetail/irrigationResumeTable/index.vue';
import productResumeComponent from '../../../components/nutritionDetail/productResume/index.vue';
import productResumeChartComponent from '../../../components/nutritionDetail/productResumeChart/index.vue';
import Observations from '../../../components/nutritionDetail/observations/index.vue';
import ApplicationEfficiencyPerPeriod from '../../../components/nutritionDetail/ApplicationEfficiencyPerPeriod/index.vue';
import Placeholder from '../../../components/nutritionDetail/Placeholder/index.vue';
import fertilizationService from '@/services/fertilizationService';
import ModalCopyReferencePlan from '../../../components/utilities/referencePlans/detail/ModalCopyReferencePlan.vue';
import Api from '../../../services/Api';

export default {
  name: 'NutritionDetailWizard',
  components: {
    navbar,
    nutritionDetailComponent,
    elementResumeComponent,
    ProductResumeTable,
    ElementResumeComponentNew,
    productResumeComponent,
    IrrigationResumeTable,
    productResumeChartComponent,
    Observations,
    ApplicationEfficiencyPerPeriod,
    Placeholder,
    ModalCopyReferencePlan,
  },
  props: ['id'],
  data() {
    return {
      fertilization: {},
      dataLoaded: false,
      ShowModal: false,
    };
  },
  computed: {
    showIrrigationTable() {
      return (
        this.fertilization?.periods_irrigation &&
        this.fertilization.periods_irrigation.every(value => value === 0)
      ) ?? true;
    },
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('nutritionStepper', ['isNew']),
  },
  mounted() {
    this.dataLoaded = false;
    fertilizationService
      .getById(this.id, this.currentPlantation.companyId)
      .then((response) => {
        const newPlan = response?.version === '2.2.0';
        this.setIsNew(newPlan);

        this.dataLoaded = true;
        this.fertilization = response;
      });
  },
  methods: {
    ...mapActions('nutritionStepper', ['setIsNew']),
    async copy(date) {
      const planDuration = moment(this.fertilization.end_date).diff(moment(this.fertilization.init_date), 'days') || 0;

      const xhr = await Api.getPlantationById(this.fertilization.company_id, this.fertilization.plantation);
      const plantation = JSON.parse(xhr.response);

      this.$f7router.navigate({
        name: 'nutrition-plans-copy',
        params: {
          id: this.id,
        },
        query: {
          date,
          duration: planDuration,
          species: plantation.species,
        },
      });
    },
    back() {
      this.$f7.views.main.router.navigate('/searcher/nutrition/', {
        reloadCurrent: true,
      });
    },
  },
};
</script>
<style src="./nutrition-detail.scss" scoped lang="scss"></style>

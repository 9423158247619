<template>
  <DxPopup
    title=""
    :visible="true"
    :close-on-outside-click="true"
    :max-width="800"
    height="auto"
    width="auto"
    :on-hidden="close"
    :drag-enabled="false"
    :max-height="600"
  >
      <div class="scroll-container">
        <div
          v-if="typeReport"
          class="pdf-type"
        >
        {{ $t("popupSelectObservationsReport.pdfType") }} : {{ typeReport }}
        </div>
        <h3 class="title-resume">{{ $t("popupSelectObservationsReport.observations") }}:</h3>
        <div
          v-if="observations.length === 0"
          class="no-observations"
        >
          <div v-if="loading">
            <Spinner />
          </div>
          <div v-else>
            {{ $t('noObservationsToSelect') }}
          </div>
        </div>
        <div
          v-if="!loading && observations.length > 0"
        >
          <div class="observations-container">
            <div
              v-for="data in observations"
              :key="data.id"
              class="observations-container__item"
              @click="selectObservations(data)"
            >
              <div v-if="data.selected" class="observations-container__item--selected">
                <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 7.5C0 5.51088 0.790176 3.60322 2.1967 2.1967C3.60322 0.790176 5.51088 0 7.5 0C9.48912 0 11.3968 0.790176 12.8033 2.1967C14.2098 3.60322 15 5.51088 15 7.5C15 9.48912 14.2098 11.3968 12.8033 12.8033C11.3968 14.2098 9.48912 15 7.5 15C5.51088 15 3.60322 14.2098 2.1967 12.8033C0.790176 11.3968 0 9.48912 0 7.5H0ZM7.072 10.71L11.39 5.312L10.61 4.688L6.928 9.289L4.32 7.116L3.68 7.884L7.072 10.711V10.71Z"
                      fill="white"
                    />
                  </svg>
              </div>
              <ImageText
                :imageSrc="data.image"
                :date="data.date"
                :category="data.category"
                :subcategory="data.subcategory"
              />
            </div>
          </div>
        </div>
        <h3 v-if="!hideFilter" class="title-resume">{{ $t("popupSelectObservationsReport.elementsResume") }}:</h3>
        <div
          v-if="!hideFilter"
          style="height: 1px; width: 100%; background: #e5e7eb"
        />

        <div
          v-if="!hideFilter"
          style="
            margin: 10px;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 5px;
            column-gap: 20px;
            cursor: pointer;
          ">
          <div class="report-pdf-checkbox-content">
            <DxCheckBox v-model="checkBoxValues.nutritionalNeeds" />
            <span
              class="report-pdf-checkbox-title"
              @click="checkBoxValues.nutritionalNeeds = !checkBoxValues.nutritionalNeeds"
            >
            {{ $t("popupSelectObservationsReport.nutritionNeeds") }}
            </span>
          </div>
          <div class="report-pdf-checkbox-content">
            <DxCheckBox v-model="checkBoxValues.previousBalance" />
            <span
              class="report-pdf-checkbox-title"
              @click="checkBoxValues.previousBalance = !checkBoxValues.previousBalance"
            >
            {{ $t("popupSelectObservationsReport.previusBalance") }}
            </span>
          </div>
          <div class="report-pdf-checkbox-content">
            <DxCheckBox v-model="checkBoxValues.ufTheoretical" />
            <span
              class="report-pdf-checkbox-title"
              @click="checkBoxValues.ufTheoretical = !checkBoxValues.ufTheoretical"
            >
            {{ $t("popupSelectObservationsReport.theoreticalUF") }}
            </span>
          </div>
          <div class="report-pdf-checkbox-content">
            <DxCheckBox v-model="checkBoxValues.ufEfficient" />
            <span
              class="report-pdf-checkbox-title"
              @click="checkBoxValues.ufEfficient = !checkBoxValues.ufEfficient"
            >
            {{ $t("popupSelectObservationsReport.efficientUF") }}
            </span>
          </div>
          <div class="report-pdf-checkbox-content">
            <DxCheckBox v-model="checkBoxValues.finalBalance" />
            <span
              class="report-pdf-checkbox-title"
              @click="checkBoxValues.finalBalance = !checkBoxValues.finalBalance"
            >
            {{ $t("popupSelectObservationsReport.finalBalance") }}
            </span>
          </div>
        </div>
        <h3 v-if="!hideFilter" class="title-resume">{{ $t("popupSelectObservationsReport.resumeProducts") }}:</h3>
        <div
          v-if="!hideFilter"
          style="height: 1px; width: 100%; background: #e5e7eb"
        />
        <div  
          v-if="!hideFilter"
          style="
            margin: 10px;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 5px;
            column-gap: 20px;
          ">
            <div class="report-pdf-checkbox-content">
              <input 
                type="radio"
                id="completeOption"
                v-model="selectedProductType"
                class="label-check"
                value="complete"
                @change="handleProductType('complete')"
              />
              <label 
                for="completeOption"
                class="report-pdf-checkbox-title label-check"
                @click="handleProductType('complete')"
              >
                {{ $t("popupSelectObservationsReport.completeTable") }}
              </label>
            </div>
            <div class="report-pdf-checkbox-content">
              <input 
                type="radio"
                id="noCostOption"
                v-model="selectedProductType"
                class="label-check"
                value="noCost"
                @change="handleProductType('noCost')"
              />
              <label 
                for="noCostOption"
                class="report-pdf-checkbox-title label-check"
                @click="handleProductType('noCost')"
              >
                {{ $t("popupSelectObservationsReport.noCostTable") }}
              </label>
            </div>
          </div>
          <div
            v-if="!hideFilter" style="height: 1px; width: 100%; background: #e5e7eb"
          />
          <div
            v-if="!hideFilter"
            style="
              margin: 10px;
              display: grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              gap: 5px;
              column-gap: 20px;
              cursor: pointer;
            "
          > 
            <div class="report-pdf-checkbox-content">
              <DxCheckBox v-model="checkBoxValues.productsAplication" />
              <span
                class="report-pdf-checkbox-title"
                @click="checkBoxValues.productsAplication = !checkBoxValues.productsAplication"
              >
              {{ $t("popupSelectObservationsReport.productsAplication") }}
              </span>
            </div>
            <div class="report-pdf-checkbox-content">
              <DxCheckBox v-model="checkBoxValues.resumeProductsGraphics" />
              <span
                class="report-pdf-checkbox-title"
                @click="checkBoxValues.resumeProductsGraphics = !checkBoxValues.resumeProductsGraphics"
              >
              {{ $t("popupSelectObservationsReport.resumeProductsGraphics") }}
              </span>
            </div>
            <div class="report-pdf-checkbox-content">
              <DxCheckBox v-model="checkBoxValues.productsAplicationTotals" />
              <span
                class="report-pdf-checkbox-title"
                @click="checkBoxValues.productsAplicationTotals = !checkBoxValues.productsAplicationTotals"
              >
              {{ $t("popupSelectObservationsReport.productsAplicationTotals") }}
              </span>
            </div>
            <div v-if="!showIrrigation" class="report-pdf-checkbox-content">
              <DxCheckBox v-model="checkBoxValues.irrigationTable" />
              <span
                class="report-pdf-checkbox-title"
                @click="checkBoxValues.irrigationTable = !checkBoxValues.irrigationTable"
              >
              {{ $t("popupSelectObservationsReport.irrigationTable") }}
              </span>
            </div>
            <div class="report-pdf-checkbox-content">
              <DxCheckBox v-model="checkBoxValues.ufEfficientTable" />
              <span
                class="report-pdf-checkbox-title"
                @click="checkBoxValues.ufEfficientTable = !checkBoxValues.ufEfficientTable"
              >
              {{ $t("popupSelectObservationsReport.ufEfficientTable") }}
              </span>
            </div>
            <div class="report-pdf-checkbox-content">
              <DxCheckBox v-model="checkBoxValues.ufEfficientGraph" />
              <span
                class="report-pdf-checkbox-title"
                @click="checkBoxValues.ufEfficientGraph = !checkBoxValues.ufEfficientGraph"
              >
              {{ $t("popupSelectObservationsReport.ufEfficientGraph") }}
              </span>
            </div>
          </div>
          <f7-row>
            <f7-col>
              <f7-button
                class="dx-btn-cancel margin-left-block btn-width-150 outlined-gray margin-top-block margin-bottom-block"
                raised
                type="default"
                styling-mode="contained"
                @click="close()"
              >
              {{ $t("popupSelectObservationsReport.buttons.cancel") }}
              </f7-button>
            </f7-col>
            <f7-col class="justify-content-end">
              <f7-button
                class="dx-btn-cancel margin-right-block btn-width-150 margin-top-block margin-bottom-block standard-blue-btn"
                raised
                type="success"
                styling-mode="contained"
                :disabled="disabledDownloadButton"
                @click="download()"
              >
                {{ buttonName }}
              </f7-button>
            </f7-col>
          </f7-row>
        </div>
  </DxPopup>
</template>
<script>
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxPopup} from 'devextreme-vue/popup';
import { mapState } from 'vuex';
import Api from '../../services/Api';
import Spinner from '../spinner.vue';
import ImageText from './imageText.vue';

export default {
  name: 'PopupSelectObservationsReport',
  components: {
    DxPopup,
    DxCheckBox,
    Spinner,
    ImageText
  },
  props: {
    typeReport: {
      type: String,
      default: '',
    },
    typeAction: {
      type: String,
      default: 'download',
    },
    irrigationTable: {
      type: Boolean,
      default: false,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedProductType: 'complete',
      disabledDownloadButton: false,
      refresh: true,
      observations: [],
      loading: true,
      checkBoxValues: {
        nutritionalNeeds: true,
        previousBalance: true,
        ufTheoretical: true,
        ufEfficient: true,
        finalBalance: true,
        resumeProductsComplete: true,
        resumeProductsNoCost: false,
        productsAplication: true,
        resumeProductsGraphics: true,
        productsAplicationTotals: true,
        irrigationTable: this.irrigationTable,
        ufEfficientTable: true,
        ufEfficientGraph: true,
      },
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('authentication', ['user']),
    showIrrigation(){
      return !this.irrigationTable;
    },
    buttonName() {
      if (this.typeAction === 'share') {
        return this.$t('shareReportButton');
      }

      return this.$t('downloadReportButton');
    },
  },
  async mounted() {
    try {
      // this.$f7.preloader.show();

      const xhr = await Api.getObservationsWithImages(
        this.currentPlantation.companyId,
        this.currentPlantation.id,
      );
      const observations = JSON.parse(xhr.response).results;
      this.observations = observations.map((observation, index) => {
        const image = observation.multimedia.length > 0
          ? Api.getBaseUrl()
              + observation.multimedia[0].attachment
              + this.user.access
          : '';
        observation.selected = false;
        observation.image = image;
        return observation;
      });

      if (this.observations.length === 0) {
        this.refresh = false;
      }
    } catch (error) {
      this.observations = [];

      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              this.$helpers.getErrorMSG(error),
            ),
          ),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.loading = false;
      // this.$f7.preloader.hide();
    }
  },
  methods: {
    selectObservations(data) {
      this.observations = this.observations.map((item) => {
        if (item.id === data.id) {
          item.selected = !item.selected;
        }
        return item;
      });
    },
    close() {
      this.$emit('close');
    },
    handleProductType(type) {
    // Cambiar la lógica según el tipo seleccionado
      this.checkBoxValues.resumeProductsComplete = type === 'complete';
      this.checkBoxValues.resumeProductsNoCost = type === 'noCost';
    },
    download() {
      this.disabledDownloadButton = true;

      if (this.typeAction === 'download') {
        this.$emit(
          'download',
          {
            observations: this.observations.filter((item) => item.selected),
            filters: this.checkBoxValues,
          },
        );
      }

      if (this.typeAction === 'share') {
        this.$emit(
          'share',
          {
            observations: this.observations.filter((item) => item.selected),
            filters: this.checkBoxValues,
          },
        );
      }
    },
    handleCheckBoxClick(checkbox) {
      // Cambiar el estado de la casilla de verificación clicada
      this.checkBoxValues[checkbox] = !this.checkBoxValues[checkbox];
    },
  },
};
</script>

<style lang="scss" scoped>

.observations-container {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
    &__item {
      cursor: pointer;
      height: 100%;
      padding: 1px;
      position: relative; 
    &--selected { 
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 10000px;
      display: flex;
      font-size: 12px;
      padding: 1px;
      position: absolute;
      right: 13px;
      top: 17px;
    }
  }
}

.pdf-type {
  font-weight: 600;
  padding: 1rem 1rem 0 1rem;
}

.no-observations {
  padding: 2rem;
  text-align: center;
}

.report-pdf-checkbox-content {
  margin-bottom: 0.5rem;
}

.report-pdf-checkbox-title {
  font-size: 0.8rem;
  font-weight: 600;
  margin-left: 0.5rem;
}

.title-resume {
  font-size: 20px;
  font-weight: 400;
  padding: 0 10px;
}
.label-check {
  cursor: pointer;  
}

/* Estilos para el scrollbar dentro de .scroll-container */
.scroll-container {
  max-height: 500px;
  max-width: 800px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 15px; /* Ancho del scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0071bd; /* Color del scrollbar */
    border-radius: 5px; /* Borde redondeado */
  }

  &::-moz-scrollbar {
    width: 15px; /* Ancho del scrollbar */
  }

  &::-moz-scrollbar-thumb {
    background-color: #0071bd; /* Color del scrollbar */
    border-radius: 5px; /* Borde redondeado */
  }
}
</style>

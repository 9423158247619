<template>
  <transition
    :css="false"
    @leave="leave"
  >
    <f7-block class="margin-top-inverted-45">
      <f7-toolbar
        tabbar
        top
        class="no-shadow padding-top"
      >
        <f7-link
          tab-link="#tab-gisWMS"
          @click="setTabActive('gisWMS')"
        >
          {{ $t('Searcher.gis.gisWMS.title') }}
        </f7-link>
        <f7-link
          tab-link="#tab-gisTimeDimension"
          @click="setTabActive('gisTimeDimension')"
        >
          {{ $t('Searcher.gis.gisTimeDimension.title') }}
        </f7-link>
        <f7-link
          tab-link="#tab-gisScanning"
          @click="setTabActive('gisScanning')"
        >
          {{ $t('Searcher.gis.gisScanning.title') }}
        </f7-link>
      </f7-toolbar>
      <f7-tabs
        animated
        routable
      >
        <f7-tab
          id="tab-gisWMS"
          class="page-content"
          style="padding-top: 50px;"
        >
          <GisWMS v-if="currentSubTab === 'gisWMS'" />
        </f7-tab>
        <f7-tab
          id="tab-gisTimeDimension"
          class="page-content"
          style="padding-top: 50px;"
        >
          <GisTimeDimension v-if="currentSubTab === 'gisTimeDimension'" />
        </f7-tab>
        <f7-tab
          id="tab-gisScanning"
          class="page-content"
          style="padding-top: 50px;"
        >
          <GisScanning v-if="currentSubTab === 'gisScanning'" />
        </f7-tab>
      </f7-tabs>
    </f7-block>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GisWMS from './gisWMS/index.vue';
import GisTimeDimension from './gisTimeDimension/index.vue';
import GisScanning from './gisScanning/index.vue';

export default {
  name: 'GisSearcher',
  components: {
    GisWMS,
    GisTimeDimension,
    GisScanning,
  },
  computed: {
    ...mapState('Utilities', ['currentSubTab']),
  },
  async beforeMount() {
    this.$fa.setScreenName('Buscador > Gis');
    this.setCurrentSubTab('gisWMS');
  },
  beforeUpdate() {
    this.disableGISWMSMapRequest();
  },
  beforeDestroy() {
    this.leave();
  },
  methods: {
    leave() {
      const timeDimension = document.getElementsByClassName('timecontrol-play')[0];
      if (timeDimension) {
        const timeDimensionClassList = timeDimension.classList;

        if (timeDimensionClassList.contains('pause')) {
          timeDimension.click();
        }
      }
    },
    disableGISWMSMapRequest() {
      if (this.currentSubTab !== 'gisTimeDimension') {
        const timeDimension = document.getElementsByClassName('timecontrol-play')[0];

        if (timeDimension) {
          const timeDimensionClassList = timeDimension.classList;

          if (timeDimensionClassList.contains('pause')) {
            timeDimension.click();
          }
        }
      }
    },
    setTabActive(tabSelected) {
      this.setCurrentSubTab(tabSelected);
    },
    ...mapActions('Utilities', ['setCurrentSubTab']),
  },
};
</script>

<style lang="scss" scoped>
@import './Gis.styles.scss';
</style>

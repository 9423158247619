<template>
  <div class="margin-top-half">
    <div class="content-devx">
      <div>
        <DxForm>
          <DxGroupItem :col-count="2">
            <DxSimpleItem
              :data-field="$t('weather_client')"
              :caption="$t('weather_client')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: allClients,
                displayExpr: 'name',
                valueExpr: 'id',
                placeholder: $t('weather_client_placeholder'),
                onValueChanged: onSelectedClient,
                searchEnabled:true
              }"
            >
              <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('weather_parameters')"
              :caption="$t('weather_parameters')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: parameters,
                placeholder: $t('registerClima_parameter_placeholder'),
                onValueChanged: onSelectedParameter,
                displayExpr: displayTranslatedParameter,
              }"
            >
              <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('dateRange')"
              is-required
            >
              <template #default>
                <f7-input
                  type="datepicker"
                  class="range-date-picker"
                  style="width: 200px"
                  :placeholder="
                    $t('PlanningComponent_datepicker_multiple_placeholder')
                  "
                  :clear-button="true"
                  :close-on-select="true"
                  :calendar-params="{
                    closeOnSelect: true,
                    rangePicker: true,
                    dateFormat: {
                      month: 'numeric',
                      day: 'numeric',
                      year: 'numeric',
                    },
                  }"
                  @calendar:change="setSearcherDates"
                />
              </template>
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('weather_plantation')"
              :caption="$t('weather_plantation')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: allPlantations,
                displayExpr: 'name',
                valueExpr: 'id',
                readOnly:campaign==='',
                placeholder: $t('registerClima_parcel_placeholder'),
                onValueChanged: onSelectedPlantation,
                searchEnabled:true
              }"
            >
              <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem :col-count="3" />
        </DxForm>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
  DxValidator,
} from 'devextreme-vue/form';

import { mapActions, mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'Weather',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  computed: {
    ...mapState('weather', ['parameters', 'allCampaigns', 'campaign', 'allPlantations', 'parcel', 'endDate',
      'initDate', 'allClients', 'clientSelected']),
    campaignSelected() {
      return this.campaign === '';
    },
  },
  async beforeMount() {
    try {
      await this.getAllClients();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    }
  },
  methods: {
    setSearcherDates(e) {
      if (e.length === 0) {
        this.setInitDate('');
        this.setEndDate('');
        return;
      }

      if (e.length === 2) {
        this.setInitDate(moment(e[0], 'YYYY/MM/DD').format('YYYY-MM-DDT00:00:00'));
        this.setEndDate(moment(e[1], 'YYYY/MM/DD').format('YYYY-MM-DDT00:00:00'));
      }
    },
    sundays(date) {
      return [0].indexOf(date.getDay()) >= 0;
    },
    onSelectedPlantation(e) {
      this.setPlantationId(e.value);
      for (const plantation of this.allPlantations) {
        if (e.value === plantation.id) {
          this.setNamePlantation(plantation.name);
          this.setCampaign(plantation.campaign);
        }
      }
      try {
        this.getPlantationByCompany();
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    onSelectedParameter(e) {
      this.setParameterSelected(e.value);
    },
    onChangeInitDate(e) {
      this.setInitDate(moment(e.value, 'YYYY/MM/DD').format('YYYY-MM-DDT00:00:00'));
    },
    onChangeEndDate(e) {
      this.setEndDate(moment(e.value, 'YYYY/MM/DD').format('YYYY-MM-DDT00:00:00'));
    },
    onSelectedClient(e) {
      this.setClient(e.value);
    },
    displayTranslatedParameter(parameter) {
      if (parameter === null) {
        return '';
      }
      return `${this.$t(`WeatherParameter.${parameter}`)}`;
    },
    ...mapActions('weather', ['getAllCampaigns', 'setCampaign', 'getPlantationByCompany', 'setParcel',
      'initStoreRegister', 'setInitDate', 'setEndDate', 'setParameterSelected', 'setNameParcel', 'setClient',
      'getAllClients', 'setPlantationId', 'setNamePlantation']),
  },
};
</script>

<style lang="scss" scoped>
@import './registerWeather.styles.scss';
</style>

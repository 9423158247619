import Request from './Request';
import Api from './Api';

const productVademecumService = {

  async getAll(agent) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/vademecum?agent=${agent}`,
      { data: {} },
      'GET', false,
      'application/json',
      Api.getHeaderAuth());
    return JSON.parse(xhr.response);
  },
  async getById(id) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/vademecum/${id}`,
      { data: {} },
      'GET', false,
      'application/json',
      Api.getHeaderAuth());
    return JSON.parse(xhr.response);
  },
  async getPdfUrl(productId) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${Api.getBaseUrl()}/vademecum_pdf?id_product=${productId}`, true);
    xhr.setRequestHeader('Authorization', Api.getHeaderAuth());
    xhr.responseType = 'blob';

    return new Promise((resolve, reject) => {
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject('Error al obtener el PDF');
        }
      };
      xhr.onerror = () => {
        reject('Error de red');
      };
      xhr.send();
    });
  },
};

export default productVademecumService;

export { productVademecumService };

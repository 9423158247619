import Api from '../../services/Api';

const level0ConfDefault = {
  fill: {
    paint: {
      'fill-color': '#0077d9',
      'fill-opacity': 0.5,
    },
  },
  line: {
    paint: {
      'line-color': '#0077d9',
      'line-width': 3,
    },
  },
};

const level1ConfDefault = {
  fill: {
    paint: {
      'fill-color': '#3ba1f5',
      'fill-opacity': 0.5,
    },
  },
  line: {
    paint: {
      'line-color': '#3ba1f5',
      'line-width': 3,
    },
  },
};

const level2ConfDefault = {
  fill: {
    paint: {
      'fill-color': '#8ec7f5',
      'fill-opacity': 0.5,
    },
  },
  line: {
    paint: {
      'line-color': '#8ec7f5',
      'line-width': 3,
    },
  },
};

const othersLevelConfDefault = {
  fill: {
    paint: {
      'fill-color': '#bcddf7',
      'fill-opacity': 0.5,
    },
  },
  line: {
    paint: {
      'line-color': '#bcddf7',
      'line-width': 3,
    },
  },
};

export default {
  addProperties(geojson, properties, clearProperties = false) {
    if (geojson.type === 'Feature') {
      if (clearProperties) {
        geojson.properties = {};
      } else {
        let uniqueId = geojson.properties.id ? geojson.properties.id : '';
        if (geojson.id) {
          uniqueId += `_${geojson.id}`;
        } else {
          uniqueId += `_${Math.random()}`;
        }
        geojson.properties = {
          ...geojson.properties,
          ...properties,
          id: uniqueId,
        };
      }
    }
    if (geojson.type === 'FeatureCollection') {
      for (const feature of geojson.features) {
        this.addProperties(feature, properties, clearProperties);
      }
    }
  },
  treeLocationToGeoJson(
    treeLocations,
    geoJSON,
    clearProperties = false,
    level0Conf = level0ConfDefault,
    level1Conf = level1ConfDefault,
    level2Conf = level2ConfDefault,
    othersLevelConf = othersLevelConfDefault,
  ) {
    for (const treeLocation of treeLocations) {
      if ('type' in treeLocation.geo_feature) {
        const geoFeature = treeLocation.geo_feature;
        let properties = {};
        switch (treeLocation.level) {
          case 0:
            properties = level0Conf;
            break;
          case 1:
            properties = level1Conf;
            break;
          case 2:
            properties = level2Conf;
            break;
          default:
            properties = othersLevelConf;
            break;
        }
        this.addProperties(geoFeature, properties, clearProperties);
        geoJSON.push(geoFeature);
      }
      if (treeLocation.children.length > 0) {
        this.treeLocationToGeoJson(
          treeLocation.children,
          geoJSON,
          clearProperties,
          level0Conf,
          level1Conf,
          level2Conf,
          othersLevelConf,
        );
      }
    }
  },
  async getIntersectSIGPACLayer(coords, featureIntersectObject) {
    let stringCoordinates = '';
    for (const coord of coords) {
      stringCoordinates += `${coord[0]}%20${coord[1]},`;
    }
    stringCoordinates += `${coords[0][0]}%20${coords[0][1]},`;
    const correctStringCoordinates = stringCoordinates.substring(0, stringCoordinates.length - 1);
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };
      const url = `${Api.getGeoServerSigpacSurface()}?polygon=${correctStringCoordinates}`;
      const sigpacIntersect = await fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let resultJSON = null;
          try {
            resultJSON = JSON.parse(result);
            if (resultJSON === null) {
              return { features: [] };
            }
            return resultJSON;
          } catch (error) {
            return { features: [] };
          }
        });
      for (const feature of sigpacIntersect.features) {
        const intersectPolygon = {};
        const intersecPercentage = Number(
          ((feature.properties.SupInterseccion * 100) / feature.properties.dn_surface).toFixed(2),
        );
        intersectPolygon.intersecPercentage = intersecPercentage;
        intersectPolygon.use = feature.properties.uso_sigpac;
        intersectPolygon.surface = Number((feature.properties.dn_surface / 10000).toFixed(2));
        intersectPolygon.intersectSurface = Number((feature.properties.SupInterseccion / 10000)
          .toFixed(2));
        intersectPolygon.provincia = feature.properties.provincia;
        intersectPolygon.municipio = feature.properties.municipio;
        intersectPolygon.agregado = feature.properties.agregado;
        intersectPolygon.zona = feature.properties.zona;
        intersectPolygon.poligono = feature.properties.poligono;
        intersectPolygon.parcela = feature.properties.parcela;
        intersectPolygon.recinto = feature.properties.recinto;
        intersectPolygon.idSIGPAC = `
              provincia: ${intersectPolygon.provincia},
              municipio: ${intersectPolygon.municipio},
              agregado: ${intersectPolygon.agregado},
              zona: ${intersectPolygon.zona},
              poligono: ${intersectPolygon.poligono},
              parcela: ${intersectPolygon.parcela},
              recinto: ${intersectPolygon.recinto}`;
        featureIntersectObject.push(intersectPolygon);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async intersectSIGPACLayer(statusMeasureTool, currentGeoFeature) {
    if (statusMeasureTool) return null;
    const listCoords = [];
    currentGeoFeature.features.forEach((feature) => {
      if (feature.geometry.type === 'MultiPolygon') {
        feature.geometry.coordinates.forEach((coords) => {
          listCoords.push(...coords);
        });
      } else {
        listCoords.push(...feature.geometry.coordinates);
      }
    });
    const featureIntersectObject = [];
    const promises = [];
    for (const coords of listCoords) {
      promises.push(this.getIntersectSIGPACLayer(coords, featureIntersectObject));
    }
    await Promise.all(promises);
    return featureIntersectObject;
  },
  getDrawCustomStyle() {
    return [
      {
        'id': 'gl-draw-polygon-fill-inactive',
        'type': 'fill',
        'filter': ['all',
          ['==', 'active', 'false'],
          ['==', '$type', 'Polygon'],
          ['!=', 'mode', 'static']
        ],
        'paint': {
          'fill-color': '#0055c7',
          'fill-outline-color': '#0055c7',
          'fill-opacity': 0.4
        }
      },
      {
        'id': 'gl-draw-polygon-fill-active',
        'type': 'fill',
        'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
        'paint': {
          'fill-color': '#fbb03b',
          'fill-outline-color': '#fbb03b',
          'fill-opacity': 0.1
        }
      },
      {
        'id': 'gl-draw-polygon-midpoint',
        'type': 'circle',
        'filter': ['all',
          ['==', '$type', 'Point'],
          ['==', 'meta', 'midpoint']],
        'paint': {
          'circle-radius': 3,
          'circle-color': '#fbb03b'
        }
      },
      {
        'id': 'gl-draw-polygon-stroke-inactive',
        'type': 'line',
        'filter': ['all',
          ['==', 'active', 'false'],
          ['==', '$type', 'Polygon'],
          ['!=', 'mode', 'static']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': '#0055c7',
          'line-width': 3
        }
      },
      {
        'id': 'gl-draw-polygon-stroke-active',
        'type': 'line',
        'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': '#fbb03b',
          'line-dasharray': [0.2, 2],
          'line-width': 2
        }
      },
      {
        'id': 'gl-draw-line-inactive',
        'type': 'line',
        'filter': ['all',
          ['==', 'active', 'false'],
          ['==', '$type', 'LineString'],
          ['!=', 'mode', 'static']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': '#0055c7',
          'line-width': 2
        }
      },
      {
        'id': 'gl-draw-line-active',
        'type': 'line',
        'filter': ['all',
          ['==', '$type', 'LineString'],
          ['==', 'active', 'true']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': '#fbb03b',
          'line-dasharray': [0.2, 2],
          'line-width': 2
        }
      },
      {
        'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
        'type': 'circle',
        'filter': ['all',
          ['==', 'meta', 'vertex'],
          ['==', '$type', 'Point'],
          ['!=', 'mode', 'static']
        ],
        'paint': {
          'circle-radius': 5,
          'circle-color': '#fff'
        }
      },
      {
        'id': 'gl-draw-polygon-and-line-vertex-inactive',
        'type': 'circle',
        'filter': ['all',
          ['==', 'meta', 'vertex'],
          ['==', '$type', 'Point'],
          ['!=', 'mode', 'static']
        ],
        'paint': {
          'circle-radius': 3,
          'circle-color': '#fbb03b'
        }
      },
      {
        'id': 'gl-draw-point-point-stroke-inactive',
        'type': 'circle',
        'filter': ['all',
          ['==', 'active', 'false'],
          ['==', '$type', 'Point'],
          ['==', 'meta', 'feature'],
          ['!=', 'mode', 'static']
        ],
        'paint': {
          'circle-radius': 5,
          'circle-opacity': 1,
          'circle-color': '#fff'
        }
      },
      {
        'id': 'gl-draw-point-inactive',
        'type': 'circle',
        'filter': ['all',
          ['==', 'active', 'false'],
          ['==', '$type', 'Point'],
          ['==', 'meta', 'feature'],
          ['!=', 'mode', 'static']
        ],
        'paint': {
          'circle-radius': 3,
          'circle-color': '#0055c7'
        }
      },
      {
        'id': 'gl-draw-point-stroke-active',
        'type': 'circle',
        'filter': ['all',
          ['==', '$type', 'Point'],
          ['==', 'active', 'true'],
          ['!=', 'meta', 'midpoint']
        ],
        'paint': {
          'circle-radius': 7,
          'circle-color': '#fff'
        }
      },
      {
        'id': 'gl-draw-point-active',
        'type': 'circle',
        'filter': ['all',
          ['==', '$type', 'Point'],
          ['!=', 'meta', 'midpoint'],
          ['==', 'active', 'true']],
        'paint': {
          'circle-radius': 5,
          'circle-color': '#fbb03b'
        }
      },
      {
        'id': 'gl-draw-polygon-fill-static',
        'type': 'fill',
        'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
        'paint': {
          'fill-color': '#404040',
          'fill-outline-color': '#404040',
          'fill-opacity': 0.1
        }
      },
      {
        'id': 'gl-draw-polygon-stroke-static',
        'type': 'line',
        'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': '#404040',
          'line-width': 2
        }
      },
      {
        'id': 'gl-draw-line-static',
        'type': 'line',
        'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': '#404040',
          'line-width': 2
        }
      },
      {
        'id': 'gl-draw-point-static',
        'type': 'circle',
        'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
        'paint': {
          'circle-radius': 5,
          'circle-color': '#404040'
        }
      }
    ]
  }
};

<template>
  <div class="content-devx">
    <f7-block class="content__barsgraph">
      <DxChart
        id="chart"
        :data-source="dataSource"
        @legend-click="onLegendClick"
        @initialized="onInit"
      >
        <DxCommonSeriesSettings argument-field="date" />
        <DxSeries
          pane="top"
          :color="measureColor"
          :value-field="`avgmeasured${weatherElement}`"
          :name="measuredSeriesName"
          type="bar"
          :bar-width="50"
        />
        <DxSeries
          pane="top"
          :color="forecastColor"
          :value-field="`avgforecast${weatherElement}`"
          type="bar"
          :bar-width="50"
          :name="forecastSeriesName"
        />

        <!---INCLUIR LÓGICA DE EVAPOTRANSPIRACIÓN CUANDO ESTÉ DISPONIBLE. DE MOMENTO SE DESHABILITAN LOS EJES PUES NO TIENEN INFORMACIÓN-->
        <!--<DxSeries
          v-if="false"
          pane="top"
          type="line"
          color="#7b3294"
          value-field="avgmeasured"
          :name="`${$t('DxWeatherElementLinesGraph_average')} ${$t(
            'Evaporacion'
          )}, ${measurement}`"
        />
        <DxSeries
          v-if="false"
          pane="top"
          type="line"
          color="#7b329499"
          value-field="avgforecastedEvapotranspiración"
          :name="`${$t(
            'DxWeatherElementLinesGraph_totalForecasted'
          )} ${$t('Evaporacion')}, ${measurement}`"
        />-->

        <DxPane name="top" />
        <DxArgumentAxis
          discrete-axis-division-mode="crossLabels"
          argument-type="datetime"
        >
          <DxLabel
            display-mode="stagger"
            format="d MMM yyyy - hh:mm"
          />
        </DxArgumentAxis>
        <DxAdaptiveLayout
          :width="80"
          :keep-labels="true"
        />
        <DxValueAxis pane="top">
          <DxGrid :visible="true" />
          <DxTitle :text="`${$t(weatherElement)}, ${measurement}`" />
        </DxValueAxis>
        <DxLegend
          :margin="30"
          vertical-alignment="bottom"
          horizontal-alignment="center"
        />
        <DxExport :enabled="false" />
        <DxTooltip
          :enabled="true"
          :z-index="999999"
          :customize-tooltip="customizeTooltip"
        />
        <DxTitle :text="`${$t(weatherElement)}`" />
        <DxScrollBar :visible="true" />
        <DxZoomAndPan argument-axis="both" />
      </DxChart>
    </f7-block>
  </div>
</template>

<script>
import DxChart, {
  DxCommonSeriesSettings,
  DxSeries,
  DxPane,
  DxScrollBar,
  DxArgumentAxis,
  DxValueAxis,
  DxTitle,
  DxLegend,
  DxLabel,
  DxTooltip,
  DxZoomAndPan,
  DxExport,
  DxGrid,
  DxAdaptiveLayout,
} from 'devextreme-vue/chart';

export default {
  components: {
    DxChart,
    DxCommonSeriesSettings,
    DxSeries,
    DxPane,
    DxGrid,
    DxArgumentAxis,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxLabel,
    DxTooltip,
    DxScrollBar,
    DxZoomAndPan,
    DxExport,
    DxAdaptiveLayout,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    weatherElement: {
      type: String,
      default: 'precipitation',
    },
    applyClass: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      measureColor: '#0077c8',
      forecastColor: '#76c2f5',
      measurement: '',
    };
  },
  computed: {
    measuredSeriesName() {
      if (this.weatherElement === 'pluviometer') {
        return `${this.$t(this.weatherElement)}, ${this.measurement}`;
      }

      return `${this.$t('average')} ${this.$t(this.weatherElement)}, ${
        this.measurement
      }`;
    },
    forecastSeriesName() {
      if (this.weatherElement === 'pluviometer') {
        return `${this.$t(this.weatherElement)} ${this.$t('forecasted')}, ${
          this.measurement
        }`;
      }

      return `${this.$t('averageForecasted')} ${this.$t(
        this.weatherElement,
      )}, ${this.measurement}`;
    },
  },
  mounted() {
    setTimeout(() => {
      if (
        typeof this.dataSource !== 'undefined'
        && this.dataSource.length > 0
      ) {
        if (typeof this.dataSource[1].unit !== 'undefined') {
          const foundByWeatherElement = this.dataSource.find((el) => {
            const keys = Object.keys(el);
            for (let i = 0; i < keys.length; i += 1) {
              if (keys[i].includes(this.weatherElement)) {
                return el;
              }
            }
          });
          if (foundByWeatherElement) {
            switch (foundByWeatherElement.unit) {
              case 'meter_second':
                this.measurement = 'm/s';
                break;
              case 'liter_m2':
                this.measurement = 'L/m²';
                break;
              case 'percentage':
                this.measurement = '%';
                break;
              case 'celsius':
                this.measurement = '°C';
                break;
              case 'wat_m2':
                this.measurement = 'W/m²';
                break;
              default:
                break;
            }
          }
        }
      }
    }, 0);
  },
  methods: {
    onInit(e) {
      // delay al event loop
      setTimeout(() => {
        e.component.series.forEach((serie) => {
          if (this.$helpers.isEmptyObject(serie.pointsByArgument)) {
            serie.hide();
          }
        });
      }, 0);
    },
    customizeTooltip(pointInfo) {
      const seriesName = pointInfo.seriesName.split(',')[0];
      const seriesMeasureUnit = pointInfo.seriesName.split(',')[1].trim();

      return {
        text: `${seriesName} on <strong>${pointInfo.argument}</strong>
          :<strong>${pointInfo.value}</strong> ${this.measurement}`,
      };
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#chart-demo {
  height: 700px;
}
#chart {
  width: 100%;
  min-width: 600px;
  height: 400px;
  margin: 0 0 15px;
}
</style>

<template>
  <section class="converter-molecular-weight margin-top-half">
    <div class="content-devx">
      <div class="card-header padding-left-half">
        {{ $t('Utilities.converterMolecularWeight.title') }}
      </div>
      <div class="card-body main">
        <div class="top margin-vertical padding-horizontal">
          <DxRadioGroup
            ref="rgUnit"
            class="radio-buttons"
            :data-source="itemUnitsMolecularWeight"
            display-expr="label"
            layout="horizontal"
            @valueChanged="onChangeUnit"
          />
        </div>
        <div class="card-header padding-left-half">
          <b>{{ $t('Utilities.converterMolecularWeight.bottomTitle') }}</b>
        </div>
        <div class="right">
          <div class="dx-field-section">
            <div class="dx-field">
              <div class="dx-field-label text-align-right">
                {{ $t('Utilities.converterMolecularWeight.eq') }}
              </div>
              <div class="dx-field-value-static input-zoom">
                <DxNumberBox
                  ref="nbEq"
                  v-model="unitSelected.eq"
                  @keyUp="changeEq"
                />
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label text-align-right">
                {{ $t('Utilities.converterMolecularWeight.percent') }}
              </div>
              <div class="dx-field-value-static input-zoom">
                <DxNumberBox
                  ref="nbPercent"
                  v-model="unitSelected.percent"
                  @keyUp="changePercent"
                />
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label text-align-right">
                {{ $t('Utilities.converterMolecularWeight.mol') }}
              </div>
              <div class="dx-field-value-static  input-zoom">
                <DxNumberBox
                  ref="nbMol"
                  v-model="unitSelected.moles"
                  @keyUp="changeMol"
                />
              </div>
            </div>
            <DxButton
              :text="$t('Utilities.converterMolecularWeight.reset')"
              type="default"
              @click="cleanData"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import DxRadioGroup from 'devextreme-vue/radio-group';
import DxNumberBox from 'devextreme-vue/number-box';
import DxButton from 'devextreme-vue/button';
import { mapState } from 'vuex';

export default {
  name: 'ConverterMolecularWeight',
  components: {
    DxRadioGroup,
    DxNumberBox,
    DxButton,
  },
  props: [],
  data() {
    return {
      unitSelected: { },
      unitStatic: { },
    };
  },
  computed: {
    ...mapState('Converter', ['itemUnitsMolecularWeight']),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.$refs.rgUnit.instance.option('value', this.itemUnitsMolecularWeight[0]);
      this.unitSelected = { ...this.itemUnitsMolecularWeight[0] };
      this.unitStatic = { ...this.itemUnitsMolecularWeight[0] };
    },
    cleanData() {
      this.initData();
    },
    onChangeUnit(e) {
      this.unitStatic = { ...e.value };
      this.unitSelected = { ...this.unitStatic };
    },
    changeEq(e) {
      const value = parseFloat(e.event.target.value);
      this.unitSelected.percent = this.roundValue(value * this.unitStatic.percent);
      this.unitSelected.moles = this.roundValue(value);
    },
    changePercent(e) {
      const value = parseFloat(e.event.target.value);
      this.unitSelected.eq = this.roundValue(value / this.unitStatic.percent);
      this.unitSelected.moles = this.roundValue(value / this.unitStatic.percent);
    },
    changeMol(e) {
      const value = parseFloat(e.event.target.value);
      this.unitSelected.percent = this.roundValue(value * this.unitStatic.percent);
      this.unitSelected.eq = this.roundValue(value);
    },
    roundValue(val) {
      return parseFloat(val.toFixed(5));
    },
  },
};

</script>
<style src="./converterMolecularWeight.scss" scoped lang="scss"></style>

<template>
  <f7-page>
    <navbar text="Informe de análisis gis" />

    <ReportNotify
      v-show="reportMessage.length > 0"
      :message="reportMessage"
    />

    <f7-row class="hide-histogram-graph">
      <f7-col>
        <div id="histogram-graph-left">
          <HistogramGraph
            v-if="histogramLeftFormatted.histogram != null"
            :histogram="histogramLeftFormatted.histogram"
            :showing-date="histogramLeftFormatted.iso_date"
            :index="currentIndexes.gisLeft"
            :is-for-pdf="true"
          />
        </div>
      </f7-col>
      <f7-col>
        <div id="histogram-graph-right">
          <HistogramGraph
            v-if="histogramRightFormatted.histogram != null"
            :histogram="histogramRightFormatted.histogram"
            :showing-date="histogramRightFormatted.iso_date"
            :index="currentIndexes.gisRight"
            :is-for-pdf="true"
          />
        </div>
      </f7-col>
    </f7-row>

    <ReportStep2
      compare-page
      @refresh-data="refreshData()"
      @commentaryChanged="handleCommentaryChange"
      @data-climates="handleClimateData"
    />

    <div class="margin">
      <f7-row>
        <f7-col>
          <f7-button
            class="dx-btn-cancel"
            type="default"
            text-color="grey"
            styling-mode="outlined"
            name="ButtonCancel"
            @click="goBack()"
          >
            {{ $t('Button_go_to_back') }}
          </f7-button>
        </f7-col>
        <f7-col class="justify-content-end">
          <div>
            <f7-button
              class="dx-btn-success"
              raised
              type="success"
              styling-mode="contained"
              :disabled="downloading || sharing"
              @click="shareFile()"
            >
              Compartir
            </f7-button>
          </div>
          <div class="margin-left">
            <f7-button
              class="dx-btn-success"
              raised
              type="success"
              styling-mode="contained"
              :disabled="downloading || sharing"
              @click="downloadReport()"
            >
              {{ $t('nutritionDetail.report.download') }}
            </f7-button>
          </div>
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { Device } from 'framework7/framework7-lite.esm.bundle';
import navbar from '../../../components/NavBar.vue';
import Api from '../../../services/Api';
import PDFMakerGisAnalysis from '../../../js/pdf-maker-gis-analysis';
import HistogramGraph from '../../../components/searcher/gis/histogramGraph/index.vue';
import ReportStep2 from '../../../components/searcher/gis/reports/reportStep2.vue';
import ReportNotify from '../../../components/reportNotify/index.vue';

export default {
  name: 'AnalysisReportGis',
  components: {
    navbar,
    HistogramGraph,
    ReportStep2,
    ReportNotify,
  },
  data() {
    return {
      histogramLeftFormatted: {},
      histogramRightFormatted: {},
      lastPdf: null,
      lastFile: null,
      pdfmakergisanalysis: null,
      sharing: false,
      downloading: false,
      reportMessage: '',
      commentary: '',
      climatesData: [],
    };
  },
  computed: {
    //  // Datos para el parametro env del endpoint get_print para el dibujo del mapa en el pdf
    getEnvRightData() {
      return `min:${this.histogramRightFormatted.min};stddev:${this.histogramRightFormatted.stddev};mean:${this.histogramRightFormatted.mean};max:${this.histogramRightFormatted.max}`; 
    },
    getEnvLeftData() {
      return `min:${this.histogramLeftFormatted.min};stddev:${this.histogramLeftFormatted.stddev};mean:${this.histogramLeftFormatted.mean};max:${this.histogramLeftFormatted.max}`; 
    },
    ...mapState('Plantation', [
      'sectorIdLeftComparator',
      'sectorIdRightComparator',
      'LeftComparatorCompanyId',
      'RightComparatorCompanyId',
      'LeftComparatorPlantationId',
      'RightComparatorPlantationId',
    ]),
    ...mapState('Gis', ['currentIndexes', 'currentInitIntervalDates', 'datesList', 'currentGisComparatorLeft', 'currentGisComparatorRight']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('ReportGis', ['initDate', 'endDate']),
    ...mapState('authentication', ['user']),
    ...mapState('TimeDimension', ['selectedObservations', 'selectedTasks']),
    getNextButtonText() {
      return this.$t('nutritionDetail.report.download');
    },
  },
  async beforeMount() {
    this.histogramLeftFormatted = await this.getFormattedHistogram(
      this.sectorIdLeftComparator,
      moment(this.currentInitIntervalDates.gisLeft ?? this.datesList.avaiable_dates[this.datesList.avaiable_dates.length - 1]).format('YYYY-MM-DD'),
      this.LeftComparatorCompanyId,
      this.currentIndexes.gisLeft,
    );

    this.histogramRightFormatted = await this.getFormattedHistogram(
      this.sectorIdRightComparator,
      moment(this.currentInitIntervalDates.gisRight ?? this.datesList.avaiable_dates[this.datesList.avaiable_dates.length - 1]).format('YYYY-MM-DD'),
      this.RightComparatorCompanyId,
      this.currentIndexes.gisRight,
    );

    await this.fetchIndexes();
  },
  methods: {
    ...mapActions('ReportGis', ['fetchTimelineIndexesToCompare']),
    reportInformation() {
      Api.report(
        {
          report: 'Informe comparativo de índices radiométricos entre parcelas',
          contact_code: this.user?.user?.contact?.code || '',
          contact_name: this.user?.user?.contact?.name || '',
          contact_surname: this.user?.user?.contact?.surname || '',
          plantations: [
            this.LeftComparatorPlantationId,
            this.RightComparatorPlantationId,
          ],
          user: this.user.user.id,
        },
      );
    },
    handleCommentaryChange(commentary) {
      this.commentary = commentary;
    },
    handleClimateData(climate) {
      this.climatesData = climate;
    },
    goBack() {
      this.$f7.views.main.router.navigate('/gisComparator/', {
        reloadCurrent: true,
      });
    },
    async fetchHistogram(location, date, company) {
      try {
        const xhr = await Api.getGeoServerTemperatureHistogram(location, date, company);
        
        return xhr.json().then((res) => res.data);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    async getFormattedHistogram(sectorId, date, company, currentIndex) {
      const histogram = await this.fetchHistogram(
        sectorId,
        moment(date).format('YYYY-MM-DD'),
        company,
      );

      if (histogram.length === 0) return {};

      const histogramData = histogram[0].values.find(
        (item) => item.index === currentIndex.toLowerCase(),
      );

      return {
        histogram: histogramData.histogram,
        iso_date: histogram[0].date,
        max: histogramData?.max,
        mean: histogramData?.mean,
        min: histogramData?.min,
        stddev: histogramData?.stddev
      };
    },
    async fetchIndexes() {
      if (this.initDate === '' || this.endDate === ''){
        this.downloading = true;
        return;
      }

      const left = {
        location: this.sectorIdLeftComparator,
        initDate: moment(this.initDate).format('YYYY-MM-DD'),
        endDate: moment(this.endDate).format('YYYY-MM-DD'),
        companyId: this.LeftComparatorCompanyId,
        currentIndex: this.currentIndexes.gisLeft,
      };

      const right = {
        location: this.sectorIdRightComparator,
        initDate: moment(this.initDate).format('YYYY-MM-DD'),
        endDate: moment(this.endDate).format('YYYY-MM-DD'),
        companyId: this.RightComparatorCompanyId,
        currentIndex: this.currentIndexes.gisRight,
      };

      try {
        await this.fetchTimelineIndexesToCompare({ left, right });
        this.downloading = false;
      } catch (error) {
        this.downloading = true;
        this.$notifyDX(
          {
            message: error.detail || this.$t('downloadReportError'),
            width: 550,
          },
          'error',
          3000,
        );
      }

    },
    async refreshData() {
      await this.fetchIndexes();
    },
    getStatusCampaign(initDate, endDate) {
      const formatedInit = moment(initDate).format('YYYY-MM-DD');
      const formatedEnd = moment(endDate).format('YYYY-MM-DD');

      if (moment(this.actualDate).isBetween(formatedInit, formatedEnd, 'days', '[]')) {
        return this.$t('status_campania_active');
      } if (moment(formatedEnd).isBefore(this.actualDate)) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    async downloadReport() {
      this.$fa.logEvent('analisis_gis_descargar', {
        initDate: this.initDate, 
        endDate: this.endDate,
        content_type: 'button',
        description: 'Evento ocurre en la vista de Análisis/gis y pulsamos el boton descargar',
      });
      if (this.initDate === '' || this.endDate === '') {
        this.$notifyDX(
          {
            message: this.$t('noDateRangeSelected'),
            width: 450,
          },
          'warning',
          2000,
        );
        return;
      }

      this.downloading = true;

      this.reportMessage = this.$t('generatingPDF');

      this.pdfmakergisanalysis = new PDFMakerGisAnalysis();

      await this.generatePDF();

      const random = moment().format('YYMMDDHHmmss');

      const title = `INFORME COMPARATIVO DE ÍNDICES RADIOMÉTRICOS ENTRE PARCELAS (${random})`;

      this.reportMessage = this.$t('downloadingPDF');

      if (Device.desktop) {
        await this.pdfmakergisanalysis.buildAndDownload(title);
        this.reportMessage = '';
      } else {
        await new Promise((resolve) => {
          const pdfdata = this.pdfmakergisanalysis.buildForMobile();

          pdfdata.getBase64((base64) => {
            const path = `${cordova.file.externalRootDirectory}Download/`;
            const fileExtension = '.pdf';

            this.savebase64AsPDF(
              path,
              title + fileExtension,
              base64,
              'application/pdf',
            );

            resolve();
          });
        });
      }

      this.reportInformation();
      // this.reportMessage = '';
      this.downloading = false;
    },
    async fetchDiagramsImagesComparator() {
      try {
        const [diagramLeftXhr, diagramRightXhr] = await Promise.all([
          Api.getGeoServerCallServices(
            this.LeftComparatorCompanyId,
            `${Api.getGeoServerDiagramBar()}`,
            `?histogram=true&server=${Api.getBaseUrlGis()}&client=timacagro&location=${this.sectorIdLeftComparator}&init_date=${this.currentInitIntervalDates.gisLeft}&index=${this.currentIndexes.gisLeft.toUpperCase()}&histogram_select=both`
          ),
          Api.getGeoServerCallServices(
            this.RightComparatorCompanyId,
            `${Api.getGeoServerDiagramBar()}`,
            `?histogram=true&server=${Api.getBaseUrlGis()}&client=timacagro&location=${this.sectorIdRightComparator}&init_date=${this.currentInitIntervalDates.gisRight}&index=${this.currentIndexes.gisRight.toUpperCase()}&histogram_select=both`
          )
        ]);

        const [imageLeft, imageRight] = await Promise.all([
          this.imageToDataResponse(diagramLeftXhr),
          this.imageToDataResponse(diagramRightXhr)
        ]);

        return { imageLeft, imageRight };

      } catch (error) {
        setTimeout(() => {
          this.$notifyDX(
            {
              message: this.$t(this.$helpers.getFilteredErrorMessage(`${error.message} wms`)),
              width: 550,
            },
            'warning',
            3000
          );
        }, 0);
      }
    },
    async fetchClimateData() {
      const data = {
        initDate: moment(this.initDate).format('YYYY-MM-DDT00:00:00'),
        endDate: moment(this.endDate).format('YYYY-MM-DDT00:00:00'),
        grouped: 'DAY',
        formula: '',
        sectorIdLeft: this.sectorIdLeftComparator,
        sectorIdRight: this.sectorIdRightComparator,
      };

      try {
        const imageXhr = await Api.getGeoServerCallServicesClimateData(
          this.LeftComparatorCompanyId,
          `${Api.getGeoServerClimateData()}`,
          `?location=${data.sectorIdLeft},${data.sectorIdRight}&init_date=${data.initDate}&end_date=${data.endDate}&grouped=${data.grouped}&formula=${data.formula}`,
          this.climatesData
          )

        const imageDataUrl = await this.imageToDataResponse(imageXhr);
        return { image: imageDataUrl } ;
      } catch (error) {
        setTimeout(() => {
          this.$notifyDX(
            {
              message: this.$t(this.$helpers.getFilteredErrorMessage(`${error.message} wms`)),
              width: 550,
            },
            'warning',
            3000
          );
        }, 0);
      }
    },
    async fetchIndexDates() {
      const initDate = moment(this.initDate).format('YYYY-MM-DD');
      const endDate = moment(this.endDate).format('YYYY-MM-DD');

      try {
        // Filtrar y transformar selectedTasks con la estructura que necesitamos
        const tasks = this.selectedTasks.map(task => ({
          date: task.init_date,
          title: task.activity,
        }));

        // Filtrar y transformar selectedObservations con la estructura que necesitamos
        const observations = this.selectedObservations.map(observation => ({
          date: observation.date,
          title: observation.scouting_point.name,
        }));

        // Construir el parámetro tasks_reports solo si hay datos
        let tasksReportsParam = '';
        const hasTasks = tasks.length > 0;
        const hasObservations = observations.length > 0;

        if (hasTasks || hasObservations) {
          const tasksReports = {
            ...(hasTasks && { task: tasks }),  // Añade 'task' solo si hay tareas
            ...(hasObservations && { observation: observations })  // Añade 'observation' solo si hay observaciones
          };

          // Convertir tasksReports a un string JSON para pasarlo como parámetro en la URL
          tasksReportsParam = encodeURI(JSON.stringify(tasksReports));
        }

        // Construimos manualmente el endpoint
        let url = `?histogram=true&client=timacagro&location=${this.sectorIdLeftComparator},${this.sectorIdRightComparator}&init_date=${initDate}&end_date=${endDate}&index=${this.currentIndexes.gisLeft.toUpperCase()},${this.currentIndexes.gisRight.toUpperCase()}`;

        // Añadir tasks_reports a la URL si está definido
        if (tasksReportsParam) {
          url += `&tasks_reports=${tasksReportsParam}`;
        }

        // Llamada a la API con la URL construida
        const imageXhr = await Api.getGeoServerCallServices(
          this.LeftComparatorCompanyId,
          `${Api.getGeoServerIndexDates()}`,
          url
        );

        const imageDataUrl = await this.imageToDataResponse(imageXhr);

        return { image: imageDataUrl };
      } catch (error) {
        setTimeout(() => {
          this.$notifyDX(
            {
              message: this.$t(this.$helpers.getFilteredErrorMessage(`${error.message} wms`)),
              width: 550,
            },
            'warning',
            3000
          );
        }, 0);
      }
    },
    async generatePDF() {
      // Creamos las promesas para obtener las URLs y poder usarlas
      const leftUrlPromise = this.getImageUrl(
        this.sectorIdLeftComparator,
        this.currentInitIntervalDates.gisLeft ?? moment(this.datesList.avaiable_dates[this.datesList.avaiable_dates.length - 1]).format('YYYY-MM-DD'),
        this.currentIndexes.gisLeft,
        this.getEnvLeftData,
        this.LeftComparatorCompanyId,
      );

      const rightUrlPromise = this.getImageUrl(
        this.sectorIdRightComparator,
        this.currentInitIntervalDates.gisRight ?? moment(this.datesList.avaiable_dates[this.datesList.avaiable_dates.length - 1]).format('YYYY-MM-DD'),
        this.currentIndexes.gisRight,
        this.getEnvRightData,
        this.RightComparatorCompanyId,
      );

      // Usamos promise all para ejecutar las promesas en paralelo
      const [leftUrl, rightUrl] = await Promise.all([leftUrlPromise, rightUrlPromise]);

      // Continuamos con la generación de las imágenes y la obtención de datos de las plantaciones para poder usarlas con un promise all
      const leftImagePromise = this.imageToDataResponse(leftUrl, this.$t('noImagesToDisplayLeft'));
      const rightImagePromise = this.imageToDataResponse(rightUrl, this.$t('noImagesToDisplayRight'));
      const leftPlantationXhrPromise = Api.getPlantationById(this.LeftComparatorCompanyId, this.LeftComparatorPlantationId);
      const rightPlantationXhrPromise = Api.getPlantationById(this.RightComparatorCompanyId, this.RightComparatorPlantationId);

       // Usamos promise all para ejecutar en paralelo y para esperar a que todas las promesas se resuelvan
      const [leftImage, rightImage, leftPlantationXhr, rightPlantationXhr] = await Promise.all([
        leftImagePromise,
        rightImagePromise,
        leftPlantationXhrPromise,
        rightPlantationXhrPromise
      ]);

      
      const leftPlantation = leftPlantationXhr.response.length > 0 ? JSON.parse(leftPlantationXhr.response) : null;
      const rightPlantation = rightPlantationXhr.response.length > 0 ? JSON.parse(rightPlantationXhr.response) : null;

      const leftPlantationArea = parseFloat(leftPlantation?.farms?.[0]?.properties?.find((item) => item.key === 'area')?.value ?? 0).toFixed(2);
      const rightPlantationArea = parseFloat(rightPlantation?.farms?.[0]?.properties?.find((item) => item.key === 'area')?.value ?? 0).toFixed(2);

      this.pdfmakergisanalysis.insertReportTitle('INFORME COMPARATIVO DE ÍNDICES RADIOMÉTRICOS ENTRE PARCELAS');

      this.pdfmakergisanalysis.insertTableWithoutBorders([
        [
          {
            title: 'Plantación 1',
            text: '',
          },
          {
            title: 'Plantación 2',
            text: '',
          },
        ],
        [
          {
            title: this.$t('DxClients_client'),
            text: leftPlantation.company_name,
          },
          {
            title: this.$t('DxClients_client'),
            text: rightPlantation.company_name,
          },
        ],
        [
          {
            title: this.$t('searchEngine_farm'),
            text: leftPlantation.explotation,
          },
          {
            title: this.$t('searchEngine_farm'),
            text: rightPlantation.explotation,
          },
        ],
        [
          {
            title: this.$t('Parcel'),
            text: leftPlantation.parcel,
          },
          {
            title: this.$t('Parcel'),
            text: rightPlantation.parcel,
          },
        ],
        [
          {
            title: this.$t('Sector'),
            text: leftPlantation.sector_name,
          },
          {
            title: this.$t('Sector'),
            text: rightPlantation.sector_name,
          },
        ],
        [
          {
            title: this.$t('Plantation'),
            text: leftPlantation.name,
          },
          {
            title: this.$t('Plantation'),
            text: rightPlantation.name,
          },
        ],
        [
          {
            title: '',
            text: '',
          },
          {
            title: '',
            text: '',
          },
        ],
        [
          {
            title: this.$t('nutritionDetail.report.cropType'),
            text: leftPlantation.species,
          },
          {
            title: this.$t('nutritionDetail.report.cropType'),
            text: rightPlantation.species,
          },
        ],
        [
          {
            title: this.$t('nutritionDetail.report.variety'),
            text: leftPlantation.variety,
          },
          {
            title: this.$t('nutritionDetail.report.variety'),
            text: rightPlantation.variety,
          },
        ],
        [
          {
            title: this.$t('nutritionDetail.report.date'),
            text: `${moment(leftPlantation.init_date).format('DD/MM/YYYY')} - ${moment(leftPlantation.end_date).format('DD/MM/YYYY')}`,
          },
          {
            title: this.$t('nutritionDetail.report.date'),
            text: `${moment(rightPlantation.init_date).format('DD/MM/YYYY')} - ${moment(rightPlantation.end_date).format('DD/MM/YYYY')}`,
          },
        ],
        [
          {
            title: this.$t('nutritionDetail.report.area'),
            text: `${leftPlantationArea} ha`,
          },
          {
            title: this.$t('nutritionDetail.report.area'),
            text: `${rightPlantationArea} ha`,
          },
        ],
        [
          {
            title: this.$t('nutritionDetail.report.clouds'),
            text: this.currentGisComparatorLeft,
          },
          {
            title: this.$t('nutritionDetail.report.clouds'),
            text: this.currentGisComparatorRight,
          },
        ],
      ]);

      this.pdfmakergisanalysis.inserTableSatelliteImages([[leftImage, rightImage]]);

      // Aquí llamamos a la nueva función fetchDiagramsImagesComparator
      const { imageLeft, imageRight } = await this.fetchDiagramsImagesComparator();

      // Pasamos las imágenes obtenidas a insertImagesDiagram
      await this.pdfmakergisanalysis.insertImagesDiagram(imageLeft, imageRight);

       // Construimos el histograma de datos climaticos 
      const fetchChartPromise = this.climatesData.length > 0 ? this.fetchClimateData() : Promise.resolve(null);
      const [chart, indexDates] = await Promise.all([
        fetchChartPromise,
        this.fetchIndexDates()
      ]);
      
      if (chart) {
        await this.pdfmakergisanalysis.insertChart(chart);
      }

      await this.pdfmakergisanalysis.insertChart(indexDates);

      const AtcName = `${this.user.user.contact.name} ${this.user.user.contact.surname}`;

      if (this.selectedObservations.length > 0) {
        const observations = await Promise.all(
        this.selectedObservations.map(async (item, index) => {
          let leftObservation = null;
          let rightObservation = null;

          // Hacemos la condicion para hacer la peticion del seleccionado que corresponda con la compañia de la izquierda
          if (item.company_id === this.LeftComparatorCompanyId) {
            const leftResponse = await Api.getObservationDetail(this.LeftComparatorCompanyId, item.id);
            leftObservation = JSON.parse(leftResponse.response);
          }

           // Hacemos la condicion para hacer la peticion del seleccionado que corresponda con la compañia de la derecha
          if (item.company_id === this.RightComparatorCompanyId) {
            const rightResponse = await Api.getObservationDetail(this.RightComparatorCompanyId, item.id);
            rightObservation = JSON.parse(rightResponse.response);
          }

          // Combinamos los resultados de ambas llamadas
          const combinedObservation = { ...leftObservation, ...rightObservation };

          const comment = [];

          combinedObservation.template_form.forEach((element) => {
            if (combinedObservation[element.field] !== null && combinedObservation[element.field]?.length > 0) {
                comment.push([element.description, combinedObservation[element.field]]);
              }
            });

            const multimedia = await Promise.all(
              combinedObservation.multimedia.filter(
                (extension) => ['.jpg', '.png', '.JPG', '.PNG'].includes(extension.extension)
              ).map(async (image) => {
                image.base64 = await this.imageToDataUrl(image.attachment);
                return image;
              })
            );

          return {
            id: combinedObservation.id,
            title: `Observación ${index + 1}:`,
            date: combinedObservation.date,
            multimedia: multimedia,
            comment,
          };
        }),
      );
        this.pdfmakergisanalysis.insertObservations(observations);
      }

      this.pdfmakergisanalysis.insertTableBottomSignature([
        [
          ['Nombre del ATC', AtcName],
          ['Fecha', moment().format('DD/MM/YYYY')],
          ['Firmado', ''],
        ],
      ]);

      // creamos constante para controlar si el comentario no esta vacio
      const commentaryIsNotEmpty = String(this.commentary).trim() !== '';
      // Si no esta vacio metemos el comentario al descargar PDF
      if (commentaryIsNotEmpty) {
        this.pdfmakergisanalysis.insertTableBottomComments([
          [
            [this.commentary],
          ],
        ]);
      }
    },
    async shareFile() {
      this.$fa.logEvent('analisis_gis_compartir', {
        initDate: this.initDate, 
        endDate: this.endDate,
        content_type: 'button',
        description: 'Evento ocurre en la vista de Análisis/gis y pulsamos el boton compartir',
      });
      if (this.initDate === '' || this.endDate === '') {
        this.$notifyDX(
          {
            message: this.$t('noDateRangeSelected'),
            width: 450,
          },
          'warning',
          2000,
        );
        return;
      }
      this.sharing = true;

      this.reportMessage = this.$t('generatingPDFShare');

      this.pdfmakergisanalysis = new PDFMakerGisAnalysis();

      await this.generatePDF();

      const random = moment().format('YYMMDDHHmmss');

      const title = `INFORME COMPARATIVO DE ÍNDICES RADIOMÉTRICOS ENTRE PARCELAS (${random})`;

      this.$f7.dialog.confirm(this.$t('confirmSharePdf'), async () => {
        const buffer = await this.pdfmakergisanalysis.getPdfAsBuffer();

        const pdf = new File([buffer], `${title}.pdf`, { type: 'application/pdf' });
        const files = [pdf];

        if (navigator.canShare) {
          try {
            await navigator.share({
              files,
              title,
              text: '',
            });
            this.reportMessage = '';
          } catch (error) {
            this.reportMessage = '';
            this.$notifyDX(
              {
                message: error.message,
                width: 550,
              },
              'error',
              3000,
            );
          }
        } else {
          try {
            await new Promise((resolve) => {
              const pdfdata = this.pdfmakergisanalysis.buildForMobile();

              pdfdata.getBase64((base64) => {
                const path = `${cordova.file.externalRootDirectory}Download/`;
                const fileExtension = '.pdf';

                this.savebase64AsPDFAndShare(
                  path,
                  title + fileExtension,
                  base64,
                  'application/pdf',
                  title,
                );

                resolve();
              });
            });
          } catch (error) {
            this.reportMessage = '';
            this.$notifyDX(
              {
                message: error.message,
                width: 550,
              },
              'error',
              3000,
            );
          }
        }
      });

      // this.reportMessage = '';
      this.sharing = false;
    },
    async getImageUrl(sectorId, date, index, env, companyId) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      try {
        const xhr = await Api.getGeoServerCallServices(
          companyId,
          `${Api.getGeoServerPrintMap()}`, 
          `?ws=${Api.getGeoServerWorkspace()}&resize=480&dimension=480&quality=50&layer=${sectorId}&date=${formattedDate}&index=${index}&env=${env}`,
        );
        return xhr
      } catch (error) {
        setTimeout(() => {
          this.$notifyDX(
            {
              message: this.$t(this.$helpers.getFilteredErrorMessage(`${error.message} wms`)),
              width: 550,
            },
            'warning',
            3000,
          );
        }, 0);
      }
    },
    async imageToDataResponse(xhr, errorMessage = '') {
      //TODO el errorMessage ya lo estoy recogiendo en el método getImageURL, por lo que habria
      //que recogerlo en ese punto mejor.
      try {
        const blob = await xhr.blob(); //Dentro del promise
        const reader = new FileReader(); //Dentro del promise

        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = () => {
            reject(new Error('Error al generar la imagen'));
          };
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        this.reportMessage = errorMessage;
        return Promise.reject(error);
      }
    },
    async imageToDataUrl(path, errorMessage = '') {
      try {
        const blob = await fetch(path).then((res) => res.blob());
        const reader = new FileReader();

        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        this.reportMessage = errorMessage;
      }

      return new Promise((resolve) => resolve(''));
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    savebase64AsPDF(folderpath, filename, content, contentType) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter(
            (fileWriter) => {
              fileWriter.write(DataBlob);
              this.reportMessage = this.$t('FileDownloadedInDownloads');

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
            () => {
              this.reportMessage = this.$t('SheetExport_sheet_export_error');

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
          );
        });
      });
    },
    savebase64AsPDFAndShare(folderpath, filename, content, contentType, message) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter(
            (fileWriter) => {
              fileWriter.write(DataBlob);
              const fileName = folderpath + filename;

              window.plugins.socialsharing.shareWithOptions({
                message,
                files: [fileName],
              });

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
            () => {
              this.reportMessage = this.$t('SheetExport_sheet_export_error');

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
          );
        });
      });
    },
  },
};
</script>

<style scoped>
.hide-histogram-graph {
  width: 100%;
  position: absolute;
  z-index: -1000;
  top: -1000000px;
}

#chart {
  height: auto;
}

.dx-btn-cancel {
  width: 30%;
}

.dx-btn-success {
  width: 100% !important;
  margin-left: 0 !important;
}
</style>
<template>
  <div class="main-gis-wms-map">
    <div class="content-gis-map">
      <div
        :id="`${nameMap}_${initialDate}`"
        :style="minHeight"
      />
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import * as turf from '@turf/turf';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import { Device } from 'framework7/framework7-lite.esm.bundle';
import Api from '../../../services/Api';
import EventBus from '../../../js/event-bus';

export default {
  name: 'GISWMSMap',
  props: {
    zoom: { type: Number, default: 1 },
    height: { type: String, default: '80vh' },
    nameMap: { type: String, default: '' },
    index: { type: String, default: 'NDVI' },
    layer: {
      type: Object,
      default: () => {},
    },
    center: {
      type: Object,
      default: () => {},
    },
    bbox: {
      type: Object,
      default: () => {},
    },
    userLocation: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      map: null,
      initialDate: Date.now(),
      currentLayers: [],
      textbox: null,
      currentDate: null,
      currentIndex: null,
      statusMeasureTool: false,
      statusZoningTool: false,
      areaLayer: null,
      lineLayer: null,
      areaLayerPopup: null,
      lineLayerPopup: null,
      toggleZoningButtonclicks: null,
      downloadImage: null,
      images: [],
      markerLayer: L.layerGroup(),
      radiusLayer: L.layerGroup(),
      svgIcon: `<svg viewbox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
  <circle cx="20" cy="20" fill="none" r="10" stroke="#06C6FF" stroke-width="2">
    <animate attributeName="r" from="8" to="20" dur="1.5s" begin="0s" repeatCount="indefinite"/>
    <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite"/>
  </circle>
  <circle cx="20" cy="20" fill="#067CEF" r="10"/>
</svg>
`,
    };
  },
  computed: {
    histogramData() {
      return this.histogram ? this.histogram[0] : undefined;
  },
    minHeight() {
      return `min-height: ${this.height}`;
    },
     // Si indice es distinto a ndvi cont carga los valores por defecto y si no lo que nos viene del backend
    getLayerData() {
      return this.currentIndex !== 'NDVI_CONT' ? 'min:0.25;stddev:0.1;mean:0.5;max:1' : `min:${this.histogram[0].min};stddev:${this.histogram[0].stddev};mean:${this.histogram[0].mean};max:${this.histogram[0].max}`;
    },
    ...mapState('Gis', [
      'osm',
      'googleHybrid',
      'currentOverlayLayer',
      'featureInfo',
      'datesList',
    ]),
    // ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Plantation', ['currentPlantation', 'treeLocations']),
    ...mapState('Gis', ['histogram']),
  },
  beforeMount() {
    EventBus.$on(`indexLayer${this.nameMap}`, this.indexLayer);
    EventBus.$on(`updateDateLayer${this.nameMap}`, this.dateLayer);
  },
  mounted() {
    this.setCurrentLayersMap([]);
    this.renderMap();
    this.renderGeoJson();
    this.addOverlayLayer();
    this.addFuncionalities();
    this.updateLayer();
    this.indexLayer(this.index);
    this.setMapEvents();
  },
  watch: {
    // Para ejecutarlo cada vez que el histograma cambia de valores y lo detecte
    histogramData: {
      handler(newHistogram, oldHistogram) {
        if (this.histogramChanged(oldHistogram, newHistogram)) {
          if (typeof this.histogramData !== 'undefined') {
            this.EnvLayer();
          }
        }
      },
      immediate: false
    }
  },
  methods: {
    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        this.layer.options.env = this.getLayerData;
        const layer = L.tileLayer.wms(this.layer.baseUrl, this.layer.options);
        if (this.$helpers.isEmptyObject(this.bbox)) {
          this.map.setView(
            [this.center.latitude, this.center.longitude],
            this.zoom,
          );
        } else {
          const bounds = L.latLngBounds([
            L.latLngBounds(
              [this.bbox.miny, this.bbox.minx],
              [this.bbox.maxy, this.bbox.maxx],
            ),
          ]);

          this.map.fitBounds(bounds, { maxZoom: 18 });
        }

        const item = this.layer;
        this.currentLayers.push({ item, layer });
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },

    indexLayer(index) {
      this.currentIndex = index;
      if (this.statusZoningTool) {
        this.currentLayers[0].layer
          .setParams({
            // Añadimos el indice en NDVI CONTRASTADO y en los demás indices añadimos la C de nubes delante
            styles: index === 'NDVI_CONT' ? index : `C${index}_variable`,
            env: index !== 'NDVI_CONT' ? 'min:0.25;stddev:0.1;mean:0.5;max:1' : `min:${this.histogram[0].min};stddev:${this.histogram[0].stddev};mean:${this.histogram[0].mean};max:${this.histogram[0].max}`
          })
          .addTo(this.map);
        const legendContent = '';
        this.map.legend.setContent(legendContent);
      } else {
        this.currentLayers[0].layer
          .setParams({
            // Añadimos el indice en NDVI CONTRASTADO y en los demás indices añadimos la C de nubes delante
            styles: index === 'NDVI_CONT' ? index : `C${index}`,
            env: index !== 'NDVI_CONT' ? 'min:0.25;stddev:0.1;mean:0.5;max:1' : `min:${this.histogram[0].min};stddev:${this.histogram[0].stddev};mean:${this.histogram[0].mean};max:${this.histogram[0].max}`
          })
          .addTo(this.map);
        let legendContent = `<h6 class="legend-index-name">${index}</h6><img src=${Api.getGeoServerProjectWorkSpace()}REQUEST=GetLegendGraphic&VERSION=2.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=10&LAYER=${
          this.layer.options.layers.split(':')[1]
        }&style=${index}&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:8;bgColor:0xFFFFFF;dpi:91&SCALE=1001; style="opacity:0.8";>`;
        if (index !== 'TCI') {
          this.map.legend.setContent(legendContent);
          const indexNameClass = document.getElementsByClassName('legend-index-name');

          if (index === 'NDMI' || index === 'VARI' || index === 'RECI') {
            indexNameClass[0].style.width = '40px';
            indexNameClass[0].style.top = '-46px';
          } else if(index === 'NDVI_CONT') {
            indexNameClass[0].style.width = '83px';
          } else {
            indexNameClass[0].style.width = '37px';
          }
        } else {
          legendContent = '';
          this.map.legend.setContent(legendContent);
        }
      }
    },

    dateLayer(date) {
      this.currentLayers[0].layer
        .setParams({
          time: date,
        })
        .addTo(this.map);
      const newFormatDate = this.formatDate(date);
      this.addTimeTextToMap(newFormatDate);
      this.currentDate = date;
    },

    histogramChanged(oldHistogram, newHistogram) {
      // Verificamos si los valores de histogram cambian
      return (
        oldHistogram.mean !== newHistogram.mean ||
        oldHistogram.min !== newHistogram.min ||
        oldHistogram.stddev !== newHistogram.stddev ||
        oldHistogram.max !== newHistogram.max
      );
    },
    // Funcion para añadir el parametro env con los nuevos valores segun el indice/fecha
    EnvLayer() {
      const { mean, min, stddev, max } = this.histogram[0];
      // Para indice distinto de NDVI_CONT el por defecto
      const defaultEnvParameter = 'min:0.25;stddev:0.1;mean:0.5;max:1';
      const envParameter = this.currentIndex !== 'NDVI_CONT' ? defaultEnvParameter : `min:${min};stddev:${stddev};mean:${mean};max:${max}`;

      // Añadidos el parametro env nuevo
      this.currentLayers[0].layer
        .setParams({
          env: envParameter
        })
        .addTo(this.map);
    },

    /**
     * Anidamos los datos para adjuntar en la ruta.
     */
    generateRouteTocenterMapWMSLayer(item) {
      const layersArray = item.options.layers.split(':');
      if (layersArray.length < 2) return '';
      const fullUrl = `getbbox/?ws=${layersArray[0]}&layer=${layersArray[1]}&ds=${item.ds}`;
      return fullUrl;
    },

    /**
     *
     */
    async dataRequestWMSLayers(route, e) {
      await this.fetchFeatureInfo({ queryParams: `wms/${route}` });
      if (
        !('features' in this.featureInfo)
        || !this.featureInfo.features.length
      ) {
        // return false;
      } else {
        const features = this.featureInfo.features[0];
        const { properties } = features;

        this.openLayerDataPopup(properties, e);
      }
    },

    /**
     *
     */
    generateRouteToDataRequestWMSLayers(item, e) {
      const BBOX = `${e.latlng.lng - 0.00002},${e.latlng.lat - 0.00002},${
        e.latlng.lng + 0.00002
      },${e.latlng.lat + 0.00002}`;
      // TODO: PONER PARÁMETRO TIME PARA CUANDO SE SELECCIONE UNA FECHA DE UNA CAPA
      const queryParams = {
        REQUEST: 'GetFeatureInfo',
        SRS: 'EPSG:4326',
        BBOX,
        HEIGHT: this.map.getSize().y,
        WIDTH: this.map.getSize().x,
        LAYERS: item,
        QUERY_LAYERS: item,
        INFO_FORMAT: 'application/json',
        X: Math.round(this.map.layerPointToContainerPoint(e.layerPoint).x),
        Y: Math.round(this.map.layerPointToContainerPoint(e.layerPoint).y),
      };
      if (this.currentDate != null) {
        queryParams.TIME = this.currentDate;
      }
      if (this.currentIndex != null) {
        // Añadimos C para que aparezcan las Nubes en el Mapa al hacer click en todos los indices menos NDVI_CONTRASTADO
        queryParams.STYLES = this.currentIndex === 'NDVI_CONT' ? this.currentIndex : `C${this.currentIndex}`;
      }

      const fullUrl = L.Util.getParamString(queryParams);
      this.dataRequestWMSLayers(fullUrl, e);
    },

    async updateLayer() {
      this.$f7.preloader.show();
      try {
        const currentOverlayLayer = this.layer.options.layers.split(':')[1];
        await this.fetchDatesLayer({ layer: currentOverlayLayer, companyId: this.companyId });
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
        const lastDate = this.datesList.avaiable_dates[this.datesList.avaiable_dates.length - 1];
        const dateNaNCheck = isNaN(Date.parse(lastDate));
        if (!dateNaNCheck) {
          const newFormatDate = this.formatDate(lastDate);
          this.addTimeTextToMap(newFormatDate);
        }
      }
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const day = dateObject.getDate();
      const month = dateObject.getMonth() + 1;
      const year = dateObject.getFullYear();
      const newFormatDate = `${day}/${month}/${year}`;
      return newFormatDate;
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`);
      this.map.options.maxZoom = 18;
      L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(
        this.map,
      );
      this.currentLayerBase = L.tileLayer(
        this.googleHybrid.route,
        this.googleHybrid.properties,
      ).addTo(this.map);
      this.currentLayerBase.bringToBack();
      this.markerLayer.addTo(this.map);
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Margaret from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>',
        );
        this.addHomeButton();
        this.addLegend();
        this.addOpacitySlider();
        // this.addZoningButton();
        this.addMeasureToolButton();
        this.addDownloadButton();
        if (this.userLocation) {
          this.addGPSButton();
        }
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(`${e}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    addHomeButton() {
      const self = this;
      L.Control.zoomHome = L.Control.extend({
        options: {
          position: 'topleft',
          zoomHomeText: '<i class="fg-search-home fa-2x"></i>',
          zoomHomeTitle: 'Zoom home',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomHomeButton = this.createButton(
            options.zoomHomeText,
            options.zoomHomeTitle,
            `${controlName}-home`,
            container,
            this.zoomHome,
          );

          return container;
        },

        zoomHome() {
          if (self.$helpers.isEmptyObject(self.bbox)) {
            self.map.setView(
              [self.center.latitude, self.center.longitude],
              self.zoom,
            );
          } else {
            const bounds = L.latLngBounds([
              L.latLngBounds(
                [self.bbox.miny, self.bbox.minx],
                [self.bbox.maxy, self.bbox.maxx],
              ),
            ]);

            self.map.fitBounds(bounds, { maxZoom: 18 });
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomHome = new L.Control.zoomHome();
      zoomHome.addTo(self.map);
    },
    addLegend() {
      const self = this;
      L.Control.legend = L.Control.extend({
        options: {
          position: 'bottomright',
        },
        onAdd() {
          self.map.legend = this;

          const container = L.DomUtil.create('div', 'legend-control-container');

          if (this.options.content) {
            container.innerHTML = this.options.content;
          }
          return container;
        },
        onRemove() {
          delete self.map.legend;
        },

        setContent(str) {
          this.getContainer().innerHTML = str;
        },
      });
      // eslint-disable-next-line new-cap
      const legend = new L.Control.legend();
      legend.addTo(self.map);
    },
    addZoningButton() {
      const self = this;
      const selector = L.control({
        position: 'topleft',
      });

      // eslint-disable-next-line func-names
      selector.onAdd = function () {
        const div = L.DomUtil.create('div', 'zoningSelector');
        div.innerHTML = '<div id="zoning-selector"><form id="zoning-form"><label class="input-min-label" for="Mininum">Min: </label><input id="zoning_input_min" type="number" min=-1 step="0.1" value="" placeholder="Escriba mínimo"></input><label class="input-max-label"for="Maximun">Max: </label><input id="zoning_input_max" type="number" step="0.1" max=1 value="" placeholder="Escriba máximo"></input></form><div class="button-container"><button id=zoning_button>Ejecutar</button><p class="modal-message"></p></div></div>';

        L.DomEvent.on(div, 'mousedown dblclick', L.DomEvent.stopPropagation).on(
          div,
          'click',
          L.DomEvent.stop,
        );
        return div;
      };

      selector.addTo(self.map);

      L.Control.zoningToggle = L.Control.extend({
        options: {
          position: 'topleft',
          zoningToggleText:
            '<i class="fg-layer-stat" style="font-size: 2.6em;"></i>',
          zoningToggleTitle: `${self.$t('Gis.Buttons.Zoning')}`,
        },
        onAdd() {
          const controlName = 'gin-control-zoning';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          self.zoningButton = this.createButton(
            options.zoningToggleText,
            options.zoningToggleTitle,
            `${controlName}-zoning`,
            container,
            this.zoningToggle,
          );

          return container;
        },

        zoningToggle() {
          const zoningSelector = document.getElementById('zoning-selector');
          self.toggleZoningButtonclicks += 1;
          if (self.toggleZoningButtonclicks % 2 === 0) {
            self.statusZoningTool = false;
            zoningSelector.style.display = 'none';
            self.indexLayer(self.currentIndex);
          } else {
            self.statusZoningTool = true;
            zoningSelector.style.display = 'block';
            const legendContent = '';
            self.map.legend.setContent(legendContent);
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoningToggle = new L.Control.zoningToggle();
      zoningToggle.addTo(self.map);
    },
    addOpacitySlider() {
      const self = this;
      L.Control.opacitySlider = L.Control.extend({
        update(value) {
          return value;
        },
        options: {
          title: `${self.$t('Gis.Buttons.Opacity')}`,
          position: 'bottomright',
          min: 0.0,
          max: 1.0,
          step: 0.1,
          id: 'opacitySlider',
          value: 1.0,
          orientation: 'vertical',
          getValue(value) {
            return value;
          },
          showValue: true,
          syncSlider: true,
        },
        initialize(f, options) {
          L.setOptions(this, options);
          if (typeof f === 'function') {
            this.update = f;
          } else {
            this.update = function (value) {
              self.currentLayers[0].layer.setOpacity(value);
            };
          }
          if (typeof this.options.getValue !== 'function') {
            this.options.getValue = function (value) {
              return value;
            };
          }
          if (this.options.orientation !== 'vertical') {
            this.options.orientation = 'horizontal';
          }
        },
        onAdd() {
          self.map.opacitySlider = this;
          this.initLayout();
          this.update(`${this.options.value}`);
          return this.container;
        },
        initLayout() {
          const className = 'leaflet-control-opacity-slider';
          this.container = L.DomUtil.create(
            'div',
            `${className} ${className}-${this.options.orientation}`,
          );
          if (this.options.showValue) {
            this.sliderValue = L.DomUtil.create(
              'p',
              `${className}-value`,
              this.container,
            );
            this.sliderValue.innerHTML = this.options.getValue(
              this.options.value,
            );
          }
          this.sliderContainer = L.DomUtil.create(
            'div',
            'leaflet-opacity-slider-container',
            this.container,
          );
          this.slider = L.DomUtil.create(
            'input',
            'leaflet-opacity-slider',
            this.sliderContainer,
          );
          if (this.options.orientation === 'vertical') {
            this.slider.setAttribute('orient', 'vertical');
          }
          this.slider.setAttribute('title', this.options.title);
          this.slider.setAttribute('id', this.options.id);
          this.slider.setAttribute('type', 'range');
          this.slider.setAttribute('min', this.options.min);
          this.slider.setAttribute('max', this.options.max);
          this.slider.setAttribute('step', this.options.step);
          this.slider.setAttribute('value', this.options.value);

          if (this.options.syncSlider) {
            L.DomEvent.on(
              this.slider,
              'input',
              function (e) {
                this.updateValue();
              },
              this,
            );
          } else {
            L.DomEvent.on(
              this.slider,
              'change',
              function (e) {
                this.updateValue();
              },
              this,
            );
          }

          L.DomEvent.disableClickPropagation(this.container);
        },
        updateValue() {
          this.value = this.slider.value;
          if (this.options.showValue) {
            this.sliderValue.innerHTML = this.options.getValue(this.value);
          }
          this.update(this.value);
        },
      });
      // eslint-disable-next-line new-cap
      const opacitySlider = new L.Control.opacitySlider();
      opacitySlider.addTo(self.map);
    },
    addGPSButton() {
      const self = this;
      L.Control.zoomLocation = L.Control.extend({
        options: {
          position: 'topleft',
          zoomLocationText: '<i class="fg-position fa-2x"></i>',
          zoomLocationTitle: `${self.$t('Gis.Buttons.CurrentPosition')}`,
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomLocationButton = this.createButton(
            options.zoomLocationText,
            options.zoomLocationTitle,
            `${controlName}-home`,
            container,
            this.zoomLocation,
          );

          return container;
        },

        zoomLocation() {
          let marker;
          self.map
            .locate({
              setView: true,
              maxZoom: 120,
              enableHighAccuracy: true,
              maximumAge: 10000,
            })
            .on('locationfound', (e) => {
              if (this.radiusLayer) {
                this.radiusLayer.clearLayers();
              }

              const latlng = [e.latitude, e.longitude];
              const layer = new L.LayerGroup();
              this.radiusLayer = layer;
              L.circle(latlng, e.accuracy, this.circleStyle).addTo(layer);

              // distancia
              const distanceInMeters = e.accuracy.toFixed(0);

              this.marker = L.marker(latlng);
              const customIcon = L.divIcon({
                html: self.svgIcon,
                className: 'location-pin',
                iconAnchor: [10, 20],
                iconSize: [20, 20],
              });
              this.marker.setIcon(customIcon);
              const popup = L.popup({ offset: [0, -10] })
                .setLatLng(latlng)
                .setContent(`Estás a ${distanceInMeters} metros de este punto`);
              this.marker.bindPopup(popup);

              this.marker.addTo(layer);
              this.radiusLayer.addTo(self.map);
            })
            .on('locationerror', () => {
              if (marker) {
                self.map.removeLayer(marker);
                marker = undefined;
              }
            });
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomLocation = new L.Control.zoomLocation();
      zoomLocation.addTo(self.map);
    },
    addMeasureToolButton() {
      const self = this;
      L.Control.measureArea = L.Control.extend({
        options: {
          position: 'topright',
          measureAreaText: '<i class="fg-measure-area fa-2x"></i>',
          measureAreaTitle: `${self.$t('Gis.Buttons.MeasureArea')}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-area';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureAreaButton = this.createButton(
            options.measureAreaText,
            options.measureAreaTitle,
            `${controlName}-measureArea`,
            container,
            this.measureArea,
          );

          return container;
        },

        measureArea() {
          self.map.pm.enableDraw('Polygon', {
            snappable: true,
            continueDrawing: false,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureArea = new L.Control.measureArea();

      L.Control.measureLineString = L.Control.extend({
        options: {
          position: 'topright',
          measureLineStringText: '<i class="fg-measure-line fa-2x"></i>',
          measureLineStringTitle: `${self.$t('Gis.Buttons.MeasureLineString')}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-line';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureLineStringButton = this.createButton(
            options.measureLineStringText,
            options.measureLineStringTitle,
            `${controlName}-measureLineString`,
            container,
            this.measureLineString,
          );

          return container;
        },

        measureLineString() {
          self.map.pm.enableDraw('Line', {
            snappable: true,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown click', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureLineString = new L.Control.measureLineString();

      L.Control.desactiveMeasure = L.Control.extend({
        options: {
          position: 'topright',
          desactiveMeasureText: '<i class="fas fa-times"></i>',
          desactiveMeasureTitle: `${self.$t(
            'Gis.Buttons.DesactiveMeasureMode',
          )}`,
        },
        onAdd() {
          const controlName = 'gin-control-desactive-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.desactiveMeasureText,
            options.desactiveMeasureTitle,
            `${controlName}-desactiveMeasure`,
            container,
            this.desactiveMeasure,
          );

          return container;
        },

        desactiveMeasure() {
          self.statusMeasureTool = false;
          self.measureArea.remove(self.map);
          self.measureLineString.remove(self.map);
          // eslint-disable-next-line no-use-before-define
          self.desactiveMeasure.remove(self.map);
          const buttonId = document.getElementById('activeMeasure');
          buttonId.style = 'display:block';
          if (self.areaLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.areaLayer);
          }
          if (self.lineLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.lineLayer);
          }
          self.map.pm.disableDraw();
          self.map.opacitySlider.addTo(self.map);
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.desactiveMeasure = new L.Control.desactiveMeasure();

      L.Control.activeMeasure = L.Control.extend({
        options: {
          position: 'topright',
          activeMeasureText: '<i class="fg-measure fa-2x"></i>',
          activeMeasureTitle: `${self.$t('Gis.Buttons.ActiveMeasureMode')}`,
        },
        onAdd() {
          const controlName = 'gin-control-active-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.activeMeasureText,
            options.activeMeasureTitle,
            `${controlName}-activeMeasure`,
            container,
            this.activeMeasure,
          );

          return container;
        },

        activeMeasure() {
          self.statusMeasureTool = true;
          self.measureArea.addTo(self.map);
          self.measureLineString.addTo(self.map);
          self.desactiveMeasure.addTo(self.map);
          // activeMeasure.remove(self.map);
          const buttonId = document.getElementById('activeMeasure');
          buttonId.style = 'display:none';
          self.map.opacitySlider.remove(self.map);
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.id = 'activeMeasure';
          link.href = '#';
          link.style = 'display:block';
          link.title = title;
          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.activeMeasure = new L.Control.activeMeasure();
      self.activeMeasure.addTo(self.map);
    },
    addDownloadButton() {
      this.$fa.logEvent('buscador_gis_i_radiometrico_di', {
        content_type: 'button',
        description: 'Evento de descarga de imagen de índice radiométrico',
      });
      const self = this;
      L.Control.downloadImage = L.Control.extend({
        options: {
          position: 'topleft',
          downloadImageText: '<i class="fg-layer-download fa-2x"></i>',
          downloadImageTitle: `${self.$t('Gis.Buttons.DownloadImage')}`,
        },
        onAdd() {
          const controlName = 'gin-control-download-image';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._downloadImageButton = this.createButton(
            options.downloadImageText,
            options.downloadImageTitle,
            `${controlName}-WMS`,
            container,
            this.downloadImage,
          );

          return container;
        },

        async downloadImage() {
          let lastDate;
          let formattedDate;
          if (self.currentDate === null) {
            lastDate = self.datesList.avaiable_dates[self.datesList.avaiable_dates.length - 1];
            formattedDate = moment(lastDate).format('YYYY-MM-DD');
          } else {
            formattedDate = moment(self.currentDate).format('YYYY-MM-DD');
          }
          const layerId = self.layer.options.layers.split(':');

          try {
            const xhr = await Api.getGeoServerCallServices(
              self.companyId, 
              `${Api.getGeoServerPrintMap()}`, 
              `?ws=${Api.getGeoServerWorkspace()}&resize=640&quality=100&layer=${layerId[1]}&date=${formattedDate}&index=${self.currentIndex}&env=${self.getLayerData}`,
            );
            await self.imageToDataResponse(xhr);
              if (self.images.length > 1) {
                self.images.shift();
              }
            self.downloadWMSImage();
          } catch (error) {
            setTimeout(() => {
              this.$notifyDX(
                {
                  message: this.$t(this.$helpers.getFilteredErrorMessage(`${error.message} wms`)),
                  width: 550,
                },
                'warning',
                3000,
              );
            }, 0);
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const downloadImage = new L.Control.downloadImage();
      downloadImage.addTo(self.map);
      self.downloadImage = downloadImage;
    },
    addTimeTextToMap(date) {
      const self = this;
      L.Control.textbox = L.Control.extend({
        options: {
          position: 'topright',
        },
        onAdd() {
          const text = L.DomUtil.create('div');
          text.id = 'info_text';
          text.innerHTML = `<strong>${date.fontcolor('white')}</strong>`;
          return text;
        },
      });
      if (self.textbox !== null) {
        self.textbox.remove(self.map);
      }
      // eslint-disable-next-line new-cap
      self.textbox = new L.Control.textbox();
      self.textbox.addTo(self.map);
    },
    openLayerDataPopup(properties, e, extra = {}) {
      let htmlString = "<div class='popup-content'>";
      htmlString += extra.before || '';
      let listHtml = '<ul>';
      listHtml += this.getLayerDataPropertiesHtml(properties);
      listHtml += '</ul>';
      htmlString += `${listHtml}</div>`;
      htmlString += extra.after || '';
      if (properties.Band1 !== 0) this.openPopup(htmlString, e.latlng);
    },
    getLayerDataPropertiesHtml(properties) {
      let listHtml = '';
      const bands = 'Band1';
      let result = 0;
      if (bands in properties) {
        switch (this.currentIndex) {
          case 'NDVI':
            result = (properties.Band8 - properties.Band4)
              / (properties.Band8 + properties.Band4);
            break;
          case 'NDVI_CONT':
            result = (properties.Band8 - properties.Band4)
              / (properties.Band8 + properties.Band4);
            break;
          case 'SAVI':
            result = ((properties.Band8 - properties.Band4)
                / (properties.Band8 + properties.Band4 + 0.428))
              * 0.428;
            break;
          case 'GNDVI':
            result = (properties.Band8 - properties.Band3)
              / (properties.Band8 + properties.Band3);
            break;
          case 'ARVI':
            result = (properties.Band8 - 0.106 * properties.Band4 + properties.Band2)
              / (properties.Band8 + 0.106 * properties.Band4 + properties.Band2);
            break;
          case 'AVI':
            result = (properties.Band8
                * (1 - properties.Band4)
                * (properties.Band8 - properties.Band4))
                ** 1
              / 3;
            break;
          case 'EVI':
            result = 2.5
              * ((properties.Band8 - properties.Band4)
                / (properties.Band8
                  + 6 * properties.Band4
                  - 7.5 * properties.Band2
                  + 1));
            break;
          case 'VARI':
            result = (properties.Band3 - properties.Band4)
              / (properties.Band3 + properties.Band4 - properties.Band2);
            break;
          case 'LAI':
            result = 0.57
              ** (2.33
                * ((properties.Band8 - properties.Band4)
                  / (properties.Band8 + properties.Band4)));
            break;
          case 'NDRE':
            result = (properties.Band8 - properties.Band5)
              / (properties.Band8 + properties.Band5);
            break;
          case 'RECI':
            result = (properties.Band8 - properties.Band11)
              / (properties.Band8 + properties.Band11);
            break;
          case 'NDMI':
            result = properties.Band8 / (properties.Band8 + properties.Band11);
            break;
          case 'MSI':
            result = properties.Band11 / properties.Band8;
            break;
          case 'GCI':
            result = properties.Band9 / properties.Band3 - 1;
            break;
          case 'NBRI':
            result = properties.Band8 / (properties.Band8 + properties.Band2);
            break;
          default:
          case 'BSI':
            result = properties.Band11
              + properties.Band4
              - (properties.Band8 + properties.Band2)
                / (properties.Band11 + properties.Band4)
              + (properties.Band8 + properties.Band2);
            break;
        }
        listHtml += `<li><b>${this.currentIndex}</b>: ${result.toFixed(
          2,
        )}</li>`;
        // eslint-disable-next-line no-prototype-builtins
      } else if (properties.hasOwnProperty('jiffle')) {
        listHtml += `<li><b>${
          this.currentIndex
        }</b>: ${properties.jiffle.toFixed(2)}</li>`;
      } else {
        for (const property in properties) {
          // eslint-disable-next-line no-continue
          if (properties[property] == null) continue;
          if (typeof properties[property] === 'object') {
            listHtml += this.getLayerDataPropertiesHtml(properties[property]);
          } else {
            listHtml += `<li><b>${property}</b>: ${properties[property]}</li>`;
          }
        }
      }
      return listHtml;
    },
    openPopup(html, latlng) {
      this.map.openPopup(html, latlng, {
        maxHeight: 4000,
      });
    },
    createLayer() {
      const self = this;
      this.map.on('pm:create', (e) => {
        try {
          self.$f7.preloader.show();
          if (e.shape === 'Polygon') {
            self.areaLayer = e.layer.addTo(self.map);
            const toGeoJson = self.areaLayer.toGeoJSON();
            const area = turf.area(toGeoJson);
            const html = `<div class='popup-content'><h3>${self.$t(
              'Gis.Editor.PopupMeasureArea',
            )}</h3><p>${self.$t('Gis.Editor.PopupMeasureResult')}: ${(
              area / 10000
            ).toFixed(2)} ${self.$t('Gis.Editor.PopupMeasureHas')}</p></div>`;
            const popup = L.popup().setContent(html);
            self.areaLayerPopup = self.areaLayer.bindPopup(popup);
            self.areaLayer.openPopup();
            self.map.fitBounds(self.areaLayer.getBounds());
            self.map.pm.disableDraw();
          }
          if (e.shape === 'Line') {
            self.lineLayer = e.layer.addTo(self.map);
            // eslint-disable-next-line no-underscore-dangle
            if (self.lineLayer._latlngs.length > 2) {
              self.map.removeLayer(self.lineLayer);
            } else {
              const from = turf.point([
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[0].lat,
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[0].lng,
              ]);
              const to = turf.point([
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[1].lat,
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[1].lng,
              ]);
              const options = {
                units: 'kilometers',
              };
              const distance = turf.distance(from, to, options);
              const html = `<div class='popup-content'><h3>${self.$t(
                'Gis.Editor.PopupMeasureLineString',
              )}</h3><p>${self.$t(
                'Gis.Editor.PopupMeasureResult',
              )}: ${distance.toFixed(2)} ${self.$t(
                'Gis.Editor.PopupMeasureKm',
              )}</p></div>`;
              const popup = L.popup().setContent(html);
              self.lineLayerPopup = self.lineLayer.bindPopup(popup);
              self.lineLayer.openPopup();
              self.map.fitBounds(self.lineLayer.getBounds());
            }
          }
        } catch (error) {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000,
          );
        } finally {
          self.$f7.preloader.hide();
        }
      });
      if (self.areaLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.areaLayer);
      }
      if (self.lineLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.lineLayer);
      }
      this.map.on('pm:globaldrawmodetoggled', (e) => {
        this.drawMode = e.enabled;
      });
    },

    setMarker(latitude, longitude) {
      const marker = new L.Marker(
        {
          lat: latitude,
          lng: longitude,
        },
        {
          draggable: false,
        },
      ).addTo(this.map);
      const redIcon = new L.Icon({
        iconUrl:
          'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        shadowUrl:
          'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      marker.setIcon(redIcon);
    },
    async imageToDataResponse(xhr, errorMessage = '') {
      try {
        const blob = await xhr.blob(); 
        const reader = new FileReader(); 

        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result);
            this.images.push(reader.result);
          };
          reader.onerror = () => {
            reject(new Error('Error al generar la imagen'));
          };
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        this.$notifyDX(
          {
            message: errorMessage,
            width: 450,
          },
          'warning',
          1000,
        );
        return Promise.reject(error);
      }
    },

    async imageToDataUrl(path, errorMessage = '') {
      try {
        const blob = await fetch(path).then((res) => res.blob());
        const reader = new FileReader();

        if (blob.type !== 'image/png') {
          throw new Error('Not a PNG image');
        }

        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result);
            this.images.push(reader.result);
          };
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        this.$notifyDX(
          {
            message: errorMessage,
            width: 450,
          },
          'warning',
          1000,
        );
      }

      return new Promise((resolve) => resolve(''));
    },

    downloadWMSImage() {
      const splitImageContent = this.images[0].split(',');
      
      if (Device.desktop) {
        const link = document.createElement('a');
        link.href = `${splitImageContent[0]},${splitImageContent[1]}`;
        link.download = 'imagen.png';
        link.click();
        URL.revokeObjectURL(link.href);
        this.$notifyDX(
          {
            message: this.$t('Gis.Alerts.downloadingImage'),
            width: 450,
          },
          'success',
          1000,
        );
      } else {
        const path = `${cordova.file.externalRootDirectory}Download/`;
        const fileExtension = '.png';
        const title = 'imagen';

        this.savebase64AsPDF(
          path,
          title + fileExtension,
          splitImageContent[1],
          'image/png',
        );
      }
    },
    savebase64AsPDF(folderpath, filename, content, contentType) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter(
            (fileWriter) => {
              fileWriter.write(DataBlob);
              this.$notifyDX(
                {
                  message: this.$t('FileDownloadedInDownloads'),
                  width: 550,
                },
                'info',
                3000,
              );
            },
            () => {
              this.$notifyDX(
                {
                  message: this.$t('SheetExport_sheet_export_error'),
                  width: 550,
                },
                'info',
                3000,
              );
            },
          );
        });
      });
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    async setMapEvents() {
      const self = this;
      // const coordSearchButton = document.getElementById('zoning_button');
      // const zoningInputMin = document.getElementById('zoning_input_min');
      // const zoningInputMax = document.getElementById('zoning_input_max');

      // coordSearchButton.addEventListener('click', (e) => {
      //   L.DomEvent.stopPropagation(e);
      //   this.currentLayers[0].layer.setParams({
      //     styles: `${this.currentIndex}_variable`,
      //     env: `min:${zoningInputMin.value};max:${zoningInputMax.value}`,
      //   }).addTo(this.map);
      // });
      this.map.on('click', (e) => {
        if (this.currentLayers.length < 1) return;
        if (this.currentIndex === 'TCI') return;
        if (this.currentLayers.length < 1 || this.statusMeasureTool) return;
        const layerId = this.currentLayers[0].item.options.layers.split(':');
        self.generateRouteToDataRequestWMSLayers(layerId[1], e);
      });
    },
    renderGeoJson() {
      const geojson = this.treeLocations?.[0]?.children?.[0]?.children?.[0].geo_feature ?? null;

      if (geojson == null) return;

      L.geoJSON(geojson, {
        style: (feature) => ({
          weight: 2,
          opacity: 1,
          fill: false,
        }),
      }).addTo(this.map);
    },
    ...mapActions('Gis', [
      'setCurrentLayersMap',
      'setCurrentOverlayLayer',
      'fetchFeatureInfo',
      'fetchDatesLayer',
    ]),
  },
};
</script>
<style>
@import './Map.styles.scss';
@import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
</style>

// - General
import logo from '../static/img/farming_up_logo_v2.svg';
import logoText from '../static/img/timac_agro_logo.svg';

import cereal from '../static/img/plansReference/yDhOhjN.jpg';
import limonPomelo from '../static/img/plansReference/TuP2EXl_d.png';
import olivo from '../static/img/plansReference/c5NegOd_d.png';
import hortícolaInvernadero from '../static/img/plansReference/iq8MvYk_d.jpeg';
import hortícolaAireLibre from '../static/img/plansReference/vPls12r.jpg';
import cultivosIndustriales from '../static/img/plansReference/g0Zpx86.jpg';
import frutal from '../static/img/plansReference/DYYThwK_d.png';
import hortícolaFruto from '../static/img/plansReference/wTBU5oj.jpg';
import frutoSeco from '../static/img/plansReference/8PmafOI.png';
import naranjaMandarina from '../static/img/plansReference/6ipM1Wu.jpg';
import alfalfa from '../static/img/plansReference/CvbknmJ.png';
import maíz from '../static/img/plansReference/KhoGWIk_d.png';
import uvaVinificación from '../static/img/plansReference/rfmb3iK.jpg';
import praderaForraje from '../static/img/plansReference/MsA1DXI_d.png';

export const ENV = process.env.NODE_ENV || 'development';
export const { TARGET } = process.env;
export const ENVIRONMENT = '';
export const APP_VERSION = `2.5.0${ENVIRONMENT}`;
export const APPLICATION_NAME = 'FARMING_UP';
export const DATABASE_NAME = 'dbTimac';

// export const CONFIG_BASE_URL = ENV === 'development' && TARGET === 'web'
//   ? LOCAL_DEV_PROXY : CONFIG_PRODUCTION_BASE;

// - Backend API
export const MILISECONDS_TO_REFRESH_AUTOMATICALLY = 180 * 1000; // 3 minutos
export const SECONDS_BETWEEN_SCANS = 3;
export const MIN_SECONDS_TO_REFRESH_TOKEN = 60;
export const MILISECONDS_TO_REFRESH_AUTOMATICALLY_DAILY = 24 * 60 * 60 * 1000; // 24 horas

// - Color Theme
export const DEFAULT_PRIMARY_COLOR = '#42526E';
export const DEFAULT_SECONDARY_COLOR = '#003caa';

// Routes
export const APP_LOGO = logo;
export const APP_TEXT_LOGO = logoText;

// STATIC IMAGES

export const CEREAL = cereal;
export const LIMON_POMELO = limonPomelo;
export const OLIVO = olivo;
export const HORTÍCOLA_INVERNADERO = hortícolaInvernadero;
export const HORTÍCOLA_AIRE_LIBRE = hortícolaAireLibre;
export const CULTIVOS_INDUSTRIALES = cultivosIndustriales;
export const FRUTAL = frutal;
export const HORTÍCOLA_FRUTO = hortícolaFruto;
export const FRUTO_SECO = frutoSeco;
export const NARANJA_MANDARINA = naranjaMandarina;
export const ALFALFA = alfalfa;
export const MAÍZ = maíz;
export const UVA_VINIFICACIÓN = uvaVinificación;
export const PRADERA_FORRAJE = praderaForraje;
export const PROVINCES = [
  { id: 1, name: 'Alava' },
  { id: 2, name: 'Albacete' },
  { id: 3, name: 'Alicante' },
  { id: 4, name: 'Almeria' },
  { id: 5, name: 'Avila' },
  { id: 6, name: 'Badajoz' },
  { id: 7, name: 'Baleares' },
  { id: 8, name: 'Barcelona' },
  { id: 9, name: 'Burgos' },
  { id: 10, name: 'Caceres' },
  { id: 11, name: 'Cadiz' },
  { id: 12, name: 'Castellon' },
  { id: 13, name: 'Ciudad real' },
  { id: 14, name: 'Cordoba' },
  { id: 15, name: 'La coruña' },
  { id: 16, name: 'Cuenca' },
  { id: 17, name: 'Girona' },
  { id: 18, name: 'Granada' },
  { id: 19, name: 'Guadalajara' },
  { id: 20, name: 'Guipuzcoa' },
  { id: 21, name: 'Huelva' },
  { id: 22, name: 'Huesca' },
  { id: 23, name: 'Jaen' },
  { id: 24, name: 'Leon' },
  { id: 25, name: 'Lerida' },
  { id: 26, name: 'La rioja' },
  { id: 27, name: 'Lugo' },
  { id: 28, name: 'Madrid' },
  { id: 29, name: 'Malaga' },
  { id: 30, name: 'Murcia' },
  { id: 31, name: 'Navarra' },
  { id: 32, name: 'Ourense' },
  { id: 33, name: 'Asturias' },
  { id: 34, name: 'Palencia' },
  { id: 35, name: 'Las palmas' },
  { id: 36, name: 'Pontevedra' },
  { id: 37, name: 'Salamanca' },
  { id: 38, name: 'Santa cruz de tenerife' },
  { id: 39, name: 'Cantabria' },
  { id: 40, name: 'Segovia' },
  { id: 41, name: 'Sevilla' },
  { id: 42, name: 'Soria' },
  { id: 43, name: 'Tarragona' },
  { id: 44, name: 'Teruel' },
  { id: 45, name: 'Toledo' },
  { id: 46, name: 'Valencia' },
  { id: 47, name: 'Valladolid' },
  { id: 48, name: 'Bizkaia' },
  { id: 49, name: 'Zamora' },
  { id: 50, name: 'Zaragoza' },
];

<template>
  <f7-page name="treatment-detail-plan">
    <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.name} > ${$t('treatment.title')}`" />
    <treatment-detail-plan-component :id="id" />
  </f7-page>
</template>
<script>
import { mapState } from 'vuex';
import navbar from '@/components/NavBar.vue';
import treatmentDetailPlanComponent from '@/components/wizardTreatment/treatmentDetailPlan/index.vue';

export default {
  name: 'TreatmentDetailPlan',
  components: {
    navbar,
    treatmentDetailPlanComponent,
  },
  props: ['id'],
  data() {
    return {

    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {

  },
  methods: {

  },
};

</script>
<style src="./treatment-detail-plan.scss" scoped lang="scss"></style>

<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="$t('LeftPanel_title_search_page')" />
    <div
      v-if="loaded"
      class="margin-top-block"
    >
      <f7-row>
        <f7-col width="100">
          <f7-block
            v-if="!loadedGIS"
            class="text-align-center"
          >
            <div class="padding-top padding-bottom-half">
              {{ $t('searchEngine_gis_view') }}
            </div>
          </f7-block>
          <f7-block v-else>
            <GISViewClients
              :zoom="zoom"
              :name-map="mainMap"
              :tree-locations-prop="treeLocations"
              :center="{latitude, longitude, radius: accuracy}"
            />
          </f7-block>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="100">
          <SearchEngine
            :go-to-view="'/searcher/home/'"
            :extra-params="extraParams"
            @search-plantation-by-dates="searchPlantationByDates"
            @clear-filtered-data="clearFilteredData"
          />
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import $ from 'jquery';
import SearchEngine from '../../components/searchEngine/index.vue';
import navbar from '../../components/NavBar.vue';
import GISViewClients from '../../components/Gis/GISViewClients/index.vue';
import 'jquery-ui-dist/jquery-ui';
import 'jquery-ui-dist/jquery-ui.min.css';

export default {
  components: {
    SearchEngine,
    navbar,
    GISViewClients,
  },
  data() {
    return {
      loaded: false,
      loadedGIS: false,
      mainMap: 'searchEngine',
      radio: 5,
      latitude: null,
      longitude: null,
      accuracy: null,
      zoom: 17,
      keyName: 'searchEngine',
      extraParams: {},
    };
  },
  computed: {
    ...mapState('Plantation', ['plantations', 'treeLocations']),
    ...mapState('SearchEngine', ['loadOffline']),
    ...mapState('SearcherFilters', ['searchFromDate', 'searchToDate']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  methods: {
    async beforein() {
      
      this.$fa.setScreenName('Buscador');
      try {
        if (this.searchFromDate && this.searchToDate) {
          this.extraParams = {
            init_date: moment(this.searchFromDate).format('YYYY-MM-DD'),
            end_date: moment(this.searchToDate).format('YYYY-MM-DD')
          };
        }

        this.loaded = true;

        if (!this.loadOffline) {
          this.completeDataBaseSync().catch((error) => {
            this.$notifyDX(
              {
                message: this.$t(`${error}`),
                width: 550,
              },
              'error',
              3000,
            );
          });
          this.setLoadOffline(true);
        }

        // Establecer geolocalización
        const navigatorOptions = {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 30000,
        };
        navigator.geolocation.getCurrentPosition(async (position) => {
          this.accuracy = position.coords.accuracy;
          try {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            await this.fetchClientLocationsByCoords({
              latitude: this.latitude,
              longitude: this.longitude,
              radio: this.radio,
            });
            if (this.treeLocations.length > 0) {
              this.loadedGIS = true;
            }
          } catch (error) {
            this.$notifyDX(
              {
                message: this.$t(`${error}`),
                width: 550,
              },
              'error',
              3000,
            );
          }
        },
        async (error) => {
          if (error.code === 3) {
            navigator.geolocation.watchPosition(async (position) => {
              this.accuracy = position.coords.accuracy;
              try {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                await this.fetchClientLocationsByCoords({
                  latitude: this.latitude,
                  longitude: this.longitude,
                  radio: this.radio,
                });
                if (this.treeLocations.length > 0) {
                  this.loadedGIS = true;
                }
              } catch (error) {
                this.$notifyDX(
                  {
                    message: this.$t(`${error}`),
                    width: 550,
                  },
                  'error',
                  3000,
                );
              }
            },
            async (error) => {

              // this.$f7.dialog.alert(`${error.message} - ${this.$t('formAction_Alert_EnableGPS')}`);
            },
            navigatorOptions);
          } else {
            this.$notifyDX(
              {
                message: `${error.message} - ${this.$t('formAction_Alert_EnableGPS')}`,
                width: 550,
              },
              'error',
              3000,
            );
          }
        },
        navigatorOptions);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    },
    async searchPlantationByDates(data) {
      this.extraParams = { init_date: moment(data.fromDate).format('YYYY-MM-DD'), end_date: moment(data.toDate).format('YYYY-MM-DD') };
    },
    async clearFilteredData() {
      this.extraParams = {};
    },
    ...mapActions('Plantation', ['fetchPlantations', 'fetchPlantationsByDate', 'fetchClientLocationsByCoords']),
    ...mapActions('databaseSync', ['completeDataBaseSync']),
    ...mapActions('SearchEngine', ['setLoadOffline']),
    ...mapActions('Pagination', ['getStore']),
  },
};
</script>

<style scoped>
.searchFilterDates{
  position: absolute;
  justify-content: flex-start;
  padding: 15px;
}
</style>

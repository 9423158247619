<template>
  <div class="content-devx no-padding-top no-padding-bottom">
    <f7-block class="no-padding">
      <DxForm
        id="form"
        :col-count="1"
        :caption="$t('title_register_plantation')"
        :align-item-labels-in-all-groups="false"
      >
        <DxGroupItem :col-count="2">
          <DxSimpleItem
            :data-field="$t('registerPlantation.specie_botanic')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: speciesAvailables,
              value: specieSelected,
              showClearButton: true,
              searchEnabled:true,
              onValueChanged: onChangeSpecieBotanic,
            }"
          >
            <DxRequiredRule message="" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('registerPlantation.variety')"
          >
            <template #default>
              <DxSelectBox
                :data-source="varietiesPaginated"
                :on-value-changed="onChangeVariety"
                :value="varietySelected"
                display-expr="name"
                value-expr="name"
                :search-enabled="true"
              />
            </template>
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="`${$t('registerPlantation.distance_between_streets')} (m)`"
            editor-type="dxNumberBox"
            :editor-options="{
              value: mulUno,
              onValueChanged: onChangeMulUno,
            }"
            :is-required="true"
          />
          <DxSimpleItem
            :data-field="`${$t('registerPlantation.distance_between_feets')} (m)`"
            editor-type="dxNumberBox"
            :editor-options="{
              value: mulDos,
              onValueChanged: onChangeMulDos,
            }"
            :is-required="true"
          />
          <DxSimpleItem
            :data-field="`${$t('registerPlantation.plants_density')}(${$t('registerPlantation.unit_plantas_ha')})`"
            editor-type="dxNumberBox"
            :editor-options="{
              value: calculatePlantsDensity,
              onValueChanged: onChangePlantDensity,
              readOnly: true,
            }"
          />
          <DxSimpleItem
            :data-field="$t('registerPlantation.plants_number')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: plantsNumber,
              onValueChanged: onChangePlantsNumber,
              readOnly: false,
            }"
          />
          <DxSimpleItem
            :data-field="$t('registerPlantation.expected_production')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: expectedProduction,
              onValueChanged: onChangeExpectedProduction,
              readOnly: false,
            }"
          />
        </DxGroupItem>
      </DxForm>
    </f7-block>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { mapState, mapActions } from 'vuex';
import DataSource from 'devextreme/data/data_source';
import { DxSelectBox } from 'devextreme-vue/select-box';

export default {
  name: 'RegisterPlantationHorticola',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxSelectBox,
  },
  data() {
    return {
      hectareToM2: 10000,
      seedForKg: 500,
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['plantationName', 'protectionTypeSelected', 'managementTypeSelected',
      'initDate', 'endDate', 'cropTypeSelected', 'plantsNumber', 'specieSelected', 'varietySelected', 'speciesAvailables', 'varieties',
      'mulUno', 'mulDos', 'sectorArea', 'isEdit', 'actualPlantation', 'expectedProduction', 'plantationNamePlaceholder']),
    calculatePlantsNumber() {
      if (this.mulUno !== 0 && this.mulDos !== 0) return parseInt((this.sectorArea * this.hectareToM2) / (this.mulUno * this.mulDos), 10);
      if (this.mulUno === 0 && this.mulDos === 0) return 0;
      return 0;
    },
    calculatePlantsDensity() {
      if (this.mulUno === 0 && this.mulDos === 0) return 0;
      if (this.mulUno !== 0 && this.mulDos !== 0) {
        return parseInt(Math.ceil(10000 / (this.mulUno * this.mulDos)), 10);
      }
      return 0;
    },
    varietiesPaginated() {
      const selectBoxData = new DataSource({
        store: this.varieties,
        paginate: true,
        pageSize: 100,
      });

      return selectBoxData;
    },
  },
  async beforeMount() {
    if (this.isEdit) {
      for (const propertie of this.actualPlantation.farms[0].properties) {
        if (propertie.key === 'area') {
          this.setSectorArea(propertie.value);
        }
      }
      this.setSpecieBotanic(this.actualPlantation.species);
      this.setVariety(this.actualPlantation.variety);
      this.setMulUno(this.actualPlantation.plantation_frame?.x ?? 0);
      this.setMulDos(this.actualPlantation.plantation_frame?.y ?? 0);
      this.setPlantsDensity(this.actualPlantation.plants_density);
      this.setPlantsNumber(this.actualPlantation.plants);
      this.setExpectedProduction(this.actualPlantation?.expected_production ?? 0);
      try {
        await this.getSelectBoxSpecies(this.actualPlantation.crop_type !== this.cropTypeSelected ? this.cropTypeSelected : this.actualPlantation.crop_type);
        await this.getSelectBoxVariety(this.actualPlantation.crop_type !== this.cropTypeSelected ? this.specieSelected : this.actualPlantation.species);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$notifyDX(
            {
              message: message.split('_').pop(),
              width: 550,
            },
            'error',
            3000,
          );
        } else if (!message.includes('404')) {
          this.$notifyDX(
            {
              message: this.$t(message),
              width: 550,
            },
            'error',
            3000,
          );
        }
      }
    }

    setTimeout(() => {
      this.setPlantationNamePlaceholder(`${this.specieSelected}-${this.actualPlantation.variety}`);
    }, 100);
  },
  methods: {
    async onChangeSpecieBotanic(e) {
      this.setSpecieBotanic(e.value);
      try {
        await this.getSelectBoxVariety(e.value);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$notifyDX(
            {
              message: message.split('_').pop(),
              width: 550,
            },
            'error',
            3000,
          );
        } else if (!message.includes('404')) {
          this.$notifyDX(
            {
              message: this.$t(message),
              width: 550,
            },
            'error',
            3000,
          );
        }
      }

      this.setPlantationNamePlaceholder(`${e.value ?? 'Especie botánica'}-${'Variedad'}`);
    },
    onChangeVariety(e) {
      this.setVariety(e.value);

      const changePlantationName = this.plantationName === this.plantationNamePlaceholder;

      this.setPlantationNamePlaceholder(`${this.specieSelected ?? 'Especie botánica'}-${e.value ?? 'Variedad'}`);

      if (this.plantationName.length === 0 || changePlantationName) {
        this.setPlantationName(`${this.specieSelected}-${e.value}`);
      }
    },
    onChangeMulUno(e) {
      this.setMulUno(e.value);
      this.setPlantsNumber(this.calculatePlantsNumber ?? 0);
    },
    onChangeMulDos(e) {
      this.setMulDos(e.value);
      this.setPlantsNumber(this.calculatePlantsNumber ?? 0);
    },
    onChangePlantDensity(e) {
      this.setPlantsDensity(e.value);
    },
    onChangePlantsNumber(e) {
      this.setPlantsNumber(e.value);
    },
    onChangeExpectedProduction(e) {
      this.setExpectedProduction(e.value);
    },
    ...mapActions('RegisterPlantation', ['setPlantationName', 'setProtectionType', 'setManagementType', 'setInitDate', 'setEndDate', 'setCultiveType',
      'setSpecieBotanic', 'setVariety', 'setPlantsNumber', 'getSelectBoxVariety', 'setMulUno', 'setSectorArea',
      'setMulDos', 'setPlantsDensity', 'initStore', 'getSelectBoxSpecies', 'setExpectedProduction', 'setPlantationNamePlaceholder']),
  },
};
</script>

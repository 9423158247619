<template>
  <section class="treatment-period-details">
    <ProductPopupDetail
      v-if="displayProductDetailPopup"
      :owner="owner"
    />
    <div class="content-devx">
      <div class="area-field">
        <div class="dx-field">
          <div class="dx-field-label">
            {{ $t("treatment.treatmentPlan.treatmentCreatePlan.area") }}
          </div>
          <div class="dx-field-value-static">
            <DxNumberBox
              :value="getArea"
              :read-only="true"
            />
          </div>
          <div class="margin-right">
            ha
          </div>
        </div>
      </div>

      <div class="margin-left margin-right margin-bottom">
        <DxTabPanel
          :data-source="getPeriods"
          :selected-index.sync="selectedIndex"
          :loop="false"
          :animation-enabled="false"
          :swipe-enabled="false"
        >
          <template #title="{ data: period }">
            <span> {{ period.title }} </span>
          </template>
          <template #item="{ data: period }">
            <div>
              <TreatmentStepTwoFormDefault
                v-if="period.default_apply_unit === 'default'"
                :period="period"
                :selected-index="selectedIndex"
              />
              <TreatmentStepTwoFormPlant
                v-if="period.default_apply_unit === 'Kg-L / planta'"
                :period="period"
                :selected-index="selectedIndex"
              />
              <TreatmentStepTwoFormVat
                v-if="period.default_apply_unit === 'Kg-L / cuva'"
                :period="period"
                :selected-index="selectedIndex"
                @update-vats-number="updateVatsNumber"
              />

              <TreatmentStepTwoTableDefault
                v-if="period.default_apply_unit === 'default'"
                :period="period"
                :selected-index="selectedIndex"
                @owner="setOwner"
              />
              <TreatmentStepTwoTablePlant
                v-if="period.default_apply_unit === 'Kg-L / planta'"
                :period="period"
                :selected-index="selectedIndex"
                @owner="setOwner"
              />
              <TreatmentStepTwoTableVat
                v-if="period.default_apply_unit === 'Kg-L / cuva'"
                :period="period"
                :selected-index="selectedIndex"
                @owner="setOwner"
              />
            </div>
          </template>
        </DxTabPanel>
      </div>
    </div>
  </section>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPopover,
  DxEditing,
} from 'devextreme-vue/data-grid';
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import { mapState } from 'vuex';
import moment from 'moment';
import DxNumberBox from 'devextreme-vue/number-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxSelectBox from 'devextreme-vue/select-box';
import TreatmentStepTwoFormDefault from '../TreatmentStepTwoForms/default.vue';
import TreatmentStepTwoFormPlant from '../TreatmentStepTwoForms/plant.vue';
import TreatmentStepTwoFormVat from '../TreatmentStepTwoForms/vat.vue';
import TreatmentStepTwoTableDefault from '../treatmentStepTwoTables/default.vue';
import TreatmentStepTwoTablePlant from '../treatmentStepTwoTables/plant.vue';
import TreatmentStepTwoTableVat from '../treatmentStepTwoTables/vat.vue';
import ProductPopupDetail from '../productDetail/index.vue';
import { getTimeValues } from '../../../js/utils/timeValuesUtils';

export default {
  name: 'TreatmentPeriodDetails',
  components: {
    DxEditing,
    DxDataGrid,
    DxColumn,
    DxPopover,
    DxTabPanel,
    DxNumberBox,
    DxTextBox,
    TreatmentStepTwoFormDefault,
    TreatmentStepTwoFormPlant,
    TreatmentStepTwoFormVat,
    TreatmentStepTwoTableDefault,
    TreatmentStepTwoTablePlant,
    TreatmentStepTwoTableVat,
    DxSelectBox,
    ProductPopupDetail,
  },
  data() {
    return {
      selectedIndex: 0,
      applyUnit: ['default', 'Kg-L / planta'],
      owner: '',
    };
  },
  computed: {
    ...mapState('treatmentStepper', ['treatmentPlan']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapState('treatment', ['displayProductDetailPopup']),
    getPeriods() {
      const { timeValues } = getTimeValues(
        moment(this.treatmentPlan.init_date,'YYYY/MM/DD'), 
        moment(this.treatmentPlan.end_date,'YYYY/MM/DD'), 
        this.treatmentPlan.period,
        this.isReferencePlan,
        true
      );

      return this.treatmentPlan.periods
        .map((period, index) => ({
          ...period,
          title: timeValues[index],
          default_apply_unit: this.treatmentPlan.default_apply_unit[index],
        }))
        .filter((period) => {
          const doseVolumeNotEmpty = period.products.some(
            ({ dose_volume, active }) => (dose_volume > 0 && active),
          );

          return doseVolumeNotEmpty;
        });
    },
     // Calculo del Area
     getArea() {
      let area = 0;

      if (this.actualPlantation.farms[0].properties) {
        const properties = this.actualPlantation.farms[0].properties;
        const areaProperty = properties.find(prop => prop.key === 'area');
        
        if (areaProperty && areaProperty.value) {
          area = this.fixNumberTo(areaProperty.value, 2);
        }
      }
      return area; // Devuelve el área
    }
  },
  methods: {
    fixNumberTo(number, digits) {
      if (!number) {
        return null;
      }
      return parseFloat(parseFloat(number).toFixed(digits));
    },
    updateVatsNumber(data) {
      let indexPeriod = 0;
      let count = data.index;

      for (const period of this.treatmentPlan.periods) {
        const doseVolumeNotEmpty = period.products.some(
          ({ dose_volume, active }) => (dose_volume > 0 && active),
        );

        if (doseVolumeNotEmpty) {
          if (count === 0) {
            break;
          }

          count--;
        }
        indexPeriod++;
      }

      this.treatmentPlan.periods[indexPeriod].vats_number = data.value;
    },
    onChangeApplyUnit(e) {
      this.treatmentPlan.default_apply_unit[this.selectedIndex] = e.value;
    },
    setOwner(data) {
      this.owner = data;
    },
  },
};
</script>

<style src="./treatmentPeriodDetails.styles.scss" scoped lang="scss"></style>

<template>
  <div>
    <div
      class="placeholder-nutrition-detail margin-half"
      style="height: 180px"
    />
    <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr));">
      <div
        class="placeholder-nutrition-detail no-margin margin-left-half margin-right-half"
        style="height: 245px"
      />
      <div
        class="placeholder-nutrition-detail no-margin margin-right-half"
        style="height: 245px"
      />
    </div>
    <div
      class="placeholder-nutrition-detail margin-half"
      style="height: 200px"
    />
    <div
      v-if="isNew"
      class="placeholder-nutrition-detail margin-half"
      style="height: 300px"
    />
    <div
      class="placeholder-nutrition-detail margin-half"
      style="height: 200px"
    />
  </div>
</template>
<script>
export default {
  props: {
    isNew: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.placeholder-nutrition-detail {
  background-color: white;
  border: 1px solid #e4e6ed;

  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;

  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
</style>

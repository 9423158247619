<template>
  <div>
    <DxPopup
      :visible="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :width="500"
      :show-close-button="false"
      :title="$t('plantations.delete_title')"
      height="auto"
    >
      <template>
        <f7-block>
          <div class="title center">
            {{ $t("plantations.delete_message") }}
          </div>
        </f7-block>
      </template>
      <f7-block class="button-container padding">
        <f7-button
          class="dx-btn-success standard-blue-btn"
          raised
          type="success"
          styling-mode="contained"
          @click="onSuccess"
        >
          Si
        </f7-button>
        <f7-button
          class="dx-btn-cancel outlined-gray"
          raised
          type="default"
          styling-mode="contained"
          @click="onCancel"
        >
          Cancelar
        </f7-button>
      </f7-block>
    </DxPopup>
  </div>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';

export default {
  name: 'PopupConfirmDelete',
  components: {
    DxPopup,
  },
  methods: {
    onSuccess() {
      this.$emit('success');
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style src="./popupConfirmDelete.styles.scss" scoped lang="scss"></style>

<template>
  <section>
    <div class="content-devx margin-half">
      <div class="main-title-content">
        {{ $t("treatment.treatmentPlan.treatmentCreatePlan.observations") }}
      </div>

      <div class="content-separatos">
        {{ getObservations }}
      </div>

      <div
        v-if="productsAnnotations.length > 0"
        class="content-separatos"
      >
        <div
          v-for="(annotation, index) of productsAnnotations"
          :key="index"
          class="margin-bottom-half"
        >
          <div style="font-weight: 500">
            • {{ annotation.name }}
          </div>
          <div class="padding-left">
            {{ annotation.annotation }}
          </div>
        </div>

      <!-- <f7-block>
        <f7-row>
          <f7-col>
            {{ getObservations }}
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col>
            <div class="margin-top">
              <div
                v-for="(annotation, index) of productsAnnotations"
                :key="index"
                class="margin-bottom-half"
              >
                <div style="font-weight: 500">
                  • {{ annotation.name }}
                </div>
                <div class="padding-left">
                  {{ annotation.annotation }}
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block> -->
      </div>
    </div>
  </section>
</template>

<script>
import { DxTextArea } from 'devextreme-vue/text-area';

export default {
  name: 'Observations',
  components: {
    DxTextArea,
  },
  props: {
    fertilization: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getObservations() {
      return this.fertilization?.observation ?? this.$t('No_Observation');
    },
    productsAnnotations() {
      return this.fertilization.contribution_products.map((item) => ({
        name: item.product_name,
        annotation: item.observation ?? '',
      })).filter((item) => item.annotation.length > 0);
    },
  },
};
</script>

<template>
  <section class="treatment-detail-order">
    <div class="main-container">
      <div class="content-devx treatment-order-form">
        <div class="card-header">
          {{ $t('treatment.treatmentPlan.treatmentDetailPlan.titleDetail') }}
        </div>
        <div class="card-body">
          <section class="data-form">
            <f7-block style="width:100%">
              <f7-row>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('treatment.treatmentCreateOrder.form.description') }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('treatment.treatmentPlan.treatmentDetailPlan.plagueAgent') }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('treatment.treatmentCreateOrder.form.date') }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('treatment.treatmentPlan.treatmentDetailPlan.status') }}
                  </div>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col>
                  <div class="item-title-bold x2">
                    {{ treatmentOrder.description }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="item-title-bold x2">
                    {{ treatmentOrder.agent }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="item-title-bold x2">
                    {{ treatmentOrder.date }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="item-title-bold x2">
                    {{ treatmentOrder.status }}
                  </div>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('treatment.treatmentPlan.treatmentDetailPlan.products.area') }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('treatment.treatmentCreateOrder.form.dosage') }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('treatment.treatmentCreateOrder.form.treatmentType') }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('treatment.treatmentCreateOrder.form.volume') }}
                  </div>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col>
                  <div class="item-title-bold x2">
                    {{ treatmentOrder.area }} ha
                  </div>
                </f7-col>
                <f7-col>
                  <div class="item-title-bold x2">
                    {{ treatmentOrder.dose_type }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="item-title-bold x2">
                    {{ treatmentOrder.treatment_type }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="item-title-bold x2">
                    {{ treatmentOrder.volume }} {{ $t('treatment.treatmentEditOrder.form.lHa') }}
                  </div>
                </f7-col>
              </f7-row>
            </f7-block>
          </section>
        </div>
      </div>
      <div class="content-devx treatment-order-product-list">
        <div class="card-header">
          {{ $t('treatment.treatmentCreateOrder.products.title') }}
        </div>
        <treatmentOrderProducts
          v-if="Object.keys(treatmentOrder).length"
          :treatment-order="treatmentOrder"
        />
      </div>
      <section class="buttons">
        <DxButton
          :text="$t('treatment.treatmentCreateOrder.back')"
          type="normal"
          @click="cancel"
        />
      </section>
    </div>
  </section>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxSummary,
  DxTotalItem,
  DxEditing,
} from 'devextreme-vue/data-grid';
import { mapState } from 'vuex';
import DxButton from 'devextreme-vue/button';
import numeral from 'numeral';
import treatmentOrderService from '@/services/treatmentOrderService';
import treatmentOrderProducts from '../treatmentOrderProducts/index.vue';

const numeralFormat = '0,0.00';

export default {
  name: 'TreatmentDetailOrder',
  components: {
    DxDataGrid,
    DxColumn,
    DxSummary,
    DxTotalItem,
    DxEditing,
    DxButton,
    treatmentOrderProducts,
  },
  props: ['id'],
  data() {
    return {
      treatmentOrder: {},
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {
    treatmentOrderService.getById(this.id, this.currentPlantation.companyId).then((response) => {
      this.treatmentOrder = response;
    });
  },
  methods: {
    calculateDosageTotalKg(rowData) {
      rowData.dosageTotalKg = rowData.dosageTotalL * this.treatmentOrder.area;
      return numeral(rowData.dosageTotalKg).format(numeralFormat);
    },
    calculateUnitCost(rowData) {
      rowData.unitCost = rowData.price === 0 ? 0
        : numeral(rowData.dosageTotalKg).value() / rowData.price;
      return numeral(rowData.unitCost).format(numeralFormat);
    },
    calculateTotalCost(rowData) {
      rowData.totalCost = numeral(rowData.unitCost).value() * this.treatmentOrder.area;
      return numeral(rowData.totalCost)
        .format(numeralFormat);
    },
    cancel() {
      this.$f7.views.main.router.navigate('/searcher/treatment/', { reloadCurrent: true });
    },
  },
};

</script>
<style src="./treatmentDetailOrder.scss" scoped lang="scss"></style>

export default {
  namespaced: true,
  state: {
    selectedAttributes: [],
  },
  actions: {
    setSelectedAttribute({ commit }, value) {
      commit('setSelectedAttribute', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setSelectedAttribute(state, value) {
      state.selectedAttributes = value;
    },
    clearFilters(state) {
      state.selectedAttributes = [];
    },
  },
};

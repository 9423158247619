<template>
  <f7-page
    class="main-viewplantationid"
  >
    <div v-if="loaded">
      <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} > ${actualPlantation.explotation} > ${actualPlantation.parcel} > ${actualPlantation.farms[0].name} > ${actualPlantation.name}`" />
      <f7-row>
        <f7-col>
          <ViewPlantation :properties="properties" />
        </f7-col>
        <f7-col>
          <GISGeoJsonMap
            class="content-devx"
            :zoom="zoom"
            :name-map="mainMap"
            :tree-locations="treeLocations"
            :selected-min-height="'420px'"
          />
          <div class="content-devx">
            <f7-block-title class="no-margin text-uppercase">
              {{ $t('enclosureSigpacTable.title') }}
            </f7-block-title>

            <DxDataGrid
              class="margin-bottom"
              :show-borders="true"
              :data-source="intersecadosTable"
              :allow-column-resizing="true"
              :column-auto-width="true"
            >
              <DxColumn
                data-field="reference"
                :caption="$t('enclosureSigpacTable.reference')"
                :allow-sorting="false"
              />
              <DxColumn
                data-field="interseccion"
                :caption="$t('enclosureSigpacTable.overlap')"
                :allow-sorting="false"
              />
              <DxColumn
                data-field="surface"
                :caption="$t('enclosureSigpacTable.enclosure')"
                :allow-sorting="false"
              />
              <DxPaging :page-size="5" />
              <DxPager
                :show-page-size-selector="true"
                :allowed-page-sizes="pageSizes"
                :show-info="true"
                info-text="{2} items"
              />
            </DxDataGrid>
          </div>
        </f7-col>
      </f7-row>
      <div
        class="container-button"
      >
        <f7-button
          class="dx-btn-cancel padding-horizontal"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t('Button_go_to_back') }}
        </f7-button>
        <f7-button
          class="button-home"
          raised
          type="success"
          styling-mode="contained"
          @click="goToHome"
        >
          {{ $t("Button_go_to_home") }}
        </f7-button>     
      </div>

      <PopupConfirmDelete
        v-if="showPopupConfirmDelete"
        @success="deletePlantation()"
        @cancel="cancelDeletePlantation()"
      />
    </div>
  </f7-page>
</template>

<script>
import {
  DxDataGrid, DxColumn, DxPaging, DxPager,
} from 'devextreme-vue/data-grid';
import { mapActions, mapState } from 'vuex';
import ViewPlantation from '../../../components/plantation/viewPlantation/index.vue';
import navbar from '../../../components/NavBar.vue';
import GISGeoJsonMap from '../../../components/Gis/GISGeoJsonMap/index.vue';
import PopupConfirmDelete from '../../../components/plantation/popupConfirmDelete/index.vue';
import Api from '../../../services/Api';

export default {
  name: 'ViewPlantationIdPage',
  components: {
    DxDataGrid,
    DxColumn,
    ViewPlantation,
    navbar,
    GISGeoJsonMap,
    PopupConfirmDelete,
    DxPaging,
    DxPager,
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
      properties: {},
      zoom: 17,
      mainMap: 'plantationMainMap',
      loaded: false,
      intersecados: {},
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['actualPlantation', 'isEdit', 'isPlantationCreated', 'treeLocations']),
    ...mapState('Plantation', ['id', 'currentPlantation', 'showPopupConfirmDelete', 'plantations']),
    ...mapState('Explotaciones', ['actualUser', 'actualLocation']),
    ...mapState('Campania', ['isView', 'viewCampaignId', 'actualCampaign', 'campaignId']),
    intersecadosTable() {
      return this.intersecados?.features?.map((item) => {
        const prop = item.properties;
        return {
          reference: `${prop.provincia}-${prop.municipio}-${prop.agregado}-${prop.zona}-${prop.poligono}-${prop.parcela}-${prop.recinto}`,
          interseccion: `${(parseFloat(prop.SupInterseccion) / 10000).toFixed(3)} ha`,
          surface: `${(parseFloat(prop.dn_surface) / 10000).toFixed(3)} ha`,
        };
      }) ?? [];
    },
  },
  async mounted() {
    this.loader = false;
    this.$f7.preloader.show();
    try {
      let currentPlantationId = this.id;
      if (currentPlantationId === '') {
        currentPlantationId = this.actualPlantation.id;
      }
      const data = {
        companyId: this.actualUser.id,
        plantationId: currentPlantationId,
      };
      // Si no se está creando una plantación no hace falta cambiar la plantación de nuevo
      if (!this.isPlantationCreated) {
        await this.getPlantationById(data);
      }

      this.actualPlantationFormated();
      await this.fetchLocationsByPlantation(data);
      this.setCentroides();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
    // se guarda la plantacion actual en la current para poder ir al inicio con todos los datos
    this.setCurrentPlantation(this.actualPlantation);
    this.setSearcherPlantation(this.actualPlantation);
    
    await this.getCordenades();
  },
  methods: {
    setCentroides() {
      this.setParcelCentroide(this.treeLocations[0].children[0].coordinates);
      this.setSectorCentroide(this.treeLocations[0].children[0].children[0].coordinates);
    },
    async deletePlantation() {
      try {
        if (this.actualCampaign.company == null || this.actualCampaign.company === '') {
          return;
        }
        this.$f7.preloader.show();
        await this.deletePlantationById({ idCompany: this.actualCampaign.company, id: this.actualPlantation.id });
        await this.getPlantationByCompany();
        this.goToBack();   
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.togglePopupConfirmDelete(false);
        this.$f7.preloader.hide();
      }
    },
    cancelDeletePlantation() {
      this.togglePopupConfirmDelete(false);
    },
    async goToBack() {
      if (this.isEdit) {
        switch (this.isView) {
          case true: {
            this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
            break;
          }
          case undefined: {
            this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
            break;
          }
          case false: {
            this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
            break;
          }
          default: {
            this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
            break;
          }
        }
      } else {
        switch (this.isView) {
          case false: {
            this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
            break;
          }
          case true: {
            this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
            break;
          }
          case undefined: {
            this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
            break;
          }
          default: {
            this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
            break;
          }
        }
      }
    },
    goToSearcher() {
      this.$f7.views.main.router.navigate('/searcher/home/', { reloadCurrent: true });
    },
    actualPlantationFormated() {
      this.properties.variety = '';
      this.properties.area = '';
      this.properties.irrigation_type = '';
      this.properties.flow = '';
      this.properties.dripper_per_plant = '';
      this.properties.ground_texture = '';
      this.properties.sprinkler_number = '';
      this.properties.transformed_surface = '';
      this.properties.planting_density = this.actualLocation.planting_density;
      this.properties.plants_density = this.actualLocation.plants_density;
      for (const propertie of this.actualPlantation.farms[0].properties) {
        this.properties.variety = propertie.key === 'variety' ? propertie.value : this.properties.variety;
        this.properties.area = propertie.key === 'area' ? propertie.value : this.properties.area;
        this.properties.irrigation_type = propertie.key === 'irrigation_type' ? propertie.value : this.properties.irrigation_type;
        this.properties.flow = propertie.key === 'flow' ? propertie.value : this.properties.flow;
        this.properties.dripper_per_plant = propertie.key === 'dripper_per_plant' ? propertie.value : this.properties.dripper_per_plant;
        this.properties.ground_texture = propertie.key === 'ground_texture' ? propertie.value : this.properties.ground_texture;
        this.properties.sprinkler_number = propertie.key === 'sprinkler_number' ? propertie.value : this.properties.sprinkler_number;
        this.properties.transformed_surface = propertie.key === 'transformed_surface' ? propertie.value : this.properties.transformed_surface;
      }
    },
    async getCordenades() {
      const type = this.treeLocations[0].children[0].children[0].geo_feature.features[0].geometry.type;

      let coordinates = [];

      if (type === 'MultiPolygon') {
        coordinates = [].concat.apply([], this.treeLocations[0].children[0].children[0].geo_feature.features[0].geometry.coordinates[0])
      } else {
        coordinates = this.treeLocations[0].children[0].children[0].geo_feature.features[0].geometry.coordinates[0];
      }

      const polygon = coordinates.map((c) => `${c[0]} ${c[1]}`).join(',');

      try {
        this.$f7.preloader.show();

        const xhr = await Api.geoserverSigpacSurfaceData(polygon);
        this.intersecados = await xhr.json();
      } catch (error) {
        this.$notifyDX(
          {
            message: 'No se han podido cargar los recintos SIGPAC solapados',
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    goToHome() {
      this.setCurrentTab('home');
      this.setActualMenuSelected('/searcher/home/')
      this.$f7.views.main.router.navigate('/searcher/', { reloadCurrent: true });
      this.setAccordionItemOpened();
    },
    setAccordionItemOpened() {
      Array.from(document.getElementsByClassName('accordion-item'))[0].classList.add('accordion-item-opened');
    },
    ...mapActions('Plantation', ['fetchTreeLocationById', 'togglePopupConfirmDelete', 'deletePlantationById', 'setCurrentPlantation', 'setOldPlantation', 'setSearcherPlantation']),
    ...mapActions('RegisterPlantation', ['getPlantationById', 'fetchLocationsByPlantation']),
    ...mapActions('Campania', ['setIsView', 'getCampaignsById', 'getPlantationByCompany']),
    ...mapActions('Gis', ['setParcelCentroide', 'setSectorCentroide']),
    ...mapActions('Utilities', ['setCurrentTab']),
    ...mapActions('authentication', ['setActualMenuSelected']),
  },
};
</script>

<style lang="scss">
.main-viewplantationid{
  .go-back-button{
    margin: 0;
    padding: 0;
  }
}
.container-button {
  display: flex;
  justify-content: space-between;
  padding: 7px;
}
.container-home {
  display: flex;
  justify-content: flex-end;
}

.button-home {
  background: var(--ha-butons-color-blue);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  height: auto;
  line-height: 3;
  box-shadow: none;
  color: white !important;
  margin: 0;
}
</style>

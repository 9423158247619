<template>
  <section class="plan-compost">
    <div class="content-devx">
      <div class="card-header">
        {{ $t('planCompost.planCompost') }}
      </div>
      <div class="card-body">
        <DxChart
          palette="SoftPastel"
          :data-source="planCompostSerie"
        >
          <DxSeriesTemplate
            name-field="productName"
          />

          <DxTooltip
            :enabled="true"
            :z-index="999999"
            :customize-tooltip="customizeTooltip"
          />

          <DxCommonSeriesSettings
            argument-field="monthWeek"
            value-field="value"
            type="stackedbar"
          />

          <DxLegend
            vertical-alignment="top"
            horizontal-alignment="center"
            item-text-position="right"
          />
        </DxChart>
      </div>
    </div>
  </section>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxTooltip,
  DxCommonSeriesSettings,
  DxLegend,
  DxSeriesTemplate,
} from 'devextreme-vue/chart';
import { mapGetters } from 'vuex';

export default {
  name: 'PlanCompost',
  components: {
    DxChart,
    DxTooltip,
    DxSeries,
    DxCommonSeriesSettings,
    DxLegend,
    DxSeriesTemplate,
  },
  props: [],
  data() {
    return {
      planCompostSerie: [],
    };
  },
  computed: {
    ...mapGetters('nutritionStepper', ['stepData']),
  },
  mounted() {
    this.stepData.dosageProduct.forEach((item, index) => {
      const itemValue = this.stepData.timeValues[index];
      for (const prop in itemValue) {
        this.planCompostSerie.push({
          productName: item.name,
          monthWeek: prop,
          value: itemValue[prop],
        });
      }
    });
  },
  methods: {
    customizeTooltip(point) {
      return {
        html: `<div><div class='tooltip-header'>${
          point.argumentText
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('product')}: ${point.seriesName} </span>
          </div>
          <span class="bold"> ${this.$t('weather_parameter_value')}: ${point.value} kg/ha</span> `,
      };
    },
  },
};

</script>
<style src="./planCompost.scss" scoped lang="scss"></style>

import pdfmake from 'pdfmake';
import html2canvas from 'html2canvas';
import { cloneDeep } from 'lodash';
import pdfImage from '../json/pdfImages.json';
import i18n from './i18n.config';

export default class PDFMaker {
  constructor() {
    pdfmake.fonts = {
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    };

    this.pdfData = {
      footer(currentPage, pageCount) {
        return [
          {
            text: `Página ${currentPage.toString()}`,
            alignment: 'right',
            margin: [0, 20, 10, 0],
          },
        ];
      },
      header(currentPage, pageCount, pageSize) {
        return [
          {
            margin: [20, 10, 20, 0],
            columns: [
              {
                image: pdfImage.timac_image,
                width: 200,
              },
              {
                margin: [100, 0, 0, 0],
                image: pdfImage.contact_image,
                width: 160,
              },
              {
                margin: [110, 10, 0, 0],
                image: pdfImage.roullier_image,
                width: 30,
              },
              {
                margin: [125, 10, 0, 0],
                image: pdfImage.iso_image,
                width: 30,
              },
            ],
          },
        ];
      },
      content: [],
      pageSize: 'A4',
      pageMargins: [20, 80, 20, 60],
      styles: {
        h1: { fontSize: 18, bold: true, margin: [0, 14, 0, 8] },
        h2: {
          fontSize: 16,
          bold: true,
          margin: [0, 20, 0, 8],
          color: '#444',
        },
        h3: {
          fontSize: 14,
          bold: true,
          margin: [0, 22, 0, 8],
          color: '#666',
        },
        tableHeaderCell: {
          margin: [2, 2, 2, 2],
          fontSize: 7,
          // fillColor: '#eee',
          fillColor: '#e5e7eb',
          bold: true,
        },
        tableNormalCell: {
          margin: [2, 2, 2, 2],
          fontSize: 8,
          fillColor: '#fff',
          bold: false,
        },
        tableNormalCellStriped: {
          margin: [2, 2, 2, 2],
          fontSize: 8,
          fillColor: '#f3f4f6',
          bold: false,
        },
        blueContainer: {
          margin: [0, 14, 0, 14],
        },
        blueContainerTitle: {
          margin: [8, 8, 8, 8],
          fillColor: '#006FA8;',
          color: '#fff',
          bold: true,
        },
        reportTitleStyle: {
          margin: [0, 8, 0, 0],
          fontSize: 16,
          color: '#006FA8',
          bold: true,
        },
        noBordersCell: {
          margin: [8, 8, 8, 8],
          fontSize: 8,
          bold: false,
        },
        noBordersCellSignature: {
          margin: [8, 25, 8, 15],
          fontSize: 8,
          bold: false,
        },
        bigger: {
          fontSize: 15,
          italics: true,
        },
        commentStyleTitle: {
          fontSize: 10,
          italics: true,
          bold: true,
        },
        commentStyle: {
          fontSize: 10,
          italics: false,
        },
        normalStyle: {
          fontSize: 10,
        },
        normalStyle2: {
          fontSize: 12,
        },
        observationsTitle: {
          fontSize: 12,
          margin: [0, 8, 0, 4],
        },
        observationsList: {
          fontSize: 12,
          margin: [10, 0, 0, 0],
        },
        legalText: {
          margin: [8, 8, 8, 8],
          fontSize: 8,
        },
        footer: {
          margin: [10, 10, 10, 10],
        },
      },
    };
  }

  getBlueContainer(title, breakTabla = true) {
    let tableOptions = {};

    if (breakTabla) {
      tableOptions = {
        // headerRows: 1,
        keepWithHeaderRows: true,
      };
    }

    return {
      style: 'blueContainer',
      table: {
        ...tableOptions,
        headerRows: 1,
        // headerRows: 1,
        // keepWithHeaderRows: true,
        // dontBreakRows: true,
        // unbreakable: true,
        widths: ['*'],
        body: [[{ text: title, style: 'blueContainerTitle' }], []],
      },
    };
  }

  insertTableWithoutBorders(data) {
    const blueContainer = this.getBlueContainer('DATOS DE LA EXPLOTACIÓN');

    const table = {
      widths: Array(data[0].length).fill(`${(100 / data[0].length).toFixed(2)}%`),
      body: [],
    };

    data.forEach((row) => {
      const headers = row.map((cell) => {
        if (cell.title.length === 0 && cell.text.length === 0) {
          return {
            style: 'noBordersCell',
            text: '',
          };
        }

        return {
          style: 'noBordersCell',
          text: [
            { text: `${cell.title}: `, fontSize: 10, bold: false },
            { text: cell.text, fontSize: 10, bold: true },
          ],
        };
      });
      table.body.push(headers);
    });

    blueContainer.table.body[1].push({ table, layout: 'noBorders' });

    this.pdfData.content.push(blueContainer);
  }

  insertTableTreatmentTable(title, tableData, topData, type = null) {
    const blueContainer = this.getBlueContainer(title);

    const topTable = {
      widths: Array(topData.length).fill(`${(100 / topData.length).toFixed(2)}%`),
      body: [[]],
    };

    const table = {
      widths: Array(tableData[0].length).fill(`${(100 / tableData[0].length).toFixed(2)}%`),
      body: [],
    };

    topData.forEach((row) => {
      topTable.body[0].push({
        style: 'noBordersCell',
        text: [
          { text: `${row[0]}: `, fontSize: 10, bold: false },
          { text: row[1], fontSize: 10, bold: true },
        ],
      });
    });

    tableData.forEach((row, index) => {
      if (index === 0) {
        if (type === 'default') {
          table.body.push([
            { text: '', colSpan: 2, style: 'tableHeaderCell' },
            '',
            {
              text: 'UNITARIOS',
              colSpan: 5,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            '',
            '',
            '',
            '',
            {
              text: 'TOTALES',
              colSpan: 3,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            '',
            '',
          ]);
        }

        if (type === 'planta') {
          table.body.push([
            { text: '', colSpan: 2, style: 'tableHeaderCell' },
            '',
            {
              text: 'UNITARIOS',
              colSpan: 4,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            '',
            '',
            '',
            {
              text: 'TOTALES',
              colSpan: 3,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            '',
            '',
          ]);
        }

        if (type === 'cuba') {
          table.body.push([
            { text: '', colSpan: 2, style: 'tableHeaderCell' },
            '',
            {
              text: 'UNITARIOS',
              colSpan: 3,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            '',
            '',
            {
              text: 'TOTALES',
              colSpan: 2,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            '',
          ]);
        }

        table.body.push(row.map((cellText) => ({ style: 'tableHeaderCell', text: cellText })));
      } else {
        table.body.push(row.map((cellText) => ({ style: 'tableNormalCell', text: cellText, fontSize: cellText && cellText.length > 6 ? 7 : 8,
        Wrap: true  })));

        if (type === 'default' && index === tableData.length - 1) {
          const costHaTotal = tableData
            .reduce((acc, cur) => {
              if (typeof cur[6] !== 'string') {
                return acc + cur[6];
              }

              return acc;
            }, 0)
            .toFixed(2);

          const costTotal = tableData
            .reduce((acc, cur) => {
              if (typeof cur[8] !== 'string') {
                return acc + cur[8];
              }

              return acc;
            }, 0)
            .toFixed(2);

          table.body.push([
            { text: 'TOTAL', style: 'tableHeaderCell' },
            { text: '', style: 'tableHeaderCell' },
            { text: '', style: 'tableHeaderCell' },
            { text: '', style: 'tableHeaderCell' },
            { text: '', style: 'tableHeaderCell' },
            { text: '', style: 'tableHeaderCell' },
            {
              text: parseFloat(costHaTotal),
              style: 'tableHeaderCell',
            },
            {
              text: '',
              style: 'tableHeaderCell',
            },
            {
              text: parseFloat(costTotal),
              style: 'tableHeaderCell',
            },
            {
              text: '',
              style: 'tableHeaderCell',
            },
          ]);
        }

        if (type === 'planta' && index === tableData.length - 1) {
          const priceTotal = tableData
            .reduce((acc, cur) => {
              if (typeof cur[4] !== 'string') {
                return acc + cur[4];
              }

              return acc;
            }, 0)
            .toFixed(2);

          const costTotal = tableData
            .reduce((acc, cur) => {
              if (typeof cur[7] !== 'string') {
                return acc + cur[7];
              }

              return acc;
            }, 0)
            .toFixed(2);

          table.body.push([
            { text: 'TOTAL', style: 'tableHeaderCell' },
            { text: '', style: 'tableHeaderCell' },
            { text: '', style: 'tableHeaderCell' },
            { text: '', style: 'tableHeaderCell' },
            { text: parseFloat(priceTotal), style: 'tableHeaderCell' },
            { text: '', style: 'tableHeaderCell' },
            {
              text: '',
              style: 'tableHeaderCell',
            },
            {
              text: parseFloat(costTotal),
              style: 'tableHeaderCell',
            },
            {
              text: '',
              style: 'tableHeaderCell',
            },
          ]);
        }

        if (type === 'cuba' && index === tableData.length - 1) {
          const doseTotal = tableData
            .reduce((acc, cur) => {
              if (typeof cur[3] !== 'string') {
                return acc + cur[3];
              }

              return acc;
            }, 0)
            .toFixed(2);

          const priceTotal = tableData
            .reduce((acc, cur) => {
              if (typeof cur[4] !== 'string') {
                return acc + cur[4];
              }

              return acc;
            }, 0)
            .toFixed(2);

          const doseKgTotal = tableData
            .reduce((acc, cur) => {
              if (typeof cur[5] !== 'string') {
                return acc + cur[5];
              }

              return acc;
            }, 0)
            .toFixed(2);

          const costTotal = tableData
            .reduce((acc, cur) => {
              if (typeof cur[6] !== 'string') {
                return acc + cur[6];
              }

              return acc;
            }, 0)
            .toFixed(2);

          table.body.push([
            { text: 'TOTAL', style: 'tableHeaderCell' },
            { text: '', style: 'tableHeaderCell' },
            { text: '', style: 'tableHeaderCell' },
            {
              text: parseFloat(doseTotal),
              style: 'tableHeaderCell',
            },
            {
              text: parseFloat(priceTotal),
              style: 'tableHeaderCell',
            },
            {
              text: parseFloat(doseKgTotal),
              style: 'tableHeaderCell',
            },
            {
              text: parseFloat(costTotal),
              style: 'tableHeaderCell',
            },
          ]);
        }
      }
    });

    blueContainer.table.body[1].push([{ table: topTable, layout: 'noBorders' }, { table }]);

    this.pdfData.content.push(blueContainer);
  }

  insertTableGeneric(title, data, lastHeaderCell = false, extraHeader = '', striped = false) {
    const blueContainer = this.getBlueContainer(title, false);

    const table = {
      widths: Array(data[0].length).fill(`${(100 / data[0].length).toFixed(2)}%`),
      body: [],
    };

    const alignment = Array(data[0].length).fill('left');

    if (data.length > 1) {
      const indexStart = extraHeader === '' ? 1 : 2;
      for (let i = indexStart; i < data.length; i += 1) {
        data[i].forEach((cell, index) => {
          if (typeof cell === 'number') {
            alignment[index] = 'right';
          }
        });
      }
    }

    if (extraHeader !== '') {
      const len = data[0].length;
      table.body.push([
        {
          text: '',
          style: 'tableHeaderCell',
        },
        {
          text: extraHeader,
          colSpan: len - 1,
          alignment: 'center',
          style: 'tableHeaderCell',
        },
        ...new Array(len - 2).fill(''),
      ]);
    }

    data.forEach((row, index) => {
      if (index === 0 ||
        (data.length - 1 === index && lastHeaderCell)) {
        table.body.push(
          row.map((cellText, cellIndex) => ({
            style: 'tableHeaderCell',
            text: cellText,
            alignment: alignment[cellIndex],
          }))
        );
      } else {
        // Zebra style table
        if (striped) {
          const style = index % 2 === 0 ? 'tableNormalCellStriped' : 'tableNormalCell';

          table.body.push(
            row.map((cellText, cellIndex) => ({ style, text: cellText, alignment: alignment[cellIndex] }))
          );
        } else {
          table.body.push(
            row.map((cellText, cellIndex) => ({
              style: 'tableNormalCell',
              text: cellText,
              alignment: alignment[cellIndex],
            }))
          );
        }
      }
    });

    blueContainer.table.body[1].push({ table });

    this.pdfData.content.push(blueContainer);
  }

  insertObservations(generalObservation, productsObservations, data) {
    const blueContainer = this.getBlueContainer('OBSERVACIONES', false);

    const table = {
      widths: ['100%'],
      body: [],
    };

    if (generalObservation) {
      table.body.push([{ text: generalObservation, style: 'observationsTitle', margin: [0, 0, 0, 15] }]);
    }

    if (productsObservations.length > 0) {
      productsObservations.forEach((product) => {
        table.body.push([
          {
            ul: [
              {
                text: [
                  { text: `${product.name}: `, style: 'commentStyleTitle' },
                  { text: `${product.annotation}. `, style: 'normalStyle' },
                ],
                style: 'observationsList',
              },
            ],
          },
        ]);
      });
    }

    data.forEach((row, index) => {
      table.body.push([{ text: row.title, style: 'observationsTitle' }]);

      const comments = [];

      row.comment.forEach((comment) => {
        comments.push({ text: `${comment[0]}: `, style: 'commentStyleTitle' });
        comments.push({ text: `${comment[1]}. `, style: 'normalStyle' });
      });

      table.body.push([
        {
          style: 'observationsList',
          ul: [
            { text: `Fecha: ${row.date}`, style: 'normalStyle' },
            {
              text: comments,
            },
          ],
        },
      ]);

      for (const multimediaItem of row.multimedia) {
        table.body.push([
          {
            image: multimediaItem.base64,
            width: 250,
            alignment: 'center',
            margin: [0, 0, 0, 15],
          },
        ]);
      }
    });

    blueContainer.table.body[1].push({ table, layout: 'noBorders' });

    this.pdfData.content.push(blueContainer);
  }

  insertTableBottomSignature(data) {
    const blueContainer = this.getBlueContainer('RESPONSABLE DE LOS CÁLCULOS');

    const table = {
      widths: [],
      body: [],
    };

    const celldimension = (100 / data[0].length).toFixed(2);
    data[0].forEach((_) => {
      table.widths.push(`${celldimension}%`);
    });

    data.forEach((row) => {
      const headers = row.map((cell) => ({
        style: 'noBordersCellSignature',
        text: [
          { text: `${cell[0]}: `, fontSize: 10, bold: false },
          { text: cell[1], fontSize: 10, bold: true },
        ],
      }));
      table.body.push(headers);
    });

    table.body.push([
      {
        colSpan: 3,
        style: 'legalText',
        text: 'Este es un informe de recomendación. Timac Agro no se hará responsable en caso de un uso incorrecto del producto o de una indebida aplicación del mismo en campo por parte del cliente. Timac Agro no será responsable en la relación con la aplicación de ningún producto que no haya sido fabricado bajo su marca.',
      },
      '',
      '',
    ]);

    blueContainer.table.body[1].push({ table, layout: 'noBorders' });

    this.pdfData.content.push(blueContainer);
  }

  async insertTableAndGraph(title, tableData, domElement) {
    const blueContainer = this.getBlueContainer(title, false);
    const canvasToImage = await html2canvas(domElement);

    const table = {
      widths: Array(tableData[0].length).fill(`${(100 / tableData[0].length).toFixed(2)}%`),
      body: [],
    };

    const graph = {
      widths: ['*'],
      body: [],
    };

    tableData.forEach((row, index) => {
      if (index === 0) {
        table.body.push([
          { text: '', style: 'tableHeaderCell' },
          {
            text: 'UNITARIOS',
            colSpan: 5,
            alignment: 'center',
            style: 'tableHeaderCell',
          },
          { text: '', style: 'tableHeaderCell' },
          { text: '', style: 'tableHeaderCell' },
          { text: '', style: 'tableHeaderCell' },
          { text: '', style: 'tableHeaderCell' },
          {
            text: 'TOTALES',
            colSpan: 2,
            alignment: 'center',
            style: 'tableHeaderCell',
          },
          '',
        ]);

        table.body.push(row.map((cellText) => ({ style: 'tableHeaderCell', text: cellText })));
      } else if (index === tableData.length - 1) {
        table.body.push(row.map((cellText) => ({ style: 'tableHeaderCell', text: cellText })));
      } else {
        table.body.push(row.map((cellText) => ({ style: 'tableNormalCell', text: cellText, fontSize: cellText && cellText.length > 6 ? 7 : 8,
        Wrap: true  })));
      }
    });

    graph.body.push([
      {
        image: canvasToImage.toDataURL(),
        width: 520,
      },
    ]);

    blueContainer.table.body[1].push([
      { table },
      {
        table: graph,
        layout: 'noBorders',
        alignment: 'center',
        margin: [0, 15, 0, 15],
      },
    ]);

    this.pdfData.content.push(blueContainer);
  }

  assignColumns(showPlant, tableComplete, tableNoCost) {
    switch (true) {
      case showPlant && tableComplete:
        return 12;
      case showPlant && tableNoCost:
        return 7;
      case !showPlant && tableComplete:
        return 9;
      case !showPlant && tableNoCost:
        return 5;
      default:
        return columns; // Mantener el valor actual si ninguna condición se cumple
    }
  }

  insertResumePerProduct(title, tableData, showPlant, tableComplete, tableNoCost) {
    const blueContainer = this.getBlueContainer(title, false);

    let columns = this.assignColumns(showPlant, tableComplete, tableNoCost);

    const table = {
      widths: Array(columns).fill(`${(100 / columns).toFixed(2)}%`),
      body: [],
    };

    tableData.forEach((row, index) => {
      if (index === 0) {
        if (showPlant && tableComplete) {
          table.body.push([
            {
              text: 'Producto',
              rowSpan: 2,
              alignment: 'left',
              style: 'tableHeaderCell',
            },
            {
              text: 'Dosis unitaria',
              colSpan: 2,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            { text: '', style: 'tableHeaderCell' },
            {
              text: 'Dosis por planta',
              colSpan: 2,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            { text: '', style: 'tableHeaderCell' },
            {
              text: 'Dosis total',
              colSpan: 2,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            { text: '', style: 'tableHeaderCell' },
            {
              text: 'Precio',
              colSpan: 2,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            { text: '', style: 'tableHeaderCell' },
            {
              text: i18n.t('nutritionDetail.productResume.costUnit'),
              alignment: 'left',
              rowSpan: 2,
              style: 'tableHeaderCell',
              Wrap: true,
            },
            {
              text: i18n.t('nutritionDetail.productResume.costPlant'),
              alignment: 'left',
              rowSpan: 2,
              style: 'tableHeaderCell',
              Wrap: true
            },
            {
              text: i18n.t('nutritionDetail.productResume.costTotal'),
              alignment: 'left',
              rowSpan: 2,
              style: 'tableHeaderCell',
              Wrap: true,
            },
          ]);

          table.body.push([
            { text: '', style: 'tableHeaderCell' },
            { text: 'kg/ha', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'l/ha', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'g/planta', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'ml/planta', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'kg', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'l', style: 'tableHeaderCell', alignment: 'right' },
            { text: '€/kg', style: 'tableHeaderCell', alignment: 'right' },
            { text: '€/l', style: 'tableHeaderCell', alignment: 'right' },
            { text: '', style: 'tableHeaderCell', alignment: 'right' },
            { text: '', style: 'tableHeaderCell', alignment: 'right' },
            { text: '', style: 'tableHeaderCell', alignment: 'right' },
          ]);
        } else if (showPlant && tableNoCost) {
          table.body.push([
            {
              text: 'Producto',
              rowSpan: 2,
              alignment: 'left',
              style: 'tableHeaderCell',
              Wrap: true,
            },
            {
              text: 'Dosis unitaria',
              colSpan: 2,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            { text: '', style: 'tableHeaderCell' },
            {
              text: 'Dosis por planta',
              colSpan: 2,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            { text: '', style: 'tableHeaderCell' },
            {
              text: 'Dosis total',
              colSpan: 2,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            { text: '', style: 'tableHeaderCell' },
      
          ]);

          table.body.push([
            { text: '', style: 'tableHeaderCell' },
            { text: 'kg/ha', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'l/ha', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'g/planta', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'ml/planta', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'kg', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'l', style: 'tableHeaderCell', alignment: 'right' },
          ]);
        } else if(!showPlant && tableComplete) {
          table.body.push([
            {
              text: 'Producto',
              rowSpan: 2,
              alignment: 'left',
              style: 'tableHeaderCell',
              Wrap: true,
            },
            {
              text: 'Dosis unitaria',
              colSpan: 2,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            { text: '', style: 'tableHeaderCell' },
            {
              text: 'Dosis total',
              colSpan: 2,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            { text: '', style: 'tableHeaderCell' },
            {
              text: 'Precio',
              colSpan: 2,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            { text: '', style: 'tableHeaderCell' },
            {
              text: i18n.t('nutritionDetail.productResume.costUnit'),
              alignment: 'right',
              rowSpan: 2,
              style: 'tableHeaderCell',
              Wrap: true,
            },
            {
              text: i18n.t('nutritionDetail.productResume.costTotal'),
              alignment: 'right',
              rowSpan: 2,
              style: 'tableHeaderCell',
              Wrap: true 
            },
          ]);

          table.body.push([
            { text: '', style: 'tableHeaderCell'},
            { text: 'kg/ha', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'l/ha', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'kg', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'l', style: 'tableHeaderCell', alignment: 'right' },
            { text: '€/kg', style: 'tableHeaderCell', alignment: 'right' },
            { text: '€/l', style: 'tableHeaderCell', alignment: 'right' },
            { text: '', style: 'tableHeaderCell', alignment: 'right' },
            { text: '', style: 'tableHeaderCell', alignment: 'right' },
          ]);
        } else if(!showPlant && tableNoCost) {
          table.body.push([
            {
              text: 'Producto',
              rowSpan: 2,
              alignment: 'left',
              style: 'tableHeaderCell',
              Wrap: true,
              fontSize: 4,
            },
            {
              text: 'Dosis unitaria',
              colSpan: 2,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            { text: '', style: 'tableHeaderCell' },
            {
              text: 'Dosis total',
              colSpan: 2,
              alignment: 'center',
              style: 'tableHeaderCell',
            },
            { text: '', style: 'tableHeaderCell' },
          ]);

          table.body.push([
            { text: '', style: 'tableHeaderCell' },
            { text: 'kg/ha', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'l/ha', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'kg', style: 'tableHeaderCell', alignment: 'right' },
            { text: 'l', style: 'tableHeaderCell', alignment: 'right' },
          ]);
        }
        
        table.body.push(
          row.map((cellText, cellIndex) => ({
            style: 'tableNormalCell',
            text: cellText ?? '',
            alignment: cellIndex === 0 ? 'left' : 'right',
            fontSize: cellText && cellText.length > 6 ? 6 : 9,
            Wrap: true 
          }))
        );
      } else if (index === tableData.length - 1) {
        table.body.push(
          row.map((cellText, cellIndex) => ({
            style: 'tableHeaderCell',
            text: cellText ?? '',
            alignment: cellIndex === 0 ? 'left' : 'right',
            fontSize: cellText && cellText.length > 6 ? 6 : 8,
          }))
        );
      } else {
        table.body.push(
          row.map((cellText, cellIndex) => ({
            style: 'tableNormalCell',
            text: cellText ?? '',
            alignment: cellIndex === 0 ? 'left' : 'right',
            fontSize: cellText && cellText.length > 6 ? 6 : 9,
            Wrap: true 
          }))
        );
      }
    });

    blueContainer.table.body[1].push([{ table }]);

    this.pdfData.content.push(blueContainer);
    this.pdfData.content.push({ text: '', pageBreak: 'after' });
  }

    
  insertIrrigationTable(title, tableData, productNames, period) {
    const blueContainer = this.getBlueContainer(title, false);
    const numProducts = productNames.length;
    const numColumns = (numProducts * 2) + 3;
  
    const table = {
      widths: Array(numColumns).fill(`${(100 / numColumns).toFixed(2)}%`),
      body: [],
    };

    // Generación de encabezados estática (primera fila)
    const headerRow = [{ text: `${period}`, rowSpan: 2, alignment: 'left', style: 'tableHeaderCell', fontSize: 7 }];
  
    for (let i = 0; i < productNames.length; i++) {
      headerRow.push(
        { text: `${productNames[i].caption}`, colSpan: 2, alignment: 'center', style: 'tableHeaderCell', fontSize: 7 },
        { text: '', style: 'tableHeaderCell' }
      );
    }
  
    headerRow.push(
      { text: 'NºTotal de Riegos', rowSpan: 2, alignment: 'center', style: 'tableHeaderCell', fontSize: 7 },
      { text: 'Tiempo/Riegos(horas)', rowSpan: 2, alignment: 'center', style: 'tableHeaderCell', fontSize: 7 }
    );
  
    table.body.push(headerRow);
  
    // Generación de encabezados dinámicos (segunda fila)
    const dynamicHeaders = [];

    dynamicHeaders.push({ text: '', style: 'tableHeaderCell' });

    for (let i = 0; i < numProducts; i++) {
      dynamicHeaders.push(
        { text: `NºRiegos`, style: 'tableHeaderCell', fontSize: 7 },
        { text: `${productNames[i].name}`, style: 'tableHeaderCell', fontSize: 7 }
      );
    }

    dynamicHeaders.push({ text: '', style: 'tableHeaderCell' });
    dynamicHeaders.push({ text: '', style: 'tableHeaderCell' });
  
    table.body.push(dynamicHeaders);
  
    // Llenado de datos de tableData
    tableData.forEach(entry => {
      const row = [
        { text: entry.name || '', fontSize: 7 },
      ];
  
      for (let i = 0; i < numProducts; i++) {
        row.push({ text: entry[`periods_product_irrigation_${i}`] || 0, fontSize: 7 });
        row.push({ text: entry[`periods_dose_${i}`] || 0, fontSize: 7 });
      }
  
      row.push({ text: entry.periods_irrigation || 0, fontSize: 7 });
      row.push({ text: entry.periods_irrigation_time || 0, fontSize: 7 });
  
      table.body.push(row);
    });
  
    // Agregar la tabla al contenedor y al PDF
    blueContainer.table.body[1].push([{ table }]);
    this.pdfData.content.push(blueContainer);
    this.pdfData.content.push({ text: '', pageBreak: 'after' });
  }

  async insertGenericChart(title, domElement) {
    const blueContainer = this.getBlueContainer(title, true);
    const canvasToImage = await html2canvas(domElement);

    const graph = {
      widths: ['*'],
      body: [],
    };

    graph.body.push([
      {
        image: canvasToImage.toDataURL(),
        width: 520,
      },
    ]);

    blueContainer.table.body[1].push([
      {
        table: graph,
        layout: 'noBorders',
        alignment: 'center',
        margin: [0, 15, 0, 15],
      },
    ]);

    this.pdfData.content.push(blueContainer);
  }

  insertReportTitle(text) {
    this.pdfData.content.push({ text, style: 'reportTitleStyle' });
  }

  async getPdfAsBuffer() {
    const pdfDocGenerator = pdfmake.createPdf(cloneDeep(this.pdfData));
    return new Promise((resolve) => {
      pdfDocGenerator.getBuffer((buffer) => {
        resolve(buffer);
      });
    });
  }

  async buildAndDownload(filename) {
    return new Promise((resolve) => {
      pdfmake.createPdf(this.pdfData).download(filename, () => {
        resolve();
      });
    });
  }

  buildForMobile() {
    return pdfmake.createPdf(cloneDeep(this.pdfData));
  }
}

import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    plans: [],
    currentPlanId: '',
    currentTypePlan: '',
    currentPlan: {},
  },
  actions: {
    async fetchPlans({ commit }, typePlan) {
      try {
        let xhr = null;
        if (typePlan === 'fertilization') {
          xhr = await Api.fetchFertilizations();
        } else {
          xhr = await Api.fetchTreatments();
        }
        const plans = JSON.parse(xhr.response).data;
        commit('fetchPlans', plans);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getPlanById({ commit }, { planId, typePlan }) {
      try {
        let xhr = null;
        if (typePlan === 'fertilization') {
          xhr = await Api.getFertilizationById(planId);
        } else {
          xhr = await Api.getTreatmentById(planId);
        }
        const plan = JSON.parse(xhr.response);
        commit('setCurrentPlan', plan);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getAnalysisSamples({ commit }, value) {
      try {
        const xhr = await Api.getAnalysisSamples(null);
        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setCurrentPlanId({ commit }, id) {
      commit('setCurrentPlanId', id);
    },
    setCurrentTypePlan({ commit }, id) {
      commit('setCurrentTypePlan', id);
    },
  },
  mutations: {
    fetchPlans(state, value) {
      state.plans = value;
    },
    setCurrentPlanId(state, value) {
      state.currentPlanId = value;
    },
    setCurrentTypePlan(state, value) {
      state.currentTypePlan = value;
    },
    setCurrentPlan(state, value) {
      state.currentPlan = value;
    },
  },
};

<template>
  <DxPopup
    id="popup-copy-plantation"
    :visible="true"
    :close-on-outside-click="false"
    height="auto"
    :title="$t('copyPlantation')"
    :width="500"
    :on-hidden="close"
    :drag-enabled="false"
  >
    <div
      v-if="!copying"
      class="form-container"
    >
      <div class="dx-field">
        <div class="dx-field-label">
          <span>{{ $t('elementsToCopy') }}</span>
        </div>
        <div class="dx-field-value">
          <DxTagBox
            v-model="selectedElementsToCopy"
            :items="elementsToCopy"
            :show-selection-controls="true"
            apply-value-mode="useButtons"
            display-expr="text"
            value-expr="id"
          />
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">
          <span>{{ $t('plantationDate') }}</span>
        </div>
        <div class="dx-field-value">
          <DxDateBox
            :key="resetDateBox"
            v-model="selectedDate"
            :display-format="'dd/MM/yyyy'"
            picker-type="calendar"
            :min="setEndDate()"
            width="100%"
            type="date"
            :accept-custom-value="false"
            :open-on-field-click="true"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="message-container"
    >
      <div class="spinner-copy-container">
        <div class="spinner-copy" />
        <div class="margin-left">
          {{ copyingMessage }}
        </div>
      </div>
    </div>
    <f7-row>
      <f7-col
        class="justify-content-between margin-top margin-left margin-right"
      >
        <f7-button
          class="
            dx-btn-cancel
            margin-right-block
            btn-width-150
            margin-top-block margin-bottom-block
          "
          style="color: #545454; background: #f7f7f7 !important;"
          raised
          type="success"
          styling-mode="contained"
          @click="close()"
        >
          {{ $t('Button_go_to_back') }}
        </f7-button>
        <f7-button
          class="
            dx-btn-cancel
            margin-right-block
            btn-width-150
            margin-top-block margin-bottom-block
            standard-blue-btn
          "
          raised
          type="success"
          styling-mode="contained"
          :disabled="copying"
          @click="startCopy()"
        >
          {{ $t('continue') }}
        </f7-button>
      </f7-col>
    </f7-row>
  </DxPopup>
</template>
<script>
import { DxPopup } from 'devextreme-vue/popup';
import { DxTagBox } from 'devextreme-vue/tag-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxSelectBox from 'devextreme-vue/select-box';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import Api from '../../../services/Api';
import fertilizationService from '../../../services/fertilizationService';
import treatmentPlanService from '../../../services/treatmentPlanService';

export default {
  name: 'PopupCopyPlantation',
  components: {
    DxSelectBox,
    DxPopup,
    DxTagBox,
    DxDateBox,
  },
  data() {
    return {
      elementsToCopy: [
        {
          id: 0,
          text: this.$t('copyNutritionalPlans'),
        },
        {
          id: 1,
          text: this.$t('copyTreatmentPlans'),
        },
        {
          id: 2,
          text: this.$t('copyIrrigationPlans'),
        },
        {
          id: 3,
          text: this.$t('copyAnalytics'),
        },
      ],
      selectedElementsToCopy: [],
      selectedDate: null,
      avaiableDates: [],
      mappedDates: {
        month: null,
        year: null,
        decade: null,
        century: null,
      },
      copyStarted: false,
      resetDateBox: 'date-box-key',
      copying: false,
      copyingMessage: '',
    };
  },
  computed: {
    ...mapState('Explotaciones', ['actualUser']),
    ...mapState('Sector', ['currentSectorId']),
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapState('authentication', ['user']),
  },
  methods: {
    setEndDate() {
      // Clonamos la fecha original para no modificarla directamente
     const clonedDate = new Date(this.actualPlantation.end_date);
    
    // Sumamos un día a la fecha clonada
      clonedDate.setDate(clonedDate.getDate() + 1);

     return moment(clonedDate).format('YYYY-MM-DD');
    },
    async startCopy() {
      this.copying = true;
      this.copyingMessage = this.$t('copyingPlantation');
      const plantations = await this.copyPlantation();

      if (plantations === undefined) {
        return;
      }

      for (const element of this.selectedElementsToCopy) {
        if (element === 0) {
          this.copyingMessage = this.$t('copyingNutritionalPlans');
          await this.copyNutritionalPlans(plantations.old, plantations.new);
        }

        if (element === 1) {
          this.copyingMessage = this.$t('copyingTreatmentPlans');
          await this.copyTreatmentPlans(plantations.old, plantations.new);
        }

        if (element === 2) {
          this.copyingMessage = this.$t('copyingIrrigationPlans');
          await this.copyIrrigation(plantations.old, plantations.new);
        }

        if (element === 3) {
          this.copyingMessage = this.$t('copyingAnalytics');
          await this.copyAnalytics(plantations.old, plantations.new);
        }
      }

      this.updateRasterDates();

      this.copyingMessage = '';
      this.copying = false;

      this.$notifyDX(
        {
          message: this.$t('copySuccess'),
          width: 550,
        },
        'success',
        3000,
      );

      this.$emit('close');
    },
    async copyPlantation() {
      try {
        const xhr = await Api.getPlantationById(
          this.actualUser.id,
          this.actualPlantation.id,
        );
        const dataPlantation = JSON.parse(xhr.response);

        let cropData = null;

        if (dataPlantation.crop_type === 'HERBÁCEO') {
          cropData = {
            planting_density: dataPlantation.planting_density,
            planting_unit: dataPlantation.planting_unit,
          };
        }
        if (dataPlantation.crop_type === 'HORTÍCOLA') {
          cropData = {
            plantation_frame: {
              x: dataPlantation.plantation_frame.x,
              y: dataPlantation.plantation_frame.y,
            },
          };
        }
        if (dataPlantation.crop_type === 'LEÑOSO') {
          cropData = {
            plantation_frame: {
              x: dataPlantation.plantation_frame.x,
              y: dataPlantation.plantation_frame.y,
            },
            plantation_date: dataPlantation.plantation_date,
            cup_diameter: dataPlantation.cup_diameter,
          };
        }

        const daysDiff = moment(dataPlantation.end_date).diff(
          moment(dataPlantation.init_date),
          'days',
        );

        const xhrPlantation = await Api.createPlantation(this.actualUser.id, {
          name: dataPlantation.name,
          management: dataPlantation.management,
          protection: dataPlantation.protection,
          crop_type: dataPlantation.crop_type,
          init_date: moment(this.selectedDate).format('YYYY-MM-DD'),
          end_date: moment(this.selectedDate)
            .add(daysDiff, 'days')
            .format('YYYY-MM-DD'),
          species: dataPlantation.species,
          variety: dataPlantation.variety,
          plants_density: dataPlantation.plants_density,
          plants: dataPlantation.plants,
          farms: dataPlantation.farms.map((farm) => farm.id),
          expected_production: dataPlantation.expected_production ?? 0,
          ...cropData,
        });

        return {
          old: dataPlantation,
          new: JSON.parse(xhrPlantation.response),
        };
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(this.$helpers.getErrorMSG(error)),
            width: 550,
          },
          'error',
          3000,
        );

        this.copying = false;
      }
    },
    async copyNutritionalPlans(oldPlantation, newPlantation) {
      try {
        const nutritionList = await fertilizationService
          .getAll(this.actualUser.id, this.actualPlantation.id)
          .then((res) => res.data);

        if (nutritionList != null && nutritionList.length > 0) {
          for (const nutrition of nutritionList) {
            const nutritionPlan = await fertilizationService.getById(
              nutrition.id,
              this.actualUser.id,
            );

            const daysDiffFromInitDate = moment(nutritionPlan.init_date).diff(
              moment(oldPlantation.init_date),
              'days',
            );

            const nutritionPlanDurationDays = moment(
              nutritionPlan.end_date,
            ).diff(moment(nutritionPlan.init_date), 'days');

            const payload = {
              ...nutritionPlan,
              description: nutritionPlan.description,
              plantation: newPlantation.id,
              init_date: moment(newPlantation.init_date)
                .add(daysDiffFromInitDate, 'days')
                .format('YYYY-MM-DD'),
              end_date: moment(newPlantation.init_date)
                .add(daysDiffFromInitDate + nutritionPlanDurationDays, 'days')
                .format('YYYY-MM-DD'),
            };

            await fertilizationService.save(payload, this.actualUser.id);
          }
        }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                this.$helpers.getErrorMSG(error),
              ),
            ),
            width: 550,
          },
          'error',
          3000,
        );

        this.copying = false;
      }
    },
    async copyTreatmentPlans(oldPlantation, newPlantation) {
      try {
        const treatmentList = await treatmentPlanService
          .getAll({
            companyId: this.actualUser.id,
            idPlantation: this.actualPlantation.id,
          })
          .then((res) => res.data);

        if (treatmentList != null && treatmentList.length > 0) {
          for (const treatment of treatmentList) {
            const treatmentPlan = await treatmentPlanService.getById(
              treatment.id,
              this.actualUser.id,
            );

            const daysDiffFromInitDate = moment(treatmentPlan.init_date).diff(
              moment(oldPlantation.init_date),
              'days',
            );

            const treatmentPlanDurationDays = moment(
              treatmentPlan.end_date,
            ).diff(moment(treatmentPlan.init_date), 'days');

            const payload = {
              ...treatmentPlan,
              description: treatmentPlan.description,
              plantation: newPlantation.id,
              init_date: moment(newPlantation.init_date)
                .add(daysDiffFromInitDate, 'days')
                .format('YYYY-MM-DD'),
              end_date: moment(newPlantation.init_date)
                .add(daysDiffFromInitDate + treatmentPlanDurationDays, 'days')
                .format('YYYY-MM-DD'),
            };

            await treatmentPlanService.save(payload, this.actualUser.id);
          }
        }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                this.$helpers.getErrorMSG(error),
              ),
            ),
            width: 550,
          },
          'error',
          3000,
        );

        this.copying = false;
      }
    },
    async copyIrrigation(oldPlantation, newPlantation) {
      try {
        const xhr = await Api.getIrrigation(
          this.actualUser.id,
          this.actualPlantation.id,
        );
        const irrigationList = JSON.parse(xhr.response).data;

        if (irrigationList != null && irrigationList.length > 0) {
          for (const irrigation of irrigationList) {
            const daysDiffFromInitDate = moment(irrigation.date).diff(
              moment(oldPlantation.init_date),
              'days',
            );

            const payload = {
              date: moment(newPlantation.init_date)
                .add(daysDiffFromInitDate, 'days')
                .format('YYYY-MM-DD'),
              doses: irrigation.doses,
              id: '',
              plantation: newPlantation.id,
              time: 0,
              type: irrigation.type,
            };
            await Api.postIrrigation(payload, this.actualUser.id);
          }
        }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                this.$helpers.getErrorMSG(error),
              ),
            ),
            width: 550,
          },
          'error',
          3000,
        );

        this.copying = false;
      }
    },
    async copyAnalytics(oldPlantation, newPlantation) {
      try {
        const xhr = await Api.getObservations('?null', {
          companyId: this.actualUser.id,
          currentPlantationId: oldPlantation.id,
        });
        const allAnalysis = JSON.parse(xhr.response);

        if (allAnalysis != null && allAnalysis.results.length > 0) {
          for (const analysis of allAnalysis.results) {
            if (analysis.category !== 'Muestreo') {
              continue;
            }

            const observationXhr = await Api.getObservationDetail(
              this.actualUser.id,
              analysis.id,
            );

            const observation = JSON.parse(observationXhr.response);

            const scoutingPointXhr = await Api.createPointObservation(
              this.actualUser.id,
              {
                name: observation.scouting_point.name,
                type: observation.scouting_point.type,
                plantation: newPlantation.id,
                latitude: observation.scouting_point.latitude,
                longitude: observation.scouting_point.longitude,
                area: observation.scouting_point?.area ?? '',
              },
            );

            const scoutingPoint = JSON.parse(scoutingPointXhr.response);

            const multimediaFormatted = await Promise.all(
              observation.multimedia.map(async (multimedia) => ({
                attachment: await this.imageToBase64(multimedia.attachment),
                description: multimedia.description,
                extension: multimedia.extension,
                tempName: multimedia.name,
              })),
            );

            const payload = {
              Descripcion: observation.Descripcion,
              Interpretacion: observation.Interpretacion,
              date: observation.date,
              multimedia: multimediaFormatted,
              plantation: newPlantation.id,
              sample_id: observation.sample_id,
              scouting_point: scoutingPoint?.id,
              template: observation.template,
            };

            if (scoutingPoint?.id != null) {
              await Api.createNewObservation(this.actualUser.id, payload);
            }
          }
        }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                this.$helpers.getErrorMSG(error),
              ),
            ),
            width: 550,
          },
          'error',
          3000,
        );

        this.copying = false;
      }
    },
    async updateRasterDates() {
      try {
        const xhr = await Api.getPlantationById(
          this.actualUser.id,
          this.actualPlantation.id,
        );
        const dataPlantation = JSON.parse(xhr.response);

        const xhrSector = await Api.getSectorById(
          this.actualUser.id,
          dataPlantation.sectorId,
        );

        const dataSector = JSON.parse(xhrSector.response);

        const daysDiff = moment(dataPlantation.end_date).diff(
          moment(dataPlantation.init_date),
          'days',
        );

        const initDate = moment(this.selectedDate).format('YYYY-MM-DD');
        const endDate = moment(this.selectedDate).add(daysDiff, 'days').format('YYYY-MM-DD');

        await Api.updateRasterDates('timacagro', JSON.stringify(dataSector.geo_feature), initDate, endDate);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(this.$helpers.getErrorMSG(error)),
            width: 550,
          },
          'error',
          3000,
        );

        this.copying = false;
      }
    },
    close() {
      this.$emit('close');
    },
    async imageToBase64(path) {
      const blob = await fetch(path).then((res) => res.blob());
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './PopupCopyPlantation.styles.scss';
</style>

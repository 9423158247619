<template>
  <div class="step-two-table">
    <DxDataGrid
      class="data-grid-products"
      :data-source="[...filteredProducts, ...lastRowWithTotal]"
      :show-borders="true"
    >
      <DxColumn
        data-field="product"
        :caption="$t('treatment.treatmentPlan.treatmentCreatePlan.product')"
        :allow-sorting="false"
        :allow-editing="false"
      />
      <DxColumn
        data-field="agent"
        :caption="$t('treatment.treatmentPlan.treatmentCreatePlan.plagueAgent')"
        :allow-sorting="false"
        :allow-editing="false"
      />
      <DxColumn
        data-field="dose_type"
        :caption="$t('treatment.treatmentPlan.treatmentCreatePlan.dosage')"
        :allow-sorting="false"
        :allow-editing="false"
      />
      <DxColumn
        :caption="
          $t('treatment.treatmentPlan.treatmentCreatePlan.treatmentType')
        "
        cell-template="selectColumnTemplate"
        :width="136"
      />

      <template #selectColumnTemplate="{ data }">
        <div v-if="data.data.isLastRow !== true">
          <DxSelectBox
            v-if="!readOnly"
            v-model="data.data.treatment_type"
            :items="treatmentTypes"
          />
          <span v-else>{{ data.data.treatment_type }}</span>
        </div>
      </template>
    </DxDataGrid>

    <DxDataGrid
      class="data-grid-products-data"
      :data-source="[...filteredProducts, ...lastRowWithTotal]"
      :column-min-width="100"
      :column-auto-width="true"
      :show-borders="true"
      :on-cell-prepared="onCellPrepared"
      @row-updated="onRowUpdated"
    >
      <DxColumn
        :caption="$t('unitaries')"
        alignment="center"
      >
        <DxColumn
          data-field="dose_volume"
          alignment="right"
          name="dose_volume_Ha"
          :caption="
            $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeHa')
          "
          :allow-sorting="false"
          :calculate-display-value="getDoseVolumeValueFirstType"
        />
        <DxColumn
          data-field="dose_volume"
          alignment="right"
          name="dose_volume_L"
          :caption="
            $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeL')
          "
          :allow-sorting="false"
          :calculate-display-value="getDoseVolumeValueSecondType"
        />
        <DxColumn
          data-field="broth_volume"
          alignment="right"
          :caption="
            $t(
              'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothVolumeHa'
            )
          "
          :allow-sorting="false"
        />
        <DxColumn
          :caption="
            $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTree')
          "
          alignment="right"
          :allow-sorting="false"
          :allow-editing="false"
          css-class="disabled-column"
          :calculate-cell-value="getDoseTree"
        />
        <DxColumn
          :caption="
            $t(
              'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothVolumeTree'
            )
          "
          alignment="right"
          :allow-sorting="false"
          :allow-editing="false"
          css-class="disabled-column"
          :calculate-cell-value="getBrothTree"
        />
        <DxColumn
          data-field="price"
          alignment="right"
          :caption="
            $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceL')
          "
          :allow-sorting="false"
        />
        <DxColumn
          :caption="
            $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceTree')
          "
          alignment="right"
          :allow-sorting="false"
          :allow-editing="false"
          css-class="disabled-column"
          :calculate-cell-value="getPriceTree"
        />
      </DxColumn>
      <DxColumn
        :caption="$t('totals')"
        alignment="center"
      >
        <DxColumn
          :caption="
            $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTotal')
          "
          alignment="right"
          :allow-sorting="false"
          :allow-editing="false"
          css-class="disabled-column"
          :calculate-cell-value="getDoseTotal"
        />
        <DxColumn
          :caption="
            $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothTotal')
          "
          alignment="right"
          :allow-sorting="false"
          :allow-editing="false"
          css-class="disabled-column"
          :calculate-cell-value="getTotalBroth"
        />
        <DxColumn
          :caption="
            $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costTotal')
          "
          alignment="right"
          :allow-sorting="false"
          :allow-editing="false"
          css-class="disabled-column"
          :calculate-cell-value="getCostTotal"
        />
      </DxColumn>
      <DxEditing
        v-if="!readOnly"
        :allow-updating="true"
        mode="cell"
      />
      <DxEditing
        v-if="!readOnly"
        :allow-updating="true"
        mode="cell"
      />
    </DxDataGrid>

    <DxDataGrid
      class="data-grid-buttons"
      :data-source="[...filteredProducts, ...lastRowWithTotal]"
      :show-borders="true"
    >
      <DxColumn
        cell-template="buttonsColumnTemplate"
        alignment="center"
      />

      <template #buttonsColumnTemplate="{ data }">
        <div class="column-buttons">
          <DxButton
            v-if="data.data.isLastRow !== true"
            styling-mode="text"
            type="success"
            icon="find"
            @click="viewProduct(data)"
          />

          <DxButton
            v-if="!readOnly && data.data.isLastRow !== true"
            styling-mode="text"
            type="danger"
            icon="trash"
            @click="deleteItem(data)"
          />
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxDataGrid, DxColumn, DxEditing } from 'devextreme-vue/data-grid';
import { mapActions, mapState } from 'vuex';
import DxButton from 'devextreme-vue/button';

export default {
  name: 'TreatmentStepTwoTablePlant',
  components: {
    DxSelectBox,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxEditing,
  },
  props: {
    period: {
      type: Object,
      default: () => {},
    },
    selectedIndex: Number,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ownerAgent: 'TIMAC',
      ownerAgentAll: 'TODOS',
      treatmentTypes: ['FOLIAR', 'RADICULAR'],
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapState('treatmentStepper', ['treatmentPlan']),
    ...mapState('Plantation', ['currentPlantation']),
    filteredProducts() {
      if (this.readOnly) {
        return this.period.products.filter((item) => item.active !== false && item.dose_volume > 0);
      }

      return this.period.products.filter((item) => item.active !== false);
    },
    lastRowWithTotal() {
      return [
        {
          product: '',
          agent: '',
          dose_type: '',
          isLastRow: true,
        },
      ];
    },
    TotalCostOfPeriod() {
      const total = this.filteredProducts.reduce((acc, item) => {
        const dose_total = this.getDoseTotal(item);

        const cost = this.calculateFormula(11, {
          price: item.price,
          dose_total,
        });

        return acc + cost;
      }, 0);

      const fixedResult = Number(total.toFixed(2));

      if (isNaN(fixedResult) || !isFinite(fixedResult)) {
        return 0;
      }

      return fixedResult;
    },
  },
  methods: {
    onRowUpdated(e) {
      const { broth_volume } = e.data;
      this.period.products = this.period.products.map((item) => {
        item.broth_volume = broth_volume;
        return item;
      });
    },
    deleteItem(item) {
      let indexPeriod = 0;
      let count = this.selectedIndex;

      for (const period of this.treatmentPlan.periods) {
        const doseVolumeNotEmpty = period.products.some(
          ({ dose_volume, active }) => (dose_volume > 0 && active),
        );

        if (doseVolumeNotEmpty) {
          if (count === 0) {
            break;
          }

          count--;
        }
        indexPeriod++;
      }

      const index = item.rowIndex;
      const { products } = this.treatmentPlan.periods[indexPeriod];

      let trueIndex = index;

      for (let i = index; i < products.length; i++) {
        if (products[i].active === false) {
          trueIndex++;
        } else {
          break;
        }
      }

      this.treatmentPlan.periods[indexPeriod].products[
        trueIndex
      ].active = false;
      this.treatmentPlan.periods[indexPeriod].products[
        trueIndex
      ].dose_volume = 0;
    },
    async viewProduct(item) {
      const product = this.treatmentPlan.products[item.rowIndex];

      if (product.agent === this.ownerAgent) {
        this.$emit('owner', this.ownerAgent);

        await this.getNutritionalProductDetail(product.id);
      } else if (product.agent === this.ownerAgentAll) {
        this.$emit('owner', this.ownerAgentAll);

        await this.getPhytosanitaryProductDetail({
          id: product.id,
          crop: this.currentPlantation.species,
        });
      } else {
        this.$emit('owner', '');

        await this.getPhytosanitaryProductDetail({
          id: product.id,
          crop: this.currentPlantation.species,
          agent: product.agent,
        });
      }

      this.setDisplayProductDetailPopup(true);
    },
    onCellPrepared(e) {
      if (e.rowType === 'data' && e.data.isLastRow === true) {
        e.cellElement.classList.add('disabled-column');
        e.cellElement.style.fontWeight = 'bold';
        e.cellElement.style.pointerEvents = 'none';
        return;
      }

      if (
        e.rowType === 'data'
        && (e.columnIndex === 0 || e.columnIndex === 1)
      ) {
        if (e.columnIndex === 0 && e.data.dose_type === 'kG-L/1000L') {
          e.cellElement.classList.add('disabled-column');
          e.cellElement.style.pointerEvents = 'none';
        }

        if (e.columnIndex === 1 && e.data.dose_type === 'kG-L/HA') {
          e.cellElement.classList.add('disabled-column');
          e.cellElement.style.pointerEvents = 'none';
        }
      }
    },
    getDoseVolumeValueFirstType(data) {
      const type = 'kG-L/HA';

      if (data.dose_type === type) {
        return data.dose_volume;
      }

      return this.calculateFormula(2, data);
    },
    getDoseVolumeValueSecondType(data) {
      const type = 'kG-L/1000L';

      if (data.dose_type === type) {
        return data.dose_volume;
      }

      // return this.calculateFormula(1, data);
      return '';
    },
    getDoseTree(data) {
      const dose_volume = this.getDoseVolumeValueFirstType(data);

      return this.calculateFormula(8, {
        dose_volume,
        plants_density: this.actualPlantation.plants_density,
        isLastRow: data.isLastRow,
      });
    },
    getBrothTree(data) {
      return this.calculateFormula(9, {
        broth_volume: data.broth_volume,
        plants_density: this.actualPlantation.plants_density,
        isLastRow: data.isLastRow,
      });
    },
    getDoseTotal(data) {
      return this.calculateFormula(10, {
        dose_volume: this.getDoseVolumeValueFirstType(data),
        area: this.currentPlantation.area,
        isLastRow: data.isLastRow,
      });
    },
    getCostTotal(data) {
      const dose_total = this.getDoseTotal(data);

      if (data.isLastRow) {
        return this.TotalCostOfPeriod;
      }

      return this.calculateFormula(11, {
        price: data.price,
        dose_total,
        isLastRow: data.isLastRow,
      });
    },
    getTotalBroth(data) {
      return this.calculateFormula(5, {
        broth_volume: data.broth_volume,
        area: this.currentPlantation.area,
        isLastRow: data.isLastRow,
      });
    },
    getPriceTree(data) {
      const dose_tree = this.getDoseTree(data);

      return this.calculateFormula(12, {
        price: data.price,
        dose_tree,
        isLastRow: data.isLastRow,
      });
    },
    calculateFormula(type, data) {
      if (data.isLastRow === true) {
        return '';
      }

      let result = 0;
      // Dosis (kg-L/1000L)= Dosis (kg-L/ha)/(Vol. Caldo (l/ha)/1000)
      if (type === 1) {
        result = data.dose_volume / (data.broth_volume / 1000);
      }

      // Dosis (kg-L/ha) =Dosis (kg-L/1000L) * (Vol. Caldo (l/ha)/1000)
      if (type === 2) {
        result = data.dose_volume * (data.broth_volume / 1000);
      }

      // Coste/ha = Dosis kg-L/ha * Precio €/Kg-L
      if (type === 3) {
        result = data.dose_volume * data.price;
      }

      // Coste = coste (€/ha) * superficie (ha)
      if (type === 4) {
        result = data.cost_ha * data.area;
      }

      // Vol caldo total = Vol caldo (L/ha) *  Superficie (ha)
      if (type === 5) {
        result = data.broth_volume * data.area;
      }

      // Dosis (kg-L/Cuba) = Dosis (kg-l/ha) / Vol.Caldo (l/ha)
      if (type === 6) {
        result = data.dose_volume / data.broth_volume;
      }

      // Precio (€/cuba) = precio (€/kg-l) / dosis (kg-l/cuba)
      if (type === 7) {
        result = data.price / data.dose_volume_cuba;
      }

      // Dosis (g-ml)/árbol = Dosis (kg-L/ha) / nº plantas (ha) *1000
      if (type === 8) {
        result = data.dose_volume / data.plants_density * 1000;
      }

      // Vol caldo (L/árbol) = Vol. caldo (l/ha) / nº plantas (ha)
      if (type === 9) {
        result = data.broth_volume / data.plants_density;
      }

      // Dosis kg-L Total = Dosis (kg-l/ha) * Superficie (ha)
      if (type === 10) {
        result = data.dose_volume * data.area;
      }

      // Coste total = Precio (€/kg-L) * Dosis kg-L Total
      if (type === 11) {
        result = data.price * data.dose_total;
      }

      // Precio/árbol = Precio (€/kg-L) * Dosis (g-ml)/árbol /1000
      if (type === 12) {
        result = data.price * data.dose_tree / 1000;
      }

      const fixedResult = Number(result.toFixed(2));

      if (isNaN(fixedResult) || !isFinite(fixedResult)) {
        return 0;
      }
      return fixedResult;
    },
    ...mapActions('Vademecum', [
      'getPhytosanitaryProductDetail',
      'getNutritionalProductDetail',
    ]),
    ...mapActions('treatment', ['setDisplayProductDetailPopup']),
  },
};
</script>

<style src="./treatmentStepTwoTables.styles.scss" scoped lang="scss"></style>

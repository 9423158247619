<template>
  <section>
    <div class="content-devx no-margin margin-left-half margin-right-half">
      <div class="main-title-content">
        {{ $t('nutritionDetail.elementResume.title') }}
      </div>
      <div class="content-separatos">
        <DxDataGrid
          class="data-grid-balance-value"
          :data-source="elementResume"
          :row-alternation-enabled="true"
          :show-borders="true"
        >
          <DxColumn
            data-field="uf"
            :caption="$t('nutritionDetail.elementResume.uf')"
            :allow-sorting="false"
            :width="170"
          />
          <DxColumn
            :caption="$t('nutritionDetail.elementResume.n')"
            data-field="n"
            data-type="number"
            :allow-sorting="false"
          />
          <DxColumn
            :caption="$t('nutritionDetail.elementResume.p')"
            data-field="p"
            data-type="number"
            :allow-sorting="false"
          />
          <DxColumn
            :caption="$t('nutritionDetail.elementResume.k')"
            data-field="k"
            data-type="number"
            :allow-sorting="false"
          />
          <DxColumn
            :caption="$t('nutritionDetail.elementResume.c')"
            data-field="c"
            data-type="number"
            :allow-sorting="false"
          />
          <DxColumn
            :caption="$t('nutritionDetail.elementResume.m')"
            data-field="m"
            data-type="number"
            :allow-sorting="false"
          />
          <DxColumn
            :caption="$t('nutritionDetail.elementResume.s')"
            data-field="s"
            data-type="number"
            :allow-sorting="false"
          />
        </DxDataGrid>
      </div>
    </div>
  </section>
</template>
<script>
import { DxColumn, DxDataGrid } from 'devextreme-vue/data-grid';

export default {
  name: 'ElementResume',
  components: {
    DxDataGrid,
    DxColumn,
  },
  props: ['fertilization'],
  data() {
    return {
      elementResume: [],
    };
  },
  computed: {},
  mounted() {
    const ufAvailableSoil = this.fertilization.uf_available_soil;
    const ufIrrigationWater = this.fertilization.uf_irrigation_water;
    const ufRestHarvest = this.fertilization.uf_rest_harvest;
    const nutritionalNeeds = this.fertilization.nutritional_needs;

    const ufTeoric = this.fertilization.uf_teoric;
    const ufEfficiencyProvided = this.fertilization.uf_efficiency_provided;
    const finalBalance = this.fertilization.final_balance;

    this.elementResume.push({
      uf: this.$t('nutritionalBalance.necNutritional'),
      n: nutritionalNeeds.n,
      p: nutritionalNeeds.p205,
      k: nutritionalNeeds.k20,
      c: nutritionalNeeds.ca0,
      m: nutritionalNeeds.mg0,
      s: nutritionalNeeds.s03,
    });

    this.elementResume.push({
      uf: this.$t('nutritionalBalance.advanceFertilization'),
      n:
        ufAvailableSoil.n
        + ufIrrigationWater.n
        + ufRestHarvest.n
        - nutritionalNeeds.n,
      p:
        ufAvailableSoil.p205
        + ufIrrigationWater.p205
        + ufRestHarvest.p205
        - nutritionalNeeds.p205,
      k:
        ufAvailableSoil.k20
        + ufIrrigationWater.k20
        + ufRestHarvest.k20
        - nutritionalNeeds.k20,
      c:
        ufAvailableSoil.ca0
        + ufIrrigationWater.ca0
        + ufRestHarvest.ca0
        - nutritionalNeeds.ca0,
      m:
        ufAvailableSoil.mg0
        + ufIrrigationWater.mg0
        + ufRestHarvest.mg0
        - nutritionalNeeds.mg0,
      s:
        ufAvailableSoil.s03
        + ufIrrigationWater.s03
        + ufRestHarvest.s03
        - nutritionalNeeds.s03,
    });

    this.elementResume.push({
      uf: 'UF teóricas',
      n: ufTeoric?.n || 0,
      p: ufTeoric?.p205 || 0,
      k: ufTeoric?.k20 || 0,
      c: ufTeoric?.ca0 || 0,
      m: ufTeoric?.mg0 || 0,
      s: ufTeoric?.s03 || 0,
    });

    this.elementResume.push({
      uf: 'UF eficientes aportadas',
      n: ufEfficiencyProvided?.n || 0,
      p: ufEfficiencyProvided?.p205 || 0,
      k: ufEfficiencyProvided?.k20 || 0,
      c: ufEfficiencyProvided?.ca0 || 0,
      m: ufEfficiencyProvided?.mg0 || 0,
      s: ufEfficiencyProvided?.s03 || 0,
    });

    this.elementResume.push({
      uf: this.$t('dosageProduct.finalBalance'),
      n: finalBalance?.n || 0,
      p: finalBalance?.p205 || 0,
      k: finalBalance?.k20 || 0,
      c: finalBalance?.ca0 || 0,
      m: finalBalance?.mg0 || 0,
      s: finalBalance?.s03 || 0,
    });

    this.elementResume.map((item) => {
      item.n = this.fixNumberTo(item.n, 0);
      item.p = this.fixNumberTo(item.p, 0);
      item.k = this.fixNumberTo(item.k, 0);
      item.c = this.fixNumberTo(item.c, 0);
      item.m = this.fixNumberTo(item.m, 0);
      item.s = this.fixNumberTo(item.s, 0);
      return item;
    });
  },
  methods: {
    fixNumberTo(number, digits) {
      if (!number) {
        return null;
      }
      return parseFloat(parseFloat(number).toFixed(digits));
    },
  },
};
</script>
<style src="./elementResume.scss" scoped lang="scss"></style>

<template>
  <f7-page>
    <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.name} > ${$t('Utilities.nutrition')}`" />
    <stepper />
    <plan-compost />
    <nutritional-distribution />
    <stepper-navigation />
  </f7-page>
</template>
<script>
import { mapState } from 'vuex';
import navbar from '@/components/NavBar.vue';
import Stepper from '@/components/wizardNutrition/stepper/index.vue';
import StepperNavigation from '@/components/wizardNutrition/stepper-navigation/index.vue';
import PlanCompost from '@/components/wizardNutrition/planCompost/index.vue';
import NutritionalDistribution from '@/components/wizardNutrition/nutritionalDistribution/index.vue';

export default {
  name: 'NutritionStepFour',
  components: {
    navbar,
    Stepper,
    StepperNavigation,
    NutritionalDistribution,
    PlanCompost,
  },
  props: [],
  data() {
    return {

    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {

  },
  methods: {

  },
};

</script>
<style src="./nutrition-step-four.scss" scoped lang="scss"></style>

<template>
  <div>
    <DxPopup
      :visible="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :width="900"
      height="auto"
      :show-close-button="true"
      :title="$t('Product_Detail_Title')"
      @hiding="close()"
    >
      <f7-block class="padding margin-half">
        <f7-row class="margin-bottom-half">
          <f7-col>{{ $t("DxProduct_nameCommercial") }}</f7-col>
          <f7-col>{{ $t("DxProduct_tipology") }}</f7-col>
          <f7-col>{{ $t("competence") }}</f7-col>
          <f7-col>{{ $t("DxProduct_ecology") }}</f7-col>
          <f7-col>{{ $t("Atc_Propietario") }}</f7-col>
          <f7-col>{{ $t("DxProduct_code") }}</f7-col>
        </f7-row>
        <f7-row class="bold">
          <f7-col>{{ nutritionalProductDetail[0].application.name }}</f7-col>
          <f7-col>{{ nutritionalProductDetail[0].application.tipology }}</f7-col>
          <f7-col>{{ competence }}</f7-col>
          <f7-col>{{ nutritionalProductDetail[0].application.ecologic }}</f7-col>
          <f7-col>{{ nutritionalProductDetail[0].application.owner }}</f7-col>
          <f7-col>{{ nutritionalProductDetail[0].application.code }}</f7-col>
        </f7-row>
      </f7-block>
      <f7-block class="padding margin-half">
        <f7-row>
          <f7-col width="50">
            <f7-row class="margin-bottom">
              {{ $t("Main_Elements") }}
            </f7-row>
            <DxDataGrid
              id="mainElements-grid"
              :data-source="[nutritionalProductDetail[0].application]"
              :column-auto-width="true"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
            >
              <DxColumn
                data-field="NT"
                :caption="'NT (% p/p)'"
                :allow-header-filtering="false"
                :allow-sorting="false"
                alignment="left"
              />
              <DxColumn
                data-field="P2015_Total"
                :caption="'P₂O₅ (% p/p)'"
                :allow-header-filtering="false"
                :allow-sorting="false"
                alignment="left"
              />
              <DxColumn
                data-field="K2O"
                :caption="'K₂O (% p/p)'"
                :allow-header-filtering="true"
                :allow-sorting="false"
                alignment="left"
              />
            </DxDataGrid>
          </f7-col>
          <f7-col width="50">
            <f7-row class="margin-bottom">
              {{ $t("Secondary_Elements") }}
            </f7-row>
            <DxDataGrid
              id="secondaryElements-grid"
              :data-source="[nutritionalProductDetail[0].application]"
              :column-auto-width="true"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
            >
              <DxColumn
                data-field="CaO"
                :caption="'CaO (% p/p)'"
                :allow-header-filtering="false"
                :allow-sorting="false"
                alignment="left"
              />
              <DxColumn
                data-field="MgO"
                :caption="'MgO (% p/p)'"
                :allow-header-filtering="false"
                :allow-sorting="false"
                alignment="left"
              />
              <DxColumn
                data-field="SO3"
                :caption="'SO₃ (% p/p)'"
                :allow-header-filtering="false"
                :allow-sorting="false"
                alignment="left"
              />
            </DxDataGrid>
          </f7-col>
        </f7-row>
      </f7-block>
      <f7-block class="margin">
        <f7-row>
          <f7-col class="justify-content-center">
            <f7-button
              class="dx-btn-cancel margin-right-block btn-width-150 margin-top-block margin-bottom-block standard-blue-btn"
              raised
              type="default"
              styling-mode="contained"
              @click="close()"
            >
              Volver
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </DxPopup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import { Device } from 'framework7/framework7-lite.esm.bundle';

export default {
  name: 'PopupProductDetail',
  components: {
    DxPopup,
    DxDataGrid,
    DxColumn,
  },
  computed: {
    ...mapState('Vademecum', ['nutritionalProductDetail']),
    competence() {
      return this.nutritionalProductDetail[0].application.tipology === 4 ? this.$t('Yes') : this.$t('No');
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style src="./popupProductDetail.styles.scss" scoped lang="scss"></style>

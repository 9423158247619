const dictionary = {
  en: {
    translation: {
      // Palabras reservadas INIT: NO TRADUCIR A OTROS LENGUAJES
      // hasta que se cambie la forma de generar los formularios
      // por enumerados y no se le envien los valores al back
      DIESEL: 'DIESEL',
      copy: 'Copy',
      GASOLINE: 'GASOLINE',
      TEMPORARY: 'TEMPORARY',
      PERMANENT: 'PERMANENT',
      CONTRATIST: 'CONTRATIST',
      HARVESTER: 'HARVESTER',
      TRACTOR: 'TRACTOR',
      'CAR & VEHICLE': 'CAR & VEHICLE',
      'SOIL GRADER': 'SOIL GRADER',
      EXCAVATOR: 'EXCAVATOR',
      FORKLIFT: 'FORKLIFT',
      TRUCK: 'TRUCK',
      BULLDOZER: 'BULLDOZER',
      SPRAYER: 'SPRAYER',
      Yes: 'Yes',
      No: 'No',
      date_invalid: 'Invalid date',
      clear: 'Clear',
      DxMachineryDatabase_column_active: 'Active',
      DxMachineryDatabase_column_inactive: 'No Active',
      activeSustance: 'Active',
      active: 'Active',
      noActive: 'No Active',
      DxEmployeeForm_operator: 'Operator',
      DxEmployeeForm_active: 'Active',
      DxEmployeeForm_noActive: 'No Active',
      // Palabras reservadas END: NO TRADUCIR A OTROS LENGUAJES
      Login_txtWelcome: 'Login',
      Log_Out: 'Log out',
      Login_inpUsername_placeholder: 'Username',
      Login_inpPassword_label: 'Password',
      Login_inpPassword_placeholder: 'Password',
      Login_chkRememberMe: 'Remember me',
      Login_txtRestorePass: 'Restore Password',
      Login_txtNeedHelp: 'Do you need some help?',
      Login_btnSignIn: 'SIGN IN',
      Login_btnSignUp_question: 'Not registered yet?',
      Login_btnSignUp_link: 'Sign up here',
      Login_msgEmail_title: 'Information',
      Login_msgEmail_content: 'You will shortly receive an email to change your password',
      Login_msgEmail_btnClose: 'Close',
      loggOut_Message: 'Are you sure you want to exit?',
      RecoveryPassword_txtDataAccess: 'Enter your email',
      RecoveryPassword_inpEmail_name: 'Email',
      RecoveryPassword_inpEmail_placeholder: 'Email',
      RecoveryPassword_btnSendEmail: 'Reset password',
      RecoveryPassword_btnGoBack: 'Go back',
      ChangePassword_txtDataAccess: 'Enter your new password',
      ChangePassword_inpEmail_placeholder: 'Password',
      ChangePassword_inpRepeatEmail_placeholder: 'Repeat password',
      ChangePassword_btnChangePassword: 'Change password',
      Register_registerText: 'REGISTER',
      Register_btnEnter: 'ENTER',
      Register_inpName_name: 'Name',
      Register_inpName_placeholder: 'Name',
      Register_inpSurnames_name: 'Surnames',
      Register_inpSurnames_placeholder: 'Surnames',
      Register_inpEmail_name: 'Email',
      Register_inpEmail_placeholder: 'Email',
      Register_inpPassword_name: 'Password',
      Register_inpPassword_placeholder: 'Password',
      Register_inpPasswordVerification_name: 'RepeatPassword',
      Register_inpPasswordVerification_placeholder: 'Repeat password',
      Register_inpUsername_name: 'Username',
      Register_inpUsername_placeholder: 'Username',
      Profile_info_profile: 'Profile info',
      Profile_name_placeholder: 'Name',
      Profile_surname_label: '',
      Profile_surname_placeholder: '',
      Profile_email_label: '',
      Profile_email_placeholder: '',
      Profile_mobile_label: '',
      Profile_mobile_placeholder: '',
      Profile_password_profile: '',
      Profile_oldPass_label: '',
      Profile_oldPass_placeholder: '',
      Profile_newPass_label: '',
      Profile_newPass_placeholder: '',
      Profile_checkNewPass_label: '',
      Profile_checkNewPass_placeholder: '',
      Profile_change_password: '',
      Profile_photo_profile: '',
      Profile_update_photo: '',
      Profile_setup_profile: '',
      Profile_language_label: '',
      Profile_theme_label: '',
      Profile_save_changes: '',
      Home_info_txtTitle: 'No workers',
      ExportWorkerList_btnExport: '',
      ExportExcel_thNombre: '',
      ExportExcel_thTipo: '',
      ExportExcel_thFecha: '',
      ExportExcel_captionListaEmpleados: '',
      SheetExport_sheet_export_ok: 'Downloaded file: ',
      SheetExport_sheet_export_error: 'Error into download',
      Start_order: 'Select Workers',
      TasksList_navbar_text: 'Work Orders',
      TasksList_lstNewTasks_title: 'Init new task',
      TasksList_lstInProgressTasks_title: 'In progress tasks',
      TasksList_lstCompletedTasks_title: 'Completed tasks',
      TasksList_Workers_title: 'Workers',
      TasksList_WorkersSelection_title: 'No workers in task',
      TasksList_WorkersSelection_subtitle: 'Click the top button to link workers',
      Home_conductor: 'Tractor driver',
      ManualList_listItem_title: 'No worker found',
      checkOut_Productivity_placeholder: 'Field Number',
      checkOut_Productivity: 'Productivity',
      checkOut_Task: 'Task',
      list_content_farm_img_alt: 'Change farm',
      farmSelection_navbar_text: 'Select Farm',
      locationSelect_navbar_text: 'Select Location',
      DxDailyPlanning_text: 'Create new OT',
      DxMonthlyPlanning_text: 'Create new monthly planning',
      DxAddEmployee_text: 'Add new Worker',
      DxTaskOrders_text: 'Register new work order',
      DetailTabs_machinary_title: 'Machinery',
      DetailTabs_attachment_title: 'Attachments',
      DetailTabs_tools_title: 'Tools',
      DetailTabs_products_title: 'Products',
      DetailTabs_workers_title: 'Workers',
      DetailTabs_locations_title: 'Locations',
      DxDailyPlanning_date_caption: 'Date',
      DxListEmployee_name_caption: 'Name',
      DxListEmployee_surname_caption: 'Surname',
      DxListEmployee_code_caption: 'Id Number',
      DxListEmployee_status_caption: 'Status',
      DxListEmployee_type_caption: 'Type',
      DxListEmployee_nfc_caption: 'Badge id',
      DxListEmployee_driver_caption: 'Driver',
      DxListEmployee_info_caption: 'Info',
      DxListEmployee_farm_caption: 'Farm',
      DxDailyPlanning_task_caption: 'Task',
      DxDailyPlanning_activity_caption: 'Activity',
      DxDailyPlanning_ha_caption: 'Ha',
      DxDailyPlanning_workers_caption: 'Workers',
      DxDailyPlanning_location_caption: 'Location',
      DxDailyPlanning_workingArea_caption: 'Working area (HA)',
      DxMonthlyPlanning_goal_caption: 'Goal',
      DxSelectSupervisor: 'At least one supervisor is needed',
      DxDailyPlanning_workingarea_caption: 'Working Area (HA)',
      DxDailyPlanning_dialogDelete_title: 'Delete planning',
      DxDailyPlanning_dialogDelete_text: 'Are you sure to delete the planning?',
      DxDailyPlanning_dialogDelete_confirm: 'Confirm',
      DxDailyPlanning_dialogDelete_cancel: 'Cancel',
      DxDailyWorkOrders_dialogDelete_title: 'Delete work order',
      DxDailyWorkOrders_dialogDelete_text: 'Are you sure to delete the work order?',
      DxDailyWorkOrders_dialogDelete_confirm: 'Confirm',
      DxDailyWorkOrders_dialogDelete_cancel: 'Cancel',
      DxDailyWorkOrders_dialogDelete_noOpen: 'Cannot delete a work order already started.',
      DxDailyWorkOrders_dialogUpdate_noOpen: 'Cannot edit a work order already started.',
      PlanningComponent_caption_text: 'Activity & Task',
      EmployeeComponent_caption_text: 'Data of Worker',
      PlanningComponent_activityName_caption: 'Activity Name',
      PlanningComponent_activitycode_caption: 'Activity Code',
      PlanningComponent_workingarea_caption: 'Working Area',
      EmployeeComponent_name_caption: 'Name',
      EmployeeComponent_status_caption: 'Status',
      EmployeeComponent_surname_caption: 'Surname',
      EmployeeComponent_identificationNumber_caption: 'Id number',
      EmployeeComponent_role_caption: 'Role',
      EmployeeComponent_type_caption: 'Type',
      EmployeeComponent_drive_caption: 'Driver',
      EmployeeComponent_group_caption: 'Group',
      EmployeeComponent_shift_caption: 'Shift',
      EmployeeComponent_email_caption: 'Email',
      EmployeeComponent_password_caption: 'New Password',
      EmployeeComponent_identification_title: 'Identification of Worker',
      EmployeeComponent_typeIdentification_caption: 'Type',
      EmployeeComponent_code_caption: 'Code',
      DxEmployeeForm_surname_require: 'Surname is required',
      DxEmployeeForm_ID_require: 'identification number is required',
      DxEmployeeForm_name_require: 'Name is required',
      DxEmployeeForm_status_require: 'Status is required',
      DxEmployeeForm_role_require: 'Role is required',
      DxEmployeeForm_type_require: 'Type is required',
      DxEmployeeForm_email_require: 'Email is required',
      DxEmployeeForm_password_require: 'Password is required',
      PlanningComponent_taskName_caption: 'Task Name',
      PlanningComponent_taskCode_caption: 'Task Code',
      DxPlannReal_plannWorkers_caption: 'Planned workers',
      DxPlannReal_workers_caption: 'Workers in',
      DxWorkOrders_PlannMachinary_caption: 'Planned machinery',
      DxWorkOrders_WorkedMachinary_caption: 'Working machinery',
      PlanningComponent_date_caption: 'Date',
      PlanningComponent_numberWorkers_caption: 'Number of Workers',
      PlanningComponent_family_caption: 'Family',
      PlanningComponent_addMachineButton_caption: 'Add Machinery',
      PlanningComponent_shift_caption: 'Shift',
      PlanningComponent_groupName_caption: 'Group Name',
      CreatePlanning_ButtonSave_caption: 'Save',
      PlanningComponent_dailyPlanning: 'DAILY PLAN',
      PlanningComponent_edit_dailyPlanning: 'Edit daily plan',
      StoreProduct_Buttoncancel_caption: 'Cancel',
      StoreProduct_Buttonsave_caption: 'Save',
      CreatePlanning_Title: 'CREATE DAILY PLAN',
      CreateMonthlyPlanning_Title: 'CREATE MONTHLY PLAN',
      EditMonthlyPlanning_Title: 'EDIT MONTHLY PLAN',
      CreatePlanning_ButtonCancel_caption: 'Cancel',
      CreatePlanning_ValidationDate: 'Date is required.',
      CreatePlanning_ValidationTaskName: 'taskName is required.',
      CreatePlanning_ValidationActivityName: 'ActivityName is required.',
      CreatePlanning_ValidationNumberOfWorker: 'The number of workers cannot be zero.',
      CreatePlanning_ValidationNumberOfDriver: 'The number of drivers cannot be zero.',
      CreatePlanning_ValidationActualLocationsSelected: 'Location is necessary',
      CreateEmployee_ValidationIdentificationNumber: 'Identificacion number is required',
      CreateOrder_ValidationSupervisorsLocation: 'There are locations without an assigned supervisor.',
      CreateOrder_ValidationItemsWorkOrder: 'There are unassigned items.',
      DxMonthlyMachinary_text: 'Add new machinery',
      CreateOrder_ValidationLocationExists: 'Task locations exists with this date.',
      CreateEmployee_ValidationName: 'Name is required.',
      CreateEmployee_ValidationSurname: 'Surname is required.',
      CreateEmployee_ValidationEmail: 'Email is required.',
      CreateEmployee_ValidationPassword: 'Password is required.',
      CreateEmployee_Title: 'CREATE WORKER',
      EditEmployee_Title: 'EDIT WORKER',
      DxDailyPlanning_edit_text: 'Edit',
      DxDailyPlanning_edit_hint: 'Edit',
      DxDailyPlanning_new_order_text: 'New order',
      DxDailyPlanning_new_order_hint: 'New order',
      DxDailyPlanning_task_status_caption: 'Task Status',
      DxDailyPlanning_work_order_status_caption: 'Work Order Status',
      DxDailyPlanning_supervisors_caption: 'Supervisors',
      MonthlyComponent_type_caption: 'Type',
      MonthlyComponent_performance_caption: 'Performance',
      MonthlyComponent_performanceunit_caption: 'Performance Unit',
      MonthlyComponent_consumption_caption: 'Consumption',
      MonthlyComponent_consumptionunit_caption: 'Consumption Unit',
      MonthlyComponent_quantity_caption: 'Quantity',
      MonthlyComponent_typeconsumption_caption: 'Consumption Type',
      MonthlyComponent_notes_caption: 'Notes',
      DetailTabs_supervisors_title: 'Supervisors',
      DetailTabAttachemnt_not_selected: 'No attachment selected',
      DetailTabProduct_no_seleccionado: 'No product selected',
      SelectSupervisor_supervisor_caption: 'Supervisor',
      SelectSupervisor_location_caption: 'Location',
      WorkOrder_title: 'WORK ORDER STATUS',
      FuelRegistration_title: 'FUEL REGISTRATION',
      Employee_title: 'LIST WORKERS',
      WorkOrderDetail_select_supervisor: 'Select supervisor',
      WorkOrderDetail_select_maquinaria: 'Select machinery',
      WorkOrderDetail_order: 'ORDER',
      DxWorkOrders_edit_text: 'Edit',
      DxWorkOrders_edit_hint: 'Edit',
      DxWorkOrders_modal_title: 'Edit Work Order',
      DxWorkOrders_machinery_caption: 'Machinery',
      DxWorkOrders_driver_caption: 'Driver',
      DxWorkOrders_km_start_caption: 'Km start',
      DxWorkOrders_km_finish_caption: 'Km end',
      DxWorkOrders_horometer_start_caption: 'Hourmeter start',
      DxWorkOrders_horometer_finish_caption: 'Hourmeter end',
      DxWorkOrders_fuel_consumption_caption: 'Fuel consumption (L)',
      DxWorkOrders_fuel_type_caption: 'Fuel type',
      DxWorkOrders_working_area_caption: 'Working area',
      DxWorkOrders_aditional_info_caption: 'Additional info',
      DxProductPerformance_unit: 'Unit',
      DxProductPerformance_products_name: 'Product',
      DxProductPerformance_products_qtyCollected: 'Quantity collected',
      DxProductPerformance_products_qtyPlanned: 'Quantity planned',
      DxProductPerformance_products_qtyTask: 'Quantity returned to warehouse',
      DxProductPerformance_products_qtyUsed: 'Quantity used',
      DxProductPerformance_products_qtyReturnWarehouse: 'Quantity returned to warehouse',
      DxProductPerformance_products_totalRealPrescription: 'Solution Used (L)',
      DxProductPerformance_products_realSolution: 'Real solution',
      DxProductPerformance_products_realDilutionFactor: 'Real dilution factor',
      DxProductPerformance_products_appliedDose: 'Applied dose',
      DxProductPerformance_products_theoricalDose: 'Theorical Dose',
      EditOrder_ButtonSave_caption: 'Save',
      EditOrder_ButtonCancel_caption: 'Cancel',
      CreateOrder_ButtonSave_caption: 'Save',
      CreateOrder_ButtonCancel_caption: 'Cancel',
      CreateOrder_ValidationOutDate: 'You cannot create a work order with an out of date schedule',
      DxSelectedItems_ya_esta_seleccionado_alert: 'Is already selected',
      DxDailyPlanning_delete_text: 'Delete',
      DxDailyPlanning_delete_hint: 'Delete',
      DxWorkOrder_Button_View_text: 'View',
      DxWorkOrder_Button_View_hint: 'View',
      DetailTabWorkers_sin_trabajadores: 'No workers selected',
      DetailTabAttachemnt_no_selected: 'No Attachment selected',
      DetailTabProduct_not_selected: 'No product selected',
      WorkOrderDetail_select_date: 'Select date',
      DxSelectDate_date_caption: 'Date',
      PlanningComponent_typeUnit_caption: 'Type unit',
      DxDailyPlanning_performanceha_caption: 'Goal',
      DxDailyPlanning_totalArea: 'Total area (ha)',
      PlanningComponent_performanceUnit_caption: 'Productivity Unit',
      PlanningComponent_performanceCount_caption: 'Productivity Goal',
      PlanningComponent_distributed_caption: 'Distributed',
      EmployeeComponent_expirationDate_caption: 'Expiration Date',
      EmployeeComponent_datepicker_expirationDate_placeholder: 'Select Date',
      EmployeeComponent_applicator_caption: 'Applicator',
      Status_Open: 'PENDING',
      Status_InProgress: 'IN PROGRESS',
      Status_Completed: 'COMPLETED',
      Status_Closed: 'Closed',
      Status_notReally_Closed: 'Not Closed',
      Status_reallyNot_Closed: 'Not Closed',
      Status_Pending: 'Pending',
      Status_Deleted: 'Deleted',
      DetailTabWorkers_sin_items: 'No items selected',
      DetailTab_sin_seleccion: 'Nothing selected',
      WorkOrderDetail_additional_info: 'Additional information',
      WorkOrderDetail_select_driver: 'Select driver',
      CreateOrder_ValidationItemsDriversWorkOrder: 'There are unassigned drivers.',
      Login_inpUsername_validate: 'Complete this field',
      Login_inpPassword_validate: 'Complete this field',
      DxEffort_level_caption: 'Level',
      DxEffort_activity_caption: 'Activity',
      DxEffort_task_caption: 'Task',
      DxEffort_hrHours_caption: 'HR Hours',
      DxEffort_totalHours_caption: 'Total Hours',
      DxEffort_totalHoursHa_caption: 'Total hours/Ha',
      DxEffort_totalHoursTotalHa_caption: '% of total hours vs total all Ha',
      DxEffort_progressCompleted_caption: 'Progress (% Complete)',
      EffortAgriFarmCockpit_navbar_title: 'REPORTING EFFORT AGRI FARM COCKPIT',
      WeatherFarmCockpit_navbar_title: 'CLIMATE GRAPHS',
      AgriFarmCockpit_navbar_title: 'REPORTING AGRI FARM COCKPIT',
      EffortTask_navbar_title: 'REPORTING EFFORT TASK',
      DxDailyPlanning_days_caption: 'Days',
      DxDailyPlanning_p_caption: 'Permanent',
      DxDailyPlanning_t_caption: 'Temporary',
      DxDailyPlanning_c: 'Contratist',
      DxDailyPlanning_coste: 'Cost',
      LeftPanel_title_reporting: 'REPORTING',
      LeftPanel_title_Analysis: 'Analysis',
      LeftPanel_title_Utilities: 'Utilities',
      LeftPanel_title_prescriptions: 'PRESCRIPTIONS',
      LeftPanel_title_weather: 'WEATHER',
      LeftPanel_title_warehouse: 'WAREHOUSE',
      LeftPanel_subtitle_fuelRegistration: 'Fuel Registration',
      LeftPanel_title_fertilizers: 'FERTILIZERS',
      LeftPanel_title_information: 'INFORMATION',
      LeftPanel_title_dataConfiguration: 'Configuration',
      LeftPanel_title_referencePlans: 'Reference plans',
      LeftPanel_subtitle_home: 'Search engine',
      LeftPanel_subtitle_AgriFarmCockpit: 'Agri Farm Cockpit',
      LeftPanel_subtitle_effortAgriFarm: 'Effort Agri Farm Cockpit',
      LeftPanel_subtitle_climate: 'climate',
      LeftPanel_subtitle_workOrders: 'Work Order Status',
      LeftPanel_subtitle_unplannedWorkOrders: 'Manual register  WO',
      LeftPanel_subtitle_activeIngredients: 'Active Ingredients authorized EU',
      LeftPanel_subtitle_weatherFarmCockpit: 'Climate Graphs',
      LeftPanel_subtitle_logOut: 'Sign out',
      LeftPanel_subtitle_phytosanitariesLocalDatabase: 'Phytosanitaries Local Database',
      LeftPanel_subtitle_Tareas: 'Task',
      LeftPanel_subtitle_productsLocalDatabase: 'Products Local Database',
      Search_placeholder: 'Search',
      WeatherForecast_title: 'Weather Forecast',
      WeatherStatus_title: 'Weather Status',
      GisMap_title: 'Map',
      PlanningComponent_machinary_caption: 'Machinery',
      DxProducts_dxColumn_Unit: 'Unit',
      DxAttachment_dxColumn_TypeAttachment: 'Type',
      DxAttachment_dxColumn_QuantityAttachment: 'Quantity',
      DxMachinery_dxColumn_TypeMachinery: 'Type',
      DxMachinery_dxColumn_QuantityMachinery: 'Quantity',
      DxProducts_dxColumn_activeIngredients: 'Active ingredients',
      DxProducts_dxColumn_productName: 'Product name',
      DxProducts_dxColumn_dosis: 'Dose',
      DxProducts_dxColumn_dosisUt: 'Dose Ut',
      DxProducts_dxColumn_dosisTotal: 'Quantity Planned',
      DxProducts_dxColumn_solution: 'Solution',
      DxProducts_dxColumn_solutionUt: 'Solution UoM',
      DxProducts_dxColumn_dilutionFactor: 'Dilution Factor',
      DxProducts_dxColumn_unit: 'Unit',
      DxProducts_dxColumn_dilutionFactorUt: 'Unit',
      DxProducts_dxColumn_solutionTotal: 'Water planned (L)',
      DxProducts_dxColumn_caution: 'Caution',
      DxProducts_dxColumn_restrictedEntryInterval: 'Entry Interval',
      DxProducts_dxColumn_preharvestInterval: 'PreHarvest Interval',
      ActiveIngredients_navbar_title: 'ACTIVE INGREDIENTS AUTHORIZED EU',
      ORCHARD: 'Orchard',
      EditProduct_navbar_create: 'EDIT PRODUCT',
      EditProduct_navbar_edit: 'CREATE NEW PRODUCT',
      DxFormProduct_code_caption: 'SAP ID',
      DxFormProduct_code_required: 'Code is required',
      DxFormProduct_status_caption: 'STATUS',
      DxFormProduct_comment_caption: 'COMMENT',
      DxFormProduct_description_caption: 'DESCRIPTION',
      DxFormProduct_component_caption: 'ACTIVE INGREDIENT',
      DxFormProduct_family_caption: 'GROUP NAME SAP',
      DxFormProduct_subfamily_caption: 'SUBGROUP',
      DxFormProduct_sapunit_caption: 'U.M SAP',
      DxFormProduct_toxicity_caption: 'TOXICITY',
      DxFormProduct_description_required: 'Description is required.',
      totalArea_field: 'Total area (ha)',
      totalArea_title: 'Summary of selected Plots',
      DxAttachment_Title: 'Attachments',
      DxMachineryPerformance_Title: 'Machinery Performance',
      DxProductsPerformance_Title: 'Products Performance',
      DxMachinery_Title: 'Machinery',
      DxTools_Title: 'Tools',
      DxProducts_Title: 'Products',
      DxTools_dxColumn_TypeTool: 'Type',
      DxTool_dxColumn_QuantityTool: 'Quantity',
      CreatePrescription_ValidationName: 'Name is required',
      CreatePrescription_ValidationNameDuplicated: 'Name already in use',
      CreatePrescription_ValidationProducts: 'You need to add at least one product',
      CreatePrescription_ValidationMethod: 'Method of prescription is required',
      CreatePrescription_ValidationType: 'Type of prescription is required',
      CreatePrescription_ValidationDate: 'A valid date is required',
      CreatePrescription_ValidationActive: 'A new prescription must be active',
      DxPlanningProducts_prescription: 'Name Prescription',
      DxPlanningProducts_prescription_required: 'Prescription is required',
      DxPlanningProducts_typePrescription: 'Type Prescription',
      DxPlanningProducts_typePrescription_required: 'Type Prescription is required',
      DxPlanningProducts_typeOfPrescription: 'Type of presc.',
      DxPlanningProducts_typeOfPrescription_required: 'Type of presc. is required',
      // DxActiveIngredientsVademecum i18next desde vuex.
      active_substance_id: 'Active substance id',
      approval_date: 'Approval date',
      approval_expiration: 'Approval expiration',
      authorisation_in_progress: 'Authorization in progress',
      authorised: 'Authorized',
      category: 'Category',
      organic_approved: 'Organic approved',
      remark: 'Remark',
      status: 'Status',
      substitution_candidate: 'Substitution candidate',
      countries: 'Countries',
      ProductsDatabase_title: 'PRODUCTS DATABASE',
      DxAttachmentMachinary_dxColumn_Machinary: 'Machinery',
      DxAttachmentMachinary_dxColumn_Attachment: 'Attachment',
      DxWeatherElementLinesGraph_average: 'Average',
      DxWeatherElementLinesGraph_averageForecasted: 'Average Forecasted',
      DxWeatherElementLinesGraph_minForecasted: 'Min Forecasted',
      DxWeatherElementLinesGraph_maxForecasted: 'Max Forecasted',
      DxWeatherElementLinesGraph_totalForecasted: 'Total Forecasted',
      DxWeatherElementLinesGraph_total: 'Total',
      DxProduts_rule_range: 'Range should be up 0',
      DxProduts_rule_solution_required: 'Solution is required',
      DxProduts_rule_dosis_required: 'Dose is required',
      DxProdutsDataBase_button_text: 'Create new product',
      DxProdutsDataBase_edit_text: 'Edit',
      DxProdutsDataBase_edit_hint: 'Edit',
      DxEmployeeTable_Title: 'Workers',
      DxTaskOrders_navbar_Name: 'REGISTER WORK ORDER',
      PlanningComponent_datepicker_multiple_placeholder: 'Select dates',
      DxProducts_dxColumn_productGroup: 'Type',
      DxProducts_dxColumn_productFamily: 'Group',
      DxPlanningForm_activity_name: 'Activity name is required',
      DxPlanningForm_Task_name: 'Task name is required',
      LeftPanel_title_machinery: 'MACHINERY',
      LeftPanel_subtitle_machineryDataBase: 'Machinery Database',
      Machinery_title: 'MACHINERY',
      CreateMachinery_Title: 'CREATE MACHINERY',
      EditMachinery_Title: 'EDIT MACHINERY',
      DxMachineryDatabase_column_status: 'Status',
      DxMachineryDatabase_column_family: 'Family',
      DxMachineryDatabase_column_subfamily: 'Subfamily',
      DxMachineryDatabase_column_description: 'Item Description SAP',
      DxMachineryDatabase_column_brand: 'Brand',
      DxMachineryDatabase_column_code: 'Internal Code',
      DxMachineryDatabase_column_attachment: 'Attachment',
      DxMachineryDatabase_button_text: 'Create new machinery',
      DxMachineryForm_code_caption: 'SAP Code',
      DxMachineryForm_code_required: 'Code is required',
      DxMachineryForm_description_caption: 'Description',
      DxMachineryForm_description_required: 'Description is required',
      DxMachineryForm_brand_caption: 'Brand',
      DxMachineryForm_brand_required: 'Brand is required',
      DxMachineryForm_model_caption: 'Model',
      DxMachineryForm_model_required: 'Model is required',
      DxMachineryForm_serial_number_caption: 'Serial Number',
      DxMachineryForm_serial_number_required: 'Serial Number required',
      DxMachineryForm_plate_caption: 'Plate',
      DxMachineryForm_plate_required: 'Plate is required',
      DxMachineryForm_internal_code_caption: 'Internal Code',
      DxMachineryForm_internal_code_required: 'Internal Code is required',
      DxMachineryForm_family_caption: 'Family',
      DxMachineryForm_family_required: 'Family is required',
      DxMachineryForm_subfamily_caption: 'Subfamily',
      DxMachineryForm_subfamily_required: 'Subfamily is required',
      DxMachineryForm_attachment_caption: 'Attachment',
      DxMachineryForm_purchase_caption: 'Purchase',
      DxMachineryForm_purchase_required: 'Purchase The field',
      DxMachineryForm_status_caption: 'Status',
      Machinery_ValidationSAPCode: 'The field SAP Code is required',
      Machinery_ValidationInternalCode: 'The field Internal Code is required',
      Machinery_ValidationBrand: 'The field Brand is required',
      Machinery_ValidationModel: 'The field Model is required',
      Machinery_ValidationSerialNumber: 'The field Serial Number is required',
      Machinery_ValidationPlate: 'The field Plate is required',
      Machinery_ValidationDescription: 'The field Description is required',
      Machinery_ValidationFamily: 'The field Family is required',
      Machinery_ValidationSubfamily: 'The field Subfamily is required',
      Machinery_ValidationPurchase: 'The field Purchase is required',
      StoreProduct_ValidationComponent: 'Component is required',
      StoreProduct_ValidationCode: 'SAP ID is required',
      StoreProduct_ValidationFamily: 'Group Name SAP is required',
      StoreProduct_ValidationSubfamily: 'Subgroup is required',
      StoreProduct_ValidationSapunit: 'SAP unit is required',
      StoreProduct_ValidationDescription: 'Description is required',
      StoreProduct_ValidationCodeDuplicated: 'Code is duplicated',
      // Generics
      Button_cancel_text: 'Cancel',
      Button_save_text: 'Save',
      Button_preview_text: 'Preview ',
      LeftPanel_subtitle_productsDataBase: 'Products Database',
      DxProductsDataBase_status_caption: 'Status',
      DxProductsDataBase_description_caption: 'Item Description SAP',
      DxProductsDataBase_code_caption: 'SAP code',
      DxProductsDataBase_family_caption: 'Group Name SAP',
      DxProductsDataBase_subfamily_caption: 'Subgroup',
      DxProductsDataBase_component_caption: 'Active Ingredient',
      ReportingTimeWorked_navbar_title: 'CLOCK IN / OUT',
      DxWorker_date_caption: 'Date',
      DxWorker_task_caption: 'Task',
      DxWorker_typeTask_caption: 'Type',
      DxWorker_codeTask_caption: 'Task Code',
      DxWorker_workerName_caption: 'Worker',
      DxWorker_name_caption: 'Name',
      DxWorker_workerSurname_caption: 'Surname',
      DxWorker_idNumber_caption: 'Id Number',
      DxWorker_typeWorker_caption: 'Type',
      DxWorker_territorialUnit_caption: 'Territorial Unit',
      DxWorker_totalHours_caption: 'Nº Hours',
      DxWorker_workingDay_caption: 'Working Day',
      DxWorker_performanceUnit_caption: 'Prformance Unit',
      DxWorker_performanceCount_caption: 'Performance',
      DxWorker_note_caption: 'Note',
      CreateMonthlyPlanning_ValidationDate:
        'The number of working days must correspond to the number of dates selected',
      CreateMonthlyPlanning_ValidationTotalWorkingDays: 'You must plan at least one work day',
      DxMonthlyForm_activityTask: 'Activity Task',
      DxMonthlyForm_goalUnit: 'Goal Unit',
      DxMonthlyForm_DateProgrammed: 'Date Programmed',
      DxMonthlyForm_numberWorkers_caption: 'Number of Workers',
      DxMonthlyForm_performanceWorker_caption: 'PERFORMANCE/WORKER',
      DxMonthlyForm_workersDaysRequired: 'WORKERS DAYS REQUIRED',
      DxMonthlyForm_machineryDaysRequired: 'MACHINERY DAYS REQUIRED',
      DxMonthlyForm_toolsConsumptionTotal: 'TOOLS CONSUMPTION TOTAL (L)',
      DxMonthlyForm_estimatedCostActivity: 'ESTIMATED COST ACTIVITY',
      DxMonthlyForm_taskDuration: 'TASK DURATION',
      DxToolsMonthlyTable_type_caption: 'Type',
      DxToolsMonthlyTable_consumption_caption: 'Consumption',
      DxToolsMonthlyTable_consumption_type_caption: 'Consumption Type',
      DxToolsMonthlyTable_consumption_unit_caption: 'Consumption Unit',
      DxMachineryMonthlyTableHADAY: 'HA/DAY',
      DxMachineryMonthlyTableUNDIA: 'UN/DAY',
      Home_navbar_title: 'HOME',
      // CRUD Costes.
      Costs_title: 'COSTS',
      DxCostsDatabase_column_name: 'Name',
      DxCostsDatabase_edit_text: 'Edit',
      DxCostsDatabase_edit_hint: 'Edit',
      LeftPanel_subtitle_costsDataBase: 'Costs Database',
      DxCostsDatabase_column_code: 'Code',
      DxCostsDatabase_column_id: 'id',
      DxCostsDatabase_column_price: 'Price',
      DxCostsDatabase_column_currency: 'Currency',
      DxCostsDatabase_column_currency_code: 'Currency Code',
      DxCostsDatabase_column_unit: 'Unit',
      DxCost_button_text: 'Create new Costs',
      DxCost_title_creation: 'CREATE COSTS',
      CreacteCost_ValidationCode: 'The code field is required',
      CreacteCost_ValidationName: 'The name field is required',
      CreacteCost_ValidationPrecio: 'The Price field is required',
      CreacteCost_ValidationUnit: 'The unit field is required',
      CreacteCost_ValidationCurrency: 'The currency field is required',
      CreacteCost_ValidationCurrencyCode: 'The currency code field is required',
      CreacteCost_ValidationId: 'The ID field is required',
      CreateCost_dialogDelete_title: 'Delete Cost',
      DeleteCost_dialogDelete_text: 'Are you sure to delete the Selected Cost?',
      LeftPanel_subtitle_reportingMachinery: 'Machinery',
      LeftPanel_subtitle_timeWorked: 'Clock in / out',
      DxListEmployee_role_caption: 'Role',
      DxMachineryDatabase_company: 'Farm',
      // Summary Machinery
      DxMachinery_Date_caption: 'Date',
      DxMachinery_Location_caption: 'Territorial Units',
      DxMachinery_Attachment_caption: 'Attachment',
      DxMachinery_unit_caption: 'unit',
      DxMachinery_init_caption: 'km start',
      DxMachinery_end_caption: 'km end',
      DxMachinery_total_caption: 'km made',
      DxMachinery_Name_caption: 'Machine',
      DxMachinery_workingArea_caption: 'Working area',
      DxMachinery_SolutionTotal_caption: 'Solution',
      DxMachinery_Variety_caption: 'Variety',
      DxMachinery_Worker_caption: 'Driver',
      DxMachinery_Task_caption: 'Task',
      DxMachinery_typeFuel_caption: 'Fuel Type',
      DxMachinery_quantityFuel_caption: 'fuel quantity',
      DxMachinery_quantity_caption: 'Quantity',
      DxMachinery_CodeTask_caption: 'Task Code',
      DxMachinery_InternalCode_caption: 'Internal Code',
      DxMachinery_TypeTask_caption: 'Type',
      DxMachinery_performance_caption: 'Performance',
      DxMachinery_Formulas_caption: 'Formulation',
      DxMachinery_comment_caption: 'Notes',
      DxWorker_ActivityCode_caption: 'Activity Code',
      DxWorker_group_caption: 'Group',
      DxWorker_shift_caption: 'Shift',
      DxWorker_Activity_caption: 'Activity',
      DxWorker_location_caption: 'Location',
      DxWorker_initDate_caption: 'Clock in',
      DxWorker_endDate_caption: 'Clock out',
      DxWorker_checkIn_caption: 'Check in',
      DxWorker_checkOut_caption: 'Check out',
      DxWorker_jornada_caption: 'workday',
      ReportingMachinery_navbar_title: 'REPORTING MACHINERY',

      DxMachinery_Activity_caption: 'Activity',
      DxMachinery_ActivityCode_caption: 'Activity Code',
      DxMonthlyPlanning_totalCost: 'Total Cost',
      CreatePlanning_ValidationTotalWorkingDays:
        'The number of working days for machinery exceeds the number of total working days. Please adjust the performance of the machinery.',
      CreateOrder_ValidationAttachments: 'There are unassigned machinery in attachments',
      LeftPanel_subtitle_logs: 'Harvest Logs',
      ReportingLogs_navbar_title: 'LOGS',
      DxErrorLogs_Date_caption: 'Date',
      DxErrorLogs_Time_caption: 'Time',
      DxErrorLogs_HDSID_caption: 'HDS Id',
      DxErrorLogs_Description_caption: 'Description',
      DxSelectSupervisorPerformance_SelectSupervisor_required: 'Supervisor required',
      CalendarInitEndDate_btnClear: 'Clear',
      CalendarInitEndDate_btnSearch: 'Search',
      PlanningComponent_seasional_caption: 'Number',
      DxProductPerformance_machinery_total_real_consumption: 'Total area prescription',
      DxWorkOrders_notes_caption: 'Notes',
      DxWorkOrders_variety_caption: 'Variety',
      ReportingEmployeeEffectiveness_navbar_title: 'WORKER EFFECTIVENESS',
      ReportingEmployeeEffectiveness_date: 'Date',
      ReportingEmployeeEffectiveness_task: 'Task',
      ReportingEmployeeEffectiveness_taskCode: 'Task Code',
      ReportingEmployeeEffectiveness_Activity: 'Activity',
      ReportingEmployeeEffectiveness_activityCode: 'Activity Code',
      ReportingEmployeeEffectiveness_performance: 'Performance',
      ReportingEmployeeEffectiveness_timeSpent: 'Time Spent',
      ReportingEmployeeEffectiveness_fullname: 'Full Name',
      ReportingEmployeeEffectiveness_type: 'Type',
      ReportingEmployeeEffectiveness_IdTask: 'Id Task',
      ReportingEmployeeEffectiveness_SumaParcial: 'Parcial Sum',
      HOURS: 'Hours',
      ReportingEmployeeEffectiveness_ratioTimeSpent: '% Time Spent',
      ReportingEmployeeEffectiveness_ratioTotalPerformance: '% Total Performance',
      ReportingEmployeeEffectiveness_ratioPerformanceGoal: '% Goal Performance',
      ReportingWorkOrders_navbar_title: 'REPORTING WORK ORDERS',
      LeftPanel_subtitle_reportingWorkOrders: 'Work Orders',
      DxListTaskGroupedActivivty_taskCode: 'Task Code',
      DxListTaskGroupedActivivty_performanceUnit: 'Performance Unit',
      DxListTaskGroupedActivivty_task: 'Tasks',
      DxListTaskGroupedActivivty_Activity: 'Activity',
      DxListTaskGroupedActivivty_NavBarName: 'ACTIVITY AND TASKS',
      LeftPanel_subtitle_ListTaskGroupedActivivty: 'Activity and Tasks',
      PlanningComponent_variety_caption: 'Variety',
      DxPlanningForm_variety_required: 'Variety required',
      DxDailyPlanning_realGoal_caption: 'Real goal',
      DxDailyPlanning_goalVsReal_caption: '% Completed Goal',
      DxDailyPlanning_realCost: 'Real cost',
      DxDailyPlanning_CostVsReal: '% Completed Cost',
      planned_title: 'PLANNED VS REAL',
      ReportingplannedVsReal_navbar_title: 'PLANNED VS REAL',
      Variety_title: 'VARIETY',
      LeftPanel_subtitle_varietyDatabase: 'Variety Database',
      DxVarietyDatabase_column_code: 'Code',
      DxVarietyDatabase_column_name: 'Name',
      DxMachineryDatabase_sapid: 'SAP ID',
      DxProductsDataBase_UMSAP_caption: 'U.M SAP',
      DxProductTable_dialogCreate_solutionNotEmpty: 'The solution field cannot be empty or less than 0',
      DxDetailTabs_products_quantityPlanned: 'Quantity Planned',
      DxDetailTabs_products_waterPlanned: 'Water Planned',
      CreatePlanning_ValidationPrescription: 'You must select a prescription',
      DxWorkOrders_kmTotal_caption: 'km performed',
      DxWorkOrders_HorometerTotal_caption: 'Horometer performed',
      DxProductPerformance_products_doseUsed: 'Doses used',
      PlanningComponent_grouped_caption: 'Grouped',
      Workers_StackedBarPlanned: 'Workers',
      machinary_StackedBarPlanned: 'Machineries',
      ChartPlanned_StackedBarPlanned: 'Planned vs Working',
      plannWorkers_StackedBarPlanned: 'Planned Workers',
      plannMachinery_StackedBarPlanned: 'Planned machineries',
      dxSideBySideBar_totalWorkerByActivity: 'Total Workers By Activity',
      dxSideBySideBar_Worker: 'Workers',
      dxSideBySideBarDayWorked_totalWorkerByActivity: 'Days Worked per Activity',
      dxSideBySideBarDayWorked_totalWorkerByActivity_Worked: 'Days Worked',
      DxToolsDatabase_column_status: 'Status',
      DxToolsDatabase_column_code: 'Code',
      DxToolsDatabase_column_family: 'Family',
      DxToolsDatabase_column_subfamily: 'Subfamily',
      DxToolsDatabase_column_description: 'Description',
      DxToolsDatabase_column_brand: 'Brand',
      DxToolsDatabase_column_id: 'id',
      DxToolsDatabase_column_fuel: 'fuel',
      DxToolsDatabase_column_itemId: 'item Id',
      LeftPanel_subtitle_tools: 'Tools',
      editTool_Title: 'EDIT TOOL',
      createTool_Title: 'CREATE TOOL',
      Tools_ValidationCode: 'The field Code is required',
      Tools_ValidationBrand: 'The field Brand is required',
      Tools_ValidationID: 'The field id is required',
      Tools_ValidationItemID: 'The field item ID is required',
      Tools_ValidationDescription: 'The field Description is required',
      Tools_ValidationFamily: 'The field Family is required',
      Tools_ValidationSubfamily: 'The field Subfamily is required',
      Tools_ValidationFuel: 'The field Fuel is required',
      DxWorkOrder_short_id: 'ID',
      DxManualPerformance_total_performance: 'Total Performance',
      DxPopupWorkOrder_Title_supervisor: 'Supervisor',
      DxPopupWorkOrder_Title_totalPerformance: 'Performance',
      DxPopupWorkOrder_Title_machinery: 'Machinery',
      DxPopupWorkOrder_Title_product: 'Products',
      DxWorkOrders_manualPerformance_caption: 'Total Performance',
      PlanningComponent_manualPerformance_caption: 'Total Performance',
      ProductCollection_navbar_title: 'PRODUCT COLLECTION',
      LeftPanel_subtitle_productCollection: 'Product Collection',
      ReturnedProduct_navbar_title: 'RETURNED PRODUCT',
      LeftPanel_subtitle_returnedProduct: 'Returned Product',
      DxProductWarehouse_column_date: 'Date',
      DxProductWarehouse_shortId: 'ID',
      DxProductWarehouse_column_activity: 'Activity Name',
      DxProductWarehouse_column_activityCode: 'Activity Code',
      DxProductWarehouse_column_task: 'Task',
      DxProductWarehouse_column_taskCode: 'Task Code',
      DxProductWarehouse_column_productName: 'Product Name',
      DxProductWarehouse_column_supervisor: 'Supervisor',
      DxProductWarehouse_column_productWarehouse: 'Quantity',
      DxProductWarehouse_column_statusCollection: 'Status',
      DxProductWarehouse_column_statusConfirmed: 'Sent',
      DxProductWarehouse_column_statusNotConfirmed: 'Not Sent',
      DxProductWarehouse_column_cost_center: 'Cost Center',
      DxProductWarehouse_column_cost_center_required: 'Cost Center required',
      DxProductWarehouse_column_warehouse: 'Warehouse',
      DxProductWarehouse_column_warehouse_required: 'Warehouse required',
      DxProductWarehouse_column_status: 'Order Status',
      DxProductWarehouse_column_COMPLETED: 'Completed',
      DxProductWarehouse_column_IN_PROGRESS: 'In Progress',
      DxProductWarehouse_column_planedQuantity: 'Planned Quantity',
      ButtonCancel_caption: 'CANCEL',
      BatchesInfoForm_name: 'Name',
      DxProductWarehouse_edit_validation: 'Product is already sent',
      DxProductWarehouse_Product_Not_Completed:
        'You can not edit this product: Product order is not completed',
      AnnualBudgets_Title: 'ANNUAL BUDGET',
      DxAnnualBudgets_year_caption: 'Year',
      DxAnnualBudgets_version_caption: 'Version',
      DxAnnualBudgets_status_caption: 'Status',
      DxAnnualBudgets_createAnnualBudget_text: 'Create Annual Budget',
      CreateAnnualBudget_Title: 'CREATE ANNUAL BUDGET',
      AnnualBudget_version_validation: 'The field Version is required',
      AnnualBudget_year_validation: 'The field Year is required',
      DxAnnualBudgetForm_version_caption: 'Version',
      DxAnnualBudgetForm_year_caption: 'Year',
      PlanningComponent_monthlyPlanning: 'Annual Budget',
      CloneAnnualBudget_Title: 'CLONE ANNUAL BUDGET VERSION',
      AnnualBudget_catch_NAME_EXIST_case: 'Two versions cannot have the same name',
      AnnualBudget_catch_NOT_CLOSED_case: 'All versions must be closed',
      AnnualBudget_catch_VERSION_NOT_FOUND_case: 'Version not found',
      AnnualBudget_catch_BADGET_NOT_FOUND_case: 'Badget not found',
      AnnualBudget_catch_ERROR_BADGET_MANY_VERSIONS_case: 'Badget cannot have more than 3 versions',
      Rules_count_more_than_zero: 'The quantity has to be greater than 0.',
      LeftPanel_subtitle_pestsDiseases: 'Pests and diseases',
      DxPestsDiseasesDatabase_edit_hint: 'Edit',
      DxPestsDiseasesDatabase_column_type: 'Type',
      DxPestsDiseasesDatabase_column_name: 'Name',
      DxPestsDiseasesDatabase_column_scientific_name: 'Scientific name',
      DxPestsDiseasesDatabase_column_symthoms: 'Symthoms',
      DxPestsDiseasesDatabase_column_photos: 'Photos',
      DxPestsDiseasesDatabase_button_create_text: 'Create a new pest/disease',
      PestsDiseases_title: 'PESTS AND DISEASES',
      LeftPanel_subtitle_extremeWeatherCondicions: 'Extreme weather conditions',
      ExtremeWeatherCondicions_title: 'EXTREME WEATHER CONDITIONS',
      DxExtremeWeatherCondicionsDatabase_edit_hint: 'Edit',
      DxExtremeWeatherCondicionsDatabase_column_type: 'Type',
      DxExtremeWeatherCondicionsDatabase_column_name: 'Name',
      DxExtremeWeatherCondicionsDatabase_button_create_text: 'Create a new extreme weather conditions',
      LeftPanel_subtitle_phenologies: 'Phenologies',
      PhenologiesDatabase_title: 'PHENOLOGIES',
      DxPhenologiesDatabase_edit_hint: 'Edit',
      DxPhenologiesDatabase_column_photos: 'Photos',
      DxPhenologiesDatabase_column_code: 'Code',
      DxPhenologiesDatabase_column_phase: 'Phase',
      DxPhenologiesDatabase_column_description: 'Description',
      DxPhenologiesDatabase_button_create_text: 'Create new phenology',
      DxPlannReal_workersOut_caption: 'Workers out',
      LeftPanel_title_scouting: 'SCOUTING',
      LeftPanel_subtitle_monitoringPoints: 'Monitoring Points',
      MonitoringPoints_title: 'MONITORING POINTS',
      CreatePoint_Title: 'CREATE POINT',
      EditPoint_Title: 'EDIT POINT',
      DxMonitoringPoints_name: 'Name',
      DxMonitoringPoints_location: 'Location',
      DxMonitoringPoints_coordinates: 'Coordinates',
      DxMonitoringPoints_numberPlants: 'Nº Plants Monitoring',
      DxMonitoringPoints_variety: 'Variety',
      DxMonitoringPoints_type: 'Type',
      DxMonitoringPoints_yearPlanting: 'Year of Planting',
      DxMonitoringPoints_statusFormatted: 'Status',
      DxMonitoringPoints_note: 'Notes',
      DxMonitoringPoints_createPoint_button: 'Create new Point',
      Point_name_validation: 'Name is required',
      DxPointForm_name_caption: 'Name',
      DxPointForm_latitude_caption: 'Latitude',
      DxPointForm_longitude_caption: 'Longitude',
      DxPointForm_numberPlants_caption: 'Nº Plants Monitoring',
      DxPointForm_variety_caption: 'Variety',
      DxPointForm_yearPlanting_caption: 'Year of Planting',
      DxPointForm_note_caption: 'Note',
      DxPointForm_name_required: 'Name is required',
      DxPointForm_type_caption: 'Type',
      DxTreePlanning_onlyLevel1_location_already_selected: 'Location already selected',
      DxPointForm_location_caption: 'Farm',
      Point_type_validation: 'Type is required',
      Button_confirm_text: 'Confirm',
      DxMonitoringPoints_dialogDelete_title: 'Delete Monitoring Point',
      DxMonitoringPoints_dialogDelete_text: 'Are you sure to delete the Monitoring Point?',
      Point_date_validation: 'Year required',
      DxWarehouse_totalKm_caption: 'Total Km',
      DxWarehouse_totalHorometer_caption: 'Total Horometer',
      DxWarehouse_totalKm_validation: 'Km start cannot be higher than Km finish',
      DxWarehouse_totalHorometer_validation: 'Horometer start cannot be higher than Horometer finish',
      scs_banned: 'SCS banned',
      DxTimeWorked_location_caption: 'Location',
      DxWorkOrders_fuel_horometerDiff_caption: 'Operating hours',
      Monitoring_column_date: 'Date',
      Monitoring_point_type: 'Point type',
      Monitoring_point_description: 'Point description',
      Monitoring_point_location: 'Location',
      Monitoring_point_longitude: 'Longitude',
      Monitoring_point_area: 'Area (ha)',
      Monitoring_point_latitude: 'Latitude',
      Monitoring_point_number_plants: 'Nº Plants',
      Monitoring_point_variety: 'Variety',
      Monitoring_point_planting_year: 'Planting year',
      Monitoring_point_note: 'Point notes',
      Monitoring_number: 'Number',
      Monitoring_plagues_and_diseases: 'Plagues/Diseases',
      Monitoring_state: 'State',
      Monitoring_phenological_phase: 'Phenological phase',
      Monitoring_comments: 'Comments',
      Monitoring_photos: 'Photos',
      LeftPanel_subtitle_monitoringPests: 'Pests',
      LeftPanel_subtitle_monitoringDiseases: 'Diseases',
      LeftPanel_subtitle_monitoringPhenologies: 'Phenologies',
      LeftPanel_subtitle_monitoringWeatherCondicions: 'Weather conditions',
      LeftPanel_subtitle_monitoringOthers: 'Others',
      Monitoring_pests_navbar_title: 'MONITORING - PESTS',
      Monitoring_diseases_navbar_title: 'MONITORING - DISEASES',
      Monitoring_phenologies_navbar_title: 'MONITORING - PHENOLOGIES',
      Monitoring_weather_condicions_navbar_title: 'MONITORING - WEATHER CONDITIONS',
      Monitoring_others_navbar_title: 'MONITORING - OTHERS',
      LeftPanel_subtitle_climateGrids: 'Climate Grids',
      ClimateGrids_navbar_title: 'CLIMATE GRIDS',
      ClimateGrids_DxWeatherHumidityGrid_blockTitle: 'HUMIDITY',
      ClimateGrids_DxWeatherPrecipitationGrid_blockTitle: 'PRECIPITATION',
      ClimateGrids_DxWeatherTemperatureGrid_blockTitle: 'TEMPERATURE',
      ClimateGrids_DxWeatherTemperatureGrid_measured_Temperature: 'Avg Temperature',
      ClimateGrids_DxWeatherTemperatureGrid_max_Temperature: 'Max Temperature',
      ClimateGrids_DxWeatherTemperatureGrid_min_Temperature: 'Min Temperature',
      ClimateGrids_DxWeatherTemperatureGrid_measured_Humidity: 'Avg Humidity',
      ClimateGrids_DxWeatherTemperatureGrid_max_Humidity: 'Max Humidity',
      ClimateGrids_DxWeatherTemperatureGrid_min_Humidity: 'Min Humidity',
      ClimateGrids_DxWeatherTemperatureGrid_measured_Precipitation: 'Avg Precipitation',
      Monitoring_point_name: 'MONITORING POINT',
      Monitoring_point_variety_code: 'Variety Code',
      no_photos: 'There are no images to show',
      show: 'Show',
      LeftPanel_subtitle_Irrigation: 'Irrigation',
      IrrigationPage_navbar_title: 'IRRIGATION',
      UploadFile_error: 'The following error has occurred:',
      UploadFile_preloader:
        'Uploading the file. This may take a little time depending on the size of the file',
      UploadFile_success:
        'File uploaded successfully. We are processing it. It may take a while to see the changes',
      UploadFile_button: 'Upload file',
      UploadFile_empty: 'Please, select a file',
      Point_location_validation: 'Select only one location',
      Point_no_selected_location_validation: 'Select a valid location',
      CreateEmployee_EmployeeCodeDuplicated: 'Worker ID already used',
      CreateEmployee_EmployeeGenericError: 'An error has ocurred: ',
      DxLocationSelector_ParentLevel: 'Upper Level',
      company_not_found: 'Company not found',
      task_type_not_found: 'Task type not found',
      planner_not_found: 'Planner not found',
      task_not_found: 'Task not found',
      item_not_found: 'Item not found',
      farm_not_found: 'Farm not found',
      product_not_found: 'Product not found',
      product_not_in_prescription: 'Product is not in prescription',
      product_not_in_task: 'Product is not in task',
      product_stock_is_less: 'Product stock is less than specified',
      prescription_not_found: 'Prescription not found',
      badget_not_found: 'Badget not found',
      badget_version_not_found: 'Badget version not found',
      badget_version_task_not_found: 'Badget version task not found',
      variety_not_found: 'Variety not found',
      scouting_not_found: 'Scouting not found',
      scouting_point_not_found: 'Scouting Point not found',
      warehouse_log_not_found: 'Warehouse´s log not found',
      warehouse_not_found: 'Warehouse not found',
      cost_center_not_found: 'Cost center not found',
      badget_version_is_closed: 'Badget Version is closed',
      planner_not_in_planning_status: 'Planner not in planning status',
      task_location_exist_with_same_date: 'Task location exists with same date',
      task_not_referenced: 'Task not referencez',
      planner_not_referenced: 'Planner not referenced',
      task_deleted_correctly: 'Task deleted correctly',
      no_task_for_selected_date_404: 'No task for selected date',
      location_not_found: 'Location not found',
      worker_not_found: 'Worker not found',
      worker_already_exist_in_task: 'Worker already in this task',
      worker_already_exist_in_other_task: 'Worker alreayd in another task',
      worker_not_exist_in_task: 'Worker is not in this task',
      code_not_exist_in_request: 'Code does not exists in request',
      code_not_exist: 'Code doesn´t exists',
      outdated: 'Outdated',
      invalid_responsible: 'Invalid responsible',
      invalid_item_family: 'Invalid item family',
      Not_Found: 'Not found',
      ID_Must_be_especified: 'ID must be especified',
      Phenological_phase_not_found: 'Phenological phase not found',
      Plagues_and_diseases_not_found: 'Plagues and diseases not found',
      SectorValidation: 'Error drawing the sector, please draw inside the plot',
      Scouting_point_required: 'Scouting Point required',
      CreatePlanning_DuplicatedEmployees: 'Duplicated workers: ',
      PlanningComponent_comments_caption: 'Comments',
      DxDailyPlanning_creation_caption: 'Creation',
      DxDailyPlanning_CreationPlanned_caption: 'Planned',
      DxDailyPlanning_CreationManually_caption: 'Manually',
      DxWorkOrders_ExportTranslation_WorkerType: 'WORKER TYPE',
      ExportDataToExcel_Button: 'Export All',
      DxDailyPlanning_CostVsReal_versionName: 'Version',
      DxProductWarehouse_Popup_Send_Title: 'Send:',
      DxProductWarehouse_ButtonText_ReSend: 'Resend',
      DxProductWarehouse_ButtonText_Adjust: 'Adjust',
      DxProductWarehouse_Alert_FieldsRequired: 'All the fields are required',
      DxProductWarehouse_Alert_CorrectResend: 'Product resent correctly',
      DxProducts_dxColumn_solution_total: 'Total Solution',
      DxListEmployee_group_caption: 'Group',
      DxListEmployee_shift_caption: 'Shift',
      DxWeatherGrid_date_time: 'Time',
      Monitoring_point_code: 'Code',
      DxFormProduct_maxdosis: 'Maximum Allowed Dose',
      DxFormProduct_currentIn: 'Restricted entry interval',
      DxFormProduct_SCS_autorizado: 'SCS Authorized',
      DxFormProduct_EU_autorizado: 'EU Authorized',
      DxFormProduct_preHarvest: 'Preharvest Interval',
      DxProductsDataBase_currentIN: 'Hours',
      DxProductsDataBase_preHarverst: 'Days',
      DxMonthlyForm_jornada: 'workdays',
      DxMachinery_AttachmentInternalCode_caption: 'Attachment code',
      DxWorkOrders_ExportTranslation_Group: 'Group',
      DxWorkOrders_ExportTranslation_Shift: 'Shift',
      DxMonthlyPlanning_comments: 'Comments',
      DxDailyPlanning_workers_performance_caption: 'Performance',
      DxMachinery_horometer_start_caption: 'Hourmeter start',
      DxMachinery_horometer_end_caption: 'Hourmeter finish',
      DxMachinery_horometer_total_caption: 'Hourmeter total',
      DxScouting_fullName: 'Fullname',
      LeftPanel_subtitle_territorialUnit: 'Territorial Units',
      LeftPanel_subtitle_territorialUnitcaps: 'TERRITORIAL UNITS',
      DxTerritorialUnit_area: 'Area',
      DxTerritorialUnit_code: 'Code',
      DxTerritorialUnit_farm: 'Farm',
      DxTerritorialUnit_id: 'ID',
      DxTerritorialUnit_name: 'Name',
      DxTerritorialUnit_pattern: 'Pattern',
      DxTerritorialUnit_planting_year: 'Planting year',
      DxTerritorialUnit_plants: 'Plants',
      DxTerritorialUnit_variety_code: 'Variety code',
      DxTerritorialUnit_variety_name: 'Variety name',
      DetailTabDrivers_sin_items: 'No data',
      DetailTabs_driver_title: 'Drivers',
      Stock_Title: 'Stock',
      LeftPanel_subtitle_stock: 'Stock',
      DxStockTable_dxColumn_Code: 'Code',
      DxStockTable_dxColumn_Description: 'Description',
      DxStockTable_dxColumn_Stock: 'Stock',
      DxProductWarehouse_column_productWarehouse_required: 'Quantity is required',
      DxWorkOrders_ExportTranslation_Variety: 'Variety',
      DxWorkOrders_ExportTranslation_Driver: 'Driver',
      DxDailyPlanning_export_activity_code: 'Activity Code',
      DxDailyPlanning_export_areaEfective: 'Area efective',
      DxDailyPlanning_export_Comments: 'Comments',
      DxDailyPlanning_export_TaskCode: 'Task code',
      DxDailyPlanning_export_variety: 'Variety',
      DxDailyPlanning_export_performance: 'Productivity',
      DxDailyPlanning_export_machinary_consumption: 'Machinery Consumption',
      DxDailyPlanning_export_machinary_consumption_type: 'Consumption Type',
      DxDailyPlanning_export_machinary_notes: 'Machinery Notes',
      DxDailyPlanning_export_machinary_performance: 'Machinery Performance',
      DxDailyPlanning_export_machinary_type: 'Machinery type',
      DxDailyPlanning_export_tools_consumption: 'Tool consumption',
      DxDailyPlanning_export_tools_consumption_type: 'Consumption type',
      DxDailyPlanning_export_tools_count: 'Quantity',
      DxDailyPlanning_export_tools_type: 'Tool type',
      DxDailyPlanning_export_products_code: 'Product Code',
      DxDailyPlanning_export_products_dilution_factor: 'Dilution Factor',
      DxDailyPlanning_export_products_dosis: 'Product Dose',
      DxDailyPlanning_export_products_dosis_total: 'Product total dose',
      DxDailyPlanning_export_products_name: 'Product Name',
      DxDailyPlanning_export_products_solution: 'Product Solution',
      DxDailyPlanning_export_products_solution_total: 'Product Total Solution',
      DxStockTable_dxColumn_Unit: 'Unit',
      DxTaskOrder_origen: 'Source',
      DxBareRootTable_dxColumn_CreationDate: 'Fecha de creación',
      DxBareRootTable_dxColumn_Name: 'Name',
      DxBareRootTable_dxColumn_Origin: 'Origin',
      DxBareRootTable_dxColumn_PlantYear: 'Planting Year',
      DxBareRootTable_dxColumn_Variety: 'Variety',
      DxBareRootTable_dxColumn_Category: 'Category',
      DxBareRootTable_dxColumn_ClassType: 'Class',
      DxBareRootTable_dxColumn_NumPlants: 'Nº Plants',
      DxBatchesInfoForm_Farm: 'Location',
      DxBatchesInfoForm_Farm_Rule: 'You must choose a location',
      DxBatchesInfoForm_Name_Rule: 'Name is required',
      DxToolsTable_Add_Change_typeTools: 'The tool is already selected',
      DxToolsTable_tools: 'Tool',
      error_401: 'The user or password is incorrect',
      TASK_WORDER_PRODUCT_PERFORMANCE_REQUIRED: 'Total kg collected must be greater than zero.',
      TASK_WORDER_MACHINERY_PERFORMANCE_INCOMPLETE: 'Machinery data is incomplete',
      TASK_WORDER_PRODUCT_PERFORMANCE_TOTAL_REAL_PRESCRIPTION_ZERO:
        'When the units are "Cc/Hl", "Gr/Hl" you must select a total prescription other than 0.',
      error_403: 'You do not have permissions to perform this action. Please contact your administrator',
      DxToolsTable_Add_Change_error_count: 'Not enough tools',
      DxTable_Date_caption: 'Date',
      DxTable_ut_caption: 'UT',
      DxTable_type_caption: 'Type',
      DxTable_variety_caption: 'Variety',
      DxTable_plantYear_caption: 'Plant year',
      DxTable_category_caption: 'Category',
      DxTable_amount_caption: 'Amount',
      DxTable_notes_caption: 'Notes',
      DxNurseryStockReport_typeReclassified_caption: 'Reclassified type',
      DxNurseryStockReport_pottedYear_caption: 'Potted year',
      DxNurseryStockReport_categoryReclassified_caption: 'Reclassified category',
      DxNurseryStockReport_finalDestination_caption: 'Final destination',
      DxNurseryReport_classification: 'Clasificación',
      DxNurseryReport_stock: 'Stock',
      DxNurseryReport_egresos: 'Expenses',
      DxNurseryEgresosReport_egresosType_caption: 'Expenses types',
      DxNurseryEgresosReport_bins_caption: 'Bins',
      DxNurseryEgresosReport_orm_caption: 'N Guides / ORM',
      DxNurseryEgresosReport_destiny_caption: 'Destiny',
      DxNurseryEgresosReport_state_caption: 'Shipping status',
      specie: 'Specie',
      group: 'Group',
      variety: 'Variety',
      DxProduct_name: 'Name',
      DxProduct_group: 'Group',
      DxProduct_category: 'Category',
      DxProduct_active_ingredients: 'Active ingredients',
      LeftPanel_subtitle_variety: 'Varieties',
      Page_subtitle_variety: 'VARIETIES',
      LeftPanel_subtitle_tasksdef: 'Tasks',
      LeftPanel_subtitle_product: 'Products',
      Page_subtitle_product: 'PRODUCTS',
      DxProduct_register_Product: 'Register Product',
      DxProduct_edit_Product: 'Edit Product',
      DxViewProduct_detail_Product: 'Detail product',
      Page_subtitle_clients: 'CLIENTS',
      DxClients_name: 'Name',
      DxClients_fecha_alta: 'entry date',
      DxClients_numero_movil: 'movil number',
      DxClients_telefono: 'phone',
      DxClients_provincia: 'province',
      DxClients_localidad: 'locality',
      DxClients_sales: 'Sales',
      DxClients_atc_propietario: 'ATC owner',
      DxClients_email: 'Email',
      LeftPanel_subtitle_client: 'Clients',
      DxClient_register_Client: 'Register Client',
      DxVariety_register_Variety: 'Register Variety',
      DxClient_edit_Client: 'Edit Client',
      DxVariety_edit_Variety: 'Edit Variety',
      DxViewClient_detail_Client: 'DETAIL CLIENT',
      DxViewClient_detail_Ventas: 'Sales',
      Button_go_to_parcel_system: 'Clients',
      Button_go_to_back: 'Back',
      Button_go_to_home: 'Go to introduction',
      DxClients_postal_code: 'Postal Code',
      DxClients_pc: 'ZP',
      DxClients_client: 'Client',
      DxAtc_atc: 'ATC',
      Campania_title: 'AGRICULTURAL CAMPAIGN',
      DxRegisterCampania_nombre: 'Agricultural campaign name',
      DxRegisterCampania_campaign_require: 'Campaign is required',
      DxRegisterCampania_plantation_require: 'Plantation is required',
      DxRegisterCampania_parameter_required: 'Parameter required',
      DxRegisterCampania_fecha_inicio: 'Init date',
      DxRegisterCampania_fecha_finalizacion: 'End date',
      DxRegisterCampania_nombre_validation: 'Campaign name is required',
      DxRegisterCampania_fecha_inicio_validation: 'Init date is required',
      DxRegisterCampania_fecha_finalizacion_validation: 'End date is required',
      DxRegisterDateRange_validation: 'Date range is required',
      Button_go_create_new_Campania: 'Save',
      LeftPanel_subtitle_campaña: 'Campaign',
      LeftPanel_title_ParcelSystem: 'Parcel System',
      Button_go_parcel: 'Parcel',
      Location_title: 'FARMS',
      ParcelSystemCampania_designacion: 'Designation',
      ParcelSystemCampania_status: 'Status',
      ParcelSystemLocation_designacion: 'Designation',
      ParcelSystemLocation_parcelas: 'Nº Plots',
      ParcelSystemLocation_plantations: 'Nº Plantations',
      ParcelSystemLocation_sectores: 'Nº Sectors',
      Register_farm: 'REGISTER OPERATION',
      Edit_farm: 'EDIT OPERATION',
      Register_farm_name: 'Name operation',
      Register_farm_provincia: 'province',
      Register_farm_localidad: 'locality',
      Register_farm_municipio: 'municipality',
      Register_Farm_numeroRegistro: 'Registration Number',
      Register_Farm_titularExplotacion: 'Owner operation',
      Register_Farm_mobile: 'Mobile number',
      Edit_Farm_successfuly: 'New data saved successfully',
      DxSimpleItem_PopUpRegister_campania: 'Operation',
      Register_completed: 'You have successfully registered the campaign.',
      End_Campaign: 'End',
      title_create_campania: '+ Create plantation',
      campañia_register: 'REGISTERED CAMPAIGN',
      ploit_register: 'REGISTERED FARM',
      title_create_parcel: '+ Create plot',
      ploit_completed: 'You have successfully registered the farm.',
      DxSales_sale_date: 'Sale date',
      DxSales_deal: 'Deal',
      DxSales_company: 'Company',
      DxSales_sale_point: 'Sale point',
      DxSales_product_code: 'Code product',
      DxSales_product: 'Product',
      DxSales_quantity: 'Quantity',
      DxSales_species_code: 'Species code',
      DxSales_status: 'Status',
      DxElement: 'Element',
      status_campania_active: 'Active',
      status_campania_end: 'Finished',
      status_campania_pending: 'Pending',
      status_active: 'Active',
      status_end: 'Finished',
      status_pending: 'Pending',
      dates_isInvalid: 'invalid dates',
      DxProduct_nameCommercial: 'COMMERCIAL NAME',
      DxProduct_code: 'Code',
      DxProduct_Ntotal: 'N TOTAL',
      DxProduct_P205: 'P₂O₅ TOTAL',
      DxProduct_K20: 'K₂O',
      DxProduct_CaO: 'CaO',
      DxProduct_tipology: 'Tipology',
      DxProduct_presentation: 'Presentation',
      DxProduct_density_no_unit: 'Density',
      DxProduct_density: 'Density (g/cm³)',
      DxProduct_density_upper: 'DENSITY (g/cm³)',
      DxProduct_ecology: 'Ecology',
      DxProduct_mainElement: 'Main Elements',
      DxProduct_secondaryElement: 'Secondary elements',
      DxProduct_microelements: 'Microelements',
      LeftPanel_subtitle_Treatment: 'Treatment',
      LeftPanel_subtitle_Nutrition: 'Nutrition',
      LeftPanel_subtitle_observation: 'Observation',
      LeftPanel_title_Informes: 'Reports',
      confirmDeleteMessage: 'Are you sure you want to delete this record?',
      registerAnalaysisTitle: 'Record laboratory analysis',
      laboratory_nutrition_description_required: 'The description cannot be empty',
      laboratory_nutrition_date_required: 'Date cannot be empty',
      laboratory_nutrition_type_required: 'Type of sample cannot be empty',
      RegisterAnalysisSampling_date: 'Sampling date:',
      RegisterAnalysisSampling_type: 'Type of sample:',
      RegisterAnalysisFile_Attachment: 'File Attachment:',
      RegisterAnalysisFile_allowed_extension: 'Allowed file extension:',
      RegisterAnalysisFile_selected: 'Select file',
      RegisterAnalysisDescription: 'Description',
      RegisterAnalysisInterpretation: 'Interpretation',
      RegisterAnalysisInterpretation_textArea: 'type a text here',
      Register_product: 'REGISTER PRODUCT',
      Edit_product: 'EDIT PRODUCT',
      first_element_floatinButton: 'High nutritional plan',
      second_element_floatinButton: 'Discharge treatment',
      third_element_floatinButton: 'High remarks',
      LeftPanel_title_utilities: 'Utilities',
      LeftPanel_subtitle_vademecum: 'Vademecum',
      LeftPanel_subtitle_converter: 'Converter',
      LeftPanel_subtitle_markets: 'Markets',
      LeftPanel_subtitle_customers: 'Customers',
      LeftPanel_subtitle_plans: 'Plans',
      Vademecum_Title: 'Vademecum',
      Vademecum_product_code: 'Product code',
      Utilities_Title: 'Utilities',
      Phytosanitary_Products: 'Phytosanitary products',
      Nutritional_Products: 'Nutritional products',
      nutritionProductsDeleted: 'The following products are no longer available:',
      nutritionDosificationProblem: 'Failed attempt to save. Problems were encountered in the dosification.',
      Plague: 'Plague',
      Product: 'Product',
      Product_Type: 'Product Type',
      Commercial_Name: 'Commercial Name',
      Holder: 'Holder',
      Registration_Number: 'Nº Registration',
      Crop: 'Crop',
      Agent: 'Agent/Effect',
      Filters: 'Filters',
      Filter: 'Filter',
      Filter_Crop: 'Crop',
      Filter_Agent: 'Agent/Effect',
      Filter_Commercial_Name: 'Commercial name',
      Select_Crop: 'Write or select crop *',
      Select_Agent: 'Write or select agent / effect *',
      Select_CommercialName: 'Select commercial name',
      Active_Product: 'Active product',
      Apply: 'Apply',
      Reset: 'Reset',
      Go_Back: 'Go back',
      Product_Detail_Title: 'PRODUCT DETAIL',
      Uses_Doses_Detail_Title: 'AUTHORIZED USES AND DOSES',
      Active_Principle: 'Active Principle',
      Display: 'Display',
      Expiration_Date: 'Expiration date',
      Application_Num: 'Number of applications',
      Dose: 'Dose',
      Liquid_Vol: 'Liquid volume',
      Application_Info: 'Application Info',
      No_Data: 'No data to display',
      No_Observation: 'No general observation to display.',
      No_Images_Data: 'Remote sensing images in process. They will be available in a few moments',
      Type_Product: 'Type product',
      Application: 'APPLICATION',
      Microelements: 'Microelements',
      Secondary_Elements: 'Secondary elements',
      Main_Elements: 'Main elements',
      How_To_Use: 'How to use',
      Packing: 'Packing',
      PermissionsReadWrite: 'Enable read / write permissions and try again',
      PermissionsReadWriteCode1: 'Read/write error. Check if a file with the same name already exists',
      FileDownloadedInDownloads: 'File downloaded in the Download folder',
      FileNotFound: 'Error. File not found',
      parcelViewClient_number_phone: 'Cell phone number',
      DxClients_atc_prop: 'ATC owner',
      Register_campaign: 'REGISTER AGRICULTURAL CAMPAIGN',
      register_location_placeholder_name: 'Name of the farm',
      register_location_placeholder_province: 'Select province',
      register_location_placeholder_locality: 'Select locality',
      register_location_placeholder_municipality: 'Select municipality',
      register_location_placeholder_number_register: 'Registration number',
      register_location_placeholder_owner: 'Headline',
      register_location_placeholder_number_phone: 'Cell phone number',
      DxRegisterCampania_fecha_inicio_mayor_validation: 'The start date is after the end date',
      WeatherStationIdentifier: 'Weather station identifier',
      Search: 'Search',
      Utilities: {
        name: 'UTILITIES',
        converter: 'Converter',
        converterWater: {
          title: 'Converter between meq / l and mg / l',
          bottomTitle: 'Enter one of the fields to estimate the other two',
          m3: 'Irrigation equipment: m³/ha',
          meq: 'meq/l',
          mg: 'mg/l',
          kg: 'kg/ha',
          reset: 'Reset',
        },
        converterGround: {
          tabTitle: 'Ground',
          title: 'Converter between meq / 100g, ppm and kg / ha',
          apparentDensity: 'apparent density (t/m3)',
          soilDepth: 'soil depth (m)',
          bottomTitle: 'Enter one of the fields to estimate the other two',
          meq: 'meq/100g',
          ppm: 'ppm',
          percent: '%',
          kg: 'kg/ha',
          reset: 'Reset',
        },
        stepperForm: {
          back: 'Back',
          next: 'Next',
          save: 'Save',
          monthly: 'Monthly',
          weekly: 'Weekly',
          planDescription: 'Plan description',
          temporalFrame: 'Temporal frame',
          ph: 'pH',
          init: 'Init',
          end: 'End',
        },
        converterNutritionalSolutions: {
          title: 'Nutrient Solutions - Ion to Oxide Converter',
          bottomTitle: 'Enter one of the fields to estimate the other two',
          element: 'Element (mg)',
          oxide: 'Oxide (mg)',
          reset: 'Reset',
        },
        converterMolecularWeight: {
          title: 'Molecular weight to pure substances',
          bottomTitle: 'Enter one of the fields to estimate the other two',
          eq: 'EQ/KH',
          percent: '%',
          mol: 'MOL/KG',
          reset: 'Reset',
        },
        nutrition: 'Nutrition',
        tabTitleOther: 'Others',
        Campania_title_deatil: 'AGRICULTURAL CAMPAIGN DETAIL',
        ViewCampania_species: 'Botanical species',
        ViewCampania_variety: 'Variety',
        ViewCampania_init_date: 'Init date',
        ViewCampania_end_date: 'End date',
        ViewCampania_farm: 'Farm',
        ViewCampania_parcel: 'Parcel',
        ViewCampania_sector: 'Sector',
        title_datagrid_plantation: 'AGRICULTURAL CAMPAIGN PLANTATIONS',
        Location_title_deatil: 'DETAIL OF FARM',
        ViewLocation_title: 'Owner of the farm',
        ViewLocation_mobile: 'Mobile number',
        ViewLocation_name_location: 'Name of the farm',
        ViewLocation_number_register: 'Registration number',
        ViewLocation_province: 'Province',
        ViewLocation_Localidad: 'Locality',
        ViewLocation_postal_code: 'Postal Code',
        ViewLocation_name: 'Name',
        title_button_viewLocation_parcel: '+ Create parcel',
        ViewLocation_parcel_column_parcela: 'Parcel',
        ViewLocation_parcel_column_area: 'Area (ha)',
        datagrid_title_parcel: 'PARCELAS',
      },
      referenceValue: {
        title: 'Reference Value',
        production: 'Production (t/ha)',
        n: 'N',
        p2o5: 'P₂O₅',
        k2o: 'K₂O',
        cao: 'CaO',
        mgo: 'MgO',
        so3: 'SO₃',
      },
      nutritionalNeeds: {
        title: 'Nutritional Needs',
        productionInput: 'Estimated production (t/ha)',
        n: 'N',
        p2o5: 'P₂O₅',
        k2o: 'K₂O',
        cao: 'CaO',
        mgo: 'MgO',
        so3: 'SO₃',
      },
      nutritionalNeedsTips: {
        unitsReference: 'Tons of dry matter',
        observation: 'Observation',
        reference: 'Reference',
      },
      nutritionalBalance: {
        title: 'BALANCE NUTRICIONAL PREVIO FERTILIZACIÓN',
        uf: 'UF (kg/ha)',
        n: 'N',
        p: 'P₂O₅',
        k: 'K₂O',
        c: 'CaO',
        m: 'MgO',
        s: 'SO₃',
        necNutritional: 'Nec. nutricionales',
        availableGround: 'UF Disponibles suelo',
        irrigationWater: 'UF Agua de riego',
        harvestScraps: 'UF Restos de cosecha',
        availableTotal: 'UF Totales disponibles',
        advanceFertilization: 'Balance previo fertilización',
      },
      dosageProduct: {
        title: 'Contribution of products and dosage',
        finalBalance: 'Balance final',
        uf: 'UF (kg/ha)',
        n: 'N',
        p: 'P2O5',
        k: 'K2O',
        c: 'CaO',
        m: 'MgO',
        s: 'SO3',
        searchFertilizer: 'Search Fertilizer',
        searchFertilizerPlaceholder: 'Search by name or code...',
        add: 'Add',
        addIrrigationTable: 'Add Irrigation Table',
        deleteIrrigationTable: 'Delete Irrigation Table',
        recalculate: 'Recalculate',
        messageRecalculate:
          'Remember to use the recalculate button (if you modify the doses of the products)',
        product: 'Product',
        riches: 'Riches %',
        dosage: 'Dosage (kg/ha)',
        dose: 'Dose (kg/ha)',
        deleteDialogMessage: 'Delete {{product}}?',
        deleteDialogTitle: 'Delete',
        deleteDialogConfirm: 'Confirm',
        deleteDialogCancel: 'Cancel',
      },
      productResume: {
        area: 'Area (ha)',
        productResume: 'RESUMEN POR PRODUCTOS',
        product: 'Product',
        dosageTotalKg: 'Dosage (kg-L / ha)',
        dosageTotalKgL: 'Dosage Total (kg-L)',
        dosageUnit: 'Unit dose (kg/ha)',
        dosagePlant: 'Dose per plant (g/plant)',
        dosageTotal: 'Total dose (kg)',
        price: 'Price (€/kg)',
        unitPrice: 'Unit price (€)',
        costUnit: 'Unit cost (€/ha)',
        costPlant: 'Cost (€/planta)',
        costTotal: 'Total cost (€)',
        chartTitle: 'PLAN DE TRATAMIENTO TIMAC AGRO (STACK DE PRODUCTOS)',
        dosage: 'Dosis (L / 1000L)',
        caldoMedium: 'Vol. caldo medio (L / ha)',
        caldoTotal: 'Vol. Total de caldo (L)',
      },
      popupSelectObservationsReport: {
        pdfType: 'This PDF is type',
        observations: 'Observations',
        elementsResume: 'Summary by elements',
        nutritionNeeds: 'Include Nutritional Needs in the report',
        previusBalance: 'Include previous balance in the report',
        theoreticalUF: 'Include theoretical UF in the report',
        efficientUF: 'Include efficient UF provided in the report',
        finalBalance: 'Include the Final Balance in the report',
        resumeProducts: 'Summary by products',
        completeTable: 'Complete table',
        noCostTable: 'Table without costs',
        productsAplication: 'Application of products in each period kg-l/ha',
        resumeProductsGraphics: 'Summary graph by products',
        productsAplicationTotals: 'Application of products in each period total kg-l',
        irrigationTable: 'Irrigation Table',
        ufEfficientTable: 'Table Application of efficient UF for each period',
        ufEfficientGraph: 'Application graph of efficient UF of each period',
        Date: 'Date',
        buttons: {
          cancel: 'cancel',
        },
      },
      elementResume: {
        elementResume: 'RESUMEN POR ELEMENTOS',
      },
      planCompost: {
        planCompost: 'PLAN DE TRATAMIENTO TIMAC AGRO (STACK DE PRODUCTOS)',
      },
      nutritionalDistribution: {
        nutritionalDistribution: 'Reparto nutricional mensual kg/ha (STACK POR ELEMENTOS)',
      },
      LeftPanel_title_analisys: 'Analisys',
      Title_page_weather: 'WEATHER',
      Title_page_analisys_treatment: 'TREATMENTS',
      Title_page_analisys_nutrition: 'NUTRITION',
      Button_add_weather: 'Add',
      weather_campaign: 'Campaign',
      weather_parameters: 'Parameters',
      weather_date: 'Date',
      weather_farm: 'Parcel',
      registerClima_campaign_placeholder: 'Select campaign',
      registerClima_parameter_placeholder: 'Select variable',
      registerClima_initDate_placeholder: 'DD/MM/AAAA',
      registerClima_endDate_placeholder: 'DD/MM/AAAA',
      registerClima_parcel_placeholder: 'Select plantation.',
      registerClima_campaign_required: 'Campaign is required.',
      egisterClima_parameter_required: 'Parameter is required.',
      registerClima_parcel_required: 'Parcel is required.',
      Configuration: {
        name: 'CONFIGURATION',
        Variety_Detail_Title: 'VARIETY DETAIL',
        Observations_Title: 'OBERVATIONS',
        Observations: 'Observations',
        Edit_Observation: 'Edit Observation',
        Register_Observation: 'Register Observation',
        Observation_Detail_Title: 'OBSERVATION DETAIL',
        Tasks_Title: 'TASKS',
        Tasks: 'Tasks',
        Edit_Task: 'Edit Task',
        Register_Task: 'Register Task',
        Create_Task: 'Create',
        Task_Detail_Title: 'TASK DETAIL',
        Activity: 'Activity',
        Name: 'Name',
        Name_validation: 'Name cannot be empty',
        Activity_validation: 'Activity cannot be empty',
        Delete_Validation: 'An error occurred while trying to delete the item',
        Subcategory_Validation: 'The form name field is required',
        Category_Validation: 'The category field is required',
        Form_Validation: 'Template does not contain attributes',
        ObservationsForms: {
          title: 'PERSONALIZED FORMS OF OBSERVATIONS',
          formName: 'Form name',
          atcProp: 'ATC Prop',
          attributes: 'Number of attributes',
          registerTitle: 'REGISTER CUSTOM OBSERVATIONS FORM',
          description: 'Description',
          descriptionRequired: 'The form name field is required',
          formNameRequired: 'The description field is required',
          attributeNameRequired: 'The field field is required',
          attributesTitle: 'ATTRIBUTES',
          name: 'Name',
          tipology: 'Typology',
          domain: 'Domain',
          detailTitle: 'DETAIL OF CUSTOM OBSERVATIONS FORM',
          view: 'VIEW',
          setup: 'SET UP',
          category: 'Category',
          field: 'Field',
          PatternRuleField: 'The Field field must not contain spaces or special characters',
          rowValidatingText: 'There are required fields without filling in',
          fieldHelpText: 'It cannot contain spaces or special characters',
          choicesHelpText: 'Enter choices separated by commas and without spaces',
          validatingChoices: 'Choices must be separated by commas and without spaces',
          validatingChoices2: 'There should be no comma followed by empty',
        },
        AttributesPopup: {
          required: 'Required',
          allowNull: 'Allow null',
          allowBlank: 'Allow blank',
          maxLength: 'Max length',
          minLength: 'Min length',
          maxValue: 'Max value',
          minValue: 'Min value',
          maxDigits: 'Max digits',
          decimalPlaces: 'Decimal places',
          choices: 'Choices',
          date: 'Date',
          dateTime: 'Datetime',
          close: 'Close',
          default: 'Default value',
          typeINT: 'Integer',
          typeFLOAT: 'Decimal number',
          typeBOOLEAN: 'True/False',
          typeTEXT: 'Text not editable',
          typeSTRING: 'Text',
          typeDATE: 'Date',
          typeDATETIME: 'Date and time',
          typeSELECT: 'Selector',
          typeLIST: 'Multiselector',
        },
      },
      Confirm_Delete_Text: 'Are you sure to delete this entry?',
      wrong_user_edit: 'It is not possible to edit products not registered by the user',
      Confirm_Delete_Title: 'Alert',
      Field_Required: 'This field is required',
      Atc_Propietario: 'Owner',
      Fields_Required: 'All fields must be filled',
      LeftPanel_subtitle_lonjas: 'Slices',
      Slices_simpleItem_province: 'Province',
      Slices_simpleItem_slice: 'Slice',
      Slices_simpleItem_family: 'Family',
      Slices_simpleItem_product: 'Product',
      Slices_simpleItem_initDate: 'from',
      Slices_simpleItem_endDate: 'to',
      Slices_simpleItem_origin: 'Origin',
      Slices_placeholder_province: 'Select province',
      Slices_placeholder_slice: 'Select slice',
      Slices_placeholder_family: 'Select family',
      Slices_placeholder_product: 'Select product',
      Slices_placeholder_initDate: 'DD/MM/AAAA',
      Slices_placeholder_endDate: 'DD/MM/AAAA',
      Slices_placeholder_origin: 'Select origin',
      Slices_validate_slice: 'The field slice is required',
      Slices_validate_family: 'The field family is required',
      Slices_validate_product: 'The field product is required',
      Slices_validate_origin: 'The field origin is required',
      notExistOrigin: 'No origin data has been found.',
      Norte: 'North',
      Sur: 'South',
      Noreste: 'Northeast',
      Este: 'East',
      Sureste: 'Southeast',
      Suroeste: 'Southwest',
      Oeste: 'West',
      Noroeste: 'Northwest',
      LeftPanel_subtitle_price_lonjas: 'PRICES SLICES',
      LeftPanel_title_search: 'Search engine',
      searchEngine_name: 'Name',
      searchEngine_description: 'Description',
      searchEngine_province: 'Province',
      searchEngine_farm: 'Farm',
      searchEngine_parcel: 'Parel',
      searchEngine_sector: 'Sector',
      searchEngine_campaign: 'Campaign',
      searchEngine_state: 'State',
      searchEngine_botanical_species: 'Crop',
      searchEngine_variety: 'Variety',
      searchEngine_init_date: 'Init date',
      LeftPanel_title_search_page: 'Home',
      searchEngine_end_date: 'End date',
      LeftPanel_subtitle_irrigation: 'Irrigation',
      irrigation_date: 'DATE',
      irrigation_doses: 'IRRIGATION RATE (L/ha)',
      irrigation_doses_m3: 'IRRIGATION RATE (m³/ha)',
      irrigation_time: 'TIME (h)',
      irrigation_totalVol: 'VOL. TOTAL (L)',
      irrigation_totalVol_m3: 'TOTAL VOL. (m³)',
      irrigation_accumulatedVol: 'ACCUMULATED VOL. (L)',
      irrigation_accumulatedVol_m3: 'ACCUMULATED VOL. (m³)',
      registerAndEditIrrigation_date: 'Irrigation date',
      registerAndEditIrrigation_doses: 'Irrigation dose (m³/ha)',
      registerAndEditIrrigation_time: 'WATERING TIME (h)',
      LeftPanel_subtitle_analysis_nutrition: 'Analysis nutrition',
      laboratoryAnalaysisTitle: 'LABORATORY ANALYSIS',
      LeftPanel_subtitle_analysis_taskByCompany: 'Tasks by company',
      taskByCompany_group: 'Task group',
      taskByCompany_Task: 'Task',
      taskByCompany_init_date: 'Init date',
      taskByCompany_end_date: 'End date',
      taskByCompany_description: 'Description',
      taskByCompany_placeholder_group: 'Select',
      taskByCompany_placeholder_task: 'Select a task',
      taskByCompany_invalid_task_selection: 'Task cannot be empty',
      taskByCompany_description_validation: 'The description cannot be empty',
      taskByCompany_placeholder_init_date: 'DD/MM/AAAA',
      taskByCompany_placeholder_description: 'Task name',
      wizardTreatment: {
        treatment: 'Treatment',
      },
      nutritionalList: {
        title: 'Nutritional Plans',
        description: 'PLAN DESCRIPTION',
        production: 'PRODUCTION (t/ha)',
        cost: 'COST €/ha',
        n: 'N TOTAL',
        p: 'P2O5 TOTAL',
        k: 'K2O TOTAL',
        c: 'CaO TOTAL',
        m: 'MgO TOTAL',
        s: 'SO3 TOTAL',
        status: 'STATUS',
        active: 'Active',
        cancel: 'Cancel',
        planned: 'Planned',
        assets: 'Assets',
        archived: 'Archived',
      },
      nutritionDetail: {
        nutritionDetail: {
          title: 'NUTRITION PLAN DETAILS',
          description: 'Description',
          initDate: 'Start date',
          endDate: 'End date',
          status: 'Status',
          expectedProduction: 'Expected production',
          area: 'Surface',
          textureSoil: 'Soil texture',
          irrigationSystem: 'Irrigation system',
          chartLine: 'Line',
          chartBar: 'Bar',
          shareError: 'Your browser does not support file sharing'
        },
        elementResume: {
          title: 'SUMMARY BY ELEMENTS',
          uf: 'UF (kg/ha)',
          n: 'N',
          p: 'P2O5',
          k: 'K2O',
          c: 'CaO',
          m: 'MgO',
          s: 'SO3',
          necNutritional: 'Nec. total nutritional',
          fertilizationBalance: 'Balance prior to fertilization',
          ufContributed: 'UF Contributed',
          finalBalance: 'Final balance',
        },
        productResume: {
          title: 'SUMMARY BY PRODUCT',
          product: 'Product',
          dosageUnit: 'Unit dose (kg/ha)',
          dosagePlant: 'Dose per plant (g/plant)',
          dosageTotal: 'Total dose (kg)',
          price: 'Price (€/kg)',
          costUnit: 'Unit cost (€/ha)',
          costTotal: 'Total cost (€)',
          chartTitle: 'TIMAC AGRO TREATMENT PLAN (PRODUCT STACK)',
          total: 'Total',
        },
        report: {
          report: 'REPORT',
          explotationData: 'EXPLOTATION DATA',
          nutritionPlan: 'NUTRITION PLAN',
          campaign: 'Campaign',
          explotation: 'Explotation',
          parcel: 'Parcel',
          sector: 'Sector',
          plantation: 'Plantation',
          cropType: 'Crop',
          specie: 'Botanical specie',
          variety: 'Variety',
          plantsDensity: 'Plants density',
          plantsNumber: 'Plants number',
          area: 'Area',
          groundTexture: 'Ground texture',
          irrigationType: 'Irrigation type',
          flow: 'Flow',
          dripperPerPlant: 'Dripper/plant',
          plantsHa: 'plants/ha',
          plagueAgent: 'Plague / agent',
          dosage: 'Dosage',
          download: 'Download',
          status: 'Status',
          clouds: 'Cloud Covered Area(%)',
        },
        annotationsProductResume: {
          title: 'PRODUCT ANNOTATIONS',
          annotation: 'ANNOTATION',
        },
        descriptionValidation: 'The plan description cannot be empty',
        dateRangeInvalid: 'Invalid date range',
        emptyReferenceValues: 'The reference values are empty',
      },
      referencePlan: {
        title: 'REFERENCE PLAN',
        titleDetail: 'REFERENCE PLAN DETAIL',
        duration: 'Duration',
      },
      Searcher: {
        name: 'SEARCHER',
        LeftPanel_title_Searcher: 'Searcher',
        nutrition: {
          title: 'NUTRITION',
        },
        treatment: {
          title: 'TREATMENT',
        },
        irrigation: {
          title: 'IRRIGATION',
        },
        tasks: {
          title: 'TASKS',
        },
        weatherGraph: {
          title: 'WHEATER',
        },
        detail: {
          title: 'DETALLE',
        },
        observations: {
          observations_title: 'OBSERVATIONS',
          edit_observation_title: 'EDIT OBSERVATIONS',
          observations: 'Observations',
          category: 'Category',
          subcategory: 'Subcategory',
          description: 'Description',
          laboratory: 'Laboratory,',
          analysis: 'Type of Analysis',
          date: 'Date',
          add_observation_title: 'ADD OBSERVATION',
          detail_observation_title: 'OBSERVATION DETAIL',
          geolocation: 'Geolocation',
          multimedia_content_title: 'MULTIMEDIA CONTENT',
          file: 'File',
          attach_file_title: 'ATTACH FILE',
          attach_file: 'Attach file',
          select_file: 'Select file',
          preview_image_title: 'Preview file',
          register_observation_point_title: 'REGISTER OBSERVATION POINT',
          point: 'Point',
          area: 'Area',
          sector: 'Sector',
          name: 'Name',
          latitude: 'Latitude',
          longitude: 'Longitude',
          area_surface: 'Area',
          observation_detail_title: 'OBSERVATION DETAIL',
          observation_point: 'Observation point',
          template: 'Template',
          geolocationValidation: 'Geolocation is required',
          categoryValidation: 'Category is required',
          subcategoryValidation: 'Subcategory is required',
          laboratoryValidation: 'Laboratory is required',
          analysisValidation: 'Type of Analysis is required',
          drop_file: 'or Drop file here',
          dateValidation: 'Date is required',
          fillFormValidation: 'Fill in all the fields of the form',
          deleteMuestreoCategory: 'It is not allowed to delete Muestreo category',
          confirmDate: 'The analysis entered is outside the planting period. Do you wish to continue?',
        },
        gis: {
          gisTimeDimension: {
            title: 'Time line',
          },
          gisScanning: {
            title: 'Scanning',
          },
          gisWMS: {
            title: 'Radiometric index',
          },
        },
        nutritionalPlanActive: 'ACTIVE NUTRITIONAL PLAN',
        lastTreatmentPlan: 'LAST TREATMENT PLAN',
      },
      wrong_old_password: 'Wrong password',
      new_email_exist: 'User email already exists',
      invalid_farm_to_campaign: "Location not exist or location isn't valid to campaign",
      invalid_farm_to_plantation: "Location not exist or location isn't valid to plantation",
      invalid_location: "Location not exist or location isn't valid",
      invalid_sector_period:
        'It is not possible to assign two plantations to the same sector and the same date.',
      invalid_campaign: 'Campaign not exist or campaing is invalid',
      invalid_plantation: 'Plantation not exist or plantation is invalid',
      invalid_periods: 'Number of invalid periods for the selected period and date range',
      invalid_template: 'Template not exist or template is invalid',
      invalid_scouting_point: 'Scouting point not exist or scouting point is invalid',
      invalid_task_definition: 'Task definition not exist or task definition is invalid',
      parent_is_my_offspring: 'New parent is my offspring',
      template_not_owner: 'The template is not your property',
      date_range_invalid: 'Date range outside the plantation range',
      NO_MARKETS: 'No markets found',
      INVALID_MARKET: 'invalid market',
      INVALID_MARKET_FAMILY: 'invalid market family',
      DATE_REQUIRED: 'Date required',
      UNKNOWN_CONTACT: 'Contact not found',
      UNKNOWN_CLIENT: 'Client not found',
      UNKNOWN_PRODUCT: 'Product not found',
      PRODUCT_NOT_OWNER: 'The product is not your property',
      PRODUCT_CODE_EXIST: 'There is another product with the same code',
      required_locations: 'The plantation does not have an associated location',
      ACTIVO: 'Active',
      CANCELADO: 'Cancel',
      PLANIFICADO: 'Inactive',
      INACTIVO: 'Inactive',
      title_task_by_company: 'TASK',
      register_irrigation_title_datagridRecommendation: 'REGISTER IRRIGATION RECOMMENDATIONS',
      registes_irrigation_title_datagrid: 'REGISTER IRRIGATION ORDERS',
      irrigation_title_datagridRecommendation: 'IRRIGATION RECOMMENDATIONS',
      irrigation_title_datagrid: 'IRRIGATION ORDERS',
      location_code_exist: 'There is a explotation with the same name and registration number',
      treatment: {
        title: 'Treatment',
        treatmentPanel: {
          treatmentOrder: 'Treatment order',
          date: 'Date',
          description: 'Description',
          product: 'Product',
          status: 'Status',
          executed: 'Executed',
          planned: 'Planned',
          canceled: 'Canceled',
        },
        treatmentEditOrder: {
          form: {
            title: 'Edit Treatment Order',
            description: 'Description',
            dosage: 'Dosage',
            date: 'Fecha',
            plagueAgent: 'Plague / Agent',
            plagueAgentPlaceholder: 'Type to search...',
            treatmentType: 'Treatment Type',
            volume: 'Volume',
            lHa: 'L/ha',
            foliar: 'Foliar',
            fertigacion: 'Fertigación',
            product_validation: 'Product cannot be empty',
            description_validation: 'Description cannot be empty',
            date_validation: 'Date cannot be empty',
            treatmentType_validation: 'Treatment type cannot be empty',
            dosage_validation: 'Dosage cannot be empty',
            productResume_validation: 'There must be at least 1 product added in the list',
          },
          products: {
            title: 'Products',
            area: 'Area',
            ha: 'ha',
            productFinder: 'Product finder',
            typeToSearch: 'Type to search...',
            add: 'Add',
            product: 'Product',
            dosageRange: 'Dosage Range',
            dosageTotalL: 'Dosage (L / 1000L)',
            dosageTotalKg: 'Dosage (kg-L / ha)',
            price: 'Price (€/kg-L)',
            unitCost: 'Unit Cost (€/ha)',
            totalCost: 'Total Cost (€)',
            total: 'Total',
          },
          back: 'Back',
          cancel: 'Cancel',
          save: 'Save',
        },
        treatmentCreateOrder: {
          form: {
            title: 'Treatment Order Register',
            description: 'Description',
            dosage: 'Dosage',
            date: 'Fecha',
            plagueAgent: 'Plague / Agent',
            plagueAgentPlaceholder: 'Type to search...',
            treatmentType: 'Treatment Type',
            volume: 'Volume',
            lHa: 'L/ha',
            foliar: 'Foliar',
            fertigacion: 'Fertigación',
            product_validation: 'Product cannot be empty',
            description_validation: 'Description cannot be empty',
            date_validation: 'Date cannot be empty',
            treatmentType_validation: 'Treatment type cannot be empty',
            dosage_validation: 'Dosage cannot be empty',
            productResume_validation: 'There must be at least 1 product added in the list',
          },
          products: {
            title: 'Products',
            area: 'Area',
            ha: 'ha',
            productFinder: 'Product finder',
            typeToSearch: 'Type to search...',
            add: 'Add',
            product: 'Product',
            dosageRange: 'Dosage Range',
            dosageTotalL: 'Dosage (L / 1000L)',
            dosageTotalKg: 'Dosage (kg-L / ha)',
            price: 'Price (€/kg-L)',
            unitCost: 'Unit Cost (€/ha)',
            totalCost: 'Total Cost (€)',
            total: 'Total',
          },
          back: 'Back',
          cancel: 'Cancel',
          save: 'Save',
        },
        treatmentPlan: {
          title: 'Treatment Plan',
          description: 'Description',
          initDate: 'Init Date',
          endDate: 'End Date',
          products: 'Products',
          cost: 'Cost (€)',
          status: 'Status',
          changesMessage: 'Changes in this view will be propagated to the respective time units.',
          treatmentCreatePlan: {
            title: 'Treatment Plan Register',
            editTitle: 'Edit Trearment Plan',
            titleRegister: 'Treatment Register',
            description: 'Description',
            dosage: 'Dosage',
            dosageCuba: 'Dosage vat',
            volumeCuba: 'Volume per vat',
            speedCuba: 'Forward speed',
            volume: 'Volume',
            speed: 'Speed',
            temporalFrame: 'Temporal frame',
            plagueAgent: 'Plague / Agent',
            initDate: 'Init Date',
            endDate: 'End Date',
            annotations: 'Annotations',
            observations: 'Observations',
            productDosage: 'Product and Dosage',
            productFinder: 'Product finder',
            typeToSearch: 'Type to search...',
            add: 'Add',
            product: 'Product',
            emptyProducts: 'The product list cannot be empty',
            dosageRange: 'Dosage range',
            numApplication: 'No Applications',
            maxApplications: 'Max applications',
            totalApplications: 'Applications',
            totalDosage: 'Total dosage',
            productStack: 'Treatment plan (Products stack)',
            resume: 'Resume',
            area: 'Area',
            ha: 'ha',
            totalDosageL: 'Total dosage (L / 1000L)',
            caldoMedium: 'Vol. Caldo Medium (L / ha)',
            totalDosageKg: 'Total dosage (kg-L / ha)',
            cost: 'Cost (€/ha)',
            totalCost: 'Total cost (€)',
            description_validation: 'Description cannot be empty',
            agent_validation: 'Plague/Agent cannot be empty',
            dose_type_validation: 'Dosage cannot be empty',
            treatmentType: 'Treatment Type',
            application: 'Application',
            stepTwo: {
              doseVolumeHa: 'Dose (Kg-L/ha)',
              doseVolumeL: 'Dose (Kg-L/1000L)',
              doseTotal: 'Dose (kg-L Total)',
              doseVat: 'Dose (kg-L/vat)',
              doseVatTotal: 'Total Dose (kg-L/vat)',
              doseTree: 'Dose (g-ml)/plant',
              doseTreeTotal: 'Total Dose plant (g-ml)',
              doseKgL: 'Dose (Kg-L)',
              brothVolumeHa: 'Vol Broth (L/ha)',
              brothVolumeTree: 'Vol. Broth (L/plant)',
              brothTotalHa: 'Vol. Broth Total (l/ha)',
              brothTotalLitre: 'Vol. Caldo Total (L)',
              brothTotal: 'Vol. Broth Total',
              priceL: 'Price (€/kg-L)',
              priceVat: 'Price (€/vat)',
              costVatTotal: 'Total Cost/vat €',
              priceTree: 'Price/plant',
              costHa: 'Cost (€/ha)',
              cost: 'Cost (€)',
              costTotal: 'Total Cost',
              costTotalTree: 'Total Cost plant €',
              vatVolume: 'Vol. Broth',
              vatNumberHa: 'Number of vats/ha',
              vatNumber: 'Number of vats',
              speed: 'Forward Speed',
              totalDose: 'Total Dose',
            },
            forwardSpeedRequired: 'Forward speed is required.',
            vatVolumeRequired: 'Vat volume is required.',
          },
          treatmentDetailPlan: {
            title: 'Treatment Plan Detail',
            titleDetail: 'Treatment Order Detail',
            titleRegister: 'Treatment Detail',
            description: 'Description',
            dosage: 'Dosage',
            temporalFrame: 'Temporal frame',
            plagueAgent: 'Plague / Agent',
            initDate: 'Init Date',
            endDate: 'End Date',
            status: 'Status',
            productDosage: 'Product and Dosage',
            chartTitle: 'TREATMENT PLAN (PRODUCTS STACK)',
            doseTotal: 'Total Dose',
            annotation: 'Annotation',
            products: {
              title: 'Products',
              area: 'Area',
              ha: 'ha',
              productFinder: 'Product finder',
              typeToSearch: 'Type to search...',
              add: 'Add',
              product: 'Product',
              dosage: 'Dosis (L / 1000L)',
              caldoMedium: 'Vol. caldo medio (L / ha)',
              dosageTotalL: 'Dosage (L / 1000L)',
              dosageTotalKg: 'Dosage (kg-L / ha)',
              price: 'Price (€/kg-L)',
              unitCost: 'Unit Cost (€/ha)',
              totalCost: 'Total Cost (€)',
              total: 'Total',
            },
            back: 'Volver',
          },
        },
      },
      laboratoryAnalysis: {
        viewDetail: {
          date: 'Date',
          sampleType: 'Sample type',
          description: 'Description',
          interpretation: 'Interpretation',
          title_view_deatils: 'LABORATORY ANALYSIS DETAIL',
        },
      },
      registerPlantation: {
        title_register_plantation: 'PLANTATION REGISTER',
        plantationName: 'Plantation Name',
        cultive_type: 'Crop type',
        protected: 'Protection',
        manejo: 'Handling',
        init_date: 'Start date',
        end_date: 'End date',
        specie_botanic: 'Botanical species',
        plantation_frameX: 'Plantation frame X',
        plantation_frameY: 'Plantation frame Y',
        distance_between_streets: 'Distance between streets',
        distance_between_feets: 'Distance between feets',
        plants_density: 'Densidad de plantas',
        variety: 'Variedad',
        plants_number: 'Number of plants',
        plantation_date: 'Plantation date',
        cup_diameter: 'Cup diameter',
        planting_density: 'Seed density',
        unit: 'unit',
        required_plantation_name: 'The description of the plantation is required.',
        required_plantation_management: 'The management of the plantation is required.',
        required_cropType: 'The management of the plantation is required.',
        required_init_date: 'The start date is required.',
        required_end_date: 'The end date is required.',
        required_endDate_bigger: 'The end date cannot be less than the start date.',
        required_specie_selected: 'The species of the plantation is required.',
        required_plants_number: 'The number of plants is required.',
        required_plantation_variety: 'The variety is required.',
        required_plants_density: 'The density of planting is mandatory',
        popUpRegisterPlantation_title: 'Select the plot and the sector.',
        popUpRegisterPlantation_parcels: 'Parcels',
        popUpRegisterPlantation_sectors: 'Sectors',
        popUpRegisterPlantation_parcel: 'Parcel',
        popUpRegisterPlantation_sector: 'Sector',
        unit_plantas_ha: 'plants/ha',
        parcel_is_required: 'Parcel is required',
        sector_is_required: 'Sector is required',
        step1: 'STEP 1',
        step2: 'STEP 2',
        subtitle_step1: 'SECTOR SELECTION',
        subtitle_step2: 'CROP DEFINITION',
        plantation_register: 'You have successfully registered the plantation',
        plantation_register_edit: 'You have successfully edited the plantation',
        register_plantation_complete: 'PLANTACIÓN REGISTRADA',
        required_protection: 'The type of protection of the plantation is required.',
        required_unit: 'The unit is required',
        required_init_date_be_in_range:
          "Plantation start date can't be lower than the beginning of the selected campaign",
        required_end_date_be_in_range:
          "Plantation end date can't be bigger than the end of the selected campaign",
        required_element_x: 'The element X is required',
        required_distance_between_streets: 'The distance between streets is required',
        required_distance_between_feets: 'The distance between feets is required',
        required_cupDiameter: 'The cup diameter is required',
        required_date_plantation: 'The planting date is required.',
        title_select_campaign: 'Create plantation',
        subtitle_select_campaign: 'Select campaign for plantation creation.',
        required_farm: 'It is necessary to select a farm',
        required_parcel: 'It is necessary to select a parcel',
        required_sector: 'It is necessary to select a sector',
        required_campaign: 'It is necessary to select a campaign',
        expected_production: 'Expected production (t/ha)',
      },
      ViewPlantation: {
        viewPlantationTitle: 'PLANTING DETAIL',
        view_description: 'Description',
        view_campaign: 'Agricultural Campaign',
        view_status: 'Status',
        view_client: 'Client',
        view_init_date: 'Init date',
        view_end_date: 'End date',
        view_explotation: 'Explotation',
        view_name_parcel: 'Parcel name',
        view_name_sector: 'Sector name',
        view_superficie: 'Surface',
        view_textura_suelo: 'Soil texture',
        view_system_irrigation: 'Irrigation system',
        view_caudal: 'Flow rate',
        view_goteros: 'Drippers per plant',
        view_specie: 'Botanical species',
        view_variety: 'Variety',
        view_crop_type: 'Crop Type',
        view_protection: 'Protection',
        view_management: 'Management',
        view_date_plantation: 'Planting date',
        view_planting_frame: 'Planting frame',
        view_planting_unit: 'Unit',
        view_planting_density: 'Planting density',
        view_density_plants: 'Plant density',
        view_number_plants: 'Number of plants',
        view_cup_diameter: 'Cup diameter',
        goToSearcher: 'Searcher',
        view_number_sprinkler_number: 'Number of sprinklers',
        plants_ha_unit: 'plants/ha',
        expected_production: 'Expected production',
      },
      DxRegisterCampania_actual_plot: 'The explotation is required',
      blank_agent: 'The product is required',
      Not_controled: 'Unexpected error. If the error continues, contact support. Sorry for the inconvenience',
      token_not_valid: 'The session has expired',
      Next: 'Next',
      next: 'Next',
      save: 'Save',
      parcel: {
        register: {
          title: 'PARCEL REGISTER',
          name: {
            field: 'Parcel name',
            caption: 'Parcel name',
            placeholder: 'Parcel name',
            requiredRule: 'Name is required',
          },
          geofeatures: {
            requiredRule: 'The parcel must have an associated polygon',
          },
          step1: 'Step 1',
          step2: 'Step 2',
          registeredParcel: 'REGISTERED PARCEL',
          completedSuccessfullyParcelRegister: 'You have completed the parcel successfully',
          completedSuccessfullyParcelUpdated: 'You have successfully updated the parcel.',
        },
        detail: {
          title: 'PARCEL DETAIL',
          client: 'Client',
          location: 'Location',
          parcelName: 'Parcel name',
          area: 'Area',
        },
      },
      sector: {
        register: {
          title: 'REGISTER SECTOR',
          step1: 'Step 1',
          step2: 'Step 2',
          sectorName: 'Sector name',
          groundTexture: 'Gound texture',
          irrigationType: 'Irrigation type',
          flowPerPlant: 'Flow per dropper (L/h)',
          dripperPerPlant: 'Dripper per plant (Units)',
          sprinklersNumber: 'Number of sprinklers (Units)',
          flow360: 'Flow 360º (l/h)',
          transformedArea: 'Transformed area (m²)',
          separationBetweenLines: 'Separation between sprinkler lines (m)',
          separationBetweenSprinkler: 'Separation between sprinklers in the same line (m)',
          flow: 'Flow (L/h)',
          formValidation: 'There are unfilled fields',
          registerValidation:
            'The operation could not be completed, please do the registration process again',
          gisValidation: 'Error drawing the sector, please draw the desired polygon again',
          registeredSector: 'REGISTERED SECTOR',
          completedSuccessfullySectorRegister: 'You have successfully registered the sector.',
          completedSuccessfullySectorUpdate: 'You have successfully updated the sector.',
          finish: 'Finish',
          required_name: 'Sector name is required',
          required_groundTexture: 'It is necessary to select the ground texture',
          required_irrigationType: 'It is necessary to select the irrigation type',
          required_flowPerPlant: 'It is necessary to specify the flow per dropper',
          required_dripperPerPlant: 'It is necessary to specify the dripper per plant',
          required_sprinklersNumber: 'It is necessary to specify teh number of sprinklers',
          required_transformedArea: 'It is necessary to specify the transformed area',
          requiredGeometry: 'Sector must have an associated geometry',
        },
        autoSector: {
          promptHeader: 'First sector',
          prompt: 'Do you want to assign the entire area of ​​the plot to the sector?',
          no: 'No',
          yes: 'Yes',
        },
        grid: {
          registeredSectors: 'REGISTERED SECTORS',
          sectorName: 'Sector name',
          groundTexture: 'Gound texture',
          irrigationType: 'Irrigation type',
          area: 'Area (ha)',
          createSector: 'Create sector',
        },
        detail: {
          title: 'SECTOR DETAIL',
          client: 'Client',
          location: 'Location',
          parcelName: 'Parcel name',
          sectorName: 'Sector name',
          area: 'Area',
          groundTexture: 'Gound texture',
          irrigationType: 'Irrigation type',
          flow: 'Flow',
          dripperPerPlant: 'Dripper per plant',
          sprinklersNumber: 'Number of sprinklers',
          transformedArea: 'Transformed surface',
          separationBetweenLines: 'Separation between sprinkler lines',
          separationBetweenSprinkler: 'Separation between sprinklers in the same line',
          m: 'm',
          m2: 'm²',
          lH: 'L/h',
        },
      },
      plantations: {
        grid: {
          plantations: 'PLANTATIONS',
          botanicSpecie: 'Botanic specie',
          campaign: 'Campaign',
          initDate: 'Init date',
          endDate: 'End date',
          status: 'Status',
          active: 'Active',
          end: 'Finished',
          pending: 'Pending',
          createPlantation: 'Create plantation',
        },
        delete_title: 'DELETE PLANTATION',
        delete_message:
          'Do you want to delete this plantation? All associated information will be lost and cannot be accessed again.',
        confirmDateChange:
          'The plantation has activity records. This change may affect them and it may be necessary to update them manually.',
      },
      select_placeholder: 'Select...',
      sectors: 'Sectors',
      parcels: 'Parcels',
      Temperature: 'Temperature',
      Precipitation: 'Precipitation',
      Evaporacion: 'Evapotranspiration',
      name: 'Name',
      weather_client: 'Client',
      weather_client_placeholder: 'Select client',
      weather_plantation: 'Plantation',
      GISGeoJsonMap_not_observations: 'An observation is required',
      GISGeoJsonMap_not_locations: 'Parcel is required',
      client: 'Client',
      area: 'Area',
      Register_fertilization_plan: 'Register fertilization plan',
      nutrition_reference_value_info: 'Unidades Fertilizantes (UF) Recomendadas (kg/ha)',
      nutrition_needs_info: 'Unidades Fertilizantes (UF) (kg/ha)',
      requiredNutritionFertiliser: "Fertiliser field can't be empty",
      GIS: 'GIS',
      layerOptions_select_index: 'Index',
      formAction_Alert_EnableGPS: 'You must grant location permissions and enable GPS',
      searchEngine_gis_view: 'There are no exploitations near your current position',
      Explotation: 'Explotation',
      Parcel: 'Parcel',
      Sector: 'Sector',
      Plantation: 'Plantation',
      Go: 'Go',
      type: 'Type',
      date: 'Date',
      action: 'Action',
      PUT: 'Update',
      POST: 'Create',
      DELETE: 'Delete',
      Register: {
        resume: {
          title: 'REGISTER RESUME',
        },
        type: {
          Fertilization: 'Nutrition plan',
          Campaign: 'Campaign',
          Climate: 'Weather',
          FertilizationPlan: 'Nutrition plan',
          Irrigation_recommendation: 'Irrigation recommendation',
          Irrigation_register: 'Irrigation register',
          Irrigation_register_doses_valitaion: 'Irrigation dose cannot be empty',
          LaboratoyAnalisis: 'Laboratoy Analisis',
          Location: 'Parcel system',
          Plantation: 'Plantation',
          Product: 'Product',
          ScoutingPoint: 'Scouting point',
          Scouting: 'Scouting',
          TaskDefinition: 'Task definition',
          Task: 'Task',
          Template: 'Template',
          TreatmentOrder: 'Treatment order',
          TreatmentPlan: 'Treatment plan',
          Variety: 'Variety',
        },
      },
      Plans: {
        description: 'Description',
        title: 'ANALYSIS > PLANS',
        LeftPanel_subtitle_plans: 'Plans',
        planName: 'Plan name',
        crop: 'Crop',
        variety: 'Variety',
        province: 'Province',
        cost: 'Cost €/ha',
        timeFrame: 'Time frame',
        atcOwner: 'ATC Owner',
        fertilizations: 'Fertilizations',
        treatments: 'Treatments',
        details: 'DETAILS',
      },
      DxSales_species: 'Species',
      register_location_required_province: 'It is necessary to select a province',
      register_location_required_locality: 'It is necessary to select a locality',
      register_location_postalCode: 'Postal code',
      register_location_placeholder_postalCode: 'Enter your postcode',
      Login_Azure: 'Login with Azure',
      Gis: {
        Editor: {
          PopupGetGeometry: 'Get geometry',
          PopupMeasureResult: 'Measure',
          PopupMeasureHas: 'Hectares',
          PopupMeasureKm: 'Kilometres',
          PopupMeasureArea: 'Area',
          PopupMeasureLineString: 'Linear distance',
          SectorOverlaps: 'The created sector overlaps other already existing sectors.',
        },
        Buttons: {
          ZoomHome: 'Zoom Home',
          CurrentPosition: 'Current position',
          DownloadImage: 'Download layer image',
          CoordinatesSearcher: 'Coordinate searcher',
          SigpacSearcher: 'SIGPAC aearcher',
          MeasureArea: 'Measure area',
          MeasureLineString: 'Measure linear distance',
          DesactiveMeasureMode: 'Desactivate measure mode',
          ActiveMeasureMode: 'Activate measure mode',
          ActiveMergeMode: 'Activate merge mode',
          ConfirmMerge: 'Confirm merge',
          DesactiveMergeMode: 'Desactivate merge mode',
          Opacity: 'Change opacity layer',
          Zoning: 'Change range value layer',
          SearcherGeometries: 'Search on this area',
        },
        Alerts: {
          AdjacentGeometry: 'The selected polygon is not adjacent to the selected geometries',
          NumberExceededGeometries: 'Exceeded number of selected geometries',
          NumberMinimumPlots: 'Select more than one plot',
          NoPlotsInRange: 'There are no plots for the selected range',
          downloadingImage: 'Downloading layer image',
        },
        SigpacReference: 'SIGPAC Reference',
        Province: 'Province',
        Municipality: 'Municipality',
        Aggregate: 'Aggregate',
        Zone: 'Zone',
        Polygon: 'Polygon',
        Plot: 'Plot',
        Enclosure: 'Enclosure',
        Search: 'Search',
        GeometryNotFound:
          'The values entered do not belong to any SIGPAC site. Please check the values entered.',
        SigpacFormIncomplete: 'Values must be entered in all fields. Please redo the search.',
      },
      irrigation_graph_precipitation: 'Accumulated precipitation',
      irrigation_graph_evaporation: 'Reference evaporation',
      Humidity: 'Humidity',
      SolarRadiation: 'Solar Radiation',
      WindSpeed: 'Wind Speed',
      error_range_date:
        'The maximum date range of the request is 30 days. Please adjust the dates and retry the query.',
      weather_agrupation_data: 'Data grouping:',
      weather_parameter: 'Parameter',
      weather_parameter_value: 'Value',
      price: 'Price',
      treatmentError: {
        error: 'of the plan have been withdrawn from the catalog, please check the dosage',
      },
      irrigationAnalysis: {
        eto: 'ETO(Accumulated)',
        irrigation: 'Irrigation(Accumulated)',
        pluviometry: 'Pluviometry(Accumulated)',
      },
      product: 'Product',
      AnalysisGis: {
        LeftPanel_subtitle_Gis: 'GIS',
        title: 'ANALYSIS > GIS',
        compare: 'Compare',
        left: 'Left',
        right: 'Right',
      },
      id_popup: 'ID',
      parcel_popup: 'Parcel',
      sector_popup: 'Sector',
      name_popup: 'Name',
      date_popup: 'Date',
      category_popup: 'Category',
      subcategory_popup: 'Subcategory',
      explotation_popup: 'Explotation',
      company_id_popup: 'Company Id',
      dateRange: 'Dates range',
      information: 'INFORMATION',
      histogram: 'HISTOGRAM',
      timeline: 'TIMELINE',
      index: 'Index',
      count: 'Count',
      class: 'Class',
      mean: 'Mean',
      MeasuringFrequency: {
        HOURLY: 'HOURLY',
        DAILY: 'DAILY',
      },
      WeatherParameter: {
        TEMPERATURE: 'TEMPERATURE AVG',
        TEMPERATURE_MIN: 'TEMPERATURE MIN',
        TEMPERATURE_MAX: 'TEMPERATURE MAX',
        PLUVIOMETER: 'PLUVIOMETER',
        HUMIDITY_AVG: 'HUMIDITY AVG',
        HUMIDITY_MAX: 'HUMIDITY MAX',
        HUMIDITY_MIN: 'HUMIDITY MIN',
        HUMIDITY: 'HUMIDITY AVG',
        WIND_SPEED: 'WIND SPEED AVG',
        WIND_SPEED_AVG: 'WIND SPEED AVG',
        WIND_SPEED_MAX: 'WIND SPEED MAX',
        EVAPO_ETO_PEMON: 'ET0',
        CLOUD_COVER: 'CLOUD COVER',
        SOLAR_RADIATION: 'SOLAR RADIATION AVG',
      },
      Sync: {
        InfoUpdate: {
          Title: 'Actualización de datos',
          Text: 'La sincronización con el servidor es necesaria para que puedas seguir trabajando incluso sin conexión.',
        },
        InfoLoading: {
          Title: 'Cargando datos…',
          Text: 'Este proceso puede durar unos minutos, según la velocidad de la conexión del dispositivo.',
        },
        TextError: 'Error:',
        ButtonSync: 'Sincronizar',
      },
      weekDays: {
        monday: 'monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
      },
      chooseDatesError: 'You must choose dates before filtering',
      pendingRegister: 'Registration pending finalization',
      pendingRegisterMessage: 'If you do not complete the process you will lose your unsaved changes.',
      pendingObservationMessage: 'If you do not save the observation, you will lose the sample ID, making it necessary to generate a new one.',
      continue: 'Continue',
      abandon: 'Abandon',
      months: 'Months',
      years: 'Years',

      cropAge: 'Crop age',
      competence: 'Competence',
      ContextMenu: {
        Delete: 'Delete',
        Edit: 'Edit',
        Copy: 'Copy',
        Download: 'Download',
        Share: 'Share',
        saveReferencePlan: 'Save Reference Plan',
      },
      noDataInRange: 'There is no data for the selected range',
      BoundsAreNotValid: 'There is no geometry to display. Please, register it with the form.',
      multimediaFileError: 'Multimedia file not supported.',
      sampleIdDuplicate: 'The id is already duplicated, generating a new one',
      productDeleted: 'The product has been deleted. Please replace it with a new one.',
      productsDeleted: 'There are products that have been eliminated. Please replace them with new ones',
      dates: 'Dates',
      attributes: 'Attributes',
      warning: 'WARNING',
      enclosureSigpacTable: {
        title: 'SIGPAC overlapping enclosures',
        reference: 'Reference of the enclosure',
        overlap: 'Overlapping surface',
        enclosure: 'Enclosure area',
      },
      DateRangeInvalidPlan: 'The dates in the plan are not valid for the plantation.',
      itemsToDisplay: 'ITEMS TO DISPLAY',
      weatherVariables: 'WEATHER VARIABLES',
      comments: 'COMMENTS',
      availableDates: 'Available Dates',
      limitSelectedDates: 'You can only select 6 dates',
      imagesToDisplay: 'IMAGES TO BE DISPLAYED',
      downloadingPDF: 'Downloading PDF',
      generatingPDF: 'Generating PDF for download',
      generatingPDFShare: 'Generating PDF for share',
      generatingFileForShare: 'Generaring file for share',
      fileDownloadError: 'Download Failed',
      fileSharedSuccessfully: 'File shared succesfully',
      fileShareNotSupported: 'Your browser does not support file sharing',
      observationsToBeDisplayed: 'OBSERVATIONS TO BE DISPLAYED',
      viewParcel: 'View parcel',
      viewSector: 'View sector',
      searching: 'Searching...',
      updateRequired: 'The app must be updated',
      unitaries: 'Unitaries',
      totals: 'Totals',
      noLeftHistogram: 'There is no data to display in the left histogram',
      noRightHistogram: 'There is no data to display in the right histogram',
      dateRangeSelector: 'DATE RANGE SELECTOR',
      noImagesToDisplayLeft: 'There is no data to display in the left image',
      noImagesToDisplayRight: 'There is no data to display in the right image',
      weatherWidget: {
        icon_200: 'Thunderstorm with light rain',
        icon_201: 'Thunderstorm with rain',
        icon_202: 'Thunderstorm with heavy rain',
        icon_230: 'Thunderstorm with light drizzle',
        icon_231: 'Thunderstorm with drizzle',
        icon_232: 'Thunderstorm with heavy drizzle',
        icon_233: 'Thunderstorm with Hail',
        icon_300: 'Light Drizzle',
        icon_301: 'Drizzle',
        icon_302: 'Heavy Drizzle',
        icon_500: 'Light Rain',
        icon_501: 'Moderate Rain',
        icon_502: 'Heavy Rain',
        icon_511: 'Shower rain',
        icon_520: 'Light shower rain',
        icon_521: 'Shower rain',
        icon_522: 'Heavy shower rain',
        icon_600: 'Light snow',
        icon_601: 'Snow',
        icon_602: 'Heavy snow',
        icon_610: 'Mix snow/rain',
        icon_611: 'Sleet',
        icon_612: 'Heavy sleet',
        icon_621: 'Snow shower',
        icon_622: 'Heavy snow shower',
        icon_623: 'Flurries',
        icon_700: 'Mist',
        icon_711: 'Smoke',
        icon_721: 'Haze',
        icon_731: 'Sand/dust',
        icon_741: 'Fog',
        icon_751: 'Freezing Fog',
        icon_800: 'Clear sky',
        icon_801: 'Few clouds',
        icon_802: 'Scattered clouds',
        icon_803: 'Broken clouds',
        icon_804: 'Overcast clouds',
        icon_900: 'Unknown Precipitation',
      },
      targetCampaign: 'Target campaign',
      elementsToCopy: 'Elements to copy',
      plantationDate: 'Plantation date',
      copyNutritionalPlans: 'Nutritional plans',
      copyTreatmentPlans: 'Treatment plans',
      copyIrrigationPlans: 'Irrigation plans',
      copyAnalytics: 'Analytics',
      copyingPlantation: 'Copying plantation',
      copyingNutritionalPlans: 'Copying nutritional plans',
      copyingTreatmentPlans: 'Copying treatment plans',
      copyingIrrigationPlans: 'Copying irrigation plans',
      copyingAnalytics: 'Copying analytics',
      useParcelGeometry: 'Use parcel geometry for sector',
      weekFirstLetter: 'W',
      weekFullName: 'Week',
      monthFullName: 'Month',
      monthShortToFull: {
        Jan: 'January',
        Feb: 'February',
        Mar: 'March',
        Apr: 'April',
        May: 'May',
        Jun: 'June',
        Jul: 'July',
        Aug: 'August',
        Sep: 'September',
        Oct: 'October',
        Nov: 'November',
        Dec: 'December',
      },
      monthFull: {
        January: 'January',
        February: 'February',
        March: 'March',
        April: 'April',
        May: 'May',
        June: 'June',
        July: 'July',
        August: 'August',
        September: 'September',
        October: 'October',
        November: 'November',
        December: 'December',
      },
      temperature: 'Temperature',
      humidity: 'Humidity',
      solar_radiation: 'Solar radiation',
      wind_speed: 'Wind speed',
      pluviometer: 'Precipitation',
      average: 'Average',
      forecasted: 'Forecasted',
      averageForecasted: 'Average Forecasted',
      minForecasted: 'Predictive Minimum',
      maxForecasted: 'Maximum Predictive',
      totalForecasted: 'Total Forecasted',
      gisNotEnoughTime: 'Remote sensing image processing running. They will be available soon',
      copySuccess: 'Copy success',
      active_campaign: 'Active',
      finished_campaign: 'Finished',
      pending_campaign: 'Pending',
      navigatorShareNotSupported: "Your browser doesn't support the Web Share API",
      sharePdfSuccess: 'Successfully shared',
      sharePdfError: 'Error sharing',
      confirmSharePdf: 'Do you want to share the Report?',
      downloadReportButton: 'Download',
      downloadReportError: 'Error in the endpoint GIS: No data',
      shareReportButton: 'Share',
      noClimateData: 'There is no meteorological data for the plantation',
      gisGenericError: 'Problem loading GIS data. Please try again later.',
      ACTIVA: 'ACTIVE',
      FINALIZADA: 'FINISHED',
      PENDIENTE: 'PENDING',
      noObservationsToSelect: 'There are no observations to select',
      noTasksToSelect: 'There are no tasks to select',
      copyPlantation: 'Copy plantation',
      noDateRangeSelected: 'There is no date range selected',
      mustSelectPoint: 'A point on the map must be selected',
      thumbnail: 'Thumbnail',
      applicationEfficiencyPerPeriod: 'Application of efficient UFs in each period',
      modalCopyReferencePlan_info_treatment: 'Select the start date of the treatment plans that you are going to generate',
      modalCopyReferencePlan_info_nutrition: 'Select the start date of the nutrition plans you are going to generate',
      modalCopyReferencePlan_info_reference: 'Select the start date of the reference plans you are going to generate',
      exclusionDates: {
        list: 'Exclusion dates',
        add: 'Add exclusion date',
        format: 'aaaa-mm-dd',
      },
      mapbox: {
        controls: {
          home: 'Home',
          style: {
            satelliteStreets: 'Satellite with streets',
            light: 'Light',
            dark: 'Dark',
            streets: 'Streets',
            outdoors: 'Outdoors',
          },
          sigpac: 'SIGPAC',
          removeLastVertex: 'Remove last vertex',
          errorRemoveLastVertex: {
            not_polygon_selected: 'The selected geometry is not a polygon',
            feature_selected_is_not_polygon: 'The selected geometry is not a polygon',
            must_have_more_4_vertices: 'The polygon must have more than 4 vertices',
          }
        },
        sigpac: {
          popup: {
            buttonLabel: 'Get geometries',
          },
          form: {
            invalidValues: 'Form values, empty or invalid',
            noResults: 'There are no plots with the information you provided',
            buttonSearch: 'Search SIGPAC',
            buttonShow: 'Show SIGPAC search',
            buttonHide: 'Hide SIGPAC search',
            provinceRequired: 'The province is required when you select a municipality',
            municipalityRequired: 'The municipality is required when you select an aggregate',
            aggregateRequired: 'The aggregate is required when you select a zone',
            zoneRequired: 'The zone is required when you select a polygon',
          },
        },
        measurement: {
          area: 'Area',
          distance: 'Distance',
          controlTitle: 'Active measurement mode',
          lineControlTitle: 'Measure linear distance',
          areaControlTitle: 'Measure area',
          closeControlTitle: 'Close measurement mode',
        },
      },
      properties: {
        agronomic_zone: "Agronomic zone",
        country: "Country",
        explotation: "Exploitation",
        locality: "Location",
        mobile: "Mobile",
        number_register: "Registration number",
        owner_Farm: "Owner",
        parcel: "Plot",
        postal_code: "ZIP CODE",
        province: "Province",
        municipality: "Municipality",
        aggregate: "Aggregate",
        zone: "Zone",
        polygon: "Polygon",
        enclosure: "Enclosure",
        dnOid: "Reference SIGPAC",
        sector: "Sector",
      },
      popupSelectObservationsTaskReport: {
        initDate: 'Init date',
        endDate: 'End date',
        date: 'Date',
        confirm: 'Confirm',
        cancel: 'Cancel',
        tasks: 'Tasks',
        observations: 'Observations'
      }
    },
  },
};
export default dictionary;

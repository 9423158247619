<template>
  <div v-if="!loading">
    <DxPopup
      :visible="showPopUp"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :on-hidden="onHidden"
      :show-title="true"
      :show-close-button="true"
      width="750px"
      height="auto"
      :title="isEditing ? $t('Edit_product') : $t('Register_product')"
    >
      <DxForm>
        <DxGroupItem
          :col-count="1"
        >
          <DxGroupItem
            :col-count="1"
          >
            <DxSimpleItem
              :data-field="$t('DxProduct_nameCommercial')"
              :editor-options="{
                value: actualName,
                onValueChanged: onChangeName,
              }"
            >
              <DxRequiredRule :message="$t('Field_Required')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('DxProduct_tipology')"
              editor-type="dxSelectBox"
              :editor-options="{
                value: actualTipology,
                items: itemsTipology,
                valueExpr: 'code',
                displayExpr: 'name',
                onValueChanged: onChangeTipology,
              }"
            >
              <DxRequiredRule :message="$t('Field_Required')" />
            </DxSimpleItem>

            <DxSimpleItem
              :data-field="$t('DxProduct_presentation')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: ['SOLIDO', 'LIQUIDO'],
                value: actualPresentation,
                onValueChanged: onChangePresentation,
              }"
            >
              <DxRequiredRule :message="$t('Field_Required')" />
            </DxSimpleItem>

            <DxSimpleItem
              v-if="actualPresentation === 'LIQUIDO'"
              :data-field="$t('DxProduct_density')"
              editor-type="dxNumberBox"
              :editor-options="{
                value: actualDensity,
                onValueChanged: onChangeDensity,
              }"
            >
              <DxRequiredRule :message="$t('Field_Required')" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem />
        </DxGroupItem>
        <DxGroupItem :col-count="2">
          <DxSimpleItem
            name="mainElement"
          >
            <template #default>
              <div>
                <p>{{ $t('DxProduct_mainElement') }}</p>
                <DxDataGrid
                  id="MainElement"
                  :show-borders="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :column-auto-width="true"
                  :data-source="mainElement"
                  :customize-columns="customizeColumns"
                  @row-updated="onUpdatedMainElement"
                >
                  <DxEditing
                    :allow-updating="true"
                    :select-text-on-edit-start="selectTextOnEditStart"
                    :start-edit-action="startEditAction"
                    mode="cell"
                  />
                </DxDataGrid>
              </div>
            </template>
          </DxSimpleItem>
          <DxSimpleItem
            name="alterElement"
            editor-type="dxNumberBox"
          >
            <template #default>
              <div>
                <p>{{ $t('DxProduct_secondaryElement') }}</p>
                <DxDataGrid
                  id="SecondaryElement"
                  :show-borders="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :column-auto-width="true"
                  :data-source="alterElement"
                  :customize-columns="customizeColumns"
                  @row-updated="onUpdatedAlterElement"
                >
                  <DxEditing
                    :allow-updating="true"
                    :select-text-on-edit-start="selectTextOnEditStart"
                    :start-edit-action="startEditAction"
                    mode="cell"
                  />
                </DxDataGrid>
              </div>
            </template>
          </DxSimpleItem>
        </DxGroupItem>
        <DxSimpleItem
          name="microElement"
          editor-type="dxNumberBox"
        >
          <template #default>
            <div class="micro-element">
              <p>{{ $t('DxProduct_microelements') }}</p>
              <DxDataGrid
                id="Microelements"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :column-auto-width="true"
                :data-source="microElement"
                :customize-columns="customizeColumns"
                @row-updated="onUpdatedMicroElement"
              >
                <DxEditing
                  :allow-updating="true"
                  :select-text-on-edit-start="selectTextOnEditStart"
                  :start-edit-action="startEditAction"
                  mode="cell"
                />
              </DxDataGrid>
            </div>
          </template>
        </DxSimpleItem>
      </DxForm>
      <div
        class="margin-top no-padding margin-bottom-block"
      >
        <f7-row>
          <f7-col />
          <f7-col>
            <f7-button
              class="dx-btn-success"
              raised
              type="success"
              styling-mode="contained"
              @click="checkOperationToDo"
            >
              {{ $t('Button_save_text') }}
            </f7-button>
          </f7-col>
        </f7-row>
      </div>
    </DxPopup>
  </div>
</template>

<script>

import Api from '../../../../services/Api';
import { mapActions, mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';
import {
  DxForm, DxSimpleItem, DxGroupItem, DxRequiredRule,
} from 'devextreme-vue/form';
import { DxDataGrid, DxEditing } from 'devextreme-vue/data-grid';

export default {
  name: 'ProductFormPopup',
  components: {
    DxPopup,
    DxDataGrid,
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEditing,
    DxRequiredRule,
  },
  data() {
    return {
      selectTextOnEditStart: true,
      startEditAction: 'click',
      itemsTipology: [],
      loading: true,
    };
  },
  computed: {
    ...mapState('Product', ['showPopUp', 'isEditing', 'actualName', 'actualTipology', 'actualEcology',
      'mainElement', 'alterElement', 'microElement', 'actualPresentation', 'actualDensity']),
  },
  mounted() {
    this.fetchItemsTipology();
    this.initStore();
  },
  methods: {
    async fetchItemsTipology() {
      this.loading = true;
      try {
        this.$f7.preloader.show();
        const xhr = await Api.getElementsByQueryParams('contact/product_typology');
        const productTypology = JSON.parse(xhr.response).tipologies;
        this.itemsTipology = productTypology;
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
        this.loading = false;
      }
      
    },
    customizeColumns(columns) {
      for (const column of columns) {
        column.dataType = 'number';
        column.caption = this.getCaption(column.dataField);
      }
    },
    getCaption(key) {
      const elements = {
        NT: 'N',
        P2015_Total: 'P₂O₅',
        K2O: 'K₂O',
        CaO: 'CaO',
        MgO: 'MgO',
        SO3: 'SO₃',
        MO: 'Mo',
      };

      return `${elements[key] ?? key} (% p/p)`;
    },
    onHidden() {
      this.initStore();
      this.setShowPopUp(false);
    },
    onChangeName(e) {
      this.setActualName(e.value);
    },
    onChangeTipology(e) {
      this.setActualTipology(e.value);
    },
    onChangePresentation(e) {
      this.setActualPresentation(e.value);
    },
    onChangeDensity(e) {
      this.setActualDensity(e.value);
    },
    onChangeEcology(e) {
      this.setActualEcology(e.value);
    },
    onUpdatedMainElement(e) {
      this.setMainElement([e.data]);
    },
    onUpdatedAlterElement(e) {
      this.setAlterElement([e.data]);
    },
    onUpdatedMicroElement(e) {
      this.setMicroElement([e.data]);
    },
    checkOperationToDo() {
      if (this.actualName === '' || this.actualTipology === '' || this.actualPresentation === '') {
        this.$notifyDX(
          {
            message: this.$t('Fields_Required'),
            width: 550,
          },
          'info',
          3000,
        );
      } else if (this.isEditing) {
        this.callToUpdateProduct();
      } else {
        this.createNewProduct();
      }
    },
    async createNewProduct() {
      if (this.actualPresentation === 'LIQUIDO' && this.actualDensity === '') {
        this.$notifyDX(
          {
            message: this.$t('Fields_Required'),
            width: 550,
          },
          'info',
          3000,
        );
        return;
      }

      this.$f7.preloader.show();
      try {
        const newProduct = {
          code: `${this.actualName}001`,
          name: this.actualName,
          NT: this.mainElement[0].NT,
          P2015_Total: this.mainElement[0].P2015_Total,
          K2O: this.mainElement[0].K2O,
          CaO: this.alterElement[0].CaO,
          MgO: this.alterElement[0].MgO,
          SO3: this.alterElement[0].SO3,
          B: this.microElement[0].B,
          Cu: this.microElement[0].Cu,
          Fe: this.microElement[0].Fe,
          Mn: this.microElement[0].Mn,
          Zn: this.microElement[0].Zn,
          Se: this.microElement[0].Se,
          MO: this.microElement[0].MO,
          I: this.microElement[0].I,
          status: true,
          tipology: this.actualTipology,
          presentation: this.actualPresentation,
          density: this.actualPresentation === 'LIQUIDO' ? parseFloat(this.actualDensity.toFixed(2)) : 1,
          ecologic: this.actualEcology,
        };
        await this.createProduct(newProduct);

        this.$f7.views.main.router.navigate('/products/', { reloadCurrent: true });
      } catch (error) {
        if (error.message.includes('500')) {
          this.$notifyDX(
            {
              message: this.$t('error_500'),
              width: 550,
            },
            'error',
            3000,
          );
        } else {
          this.$notifyDX(
            {
              message: this.$t(`${this.$helpers.getError(error)}`),
              width: 550,
            },
            'error',
            3000,
          );
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async callToUpdateProduct() {
      if (this.checkTypeOfPropertiesObject(this.mainElement[0])
        && this.checkTypeOfPropertiesObject(this.alterElement[0])
        && this.checkTypeOfPropertiesObject(this.microElement[0])) {
        this.$f7.preloader.show();
        try {
          await this.updateProduct();
        } catch (error) {
          const message = this.$helpers.getFilteredErrorMessage(error);
          if (message.includes('default_') && !message.includes('404')) {
            this.$notifyDX(
              {
                message: message.split('_').pop(),
                width: 550,
              },
              'error',
              3000,
            );
          } else if (!message.includes('404')) {
            this.$notifyDX(
              {
                message: this.$t(message),
                width: 550,
              },
              'error',
              3000,
            );
          }
        } finally {
          this.$f7.preloader.hide();
          this.$f7.views.main.router.navigate('/products/', { reloadCurrent: true });
        }
      } else {
        this.$notifyDX(
          {
            message: this.$t('Fields_Required'),
            width: 550,
          },
          'info',
          3000,
        );
      }
    },
    checkTypeOfPropertiesObject(object) {
      for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
          if (typeof object[key] !== 'number') {
            return false;
          }
        }
      }
      return true;
    },
    ...mapActions('Product', ['setShowPopUp', 'setName', 'setMainElement',
      'setAlterElement', 'setMicroElement', 'setActualName', 'setActualTipology', 'setActualEcology',
      'initStore', 'setActualEcology', 'createProduct', 'updateProduct', 'setActualPresentation', 'setActualDensity']),
  },
};
</script>
<style lang="scss" scoped>
@import './ProductFormPopup.styles.scss';
</style>

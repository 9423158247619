<template>
  <f7-block>
    <f7-row>
      <f7-col width="60">
        <TreatmentPlanComponent />
      </f7-col>
      <f7-col width="40">
        <GISWMSMap
          :zoom="zoom"
          :name-map="mainMap"
          :layer="layer"
          :index="'NDRE'"
          :center="center"
          :bbox="bbox"
          :height="'445px'"
          :companyId="currentPlantation.companyId"
        />
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GISWMSMap from '../../Gis/GISWMSMap/index.vue';
import TreatmentPlanComponent from '../../wizardTreatment/treatmentPlan/index.vue';
import Api from '../../../services/Api';

export default {
  name: 'Treatment',
  components: {
    GISWMSMap,
    TreatmentPlanComponent,
  },
  data() {
    return {
      loadedGIS: false,
      zoom: 17,
      mainMap: 'treatment',
      layer: {
        name: 'treatment',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpace(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: '',
        },
      },
      center: {},
      ws: Api.getGeoServerWorkspace(),
      bbox: {},
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation', 'treeLocations']),
    ...mapState('Gis', ['currentBoundsWMSLayer']),
  },
  async beforeMount() {
    this.$fa.setScreenName('Buscador > Tratamiento');
    try {
      this.layer.options.layers = `${this.ws}:${this.currentPlantation.sectorId}`;
      this.center = { ...this.currentPlantation.coordinates };
      await this.fetchLocationsByPlantation();
      await this.boundsWMSLayer(`timacagro:${this.currentPlantation.sectorId}`);
      this.bbox = { ...this.currentBoundsWMSLayer.coverage.latLonBoundingBox };
      this.center = { ...this.currentPlantation.coordinates };
      this.loadedGIS = true;
    } catch (error) {
      if (error.message.includes('Unexpected token')) {
        // this.$notifyDX(
        //   {
        //     message: this.$t('Problema al cargar índice GIS'),
        //     width: 550,
        //   },
        //   'warning',
        //   3000,
        // );
        return;
      }

      if (Object.keys(this.currentBoundsWMSLayer).length === 0) {
        return;
      }

      this.$notifyDX(
        {
          message: this.$t(this.$helpers.getFilteredErrorMessage(error.message)),
          width: 550,
        },
        'error',
        3000,
      );
    }
  },
  methods: {
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
    ...mapActions('Gis', ['boundsWMSLayer']),
  },
};
</script>

<style lang="scss" scoped>
@import './Treatment.styles.scss';
</style>

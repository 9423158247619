<template>
  <DxChart
    id="chart"
    :data-source="timelineIndexesFormatted"
    palette="Material"
    @legend-click="onLegendClick"
  >
    <DxCommonSeriesSettings
      type="line"
      argument-field="date"
    />
    <DxSeries
      value-field="mean"
      :name="index"
    />
    <DxSeries
      v-for="task in tasksSeries"
      v-if="tasks.length > 0"
      :key="task.id"
      tag="constantLine"
      :name="task.activity"
      :color="task.color"
    />
    <DxSeries
      v-for="observation in observationsSeries"
      v-if="observations.length > 0"
      :key="observation.id"
      tag="constantLine"
      :name="observation.category"
      :color="observation.color"
    />
    <DxArgumentAxis
      argument-type="datetime"
      :value-margins-enabled="false"
      discrete-axis-division-mode="crossLabels"
    >
      <DxConstantLine
        v-for="(task, taskIndex) in filteredTasks"
        v-if="tasks.length > 0"
        :key="'task-' + taskIndex"
        :width="2"
        :value="task.init_date"
        :color="task.color"
        dash-style="dash"
      >
        <DxLabel text="" />
      </DxConstantLine>
      <DxConstantLine
        v-for="(observation, observationIndex) in filteredObservations"
        v-if="observations.length > 0"
        :key="'observation' + observationIndex"
        :width="2"
        :value="observation.date"
        :color="observation.color"
        dash-style="dash"
      >
        <DxLabel text="" />
      </DxConstantLine>
      <DxLabel
        display-mode="stagger"
        format="yyyy-MM-dd"
      />
    </DxArgumentAxis>
    <DxLegend
      vertical-alignment="bottom"
      horizontal-alignment="center"
      item-text-position="right"
    />
    <DxTitle :text="''" />
    <DxTooltip
      :enabled="true"
      :z-index="999999"
      :customize-tooltip="customizeTooltip"
    />
  </DxChart>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxLegend,
  DxTitle,
  DxTooltip,
  DxConstantLine,
  DxLabel,
} from 'devextreme-vue/chart';
import moment from 'moment';
import Vue from 'vue';

export default {
  name: 'TimelineGraph',
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxLegend,
    DxTitle,
    DxTooltip,
    DxConstantLine,
    DxLabel,
  },
  props: {
    timelineIndexes: {
      type: Array,
      default: () => [],
    },
    index: {
      type: String,
      default: '',
    },
    tasks: {
      type: Array,
      default: () => [],
    },
    observations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hideConstantLine: {},
    };
  },
  computed: {
    timelineIndexesFormatted() {
      const timelineIndexesFormatted = [];
      for (const timelineIndex of this.timelineIndexes) {
        if (timelineIndex.values.length !== 0) {
          const timeLineFiltered = this.applyTimeLinesFilterByIndex(timelineIndex.values);
          timeLineFiltered.date = timelineIndex.date;
          timelineIndexesFormatted.push(timeLineFiltered);
        }
      }
      return timelineIndexesFormatted;
    },
    filteredTasks() {
      return this.tasks.filter((task) => this.hideConstantLine[task.activity] === undefined || this.hideConstantLine[task.activity] === false);
    },
    filteredObservations() {
      return this.observations.filter((observation) => this.hideConstantLine[observation.subcategory] === undefined || this.hideConstantLine[observation.subcategory] === false);
    },
    tasksSeries() {
      const tasksActivities = {};
      for (const task of this.tasks) {
        if (task.activity) {
          tasksActivities[task.activity] = {
            activity: task.activity,
            color: task.color,
          };
        }
      }
      return Object.values(tasksActivities);
    },
    observationsSeries() {
      if (this.observations.length > 0) {
        return [{
          category: 'Incidencias',
          color: '#65a30d',
        }];
      }

      return [];
      // const observationsSubcategory = {};
      // for (const observation of this.observations) {
      //   observationsSubcategory[observation.subcategory] = {
      //     category: observation.category,
      //     subcategory: observation.subcategory,
      //     color: observation.color,
      //   };
      // }
      // return Object.values(observationsSubcategory);
    },
  },
  methods: {
    customizeTooltip(pointInfo) {
      return {
        html: `<div><div class='tooltip-header'>${moment(pointInfo.argument).format('DD-MM-YYYY')}</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('index')}: </span>${pointInfo.seriesName}
          </span> </div>
          <span class="bold"> ${this.$t('mean')}:</span> ${pointInfo.valueText}
        </span> </div></div></div>`,
      };
    },
    applyTimeLinesFilterByIndex(values) {
      let timeLine = null;
      values.forEach((value) => {
        if (value.index.toString().toUpperCase() === this.index.toString().toUpperCase()) {
          timeLine = value;
        }
      });

      return timeLine;
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();

        if (series.tag === 'constantLine') {
          Vue.set(this.hideConstantLine, series.name, true);
        }
      } else {
        series.show();

        if (series.tag === 'constantLine') {
          this.hideConstantLine[series.name] = false;
        }
      }

      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './TimelineGraph.styles.scss';
</style>

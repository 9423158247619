<template>
  <section class="dosage-product">
    <PopupProductAnnotation
      v-if="showAnnotationProduct"
      :product="annotationProduct"
      @close="closeAnnotationProduct"
      @save="save"
    />
    <PopupProductDetail
      v-if="showProductDetail"
      @close="showProductDetail = false"
    />
    <div class="content-devx">
      <div class="card-header">
        {{ $t("dosageProduct.title") }}
      </div>
      <div
        class="card-body margin-left-block margin-right-block margin-bottom-block"
      >
        <section class="top">
          <DxDataGrid
            :key="dosageProductBalanceKey"
            class="data-grid-balance-value"
            :data-source="dosageProductBalance"
            :show-borders="true"
          >
            <DxColumn
              data-field="uf"
              :caption="$t('dosageProduct.uf')"
              :width="200"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.n')"
              data-field="n"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.p')"
              data-field="p"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.k')"
              data-field="k"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.c')"
              data-field="c"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.m')"
              data-field="m"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.s')"
              data-field="s"
              data-type="number"
              :allow-sorting="false"
            />
          </DxDataGrid>
        </section>
        <section
          class="search margin-top-block margin-bottom-block"
          style="margin-top: 1.4rem"
        >
          <div class="dx-field product-finder justify-content-end">
            <div class="margin-right-block">
              {{ $t("dosageProduct.searchFertilizer") }}
            </div>
            <div class="margin-right-block width300" style="position: relative">
              <Spinner
                v-if="loadingProductFertilizers"
                size="25px"
                style="position: absolute; z-index: 50; right: 5px; top: 5px"
              />
              <DxSelectBox
                ref="product-fertilizer"
                :data-source="productFertilizersPaginated"
                display-expr="name"
                :search-enabled="true"
                value-expr="id"
                item-template="product-name"
                :search-expr="['name', 'code']"
                :placeholder="
                  $t('treatment.treatmentEditOrder.products.typeToSearch')
                "
                :no-data-text="$t('No_Data')"
                width="auto"
                :disabled="loadingProductFertilizers"
                @value-changed="selectProductItem"
              >
                <template #product-name="{ data }">
                  <div>
                    <div
                      v-if="data.presentation"
                      style="display: flex; justify-content: flex-end"
                    >
                      <span
                        style="
                          font-size: 0.75rem;
                          background: #e5e7eb;
                          padding: 0 0.4rem;
                          border-radius: 9999px;
                          text-align: center;
                        "
                      >
                        {{ data.presentation }}
                      </span>
                    </div>
                    <div class="text-color-gray" style="word-break: break-all">
                      {{ data.code }}
                    </div>
                    <div style="word-break: break-all">
                      {{ data.name }}
                    </div>
                  </div>
                </template>
              </DxSelectBox>
            </div>
            <DxButton
              :text="$t('dosageProduct.add')"
              type="default"
              @click="addProductFertilizer"
            />
          </div>
        </section>
        <section class="bottom">
          <DxDataGrid
            class="data-grid-dosage-product"
            :data-source="stepData.dosageProduct"
            :show-borders="true"
            @cellHoverChanged="cellHoverChanged"
          >
            <DxPaging :enabled="false" />
            <DxColumn
              data-field="name"
              :caption="$t('dosageProduct.uf')"
              :allow-sorting="false"
              cell-template="dosageProductName"
            />
            <template #dosageProductName="{ data }">
              <div>
                <div v-if="data.data.product_deleted">
                  <span :id="'alert-' + data.rowIndex">
                    <img
                      :src="getIconPath + 'alert.svg'"
                      alt="Alert icon"
                      @mouseenter="togglePopUpInfoDotsVisible"
                      @mouseleave="togglePopUpInfoDotsVisible"
                    />
                    <span>{{ data.data.name }}</span>
                  </span>

                  <DxPopover
                    :visible="visible && currentRowIndex === data.rowIndex"
                    :animation="animationConfig"
                    :target="'#alert-' + data.rowIndex"
                    :position="position"
                  >
                    <div class="padding-10">
                      {{ $t("productDeleted") }}
                    </div>
                  </DxPopover>
                </div>
                <div v-else>
                  {{ data.data.name }}
                </div>
              </div>
            </template>
            <DxColumn
              :caption="$t('dosageProduct.n') + ' (% P/P)'"
              data-field="n"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.p') + ' (% P/P)'"
              data-field="p"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.k') + ' (% P/P)'"
              data-field="k"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.c') + ' (% P/P)'"
              data-field="c"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.m') + ' (% P/P)'"
              data-field="m"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.s') + ' (% P/P)'"
              data-field="s"
              data-type="number"
              :allow-sorting="false"
            />
          </DxDataGrid>

          <DxDataGrid
            ref="data-grid-time-values"
            class="data-grid-time-values"
            :data-source="getUniqueTimeValues"
            :column-min-width="50"
            :column-auto-width="true"
            :show-borders="true"
            @row-updated="onRowUpdated"
            @content-ready="onContentReady"
          >
            <DxSorting mode="none" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxPaging :enabled="false" />
            <DxEditing :allow-updating="true" mode="cell" />
          </DxDataGrid>
          <DxDataGrid
            :key="keyGridDosages"
            class="data-grid-dosages"
            :data-source="stepData.dosages"
            :column-min-width="50"
            :remote-operations="true"
            :show-borders="true"
          >
            <DxPaging :enabled="false" />
            <DxColumn
              v-if="!isNew"
              data-field="dosage"
              :caption="$t('dosageProduct.dose')"
              :allow-sorting="false"
            />
            <DxColumn
              v-if="isNew"
              data-field="dosage"
              name="dosage_kg"
              :caption="$t('dosageProduct.dose')"
              :min-width="82"
              :allow-sorting="false"
              :allow-editing="false"
              :calculate-display-value="getDosageKg"
            />
            <DxColumn
              v-if="isNew"
              data-field="dosage"
              name="dosage_l"
              caption="Dosis (L/ha)"
              :min-width="82"
              :allow-sorting="false"
              :allow-editing="false"
              :calculate-display-value="getDosageL"
            />
            <DxColumn
              cell-template="buttonsColumnTemplate"
              alignment="center"
              :width="90"
            />
            <template #buttonsColumnTemplate="{ data }">
              <div class="column-buttons">
                <DxButton
                  styling-mode="text"
                  icon="edit"
                  @click="openProductAnnotations(data)"
                />
                <DxButton
                  styling-mode="text"
                  type="success"
                  icon="find"
                  @click="viewProduct(data)"
                />
                <DxButton
                  styling-mode="text"
                  type="danger"
                  icon="trash"
                  @click="deleteItem(data)"
                />
              </div>
            </template>
          </DxDataGrid>
        </section>
      </div>
    </div>
    <section class="content-devx">
      <div class="button-irrigation">
        <div class="button-recalculate">
          <DxButton
            v-if="showButtonRecalculate"
            :text="$t('dosageProduct.recalculate')"
            type="default"
            @click="recalculateIrrigationTable"
          />
        </div>
        <DxButton
          :text="buttonMessageIrrigation"
          type="default"
          @click="toggleIrrigationTable"
        />
      </div>
      <div
        v-if="showIrrigationTable"
        class="card-body margin-left-block margin-right-block margin-bottom-block"
      >
        <DxDataGrid
          id="irrigationTable"
          class="data-grid-reference-value"
          :data-source="stepData.irrigationTable"
          :show-borders="true"
          @row-updated="handleCellValueChanging"
        >
          <DxEditing :allow-updating="true" mode="cell" />
          <DxColumn caption="">
            <DxColumn
              caption=""
              data-field="week"
              :allow-editing="false"
              :allow-sorting="false"
            />
          </DxColumn>
          <DxColumn
            v-for="(product, index) in stepData.dosageProduct"
            :key="`${product.code}_${index}`"
            :caption="`${product.name}`"
          >
            <DxColumn
              :caption="'NºRiegos'"
              :data-field="`periods_product_irrigation_${index}`"
              data-type="number"
              :allow-editing="true"
            />
            <DxColumn
              :caption="product.caption"
              :data-field="`periods_dose_${index}`"
              data-type="number"
              :allow-editing="false"
            />
          </DxColumn>
          <DxColumn 
            :caption="'NºTotal de riegos'" 
            data-field="periods_irrigation"
            data-type="number"
            :allow-editing="true"
          />
          <DxColumn 
            :caption="'Tiempo/Riego(horas)'" 
            data-field="periods_irrigation_time" 
            data-type="number"
            :allow-editing="true"
          />
        </DxDataGrid>
      </div>
    </section>
  </section>
</template>
<script>
import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
import {
  DxDataGrid,
  DxColumn,
  DxSorting,
  DxScrolling,
  DxPaging,
  DxEditing,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import DxSelectBox from 'devextreme-vue/select-box';
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { Device } from 'framework7/framework7-lite.esm.bundle';
import { DxPopover } from 'devextreme-vue/popover';
import DataSource from 'devextreme/data/data_source';
import { uniqueId } from 'lodash';
import PopupProductDetail from '../popupProductDetail/index.vue';
import PopupProductAnnotation from '../PopupProductAnnotation/index.vue';
import Api from '../../../services/Api';
import Spinner from '../../spinner.vue';
import { getTimeValues } from '../../../js/utils/timeValuesUtils';

export default {
  name: 'DosageProduct_nutrition',
  components: {
    DxLoadIndicator,
    DxDataGrid,
    DxColumn,
    DxButton,
    DxSelectBox,
    DxSorting,
    DxScrolling,
    DxPaging,
    DxEditing,
    PopupProductDetail,
    DxPopover,
    PopupProductAnnotation,
    Spinner,
  },
  props: [],
  data() {
    return {
      productFertilizerSelected: '',
      productFertilizerSelectedValue: '',
      buttonMessageIrrigation: this.$t('dosageProduct.addIrrigationTable'),
      showProductDetail: false,
      showAnnotationProduct: false,
      showIrrigationTable: false,
      annotationProduct: null,
      environment: process.env.NODE_ENV,
      visible: false,
      currentRowIndex: 0,
      animationConfig: {
        show: {
          type: 'pop',
          from: {
            scale: 0,
          },
          to: {
            scale: 1,
          },
        },
        hide: {
          type: 'fade',
          from: 1,
          to: 0,
        },
      },
      position: 'top',
      dosageProductBalanceKey: 'dosage-product-balance-key',
      keyGridDosages: 'key-grid-dosages',
      previousRowValues: {},
    };
  },
  computed: {
    ...mapGetters('nutritionStepper', ['stepData']),
    ...mapGetters('productFertilizerStore', ['productFertilizers']),
    ...mapState('productFertilizerStore', ['loadingProductFertilizers']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Nutrition', ['productsNotInNutritionPlan']),
    ...mapState('nutritionStepper', [
      'isEditing',
      'editingStepData',
      'isCopying',
      'copyingStepData',
      'isNew',
    ]),
    showButtonRecalculate() {
      return this.buttonMessageIrrigation === this.$t('dosageProduct.deleteIrrigationTable');
    },
    getMessageButtonIrrigation() {
      this.buttonMessageIrrigation = this.showIrrigationTable ? this.$t('dosageProduct.deleteIrrigationTable') : this.$t('dosageProduct.addIrrigationTable')
    },
    getUniqueTimeValues() {
      const formatDose = (dose) => {
        Object.keys(dose).forEach((key) => {
          dose[key] = this.fixNumberTo(dose[key], 1);
        });

        return dose;
      };

      return this.stepData.timeValues.map((i) => ({
        id: uniqueId('time-values-'),
        ...formatDose(i),
      }));
    },
    productFertilizersPaginated() {
      const selectBoxData = new DataSource({
        store: this.productFertilizers,
        paginate: true,
        pageSize: 100,
      });

      return selectBoxData;
    },
    dosageProductBalance() {
      return this.stepData.dosageProductBalance.map((item) => {
        if (!item) return item;

        return {
          c: this.fixNumberTo(item.c, 0),
          k: this.fixNumberTo(item.k, 0),
          m: this.fixNumberTo(item.m, 0),
          n: this.fixNumberTo(item.n, 0),
          p: this.fixNumberTo(item.p, 0),
          s: this.fixNumberTo(item.s, 0),
          uf: item.uf,
        };
      });
    },
    getIconPath() {
      if (Device.cordova) {
        return this.environment === 'development'
          ? '../../../static/img/'
          : 'static/img/';
      }
      return this.environment === 'development'
        ? '../../../static/img/'
        : './static/img/';
    },
  },
  watch: {
    'stepData.timeValues': {
      handler(val) {
        if (val.length === 0) {
          return;
        }
        this.calcTotalBalance(val);
        this.calculateUfEficency(val);
      },
      deep: true,
    },
  },
  created() {
    this.getProductFertilizers(this.currentPlantation.companyId);
    if (this.stepData.dosageProduct.length > 0) {
      if (this.stepData.dosageProductBalance.length === 1) {
        this.stepData.dosageProductBalance.push(
          this.getRowDataSource(this.$t('dosageProduct.finalBalance')),
        );

        this.calcTotalBalance(this.stepData.timeValues);
      }
      return;
    }
    this.stepData.dosageProductBalance = [];
    this.stepData.dosageProduct = [];
    this.stepData.timeValues = [];
    this.stepData.dosages = [];

    this.stepData.dosageProductBalance.push(
      this.stepData.nutritionalBalanceData[5],
    );

    if (this.isNew) {
      this.stepData.dosageProductBalance.push(
        this.getRowDataSource('UF teóricas'),
      );
      this.stepData.dosageProductBalance.push(
        this.getRowDataSource('UF eficientes aportadas'),
      );
    }

    this.stepData.dosageProductBalance.push(
      this.getRowDataSource(this.$t('dosageProduct.finalBalance')),
    );
  },
  async beforeMount() {
    const FULL_EFFICIENCY = {
      n: 100,
      p: 100,
      k: 100,
      c: 100,
      m: 100,
      s: 100,
    };

    await Promise.all(
      this.stepData.dosageProduct.map(async (item) => {
        const hasReduction = item.reduction_percentage > 0;
        let eficiencies = FULL_EFFICIENCY;

        if (!hasReduction) {
          eficiencies = await Api.getProductEfficiency({
            code: item?.code,
            tipology: item?.tipology,
            ph: this.stepData.ph || 0,
          })
            .then((xhr) => {
              const data = JSON.parse(xhr.response);

              return {
                n: data.NT,
                p: data.P2015_Total,
                k: data.K2O,
                c: data.CaO,
                m: data.MgO,
                s: data.SO3,
              };
            })
            .catch(() => ({
              n: 0,
              p: 0,
              k: 0,
              c: 100,
              m: 100,
              s: 100,
            }));
        }

        item.eficiencies = eficiencies;

        return item;
      }),
    );

    this.calcTotalBalance(this.stepData.timeValues);
    this.calculateUfEficency(this.stepData.timeValues);

    if (this.stepData.dosageProductBalance.length === 1) {
      this.stepData.dosageProductBalance.push(
        this.getRowDataSource(this.$t('dosageProduct.finalBalance')),
      );
    }
    this.calcTotalBalance(this.stepData.timeValues);
  },
  methods: {
    onRowUpdated(e) {
      const index = e.component.getRowIndexByKey(e.key);

      const originalData = { ...e.data };
      delete originalData.id;

      const keys = Object.keys(originalData);

      keys.forEach((key) => {
        const notNull = originalData[key] != null;
        originalData[key] = notNull ? this.fixNumberTo(originalData[key], 1) : 0;
      });
      // this.stepData.timeValues[index] = originalData;

      this.$set(this.stepData.timeValues, index, originalData);

      if (this.isEditing) {
        // this.editingStepData.timeValues[index] = originalData;
        this.$set(this.editingStepData.timeValues, index, originalData);
      }

      if (this.isCopying) {
        // this.copyingStepData.timeValues[index] = originalData;
        this.$set(this.copyingStepData.timeValues, index, originalData);
      }

      this.keyGridDosages = uniqueId('key-grid-dosages-');
    },
    onContentReady(e) {
      e.component.columnOption('id', { visible: false });
    },
    cellHoverChanged(e) {
      this.currentRowIndex = e.rowIndex;
    },
    selectProductItem(e) {
      if (!e.value) {
        this.productFertilizerSelected = '';
        return;
      }

      const product = this.productFertilizers.find(
        (item) => item.id === e.value,
      );

      if (product != null) {
        this.productFertilizerSelected = product;
        this.productFertilizerSelectedValue = this.productFertilizerSelected.name;
      }
    },
    togglePopUpInfoDotsVisible() {
      this.visible = !this.visible;
    },
    getRowDataSource(titleColumn) {
      return {
        uf: titleColumn,
        n: 0,
        p: 0,
        k: 0,
        c: 0,
        m: 0,
        s: 0,
      };
    },
    toggleIrrigationTable() {
      this.buttonMessageIrrigation = this.showIrrigationTable ? this.$t('dosageProduct.addIrrigationTable') : this.$t('dosageProduct.deleteIrrigationTable');
      this.showIrrigationTable = !this.showIrrigationTable;
      
      if (this.buttonMessageIrrigation === this.$t('dosageProduct.deleteIrrigationTable')) {
        this.$notifyDX({
          message: this.$t('dosageProduct.messageRecalculate'),
          width: 550,
        }, 'warning', 6000);
      }
    },
    async addProductFertilizer() {
      if (!this.productFertilizerSelected) {
        this.$notifyDX(
          {
            message: this.$t('requiredNutritionFertiliser'),
            width: 550,
          },
          'warning',
          3000,
        );
        return;
      }

      const { timeValues, timeValuesIrrigation } = getTimeValues(
        moment(this.stepData.initDate),
        moment(this.stepData.endDate),
        this.stepData.temporalFrame.id,
      );
     
      this.stepData.dosageProduct.push({ ...this.productFertilizerSelected });
      this.stepData.dosages.push({
        dosage: 0,
        presentation: this.productFertilizerSelected?.presentation,
        density: this.productFertilizerSelected?.density,
      });

      this.stepData.timeValues = this.stepData.timeValues.concat(timeValues);
      if (!this.isEditing) {
        this.stepData.timeValuesIrrigation = this.stepData.timeValuesIrrigation.concat(timeValuesIrrigation);
      }
      
      if (this.isEditing) {
        this.editingStepData.timeValues = this.editingStepData.timeValues.concat(timeValues);
      }

      if (this.isCopying) {
        this.copyingStepData.timeValues = this.copyingStepData.timeValues.concat(timeValues);
        this.copyingStepData.timeValuesIrrigation = this.copyingStepData.timeValuesIrrigation.concat(timeValuesIrrigation);
      }

      await this.calculateReductionFertirrigation(
        this.stepData.dosageProduct.length - 1,
      );

      this.productFertilizerSelected = null;
      this.productFertilizerSelectedValue = null;

      this.$refs['product-fertilizer'].instance.reset();
      this.setIrrigationTableDataSource();
    },
    async calculateReductionFertirrigation(indexProduct) {
      const allReduction = this.stepData.dosageProduct.map(
        (i) => i?.reduction_percentage ?? 0,
      );
      const fertigationReduction = allReduction.length
        ? Math.max(...allReduction)
        : 0;

      const actualProductReduction = this.productFertilizerSelected?.reduction_percentage ?? 0;

      this.reduceNutritionalNeeds(fertigationReduction);

      if (actualProductReduction === 0) {
        if (this.stepData.dosageProduct[indexProduct] != null) {
          await Api.getProductEfficiency({
            code: this.productFertilizerSelected.code,
            tipology: this.productFertilizerSelected.tipology,
            ph: this.stepData.ph,
          })
            .then((xhr) => {
              const data = JSON.parse(xhr.response);

              this.stepData.dosageProduct[indexProduct].eficiencies = {
                n: data.NT,
                p: data.P2015_Total,
                k: data.K2O,
                c: data.CaO,
                m: data.MgO,
                s: data.SO3,
              };
            })
            .catch((error) => {
              this.$notifyDX({ message: error, width: 550 }, 'error', 3000);
            });
        }
      } else {
        this.stepData.dosageProduct[indexProduct].eficiencies = {
          n: 100,
          p: 100,
          k: 100,
          c: 100,
          m: 100,
          s: 100,
        };
      }
    },
    reduceNutritionalNeeds(percentage) {
      const value = this.stepData.expectedProduction;

      // this.stepData.nutritionalNeeds[1].production = value;
      this.calculateValue(
        this.stepData.nutritionalNeeds,
        'n',
        value,
        percentage,
      );
      this.calculateValue(
        this.stepData.nutritionalNeeds,
        'p',
        value,
        percentage,
      );
      this.calculateValue(
        this.stepData.nutritionalNeeds,
        'k',
        value,
        percentage,
      );
      this.calculateValue(
        this.stepData.nutritionalNeeds,
        'c',
        value,
        percentage,
      );
      this.calculateValue(
        this.stepData.nutritionalNeeds,
        'm',
        value,
        percentage,
      );
      this.calculateValue(
        this.stepData.nutritionalNeeds,
        's',
        value,
        percentage,
      );

      // Recalculamos datos como en el Paso 2
      this.stepData.nutritionalBalanceData[0] = this.stepData.nutritionalNeeds[1];
      this.stepData.nutritionalBalanceData[0].uf = this.$t(
        'nutritionalBalance.necNutritional',
      );

      this.calcTotalBalanceStep2(this.stepData.nutritionalBalanceData, 'n');
      this.calcTotalBalanceStep2(this.stepData.nutritionalBalanceData, 'p');
      this.calcTotalBalanceStep2(this.stepData.nutritionalBalanceData, 'k');
      this.calcTotalBalanceStep2(this.stepData.nutritionalBalanceData, 'c');
      this.calcTotalBalanceStep2(this.stepData.nutritionalBalanceData, 'm');
      this.calcTotalBalanceStep2(this.stepData.nutritionalBalanceData, 's');

      this.calcDosageProductBalanceStep2(this.stepData.nutritionalBalanceData);

      this.dosageProductBalanceKey = `dosage-product-balance-key-${new Date().getTime()}`;
    },
    calcTotalBalanceStep2(array, param) {
      array[4][param] = this.roundValue(
        parseFloat(array[1][param])
          + parseFloat(array[2][param])
          + parseFloat(array[3][param]),
      );
      array[5][param] = this.roundValue(
        parseFloat(array[4][param]) - parseFloat(array[0][param]),
      );
    },
    calcDosageProductBalanceStep2(data) {
      const balance = data.find(
        (item) => item?.uf === this.$t('nutritionalBalance.advanceFertilization'),
      );
      if (balance) {
        this.stepData.dosageProductBalance[0] = balance;
      }
    },
    calculateValue(array, param, value, reduccion = 0) {
      const percentage = 1 - reduccion / 100;

      array[1][param] = this.roundValue(array[0][param] * value * percentage);
    },
    roundValue(val) {
      return parseFloat(val.toFixed(1));
    },
    getCalculationByPresentation(data) {
      const { dosage, density, presentation } = data;
      const densityValue = density ?? 1;
      const cpPresentation = 'SOLIDO';
      if (presentation === cpPresentation) {
        return dosage;
      }
      return dosage * densityValue;
    },
    calculateUfEficency(array) {
      const ufContributed = {
        n: 0,
        p: 0,
        k: 0,
        c: 0,
        m: 0,
        s: 0,
      };

      const fixValue = (value) => {
        if (value == null) {
          return 0;
        }
        return parseFloat(value.toFixed(1));
      };

      this.stepData.dosageProduct.forEach((product, index) => {
        const dataCalculation = this.stepData.dosages[index];
        const calculation = this.getCalculationByPresentation(dataCalculation);
        if (product?.eficiencies != null) {
          ufContributed.n
            += fixValue((calculation * product.n) / 100)
            * (product.eficiencies.n / 100);
          ufContributed.p
            += fixValue((calculation * product.p) / 100)
            * (product.eficiencies.p / 100);
          ufContributed.k
            += fixValue((calculation * product.k) / 100)
            * (product.eficiencies.k / 100);
          ufContributed.c
            += fixValue((calculation * product.c) / 100)
            * (product.eficiencies.c / 100);
          ufContributed.m
            += fixValue((calculation * product.m) / 100)
            * (product.eficiencies.m / 100);
          ufContributed.s
            += fixValue((calculation * product.s) / 100)
            * (product.eficiencies.s / 100);
        } else {
          ufContributed.n
            += fixValue((calculation * product.n) / 100)
            * (product.n / 100);
          ufContributed.p
            += fixValue((calculation * product.p) / 100)
            * (product.p / 100);
          ufContributed.k
            += fixValue((calculation * product.k) / 100)
            * (product.k / 100);
          ufContributed.c
            += fixValue((calculation * product.c) / 100)
            * (product.c / 100);
          ufContributed.m
            += fixValue((calculation * product.m) / 100)
            * (product.m / 100);
          ufContributed.s
            += fixValue((calculation * product.s) / 100)
            * (product.s / 100);
        }
      });

      // UF eficientes aportadas redondeadas
      const ufContributedRounded = {
        n: fixValue(ufContributed.n),
        p: fixValue(ufContributed.p),
        k: fixValue(ufContributed.k),
        c: fixValue(ufContributed.c),
        m: fixValue(ufContributed.m),
        s: fixValue(ufContributed.s),
      };

      this.updateElementBalance(
        ufContributedRounded,
        this.stepData.dosageProductBalance[2],
      );

      // this.updateElementBalance(
      //   ufContributed,
      //   this.stepData.dosageProductBalance[3],
      // );

      this.differenceElementBalance('n');
      this.differenceElementBalance('p');
      this.differenceElementBalance('k');
      this.differenceElementBalance('c');
      this.differenceElementBalance('m');
      this.differenceElementBalance('s');
    },
    calcTotalBalance(array) {
      const ufContributed = {
        n: 0,
        p: 0,
        k: 0,
        c: 0,
        m: 0,
        s: 0,
      };
      array.forEach((item, index) => {
        let totalProps = 0;
        for (const prop in item) {
          if (prop !== 'id') {
            totalProps += item[prop];
          }
        }
        this.stepData.dosages[index].dosage = totalProps;

        if (this.stepData.dosages[index].presentation === undefined) {
          this.stepData.dosages[index].presentation = this.stepData.dosageProduct[index].presentation;
        }

        if (this.stepData.dosages[index].density === undefined) {
          this.stepData.dosages[index].density = this.stepData.dosageProduct[index].density;
        }
      });

      // Forzar que la tabla se renderice con los datos nuevos.
      this.stepData.dosages.push({ dosage: 0 });
      this.stepData.dosages.pop();

      this.stepData.dosageProduct.forEach((item, index) => {
        for (const prop in item) {
          ufContributed[prop] += this.calculateElementBalance(index, prop);
        }
      });

      this.updateElementBalance(
        ufContributed,
        this.stepData.dosageProductBalance[1],
      );

      this.updateElementBalance(
        ufContributed,
        this.stepData.dosageProductBalance[3],
      );

      this.differenceElementBalance('n');
      this.differenceElementBalance('p');
      this.differenceElementBalance('k');
      this.differenceElementBalance('c');
      this.differenceElementBalance('m');
      this.differenceElementBalance('s');
    },
    calculateElementBalance(index, prop) {
      const dataCalculation = this.stepData.dosages[index];
      const calculation = this.getCalculationByPresentation(dataCalculation);
      return Math.round(
        (this.stepData.dosageProduct[index][prop] / 100)
          * calculation,
      );
    },
    differenceElementBalance(prop) {
      // eslint-disable-next-line max-len
      // this.stepData.dosageProductBalance[3][prop] = Number(this.stepData.dosageProductBalance[0][prop])
      //   + Number(this.stepData.dosageProductBalance[1][prop])
      //   + Number(this.stepData.dosageProductBalance[2][prop]);
      // eslint-disable-next-line max-len
      this.stepData.dosageProductBalance[3][prop] = Number(this.stepData.dosageProductBalance[0][prop])
        + Number(this.stepData.dosageProductBalance[2][prop]);

      this.stepData.dosageProductBalance[3][prop] = Number(
        this.stepData.dosageProductBalance[3][prop],
      ).toFixed(1);
    },
    updateElementBalance(elementSource, elementTarget) {
      elementTarget.n = elementSource.n;
      elementTarget.p = elementSource.p;
      elementTarget.k = elementSource.k;
      elementTarget.c = elementSource.c;
      elementTarget.m = elementSource.m;
      elementTarget.s = elementSource.s;
    },
    deleteItem(item) {
      this.$f7.dialog.confirm(
        this.$t('dosageProduct.deleteDialogMessage', {
          product: this.stepData.dosageProduct[item.row.rowIndex].name,
        }),
        this.$t('dosageProduct.deleteDialogTitle'),
        () => {
          this.stepData.timeValues.splice(item.row.rowIndex, 1);
          this.stepData.dosages.splice(item.row.rowIndex, 1);
          this.stepData.dosageProduct.splice(item.row.rowIndex, 1); 
          this.stepData.productResume.splice(item.row.rowIndex, 1); 
          
         // Obtenemos el índice a eliminar
        let rowIndexToDelete = item.row.rowIndex;

        // Obtenemos el sufijo basado en el índice a eliminar
        let suffixToDelete = `_${rowIndexToDelete}`;

        // Creamos un array para almacenar las claves a mantener
        let keysToKeep = [];

        // Recorremos el objeto y eliminamos las claves correspondientes al sufijo item.row.rowIndex
        for (let key in this.stepData.irrigationTable) {
            if (key.endsWith(suffixToDelete)) {
                delete this.stepData.irrigationTable[key];
            } else {
                keysToKeep.push(key); // Almacenamos las claves que no deben ser eliminadas
            }
        }

        // Recorremos nuevamente el array para ajustar las claves
        keysToKeep.forEach(key => {
            // Creamos un nuevo nombre de clave basado en el índice ajustado
            let adjustedKey = key.replace(/_\d+$/, match => {
                let index = parseInt(match.slice(1)); // Obtenemos el número del sufijo
                if (index > rowIndexToDelete) {
                    return `_${index - 1}`; // Ajustamos el índice si es mayor que el índice eliminado
                }
                return match;
            });

            // Si la clave tiene un nuevo nombre, asignamos su valor correspondiente
            if (key !== adjustedKey) {
                this.stepData.irrigationTable[adjustedKey] = this.stepData.irrigationTable[key];
                delete this.stepData.irrigationTable[key];
            }
        });

        // Actualizamos el índice de item.row.rowIndex en los objetos
        this.stepData.irrigationTable.forEach(obj => {
            for (let key in obj) {
                if (key.startsWith('periods_product_irrigation_') || key.startsWith('periods_dose_') || key.startsWith('code_') || key.startsWith('caption_')) {
                    let index = parseInt(key.split('_').pop());
                    if (index > rowIndexToDelete) {
                        let newKey = key.replace(`_${index}`, `_${index - 1}`);
                        obj[newKey] = obj[key];
                        delete obj[key];
                    }
                }
            }
        });

          if (this.isEditing) {
            this.editingStepData.timeValues.splice(item.row.rowIndex, 1);
          }

          if (this.isCopying) {
            this.copyingStepData.timeValues.splice(item.row.rowIndex, 1);
          }

          if (this.stepData.timeValues.length === 0) {
            // borramos los datos de la tabla Riego de la store
            this.resetTimeValuesIrrigation();
            // logic to reset the balance
            this.$emit('resetBalance');
            // borramos los datos de la tabla Riego si no hay productos
            this.stepData.irrigationTable = [];
            // borramos el objeto de datos previos de la tabla Riego si no hay productos
            this.previousRowValues = {}
          }
          this.calculateReductionFertirrigation();
        },
      );
    },
    openProductAnnotations(item) {
      const index = item.row.rowIndex;
      this.annotationProduct = {
        index,
        name: this.stepData.dosageProduct[index].name,
        observation: this.stepData.dosageProduct[index].observation ?? '',
      };
      this.showAnnotationProduct = true;
    },
    save(e) {
      this.stepData.dosageProduct[e.index].observation = e.observation;
    },
    async viewProduct(item) {
      const index = item.row.rowIndex;
      const { id } = this.stepData.dosageProduct[index];

      this.$f7.preloader.show();
      try {
        await this.getNutritionalProductDetail(id);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$notifyDX(
            {
              message: message.split('_').pop(),
              width: 550,
            },
            'error',
            3000,
          );
        } else if (!message.includes('404')) {
          this.$notifyDX(
            {
              message: this.$t(message),
              width: 550,
            },
            'error',
            3000,
          );
        }
      } finally {
        this.$f7.preloader.hide();
        this.showProductDetail = true;
      }
    },
    fixNumberTo(number, digits) {
      return parseFloat(parseFloat(number).toFixed(digits));
    },
    closeAnnotationProduct() {
      this.showAnnotationProduct = false;
    },
    getDosageKg(data) {
      const presentation = 'SOLIDO';
      const density = data.density ?? 1;

      if (data.presentation === presentation) {
        return this.fixNumberTo(data.dosage, 1);
      }

      return this.fixNumberTo((data.dosage * density), 1);
    },
    getDosageL(data) {
      const presentation = 'LIQUIDO';

      if (data.presentation === presentation) {
        return this.fixNumberTo(data.dosage, 1);
      }

      return '';
    },
    recalculateIrrigationTable() {
      this.stepData.timeValues.forEach((timeValue, indexTimeValue) => {
          Object.keys(timeValue).forEach((timeValueWeek) => {
              this.stepData.irrigationTable.forEach((irrigationItem) => {
                  const weekValue = irrigationItem.week;

                  if (weekValue === timeValueWeek) {
                      const productKey = `periods_product_irrigation_${indexTimeValue}`;
                      
                      if (irrigationItem.hasOwnProperty(productKey)) {
                          const periodsProductIrrigation = Math.floor(irrigationItem[productKey]);

                          if (periodsProductIrrigation !== null && periodsProductIrrigation !== 0) {
                              const doseResult = timeValue[timeValueWeek] / periodsProductIrrigation;
                              const doseKey = `periods_dose_${indexTimeValue}`;
                              irrigationItem[doseKey] = this.fixNumberTo(doseResult, 1);
                          } else {
                              const doseKey = `periods_dose_${indexTimeValue}`;
                              irrigationItem[doseKey] = 0;
                          }
                      }
                  }
              });
          });
      });
    },
    handleCellValueChanging(e) {
      const { data } = e;
      let maxDose = 0;
      let dataIrrigationL = {}

      // Obtener los valores de timeValues
      const { timeValues } = this.stepData;

      // Nos aseguramos que la variable periods_irrigation_time tenga 2 decimales (Tiempo/Riego(horas))
      Object.keys(data).forEach(key => {
        if (key === "periods_irrigation_time") {
          data[key] = this.fixNumberTo(data[key], 2);
        }
      });

      // Nos aseguramos que la variable periods_irrigation no tenga decimales (NºTotal de Riegos))
       Object.keys(data).forEach(key => {
        if (key === "periods_irrigation") {
          data[key] = this.fixNumberTo(data[key], 0);
        }
      });

      if ((data.periods_irrigation_time !== this.previousRowValues[data.week]?.periods_irrigation_time) && Object.keys(this.previousRowValues).length !== 0) {
        Object.keys(data).forEach(key => {
          if (key.startsWith('periods_product_irrigation_')) {
            //Si el valor anterior de periods_product_irrigation_X es igual al del evento cambiado en ese momento que se actualice y se salga
            if (data[key] === this.previousRowValues[data.week]?.[key]) {
                const semanaEncontradaIndex = this.stepData.irrigationTable.findIndex(item => item.week === data.week);
                this.stepData.irrigationTable[semanaEncontradaIndex] = data;
                return 0;
            }
          }
        });
      }

      timeValues.forEach((object, index) => {
        const weekValue = data.week;

        // Buscar la semana correspondiente en el objeto actual del timeValues
        if (object.hasOwnProperty(weekValue)) {
            const productKey = `periods_product_irrigation_${index}`;

            // Verificar si existe la clave correspondiente en el data(el evento)
            if (data.hasOwnProperty(productKey)) {
                const periodsProductIrrigation = Math.floor(data[productKey]);

                // Realizar la división si el valor no es null ni 0
                if (periodsProductIrrigation !== null && periodsProductIrrigation !== 0) {
                    const doseResult = object[weekValue] / periodsProductIrrigation;
                    const doseKey = `periods_dose_${index}`;
                    data[doseKey] = this.fixNumberTo(doseResult, 1); // Asignar el resultado de la división
                } else {
                    const doseKey = `periods_dose_${index}`;
                    data[doseKey] = 0; // Asignar 0 si periodsProductIrrigation es null
                }
            }
          }
      });

      // Nos aseguramos que la variable periods_product_irrigation_  sea un numero entero (NºRiegos)
      Object.keys(data).forEach(key => {
        if (key.startsWith("periods_product_irrigation_")) {
          data[key] = this.fixNumberTo(data[key], 0);
        }
      });

      // Mostrar los resultados actualizados en data y actualizamos dataIrrigationL
      dataIrrigationL = data;

      // Calcular el valor máximo de 'periods_product_irrigation'
      for (let key in dataIrrigationL) {
        if (key.startsWith('periods_product_irrigation')) {
            maxDose = Math.max(Math.max(Math.floor(maxDose), data[key]), data['periods_irrigation']);
        }
      }

      const semanaEncontradaIndex = this.stepData.irrigationTable.findIndex(item => item.week === data.week);
      // Si se encuentra la semana se actualiza con todos los datos calculados de l/Riego
      this.stepData.irrigationTable[semanaEncontradaIndex] = dataIrrigationL

      if (!this.previousRowValues[data.week]) {
          // Si no hay un registro anterior para esta semana, se agrega
          this.previousRowValues[data.week] = { ...data, periods_irrigation: maxDose };
          this.stepData.irrigationTable[semanaEncontradaIndex].periods_irrigation = maxDose;
      } else {
          // Se verifica si no hay cambios en 'periods_irrigation'
          if (data.periods_irrigation === this.previousRowValues[data.week].periods_irrigation) {
              if (semanaEncontradaIndex !== -1) {
                  // Si se encuentra la semana en el conjunto de datos, se actualiza 'periods_irrigation' con maxDose
                  this.stepData.irrigationTable[semanaEncontradaIndex].periods_irrigation = maxDose;
              } else {
                  // Si no se encuentra la semana, se agrega un nuevo objeto con los valores actualizados
                  const nuevoObjeto = {
                      ...data,
                      week: data.week,
                      periods_irrigation: maxDose, // Asignar el valor de maxDose a 'periods_irrigation'
                  };
                  this.stepData.irrigationTable.push(nuevoObjeto);
              }
          }
        // Se actualiza el registro de valores anteriores con los nuevos valores
        this.previousRowValues[data.week] = { ...dataIrrigationL};
      }
    },
    setIrrigationTableDataSource() {
      // Si estamos en el modo edición
      if(this.isEditing) {
      // Hacemos el calculo para poner el nombre de la dosis L o KG segun si es Liquido o Solido
      const dosageProduct = this.stepData.dosageProduct = this.stepData.dosageProduct.map(product => ({
          ...product,
          caption: product.presentation === 'LIQUIDO' ? 'l/Riego' : 'kg/Riego'
        }));

        const lastIndex = dosageProduct.length - 1;
        const lastProduct = dosageProduct[lastIndex];

        const newTimeValuesIrrigation = {
          [`code_${lastIndex}`]: lastProduct.code,
          [`periods_dose_${lastIndex}`]: 0,
          [`periods_product_irrigation_${lastIndex}`]: 0,
          [`caption_${lastIndex}`]: lastProduct.caption,
        };
        // Agregar los nuevos valores del último producto a cada objeto del array this.stepdata.irrigationTable
        this.stepData.irrigationTable.forEach(obj => {
          Object.keys(newTimeValuesIrrigation).forEach(key => {
            // Usamos set para que al añadir los atributos nuevos mantenga la reactividad
            this.$set(obj, key, newTimeValuesIrrigation[key]);
          });
        });        
      } else {
        const timeValuesIrrigation = this.stepData.timeValuesIrrigation;
        
        // Hacemos el calculo para poner el nombre de la dosis L o KG segun si es Liquido o Solido
        const dosageProduct = this.stepData.dosageProduct = this.stepData.dosageProduct.map(product => {
            return {
              ...product,
              caption: product.presentation === 'LIQUIDO' ? 'l/Riego' : 'kg/Riego'
            };
        });
        
        // Limpiar los datos de la Tabla de riego stepData.irrigationTable
        this.stepData.irrigationTable = [];
        // Datos anteriores lo limpiamos
        this.previousRowValues = {};
          
        for (const week in timeValuesIrrigation[0]) {
          if (Object.hasOwnProperty.call(timeValuesIrrigation[0], week)) {
            const newTimeValuesIrrigation = {};
            newTimeValuesIrrigation.week = week;

            dosageProduct.forEach((product, indexProduct) => {
              const code = product.code;
              const timeValuesIrrigationProduct = timeValuesIrrigation[indexProduct];
              const { periods_dose, periods_product_irrigation } = timeValuesIrrigationProduct[week];
              newTimeValuesIrrigation[`code_${indexProduct}`] = code;
              newTimeValuesIrrigation[`periods_dose_${indexProduct}`] = periods_dose;
              newTimeValuesIrrigation[`periods_product_irrigation_${indexProduct}`] = periods_product_irrigation;
              newTimeValuesIrrigation[`caption_${indexProduct}`] = product.caption;

            });
            newTimeValuesIrrigation.periods_irrigation = 0;
            newTimeValuesIrrigation.periods_irrigation_time = 0;

            this.stepData.irrigationTable.push(newTimeValuesIrrigation);
          }
        }
      }
    },
    ...mapActions('productFertilizerStore', ['getProductFertilizers']),
    ...mapActions('Vademecum', ['getNutritionalProductDetail']),
    ...mapActions('nutritionStepper', ['next', 'back', 'resetTimeValuesIrrigation']),
  },
};
</script>
<style src="./dosageProduct.scss" scoped lang="scss"></style>

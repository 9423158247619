<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.name} > ${$t('Utilities.nutrition')}`" />
    <CopyNutritionPlanSearchEngine
      :plantations="plantationsFormatted"
      :go-to-view="'/searcher/home/'"
    />

    <f7-row class="margin-top">
      <f7-col width="20">
        <f7-button
          class="dx-btn-cancel margin-right btnBack"
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack"
        >
          {{ $t('Go_Back') }}
        </f7-button>
      </f7-col>

      <f7-col width="60" />

      <f7-col width="20">
        <f7-button
          v-if="isPlantationSelected"
          class="dx-btn-success button-raised"
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCopyNewPlantation"
          @click="copyPlantationToNewPlan"
        >
          {{ $t('copy') }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import navbar from '@/components/NavBar.vue';

import CopyNutritionPlanSearchEngine from '../../../components/wizardNutrition/copyNutritionPlanSearchEngine/index.vue';

export default {
  name: 'ViewCopyNutritionPlan',
  components: {
    navbar,
    CopyNutritionPlanSearchEngine,
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation', 'plantations', 'plantationsFormatted', 'isPlantationSelected', 'oldPlantation']),
    ...mapState('fertilizationStore', ['currentFertilizationId']),
    ...mapState('Plans', ['currentPlanId']),
  },
  methods: {
    async beforein() {
      try {
        this.setOldPlantation(this.currentPlantation);
        await this.fetchPlantations();
        this.setPlantationsFormatted(this.formattingPlantations());
        this.setIsPlantationSelected('');
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    copyPlantationToNewPlan() {
      this.setIsCopying(true);

      if (this.currentFertilizationId) {
        const url = this.$f7.views.main.router.generateUrl({
          name: 'utilities_nutrition_nutrition-step-one',
          params: { id: this.currentFertilizationId },
        });
        this.$f7.views.main.router.navigate(
          url,
          { reloadCurrent: true },
        );
      } else {
        const url = this.$f7.views.main.router.generateUrl({
          name: 'utilities_nutrition_nutrition-step-one',
          params: { id: this.currentPlanId },
        });
        this.$f7.views.main.router.navigate(
          url,
          { reloadCurrent: true },
        );
      }
    },
    goToBack() {
      this.setCurrentPlantation(this.oldPlantation);
      if (this.currentFertilizationId) {
        const url = this.$f7.views.main.router.generateUrl({
          name: 'utilities_nutrition_nutrition-detail',
          params: { id: this.currentFertilizationId },
        });
        this.$f7.views.main.router.navigate(
          url,
          { reloadCurrent: true },
        );
      } else {
        this.$f7.views.main.router.navigate('/viewPlanDetail/', { reloadCurrent: true });
      }
    },
    getStatusCampaign(initDate, endDate) {
      if (moment(this.actualDate).isBetween(initDate, endDate)) {
        return this.$t('status_campania_active');
      } if (endDate < this.actualDate) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    formattingPlantations() {
      const plantationsFormatted = [];
      for (const plantation of this.plantations) {
        if (plantation.species === this.currentPlantation.species && plantation.id !== this.currentPlantation.id) {
          plantationsFormatted.push({
            id: plantation.id,
            companyId: plantation.company_id,
            company: plantation.company,
            name: plantation.name,
            province: plantation.province,
            explotation: plantation.explotation,
            parcel: plantation.parcel,
            sector: plantation.sector,
            campaign: plantation.campaign,
            variety: plantation.variety,
            initDate: plantation.init_date,
            endDate: plantation.end_date,
            species: plantation.species,
            status: plantation.status === 'ACTIVA' ? this.$t('status_campania_active')
              : plantation.status === 'PENDIENTE' ? this.$t('status_campania_pending') : this.$t('status_campania_end'),
            area: plantation.area,
            coordinates: plantation.coordinates,
            sectorId: plantation.sector_id,
            sectorIdLeftComparator: false,
            sectorIdRightComparator: false,
            isPlantationSelected: false,
          });
        }
      }

      return plantationsFormatted;
    },

    ...mapActions('Plantation', ['setPlantationsFormatted', 'setIsPlantationSelected', 'fetchPlantations', 'setCurrentPlantation', 'setOldPlantation']),
    ...mapActions('nutritionStepper', ['setIsCopying']),
  },
};
</script>

<style scoped>
</style>

<template>
  <section class="converter-ground">
    <div class="content-devx">
      <div class="card-header padding-left-half">
        {{ $t('Utilities.converterGround.title') }}
      </div>
      <div class="card-body main">
        <div class="top margin-vertical padding-horizontal">
          <DxRadioGroup
            ref="rgUnit"
            class="radio-buttons"
            :data-source="itemUnitsGround"
            display-expr="label"
            layout="horizontal"
            @valueChanged="onChangeUnit"
          />
          <div class="right">
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('Utilities.converterGround.apparentDensity') }}
              </div>
              <div class="dx-field-value-static input-zoom">
                <DxNumberBox
                  ref="nbApparentDensity"
                  v-model="unitSelected.apparentDensity"
                  @keyUp="changeApparentDensity"
                />
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('Utilities.converterGround.soilDepth') }}
              </div>
              <div class="dx-field-value-static input-zoom">
                <DxNumberBox
                  ref="nbSoilDepth"
                  v-model="unitSelected.soilDepth"
                  @keyUp="changeSoilDepth"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body main">
        <div class="card-header padding-left-half">
          {{ $t('Utilities.converterGround.bottomTitle') }}
        </div>
        <div class="dx-field-section padding-horizontal">
          <div class="dx-field">
            <div class="dx-field-label text-align-center">
              {{ $t('Utilities.converterGround.meq') }}
            </div>
            <div class="dx-field-value-static input-zoom">
              <DxNumberBox
                ref="nbMeq"
                v-model="unitSelected.meq"
                @keyUp="changeMeq"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label text-align-right">
              {{ $t('Utilities.converterGround.ppm') }}
            </div>
            <div class="dx-field-value-static input-zoom">
              <DxNumberBox
                ref="nbPpm"
                :value="unitSelected.ppm"
                @keyUp="changePpm"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label text-align-right">
              {{ $t('Utilities.converterGround.percent') }}
            </div>
            <div class="dx-field-value-static input-zoom">
              <DxNumberBox
                ref="nbPercent"
                v-model="unitSelected.percent"
                @keyUp="changePercent"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label text-align-right">
              {{ $t('Utilities.converterGround.kg') }}
            </div>
            <div class="dx-field-value-static input-zoom">
              <DxNumberBox
                ref="nbKg"
                v-model="unitSelected.kg"
                :read-only="true"
                @keyUp="changeKg"
              />
            </div>
          </div>
          <DxButton
            :text="$t('Utilities.converterGround.reset')"
            type="default"
            @click="cleanData"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import DxRadioGroup from 'devextreme-vue/radio-group';
import DxNumberBox from 'devextreme-vue/number-box';
import DxButton from 'devextreme-vue/button';
import { mapState } from 'vuex';

export default {
  name: 'ConverterGround',
  components: {
    DxRadioGroup,
    DxNumberBox,
    DxButton,
  },
  props: [],
  data() {
    return {
      unitSelected: {},
      unitStatic: {},
    };
  },
  computed: {
    ...mapState('Converter', ['itemUnitsGround', 'soilDepth', 'apparentDensity']),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.$refs.rgUnit.instance.option('value', this.itemUnitsGround[0]);
      this.unitStatic = { ...this.itemUnitsGround[0] };
      this.unitStatic.apparentDensity = this.apparentDensity;
      this.unitStatic.soilDepth = this.soilDepth;
      this.unitSelected = { ...this.unitStatic };
      this.initCalculate();
    },
    cleanData() {
      this.initData();
    },
    initCalculate() {
      this.unitSelected.kg = this.calculateKg(this.unitSelected.ppm,
        this.unitSelected.apparentDensity,
        this.unitSelected.soilDepth);
      this.unitSelected.percent = this.calculatePercent(this.unitSelected.ppm);
      this.unitSelected.percent = this.roundValue(this.unitSelected.percent);
      this.unitSelected.kg = this.roundValue(this.unitSelected.kg);
    },
    onChangeUnit(e) {
      this.unitStatic = { ...e.value };
      this.unitStatic.apparentDensity = this.apparentDensity;
      this.unitStatic.soilDepth = this.soilDepth;
      this.unitSelected = { ...this.unitStatic };
      this.initCalculate();
    },
    changeApparentDensity(e) {
      this.unitSelected.kg = this.calculateKg(this.unitSelected.ppm,
        e.event.target.value,
        this.unitSelected.soilDepth);

      this.unitSelected.kg = this.roundValue(this.unitSelected.kg);
    },
    changeSoilDepth(e) {
      this.unitSelected.kg = this.calculateKg(this.unitSelected.ppm,
        this.unitSelected.apparentDensity,
        e.event.target.value);

      this.unitSelected.kg = this.roundValue(this.unitSelected.kg);
    },
    changeMeq(e) {
      this.unitSelected.ppm = this.calculatePpm(e.event.target.value);
      this.unitSelected.percent = this.calculatePercent(this.unitSelected.ppm);
      this.unitSelected.kg = this.calculateKg(this.unitSelected.ppm,
        this.unitSelected.apparentDensity,
        this.unitSelected.soilDepth);

      this.unitSelected.ppm = this.roundValue(this.unitSelected.ppm);
      this.unitSelected.percent = this.roundValue(this.unitSelected.percent);
      this.unitSelected.kg = this.roundValue(this.unitSelected.kg);
    },
    changePpm(e) {
      this.unitSelected.meq = this.calculateMeq(e.event.target.value);
      this.unitSelected.percent = this.calculatePercent(e.event.target.value);
      this.unitSelected.kg = this.calculateKg(e.event.target.value,
        this.unitSelected.apparentDensity,
        this.unitSelected.soilDepth);

      this.unitSelected.meq = this.roundValue(this.unitSelected.meq);
      this.unitSelected.percent = this.roundValue(this.unitSelected.percent);
      this.unitSelected.kg = this.roundValue(this.unitSelected.kg);
    },
    changePercent(e) {
      this.unitSelected.ppm = e.event.target.value * 10000;
      this.unitSelected.meq = this.calculateMeq(this.unitSelected.ppm);
      this.unitSelected.kg = this.calculateKg(this.unitSelected.ppm,
        this.unitSelected.apparentDensity,
        this.unitSelected.soilDepth);
      this.unitSelected.kg = this.roundValue(this.unitSelected.kg);
      this.unitSelected.meq = this.roundValue(this.unitSelected.meq);
      this.unitSelected.ppm = this.roundValue(this.unitSelected.ppm);
    },
    changeKg(e) {
      this.unitSelected.ppm = ((e.event.target.value / 10) / this.unitSelected.soilDepth)
                / this.unitSelected.apparentDensity;
      this.unitSelected.meq = this.calculateMeq(this.unitSelected.ppm);
      this.unitSelected.meq = this.roundValue(this.unitSelected.meq);
      this.unitSelected.ppm = this.roundValue(this.unitSelected.ppm);
    },
    calculateKg(ppm, da, sd) {
      return parseFloat(ppm) * parseFloat(da) * parseFloat(sd) * 10;
    },
    calculatePpm(meq) {
      return meq * this.unitStatic.ppm;
    },
    calculatePercent(ppm) {
      return ppm / 10000;
    },
    calculateMeq(ppm) {
      return ppm / this.unitStatic.ppm;
    },
    roundValue(val) {
      return parseFloat(val.toFixed(2));
    },
  },
};

</script>
<style src="./converterGround.scss" scoped lang="scss"></style>

<template>
  <f7-page>
    <navbar :text="`${$t('Searcher.treatment.title')} > ${$t('nutritionDetail.report.report')}`" />

    <f7-row
      id="report-header-image"
      style="max-height:200px;"
    >
      <f7-col width="5" />
      <f7-col width="40">
        <img
          v-if="loaded"
          :src="reportHeaderImage"
          style="width:100%;"
          alt="timac agro logo"
        >
      </f7-col>
      <f7-col width="10" />
      <f7-col
        width="20"
      >
        <p class="report-header-text">
          Polígono Arazuri-Orcoyen, Calle C, nº 32<br>
          31160 ORCOYEN Navarra - España<br>
          Teléfono 948 324 500 - Fax 948 324 032<br>
          timacagro@timacagro.es &#8226; www.timacagro.es<br>
          FÁBRICA LODOSA<br>
          Teléfono 948 662 308 - Fax 948 693 657
        </p>
      </f7-col>
      <f7-col width="20">
        <img
          v-if="loaded"
          :src="reportHeaderImageTwo"
          style="width:100%;"
          alt="certificados"
        >
      </f7-col>
      <f7-col width="5" />
    </f7-row>
    <HeaderReportInfo
      v-if="loaded"
      :explotation-data="actualPlantation"
      :data="treatmentPlan"
    />
    <ExplotationData
      v-if="loaded"
      :explotation-data="actualPlantation"
      :treatment-plan="treatmentPlan"
    />
    <TreatmentDetailPlanComponent
      v-if="loaded"
      :id="currentTreatmentId"
      :hide-back-button="true"
      :hide-detail="true"
      :hide-report-button="true"
    />
    <f7-row>
      <f7-col>
        <f7-button
          v-if="loaded"
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t('Button_go_to_back') }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          v-if="loaded"
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="downloadReport()"
        >
          {{ $t("nutritionDetail.report.download") }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Device } from 'framework7/framework7-lite.esm.bundle';
import navbar from '../../components/NavBar.vue';
import HeaderReportInfo from '../../components/headerReportInfo/index.vue';
import ExplotationData from '../../components/wizardTreatment/explotationData/index.vue';
import TreatmentDetailPlanComponent from '../../components/wizardTreatment/treatmentDetailPlan/index.vue';
import reportHeaderImage from '../../static/img/TIMAC-Agro.png';
import reportHeaderImageTwo from '../../static/img/reportHeaderTwo.png';

import PDFactory from '../../js/pdf-factory';

export default {
  name: 'ViewTreatmentReport',
  components: {
    navbar,
    HeaderReportInfo,
    ExplotationData,
    TreatmentDetailPlanComponent,

  },
  data() {
    return {
      loaded: false,
      fertilization: {},
      reportHeaderImage,
      reportHeaderImageTwo,
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('treatmentStepper', ['currentTreatmentId', 'treatmentPlan']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getPlantationById({ companyId: this.currentPlantation.companyId, plantationId: this.actualPlantation.id });
      await this.getTreatmentPlanByIdandCompanyId({ companyId: this.currentPlantation.companyId, treatmentId: this.currentTreatmentId });
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    goToBack() {
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_treatment_treatment-detail-plan',
        params: { id: this.currentTreatmentId },
      });
      this.$f7.views.main.router.navigate(
        url,
        { reloadCurrent: true },
      );
    },

    async downloadReport() {
      const pdfactory = new PDFactory();

      // -------------------------------------------------
      // Logo

      const headerImg = document.getElementById('report-header-image');
      await pdfactory.insertImageFromHTMLElementAsync(headerImg, 500);

      pdfactory.insertHeader(this.$t('nutritionDetail.report.report'), 'h1');

      // -------------------------------------------------
      // Treatment plan
      pdfactory.insertHeader(this.$t('nutritionDetail.report.treatmentPlan'), 'h2');
      pdfactory.insertTable(
        [
          this.$t('nutritionDetail.report.client'),
          this.$t('nutritionDetail.report.date'),
          this.$t('nutritionDetail.report.description'),
        ],
        [
          [
            this.treatmentPlan.company_name,
            this.actualPlantation.init_date,
            this.treatmentPlan.description,
          ],
        ],
      );

      // -------------------------------------------------
      // Explotation Data
      pdfactory.insertHeader(this.$t('nutritionDetail.report.explotationData'), 'h2');

      const rawarea = this.$helpers.getProperty(this.actualPlantation.farms[0].properties, 'area');
      pdfactory.insertVerticalTable(
        [
          this.$t('nutritionDetail.report.campaign'),
          this.$t('nutritionDetail.report.cropType'),
          this.$t('nutritionDetail.report.area'),
          this.$t('nutritionDetail.report.explotation'),
          this.$t('nutritionDetail.report.specie'),
          this.$t('nutritionDetail.report.plagueAgent'),
          this.$t('nutritionDetail.report.parcel'),
          this.$t('nutritionDetail.report.variety'),
          this.$t('nutritionDetail.report.dosage'),
          this.$t('nutritionDetail.report.sector'),
          this.$t('nutritionDetail.report.plantsDensity'),
          this.$t('nutritionDetail.report.plantation'),
          this.$t('nutritionDetail.report.plantsNumber'),
        ],
        [
          [
            this.actualPlantation.campaign,
            this.actualPlantation.crop_type,
            `${parseFloat(rawarea).toFixed(2)}  ha`,
            this.actualPlantation.explotation,
            this.actualPlantation.species,
            this.treatmentPlan.agent,
            this.actualPlantation.parcel,
            this.actualPlantation.variety,
            this.treatmentPlan.dose_type,
            this.actualPlantation.farms[0].name,
            `${this.actualPlantation.planting_density} ${this.$t('nutritionDetail.report.plantsHa')}`,
            this.actualPlantation.name,
            this.actualPlantation.plants,
          ],
        ],
      );

      pdfactory.insertPageBreak();

      // -------------------------------------------------
      // Products
      pdfactory.insertHeader(this.$t('treatment.treatmentPlan.treatmentDetailPlan.title'), 'h2');

      const columns = this.treatmentPlan.products.map((element) => {
        const totalDosage = element.periods.reduce((total, num) => total + num);
        return [
          element.product,
          totalDosage,
          totalDosage,
          totalDosage,
          element.price,
          element.unit_cost,
          element.total_cost,
        ];
      });

      pdfactory.insertVerticalTable(
        [
          this.$t('treatment.treatmentPlan.treatmentDetailPlan.products.product'),
          this.$t('treatment.treatmentPlan.treatmentDetailPlan.products.dosage'),
          this.$t('treatment.treatmentPlan.treatmentDetailPlan.products.caldoMedium'),
          this.$t('treatment.treatmentPlan.treatmentDetailPlan.products.dosageTotalKg'),
          this.$t('treatment.treatmentPlan.treatmentDetailPlan.products.price'),
          this.$t('treatment.treatmentPlan.treatmentDetailPlan.products.unitCost'),
          this.$t('treatment.treatmentPlan.treatmentDetailPlan.products.totalCost'),
        ],
        columns,
      );

      // -------------------------------------------------
      // Graph
      pdfactory.insertHeader(this.$t('treatment.treatmentPlan.treatmentDetailPlan.chartTitle'), 'h2');

      const graph = document.querySelector('.treatment-plan-product-chart .product-table');
      await pdfactory.insertImageFromHTMLElementAsync(graph, 500);

      // pdfactory.buildAndOpen();
      const title = `${this.$t('Searcher.treatment.title')}_${this.$t('nutritionDetail.report.report')}_${this.treatmentPlan.description}`;

      if (Device.desktop) {
        pdfactory.buildAndDownload(title);
      } else {
        const pdfdata = pdfactory.buildForMobile();

        pdfdata.getBase64((base64) => {
          const path = `${cordova.file.externalRootDirectory}Download/`;
          const fileExtension = '.pdf';

          this.savebase64AsPDF(path, title + fileExtension, base64, 'application/pdf');
        });
      }
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    savebase64AsPDF(folderpath, filename, content, contentType) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter((fileWriter) => {
            fileWriter.write(DataBlob);
            this.$notifyDX(
              {
                message: this.$t('FileDownloadedInDownloads'),
                width: 550,
              },
              'info',
              3000,
            );
          }, () => {
            this.$notifyDX(
              {
                message: this.$t('SheetExport_sheet_export_error'),
                width: 550,
              },
              'error',
              3000,
            );
          });
        });
      });
    },
    ...mapActions('RegisterPlantation', ['getPlantationById']),
    ...mapActions('treatmentStepper', ['getTreatmentPlanByIdandCompanyId']),
  },
};
</script>

<style scoped>
.dx-btn-cancel.margin-right {
  width: 15%;
  margin-left: 2%;
}
.report-header-text {
  overflow:hidden;
  max-height:180px;
}
</style>

<template>
  <div>
    <PopupSelectObservationsTaskReports
      v-if="showSelectObservationsTaskReports"
      :allTasks="tasks"
      :compareGis="comparePage"
      @close="closeSelectObservationsTaskReports"
    />
    <div class="hide-histogram-graph">
      <f7-row
        v-for="(histogram, index) of histograms"
        :key="`histogram-col-${index}`"
        style="width: 490px !important;"
      >
        <f7-col>
          <div :id="`histogram-by-class-graph-${index}`">
            <HistogramByClass
              v-if="histogram.histogram != null"
              :histogram="histogram.histogram"
              :showing-date="histogram.iso_date"
              :index="comparePage ? histogram.current_index : currentIndexes.GisTimeDimension"
              :max="comparePage ? 1 : histogramData[0].max"
              :min="comparePage ? 0 : histogramData[0].min"
              :is-for-pdf="true"
            />
          </div>
        </f7-col>
      </f7-row>
    </div>

    <div class="content-devx margin">
      <div class="margin title-section">
        {{ $t('dateRangeSelector') }}
      </div>
      <div class="margin">
        <div class="margin-bottom-half">
          <f7-row>
            <f7-col>
              <div class="dx-field">
                <div class="dx-field-label">
                  <span>{{ $t('dates') }}</span>
                </div>
                <div class="dx-field-value">
                  <f7-input
                    type="datepicker"
                    class="double-date-picker"
                    style="width: 200px"
                    :placeholder="
                      $t('PlanningComponent_datepicker_multiple_placeholder')
                    "
                    :clear-button="true"
                    :close-on-select="true"
                    :calendar-params="{
                      value: datePickerValue,
                      closeOnSelect: true,
                      rangePicker: true,
                      dateFormat: {
                        month: 'numeric',
                        day: 'numeric',
                        year: 'numeric',
                      },
                    }"
                    @calendar:change="setSearcherDates"
                  />
                </div>
              </div>
            </f7-col>
            <f7-col />
          </f7-row>
        </div>
      </div>

      <div class="margin title-section">
        {{ $t('itemsToDisplay') }}
      </div>
      <div class="margin">
        <div class="margin-bottom-half">
          <f7-row>
            <f7-col>
              <DxCheckBox v-model="showSelectObservationsTaskReports" :disabled="rangeDateNotSelected" @change="toggleObservationsTaskPopup" />
              {{ $t('Searcher.observations.observations') }} y {{ $t('Configuration.Tasks') }}
            </f7-col>
          </f7-row>
        </div>
      </div>

      <div class="margin-top padding-top margin title-section">
        {{ $t('weatherVariables') }}
      </div>
      <div class="weather-variables-container margin">
        <div class="check-box-container">
          <DxCheckBox
            v-model="showTemperatures"
            :disabled="rangeDateNotSelected"
            @valueChanged="updateClimateData"
          />
          {{ $t('Temperature') }} {{ $t('DxWeatherElementLinesGraph_average') }}
        </div>
        <div class="check-box-container">
          <DxCheckBox
            v-model="showTemperaturesMax"
            :disabled="rangeDateNotSelected"
            @valueChanged="updateClimateData"
          />
          {{ $t('Temperature') }} Max
        </div>
        <div class="check-box-container">
          <DxCheckBox
            v-model="showTemperaturesMin"
            :disabled="rangeDateNotSelected"
            @valueChanged="updateClimateData"
          />
          {{ $t('Temperature') }} Min
        </div>
        <div
          v-if="loadingWeatherData"
          class="spinner-weather-container"
        >
          <div class="spinner-weather" />
          <div class="margin-left">
            {{ $t('Sync.InfoLoading.Title') }}
          </div>
        </div>
      </div>
      <div class="weather-variables-container margin">
        <div class="check-box-container">
          <DxCheckBox
            v-model="showPrecipitations"
            :disabled="rangeDateNotSelected"
            @valueChanged="updateClimateData"
          />
          {{ $t('Precipitation') }}
        </div>
      </div>

      <div class="margin-top padding-top margin title-section">
        {{ $t('comments') }}
      </div>
      <div class="margin">
        <div>
          <DxTextBox
            :value="commentary"
            @valueChanged="onCommentaryChange"
          />
        </div>
      </div>
    </div>

    <div class="content-devx margin padding">
      <div
        id="custom-tool-tip"
        class="custom-tool-tip"
      >
        <div v-if="toolTipType === 'O'">
          <div class="tool-tip-title">
            {{ $t('Searcher.observations.observations') }}
          </div>
          <div class="tool-tip-value">
            <span
              style="font-weight: 600;"
            >{{ $t('Searcher.observations.date') }}:</span>
            <span>{{ toolTipData.date }}</span>
          </div>
          <div class="tool-tip-value">
            <span
              style="font-weight: 600;"
            >{{ $t('Searcher.observations.category') }}:</span>
            <span>{{ toolTipData.category }}</span>
          </div>
          <div class="tool-tip-value">
            <span
              style="font-weight: 600;"
            >{{ $t('Searcher.observations.subcategory') }}:</span>
            <span>{{ toolTipData.subcategory }}</span>
          </div>
        </div>
        <div v-if="toolTipType === 'T'">
          <div class="tool-tip-title">
            {{ $t('taskByCompany_Task') }}
          </div>
          <div class="tool-tip-value">
            <span
              style="font-weight: 600;"
            >{{ $t('taskByCompany_group') }}:</span>
            <span>{{ toolTipData.activity }}</span>
          </div>
          <div class="tool-tip-value">
            <span style="font-weight: 600;">{{ $t('name') }}:</span>
            <span>{{ toolTipData.name }}</span>
          </div>
          <div class="tool-tip-value">
            <span
              style="font-weight: 600;"
            >{{ $t('taskByCompany_init_date') }}:</span>
            <span>{{ toolTipData.init_date }}</span>
          </div>
          <div class="tool-tip-value">
            <span
              style="font-weight: 600;"
            >{{ $t('taskByCompany_end_date') }}:</span>
            <span>{{ toolTipData.end_date }}</span>
          </div>
        </div>
      </div>
      <DxChart
        id="chart-gis-report"
        :key="randomKeyChart"
        :data-source="datSourceGraph"
        :on-drawn="onDrawn"
        @legend-click="onLegendClick"
      >
        <DxCommonSeriesSettings
          type="line"
          argument-field="date"
        />
        <DxSeries
          v-if="!comparePage"
          value-field="mean"
          color="#eab308"
          :name="currentIndexes.GisTimeDimension"
        />
        <DxSeries
          v-if="comparePage"
          value-field="mean_left"
          color="#eab308"
          :name="currentIndexes.gisLeft + ' ' + 1"
        />
        <DxSeries
          v-if="comparePage"
          value-field="mean_right"
          color="#fde047"
          :name="currentIndexes.gisRight + ' ' + 2"
        />
        <DxSeries
          v-if="showPrecipitations && !comparePage"
          pane="rainfall"
          axis="rainfallPrecipitations"
          value-field="avgmeasuredPluviometer"
          :name="
            `${$t('DxWeatherElementLinesGraph_total')} Precipitación, L/m²`
          "
          type="bar"
          color="#38bdf8"
        />
        <DxSeries
          v-if="showPrecipitations && comparePage"
          pane="rainfall"
          axis="rainfallPrecipitations"
          value-field="avgmeasuredPluviometer_1"
          :name="
            `${$t('DxWeatherElementLinesGraph_total')} Precipitación 1, L/m²`
          "
          type="bar"
          color="#38bdf8"
        />
        <DxSeries
          v-if="showPrecipitations && comparePage"
          pane="rainfall"
          axis="rainfallPrecipitations"
          value-field="avgmeasuredPluviometer_2"
          :name="
            `${$t('DxWeatherElementLinesGraph_total')} Precipitación 2, L/m²`
          "
          type="bar"
          color="#075985"
        />
        <DxSeries
          v-if="showTemperaturesMax && !comparePage"
          pane="rainfall"
          axis="rainfallTemperatures"
          value-field="maxmeasuredTemperatura"
          :name="`Max Temperatura, °C`"
          color="#ca0020"
        />
        <DxSeries
          v-if="showTemperaturesMax && comparePage"
          pane="rainfall"
          axis="rainfallTemperatures"
          value-field="maxmeasuredTemperatura_1"
          :name="`Max Temperatura 1, °C`"
          color="#ca0020"
        />
        <DxSeries
          v-if="showTemperaturesMax && comparePage"
          pane="rainfall"
          axis="rainfallTemperatures"
          value-field="maxmeasuredTemperatura_2"
          :name="`Max Temperatura 2, °C`"
          color="#cd596b"
        />
        <DxSeries
          v-if="showTemperaturesMin && !comparePage"
          pane="rainfall"
          axis="rainfallTemperatures"
          value-field="minmeasuredTemperatura"
          :name="`Min Temperatura, °C`"
          color="#16a34a"
        />
        <DxSeries
          v-if="showTemperaturesMin && comparePage"
          pane="rainfall"
          axis="rainfallTemperatures"
          value-field="minmeasuredTemperatura_1"
          :name="`Min Temperatura 1, °C`"
          color="#16a34a"
        />
        <DxSeries
          v-if="showTemperaturesMin && comparePage"
          pane="rainfall"
          axis="rainfallTemperatures"
          value-field="minmeasuredTemperatura_2"
          :name="`Min Temperatura 2, °C`"
          color="#4ade80"
        />
        <DxSeries
          v-if="showTemperatures && !comparePage"
          pane="rainfall"
          axis="rainfallTemperatures"
          value-field="avgmeasuredTemperatura"
          :name="`${$t('DxWeatherElementLinesGraph_average')} Temperatura, °C`"
          color="#7b3294"
        />
        <DxSeries
          v-if="showTemperatures && comparePage"
          pane="rainfall"
          axis="rainfallTemperatures"
          value-field="avgmeasuredTemperatura_1"
          :name="
            `${$t('DxWeatherElementLinesGraph_average')} Temperatura 1, °C`
          "
          color="#7b3294"
        />
        <DxSeries
          v-if="showTemperatures && comparePage"
          pane="rainfall"
          axis="rainfallTemperatures"
          value-field="avgmeasuredTemperatura_2"
          :name="
            `${$t('DxWeatherElementLinesGraph_average')} Temperatura 2, °C`
          "
          color="#c34feb"
        />
        <DxSeries
          v-for="(observation, index) in selectedCategoriesTitles"
          :key="'observacion-' + index"
          :name="observationTitleName(observation)"
          :color="observation.color"
        />
        <DxSeries
          v-for="(task, index) in selectedTasksTitles"
          :key="'Tarea-' + index"
          :name="taskTitleName(task)"
          :color="task.color"
        />
        <DxLegend
          vertical-alignment="bottom"
          horizontal-alignment="center"
          item-text-position="right"
        />
        <DxTooltip
          :enabled="true"
          :z-index="999999"
          :customize-tooltip="customizeTooltip"
        />
        <DxArgumentAxis argument-type="datetime">
          <DxConstantLine
            v-for="constantLine in constantLinesObservations"
            :key="constantLine.id + constantLine.position"
            :width="2"
            :value="constantLine.date"
            :color="constantLine.color"
            dash-style="dash"
          >
            <DxLabel :text="'O - ' + constantLine.id" />
          </DxConstantLine>
          <DxConstantLine
            v-for="constantLine in constantLinesTasks"
            :key="constantLine.id + constantLine.position"
            :width="2"
            :value="constantLine.init_date"
            :color="constantLine.color"
            dash-style="dash"
          >
            <DxLabel :text="'T - ' + constantLine.id" />
          </DxConstantLine>
          <DxLabel
            display-mode="stagger"
            format="yyyy-MM-dd"
          />
        </DxArgumentAxis>

        <DxPane
          v-if="
            (showTemperatures || showPrecipitations || showTemperaturesMax || showTemperaturesMin) &&
              fetchedWeatherData === true &&
              loadingWeatherData === false
          "
          name="rainfall"
        />
        <DxPane name="bottomPane" />

        <DxValueAxis pane="bottomPane" />
        <DxValueAxis
          v-if="showTemperatures || showTemperaturesMax || showTemperaturesMin"
          :synchronized-value="0"
          pane="rainfall"
          name="rainfallTemperatures"
          position="right"
        >
          <DxGrid :visible="true" />
          <DxTitle text="Cº" />
        </DxValueAxis>
        <DxValueAxis
          v-if="showPrecipitations"
          :synchronized-value="0"
          pane="rainfall"
          name="rainfallPrecipitations"
          position="left"
        >
          <DxGrid :visible="true" />
          <DxTitle text="L/m²" />
        </DxValueAxis>
      </DxChart>
    </div>
  </div>
</template>

<script>
import { DxTagBox } from 'devextreme-vue/tag-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxSelectBox from 'devextreme-vue/select-box';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  DxChart,
  DxSeries,
  DxValueAxis,
  DxLabel,
  DxConstantLine,
  DxLegend,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxTooltip,
  DxGrid,
  DxTitle,
  DxPane,
} from 'devextreme-vue/chart';
import DxTextBox from 'devextreme-vue/text-box';
import moment from 'moment';
import Vue from 'vue';
import Api from '../../../../services/Api';
import HistogramByClass from '../histogramByClass/index.vue';
import eventBus from '../../../../js/event-bus';
import PopupSelectObservationsTaskReports from '../../../../components/reports/popupSelectObservationsTaskReports.vue';

export default {
  name: 'ReportStep2',
  components: {
    DxTooltip,
    DxCheckBox,
    DxSelectBox,
    DxTagBox,
    DxChart,
    DxSeries,
    DxValueAxis,
    DxLabel,
    DxConstantLine,
    DxLegend,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxGrid,
    DxTitle,
    DxPane,
    DxTextBox,
    HistogramByClass,
    PopupSelectObservationsTaskReports
  },
  props: {
    comparePage: {
      type: Boolean,
      default: false,
    },
    selectedDates: {
      type: Array,
      default: () => [],
    },
    // Añadimos HistogramData para poder meter al componente HistogramByClass el max y min necesario para el NDVI_CON
    // ya que este es dinamico y los demas estaticos (viene de la store Gis histogram)
    histogramData: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      step: 1,
      fetchedWeatherData: false,
      loadingWeatherData: false,
      selectedSubCategories: [],
      selectedTaskActivities: [],
      selectedTaskDefinition: {},
      temperaturesDataSource: [],
      precipitationsDataSource: [],
      showTemperatures: false,
      showTemperaturesMax: false,
      showTemperaturesMin: false,
      showPrecipitations: false,
      showSelectObservationsTaskReports: false,
      hideObservations: {},
      hideTasks: {},
      histograms: [],
      toolTipType: '',
      toolTipData: {},
      commentary: '',
      randomKeyChart: 'chart-gis-report',
      indexLimits: {
        NDVI: {
          max: 1,
          min: 0,
        },
        NDVI_CONT: {
          max: 1,
          min: 0,
        },
        SAVI: {
          max: 1,
          min: 0,
        },
        ARVI: {
          max: 1,
          min: 0,
        },
        VARI: {
          max: 1,
          min: -0.6,
        },
        LAI: {
          max: 1,
          min: 0,
        },
        NDRE: {
          max: 1,
          min: 0,
        },
        RECI: {
          max: 1,
          min: -0.6,
        },
        NDMI: {
          max: 1,
          min: -1,
        },
        GNDVI: {
          max: 1,
          min: 0,
        },
        GLI: {
          max: 1,
          min: -1,
        },
        TCI: {
          max: 1,
          min: -1,
        },
        MSAVI: {
          max: 1,
          min: -0.8,
        },
      },
    };
  },
  computed: {
    ...mapState('SearcherObservations', [
      'categoriesOfTemplates',
      'templatesByCategory',
    ]),
    ...mapState('TaskByCompany', ['taskActivity', 'taskDefinition']),
    ...mapState('Gis', [
      'currentIndexes',
      'currentInitIntervalDates',
      'currentEndIntervalDates',
      'timelineIndexes',
      'datesList',
      'cloudsValues',
    ]),
    ...mapState('Plantation', [
      'currentPlantation',
      'sectorIdLeftComparator',
      'sectorIdRightComparator',
      'LeftComparatorCompanyId',
      'RightComparatorCompanyId',
      'LeftComparatorPlantationId',
      'RightComparatorPlantationId',
    ]),
    ...mapState('ReportGis', [
      'initDate',
      'endDate',
      'observations',
      'timelineIndexes',
      'weatherData',
    ]),
    ...mapState('TimeDimension', ['tasks', 'observations', 'selectedTasks', 'selectedObservations']),
    ...mapGetters('weatherFarm', [
      'dailyMeasuredTemperatures',
      'dailyForecastedTemperatures',
      'dailyMeasuredPrecipitations',
      'dailyForecastedPrecipitations',
    ]),
    rangeDateNotSelected() {
      return this.initDate === '' || this.endDate === '';
    },
    datePickerValue() {
      if (this.initDate !== '' && this.endDate !== '' && !this.comparePage) {
        return [
          moment(this.initDate).format('YYYY-MM-DD'),
          moment(this.endDate).format('YYYY-MM-DD'),
        ];
      }

      return [];
    },
    datSourceGraph() {
      let indexes = this.timelineIndexes;

      if (this.showTemperatures || this.showTemperaturesMax || this.showTemperaturesMin) {
        indexes = indexes.concat(this.weatherData.temperature);
      }

      if (this.showPrecipitations) {
        indexes = indexes.concat(this.weatherData.pluviometer);
      }

      return indexes;
    },
    constantLinesObservations() {
      // Verificar si todos los elementos de selectedObservations tienen selected como falso
      const allUnchecked = this.selectedObservations.every(observation => !observation.selected);
      if (allUnchecked) return [];
      return this.selectedObservations.filter((observation) => {
        const state = this.hideObservations[
          `Observaciones - ${observation.scouting_point.name}`
        ];
        return state === undefined || state === false;
      });
    },
    constantLinesTasks() {
      // Verificar si todos los elementos de selectedTasks tienen selected como falso
      const allUnchecked = this.selectedTasks.every(task => !task.selected);
      if (allUnchecked) return [];
      return this.selectedTasks.filter((task) => {
        const state = this.hideTasks[`Tarea - ${task.activity}`];
        return state === undefined || state === false;
      });
    },
    getAvaiableDates() {
      return this.datesList.avaiable_dates.map((item) => moment(item).format('DD-MM-YYYY'));
    },
    selectedCategoriesTitles() {
      // Verificar si todos los elementos de selectedTasks tienen selected como falso
      const allUnchecked = this.selectedObservations.every(observation => !observation.selected);
      if (allUnchecked) return [];

      const seen = {};
      return this.selectedObservations.filter((item) => {
        if (seen[item.category + item.position] === undefined) {
          seen[item.category + item.position] = true;
          return true;
        }

        return false;
      });
    },
    selectedSubCategoriesTitles() {
      // Verificar si todos los elementos de selectedTasks tienen selected como falso
      const allUnchecked = this.selectedObservations.every(observation => !observation.selected);
      if (allUnchecked) return [];

      const seen = {};
      return this.observations.filter((item) => {
        if (seen[item.subcategory + item.position] === undefined) {
          seen[item.subcategory + item.position] = true;
          return true;
        }

        return false;
      });
    },
    selectedTasksTitles() {
      // Verificar si todos los elementos de selectedTasks tienen selected como falso
      const allUnchecked = this.selectedTasks.every(task => !task.selected);
      if (allUnchecked) return []

      const seen = {};
      return this.selectedTasks.filter((item) => {
        if (seen[item.activity + item.position] === undefined) {
          seen[item.activity + item.position] = true;
          return true;
        }

        return false;
      });
    },
  },
  watch: {
    async showTemperatures() {
      if (!this.fetchedWeatherData) {
        this.fetchedWeatherData = true;

        await this.getWeatherData();
      }
    },
    async showTemperaturesMin() {
      if (!this.fetchedWeatherData) {
        this.fetchedWeatherData = true;

        await this.getWeatherData();
      }
    },
    async showTemperaturesMax() {
      if (!this.fetchedWeatherData) {
        this.fetchedWeatherData = true;

        await this.getWeatherData();
      }
    },
    async showPrecipitations() {
      if (!this.fetchedWeatherData) {
        this.fetchedWeatherData = true;

        await this.getWeatherData();
      }
    },
    rangeDateNotSelected(value) {
      if (value) {
        this.selectedSubCategories = [];
        this.selectedTaskActivities = [];
        this.resetObservations();
        this.resetTasks();
        this.resetWeatherData();
        this.resetIndexData();
      }
    },
  },
  async beforeMount() {
    eventBus.$on('lateral-panel-toggle', () => {
      this.randomKeyChart = `chart-gis-report-${Math.random()}`;
    });

    this.$f7.preloader.show();

    try {
      if (!this.comparePage) {
        await this.fetchAllHistogram();
      } else {
        await this.fetchAllHistogramCompare();
      }
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(this.$helpers.getErrorMSG(error)),
          width: 550,
        },
        'error',
        3000,
      );
    }

    try {
      this.initStore();
      await this.getCategoriesOfTemplates();
      await this.getTasksForActivity();

      this.setCurrentInitIntervalDates({
        key: 'GisTimeDimension',
        newCurrentInitIntervalDate: moment(this.initDate).format(
          'YYYY-MM-DDT00:00:00',
        ),
      });
      this.setCurrentEndIntervalDates({
        key: 'GisTimeDimension',
        newCurrentEndIntervalDate: moment(this.endDate).format(
          'YYYY-MM-DDT00:00:00',
        ),
      });

      this.setWeatherSearchInitDate(
        moment(this.initDate).format('YYYY-MM-DDT00:00:00'),
      );
      this.setWeatherSearchEndDate(
        moment(this.endDate).format('YYYY-MM-DDT00:00:00'),
      );
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(this.$helpers.getErrorMSG(error)),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.$f7.preloader.hide();
    }
    await this.getDataClouds();
  },
  methods: {
    ...mapActions('SearcherObservations', [
      'initStore',
      'getCategoriesOfTemplates',
      'getTemplatesByCategory',
    ]),
    ...mapActions('TaskByCompany', [
      'getTasksForActivity',
      'getTasksForDefinition',
    ]),
    ...mapActions('weatherFarm', [
      'fetchWeatherForecastData',
      'fetchMeasuredWeatherData',
    ]),
    ...mapActions('weather', [
      'setWeatherSearchInitDate',
      'setWeatherSearchEndDate',
    ]),
    ...mapActions('Gis', [
      'setCurrentInitIntervalDates',
      'setCurrentEndIntervalDates',
      'fetchTimelineIndexes',
      'setCloudsValues',   
    ]),
    ...mapActions('ReportGis', [
      'fetchObservations',
      'resetObservations',
      'removeObservations',
      'setInitDate',
      'setEndDate',
      'fetchWeatherData',
      'fetchTasks',
      'removeTasks',
      'resetTasks',
      'resetWeatherData',
      'resetIndexData',
    ]),
    ...mapActions('TimeDimension', ['fetchTasks', 'resetData']),
    closeSelectObservationsTaskReports() {
      this.showSelectObservationsTaskReports = false;
    },
    // Método que envia los parametros para los datos climaticos
    updateClimateData() {
      const climates = [];
      if (this.showTemperatures) climates.push('TEMPERATURE');
      if (this.showTemperaturesMax) climates.push('TEMPERATURE_MAX');
      if (this.showTemperaturesMin) climates.push('TEMPERATURE_MIN');
      if (this.showPrecipitations) climates.push('PLUVIOMETER');
      
      this.$emit('data-climates', climates);
    },
    // Abrir popup tareas/observaciones
    toggleObservationsTaskPopup() {
      this.showSelectObservationsTaskReports = !this.showObservationsPopup;
    },
    // Funcion para sacar los resultados del porcentaje de nubes en las fechas seleccionadas
    async getDataClouds() {
      try {
        let formattedCloudsDates = this.selectedDates.map(date => moment(date).format('YYYY-MM-DD'));
        this.setCloudsValues([]); // Inicializar el array para almacenar los resultados de cada solicitud

        const cloudPromises = formattedCloudsDates.map(date =>
          Api.getGeoServerClouds(this.currentPlantation.sectorId, date)
            .then(result => {
                if (result.response) {
                  return JSON.parse(result.response).clouds;
                } else {
                  return 0; 
                }
            })
            .catch(error => {
              console.error('Error fetching cloud data:', error.cause);
              return 0; // Retorna 0 si hay un error en la solicitud
            })
        );

        const results = await Promise.all(cloudPromises);
        this.setCloudsValues(results); // Actualizar el estado con los resultados de nubes
      } catch (error) {
        console.error('error:', error);
      }
    },
    // Funcion para obtener todas las tareas
    async getTasks() {
      this.resetData();
      try {
        const leftTasksPromises = this.taskActivity.map(activity => 
          this.fetchTasks({
            idCompany: this.LeftComparatorCompanyId,
            idPlantation: this.LeftComparatorPlantationId,
            activity,
            initDate: this.initDate,
            endDate: this.endDate
          })
        );
        const rightTasksPromises = this.taskActivity.map(activity => 
          this.fetchTasks({
            idCompany: this.RightComparatorCompanyId,
            idPlantation: this.RightComparatorPlantationId,
            activity,
            initDate: this.initDate,
            endDate: this.endDate
          })
        );

        // Ejecutar todas las promesas de la izquierda y la derecha en paralelo
        await Promise.all([...leftTasksPromises, ...rightTasksPromises]);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    subcategoryTitleName(subcategory) {
      if (subcategory.position) {
        return `${this.$t('Configuration.Observations')} ${
          subcategory.position
        } - ${subcategory.subcategory}`;
      }

      return `${this.$t('Configuration.Observations')} - ${
        subcategory.subcategory
      }`;
    },
    observationTitleName(category) {
      if (category.position) {
        return `${this.$t('Configuration.Observations')} ${
          category.position
        } - ${category.scouting_point.name}`;
      }

      return `${this.$t('Configuration.Observations')} - ${
        category.scouting_point.name
      }`;
    },
    taskTitleName(task) {
      if (task.position) {
        return `${this.$t('taskByCompany_Task')} ${task.position} - ${
          task.activity
        }`;
      }

      return `${this.$t('taskByCompany_Task')} - ${task.activity}`;
    },
    // Funcion que guarda comentario y se hace un emit para que lo recoja el padre (report.vue)
    onCommentaryChange(e) {
      this.commentary = e.value;
      this.$emit('commentaryChanged', this.commentary);
    },
    customizeTooltip(pointInfo) {
      const valueTitle = this.indexLimits[pointInfo.seriesName] === undefined ? this.$t('weather_parameter_value') : this.$t('mean');

      return {
        html: `<div><div class='tooltip-header'>${moment(
          pointInfo.argument,
        ).format('DD-MM-YYYY')}</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('index')}: </span>${pointInfo.seriesName}
          </span> </div>
          <span class="bold"> ${valueTitle}:</span> ${pointInfo.valueText}
        </span> </div></div></div>`,
      };
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }

      if (series.name.includes('Observaciones')) {
        if (this.hideObservations[series.name] == null) {
          Vue.set(this.hideObservations, series.name, true);
        } else {
          Vue.delete(this.hideObservations, series.name);
        }
      }

      if (series.name.includes('Tarea')) {
        if (this.hideTasks[series.name] == null) {
          Vue.set(this.hideTasks, series.name, true);
        } else {
          Vue.delete(this.hideTasks, series.name);
        }
      }
    },
    async setSearcherDates(e) {
      if (e.length === 0) {
        this.setInitDate('');
        this.setEndDate('');
        return;
      }

      if (e.length === 2) {
        this.setInitDate(moment(e[0], 'YYYY/MM/DD').format('YYYY-MM-DD'));
        this.setEndDate(moment(e[1], 'YYYY/MM/DD').format('YYYY-MM-DD'));

        this.setWeatherSearchInitDate(
          moment(e[0], 'YYYY/MM/DD').format('YYYY-MM-DDT00:00:00'),
        );
        this.setWeatherSearchEndDate(
          moment(e[1], 'YYYY/MM/DD').format('YYYY-MM-DDT00:00:00'),
        );

        if (this.showTemperatures || this.showPrecipitations || this.showTemperaturesMax || this.showTemperaturesMin) {
          await this.getWeatherData();
        }
        
        // Cargamos todas las tareas disponibles en las fechas seleccionadas solo para la vista de analisis (vista de comparacion)
        if (this.comparePage) {
          this.getTasks();
        }
        this.$emit('refresh-data');
      }
    },
    async getWeatherData() {
      if (this.initDate === '' || this.endDate === '') {
        this.fetchedWeatherData = false;
        return;
      }

      this.loadingWeatherData = true;

      if (this.comparePage) {
        // getch weather 2 times
        await this.fetchWeatherData({
          companyId: this.LeftComparatorCompanyId,
          plantationId: this.LeftComparatorPlantationId,
          initDate: moment(this.initDate).format('YYYY-MM-DDT00:00:00'),
          endDate: moment(this.endDate).format('YYYY-MM-DDT00:00:00'),
          name: '_1',
        });

        await this.fetchWeatherData({
          companyId: this.RightComparatorCompanyId,
          plantationId: this.RightComparatorPlantationId,
          initDate: moment(this.initDate).format('YYYY-MM-DDT00:00:00'),
          endDate: moment(this.endDate).format('YYYY-MM-DDT00:00:00'),
          name: '_2',
        });
      } else {
        await this.fetchWeatherData({
          companyId: this.currentPlantation.companyId,
          plantationId: this.currentPlantation.id,
          initDate: moment(this.initDate).format('YYYY-MM-DDT00:00:00'),
          endDate: moment(this.endDate).format('YYYY-MM-DDT00:00:00'),
        });
      }

      this.loadingWeatherData = false;
      this.fetchedWeatherData = true;
    },
    async fetchAllHistogram() {
      const histogramsData = [];
      for (const date of this.selectedDates) {
        histogramsData.push(
          this.getFormattedHistogram(
            this.currentPlantation.sectorId,
            moment(date).format('YYYY-MM-DD'),
            this.currentPlantation.companyId,
            this.currentIndexes.GisTimeDimension,
          ),
        );
      }

      this.histograms = await Promise.all(histogramsData);
    },
    async fetchAllHistogramCompare() {
      const histogramsData = [];
      histogramsData.push(
        this.getFormattedHistogram(
          this.sectorIdLeftComparator,
          this.currentInitIntervalDates.gisLeft ?? moment(this.datesList.avaiable_dates[this.datesList.avaiable_dates.length - 1]).format('YYYY-MM-DD'),
          this.LeftComparatorCompanyId,
          this.currentIndexes.gisLeft,
        ),
      );
      histogramsData.push(
        this.getFormattedHistogram(
          this.sectorIdRightComparator,
          this.currentInitIntervalDates.gisRight ?? moment(this.datesList.avaiable_dates[this.datesList.avaiable_dates.length - 1]).format('YYYY-MM-DD'),
          this.RightComparatorCompanyId,
          this.currentIndexes.gisRight,
        ),
      );

      this.histograms = await Promise.all(histogramsData);
    },
    async fetchHistogram(location, date, company) {
      const xhr = await Api.getGeoServerTemperatureHistogram(
        location,
        date,
        company,
      );

      return xhr.json().then((res) => res.data);
    },
    async getFormattedHistogram(sectorId, date, company, currentIndex) {
      const histogram = await this.fetchHistogram(
        sectorId,
        moment(date).format('YYYY-MM-DD'),
        company,
      );

      if (histogram.length === 0) return {};

      const histogramData = histogram[0].values.find(
        (item) => item.index === currentIndex.toLowerCase(),
      );

      return {
        histogram: histogramData.histogram,
        iso_date: histogram[0].date,
        current_index: currentIndex,
      };
    },
    onDrawn(e) {
      const dom = e.element.getElementsByClassName(
        'dxc-constant-lines-group',
      )?.[1];

      const allConstant = dom?.querySelectorAll('.dxc-arg-constant-lines') ?? [];

      for (const constant of allConstant) {
        if (constant.innerHTML.length > 0) {
          for (let i = 0; i < constant.children.length; i += 2) {
            const position = constant.children[i].getBoundingClientRect();
            const color = constant.children[i].getAttribute('stroke');
            const _this = this;

            constant.children[i].addEventListener(
              'mouseenter',
              function (event) {
                const lateralBar = document.querySelectorAll(
                  '.panel-in-breakpoint .content-menu-header.justify-content-center',
                );
                const lateralBarWidth = lateralBar[0] !== undefined ? lateralBar[0].offsetWidth : 0;

                constant.children[i].setAttribute('stroke', 'black');

                const [type, id] = constant.children[i + 1].innerHTML.split(
                  ' - ',
                );

                if (type === 'T') {
                  const task = _this.selectedTasks?.find((item) => item.id === id);
                  _this.toolTipType = type;
                  _this.toolTipData = {
                    ...task,
                    init_date: moment(task.init_date).format('DD/MM/YYYY'),
                    end_date: moment(task.end_date).format('DD/MM/YYYY'),
                  };
                } else if (type === 'O') {
                  const observation = _this.selectedObservations?.find(
                    (item) => item.id === id,
                  );
                  _this.toolTipType = type;
                  _this.toolTipData = {
                    ...observation,
                    date: moment(observation.date).format('DD/MM/YYYY'),
                  };
                }

                this.showTool = true;
                const tool = document.getElementById('custom-tool-tip');
                tool.style.cssText = `display: block;position: absolute; overflow: hidden; pointer-events: none; left: ${position.left
                - 68
                - lateralBarWidth}px; top: -20px;`;
              },
              false,
            );

            constant.children[i].addEventListener(
              'mouseleave',
              function (event) {
                constant.children[i].setAttribute('stroke', color);
                const tool = document.getElementById('custom-tool-tip');
                tool.style.cssText = 'display: none !important;';
                this.showTool = false;
              },
              false,
            );

            constant.children[i + 1].style.cssText = 'display: none !important;';
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './reportGis.styles.scss';
</style>

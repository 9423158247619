<template>
  <div>
    <!-- <navbar :text="$t('WeatherFarmCockpit_navbar_title')" />
    <f7-subnavbar class="subnavbar">
      <div class="subnavbar__element">
        <span>Data Frequency:</span>
        <DxSelectBox
          ref="measuringSelect"
          :data-source="measuringFrequency"
          :value="measuringFrequency[0]"
          :on-value-changed="updateMeasuringFrequency"
        />
      </div>
    </f7-subnavbar> -->
    <div>
      <div class="content-devx">
        <f7-row>
          <f7-col width="30">
            <f7-block>
              <f7-row>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t("WeatherStationIdentifier") }}
                  </div>
                </f7-col>
              </f7-row>
              <f7-row style="align-items: center">
                <f7-col class="margin-top-half">
                  {{ station }}
                </f7-col>
              </f7-row>
            </f7-block>
          </f7-col>

          <f7-col width="50">
            <div class="container-agrupation-range-data">
              <div>
                <div
                  class="label-text-data padding-b-12"
                >
                  {{ $t("weather_agrupation_data") }}
                </div>
                <div>
                  <DxSelectBox
                    :data-source="measuringFrequency"
                    :value="measuringFrequency[0]"
                    :on-value-changed="updateMeasuringFrequency"
                    :display-expr="displayTranslatedMeasuringFrequency"
                    width="max-content"
                  />
                </div>
              </div>

              <div style="width: 100%">
                <div class="label-text-data">
                  {{ $t("dateRange") }}
                </div>
                <div
                  class="label-text-data-opacity-1"
                >
                  <div
                    class="label-text-data-opacity-1"
                  >
                    <CalendarFilter
                      @searchFilterWeather="searchFilterWeather"
                      @clearFilteredDataWeather="clearFilteredDataWeather"
                    />
                  </div>
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
        <!--<f7-row
          v-if="dataIsNotAvailableForRange"
          style="margin-left: 8px"
        >
          <f7-col>
            <div class="alert alert-warning alert-white rounded">
              <div class="icon-container">
                <f7-icon md="material:warning" />
              </div>
              <h5>{{ $t('noDataInRange') }}</h5>
            </div>
          </f7-col>
        </f7-row>-->
      </div>
    </div>
    <div class="content__graphs">
      <f7-row
        v-if="loaded"
        no-gap
      >
        <f7-col
          width="100"
          medium="100"
        >
          <DxWeatherElementLinesGraph
            v-if="loaded"
            :data-source="data"
            weather-element="temperature"
          />

          <DxWeatherElementLinesGraph
            :data-source="data"
            weather-element="humidity"
          />
        </f7-col>
      </f7-row>
    </div>
    <div>
      <f7-col
        v-if="loaded"
        width="100"
        medium="100"
      >
        <DxWeatherElementLinesGraph
          :data-source="data"
          weather-element="solar_radiation"
        />
        <DxWeatherElementLinesGraph
          :data-source="data"
          weather-element="wind_speed"
        />
        <DxWeatherElementBarsGraph
          :data-source="data"
          weather-element="pluviometer"
        />
      </f7-col>
    </div>
  </div>
</template>

<script>

import DxSelectBox from 'devextreme-vue/select-box';
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import * as overlay from 'devextreme/ui/overlay';
import DxWeatherElementLinesGraph from './weatherElementLinesGraph/index.vue';
import DxWeatherElementBarsGraph from './weatherElementBarsGraph/index.vue';
import navbar from '../../NavBar.vue';
import CalendarFilter from './calendarFilter/index.vue';

export default {
  name: 'WeatherGraph',
  components: {
    navbar,
    DxWeatherElementLinesGraph,
    DxWeatherElementBarsGraph,
    DxSelectBox,
    CalendarFilter,
  },
  data() {
    return {
      loaded: false,
      keyName: 'searcher/weatherGraph',
      data: [],
      station: '',
    };
  },
  computed: {
    ...mapState('weatherFarm', [
      'measuringFrequency',
      'selectedFarmID',
      'selectedMeasuringFrequency',
      'measuredProvider',
    ]),
    ...mapState('Plantation', ['currentPlantation', 'treeLocations', 'searcherLocation']),
    ...mapState('weather', ['searchInitDate', 'searchEndDate', 'parameters']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  beforeMount() {
    this.$fa.setScreenName('Buscador > Clima');
    overlay.baseZIndex(9999);
  },
  async mounted() {
    this.$f7.preloader.show();
    this.setSelectedMeasuringFrequency('HOURLY');

    await this.requestData(moment(new Date().setDate(new Date().getDate() - 7)).format('YYYY-MM-DD HH:mm:ss'), moment(new Date().setDate(new Date().getDate() + 7)).format('YYYY-MM-DD HH:mm:ss'));

    this.$f7.preloader.hide();
  },
  methods: {
    async requestData(initDate, endDate, group = '') {
      this.loaded = false;
      const newData = await this.getClimateData({
        initDate,
        endDate,
        parameters: this.parameters,
        sectorId: this.currentPlantation.sectorId,
        grouped: group,
        formula: '',
      });

      this.$f7.preloader.hide();

      const newMeasured = this.formatParamsMeasured(newData);
      const newForecast = this.formatParamsForecasted(newData);
      this.data = newMeasured.concat(newForecast);
      this.station = newData?.station ?? '';
      this.loaded = true;
    },
    formatParamsMeasured(data) {
      for (const param in data.measured.parameters) {
        const weatherElements = param.split('_');
        const weatherElementUnits = param.split('_');

        let weatherElement = null;
        let weatherElementUnit = null;

        if (weatherElements.length - 1 === 2) {
          weatherElement = `${weatherElements[0]}_${weatherElements[1]}`;
        } else {
          weatherElement = weatherElements[0];
        }
        if (weatherElementUnits.includes('MIN')) {
          weatherElementUnit = 'MIN';
        } else if (weatherElementUnits.includes('MAX')) {
          weatherElementUnit = 'MAX';
        }

        let key = '';
        if (!weatherElementUnit || !weatherElement) {
          key = `avgmeasured${param.toLowerCase()}`;
        } else {
          key = `${weatherElementUnit.toLowerCase()}measured${weatherElement.toLowerCase()}`;
        }

        if (key.endsWith('_avg')) {
          const index = key.indexOf('_avg');
          key = key.substring(0, index);
        } else if (key.endsWith('_min')) {
          const index = key.indexOf('_min');
          key = key.substring(0, index);
        } else if (key.endsWith('_max')) {
          const index = key.indexOf('_max');
          key = key.substring(0, index);
        }

        data.measured.parameters[param] = data.measured.parameters[
          param
        ].map((parameter) => ({
          unit: parameter.unit,
          [key]: parameter.value,
          date: parameter.date,
        }));
      }

      const paramsFormatted = [];
      for (const param in data.measured.parameters) {
        paramsFormatted.push(...data.measured.parameters[param]);
      }

      return paramsFormatted;
    },
    formatParamsForecasted(data) {
      for (const param in data.forecast.parameters) {
        const weatherElements = param.split('_');
        const weatherElementUnits = param.split('_');

        let weatherElementUnit = null;
        let weatherElement = null;

        if (weatherElements.length - 1 === 2) {
          weatherElement = `${weatherElements[0]}_${weatherElements[1]}`;
        } else {
          weatherElement = weatherElements[0];
        }

        if (weatherElementUnits.includes('MIN')) {
          weatherElementUnit = 'MIN';
        } else if (weatherElementUnits.includes('MAX')) {
          weatherElementUnit = 'MAX';
        }

        let key = '';
        if (!weatherElementUnit || !weatherElement) {
          key = `avgforecast${param.toLowerCase()}`;
        } else {
          key = `${weatherElementUnit.toLowerCase()}forecast${weatherElement.toLowerCase()}`;
        }

        if (key.endsWith('_avg')) {
          const index = key.indexOf('_avg');
          key = key.substring(0, index);
        } else if (key.endsWith('_min')) {
          const index = key.indexOf('_min');
          key = key.substring(0, index);
        } else if (key.endsWith('_max')) {
          const index = key.indexOf('_max');
          key = key.substring(0, index);
        }

        data.forecast.parameters[param] = data.forecast.parameters[
          param
        ].map((parameter) => ({
          unit: parameter.unit,
          [key]: parameter.value,
          date: parameter.date,
        }));
      }

      const paramsFormatted = [];
      for (const param in data.forecast.parameters) {
        paramsFormatted.push(...data.forecast.parameters[param]);
      }

      return paramsFormatted;
    },
    async searchFilterWeather() {
      this.$f7.preloader.show();
      const selectedFrequency = this.selectedMeasuringFrequency === 'DAILY' ? 'DAY' : '';

      if (!this.searchInitDate || !this.searchEndDate) {
        await this.requestData(moment(new Date().setDate(new Date().getDate() - 7)).format('YYYY-MM-DD HH:mm:ss'), moment(new Date().setDate(new Date().getDate() + 7)).format('YYYY-MM-DD HH:mm:ss'), selectedFrequency);
      } else {
        await this.requestData(moment(this.searchInitDate).format('YYYY-MM-DD HH:mm:ss'), moment(this.searchEndDate).format('YYYY-MM-DD HH:mm:ss'), selectedFrequency);
      }

      this.$f7.preloader.hide();
    },
    async clearFilteredDataWeather() {
      this.$f7.preloader.show();
      const selectedFrequency = this.selectedMeasuringFrequency === 'DAILY' ? 'DAY' : '';

      await this.requestData(moment(new Date().setDate(new Date().getDate() - 7)).format('YYYY-MM-DD HH:mm:ss'), moment(new Date().setDate(new Date().getDate() + 7)).format('YYYY-MM-DD HH:mm:ss'), selectedFrequency);

      this.$f7.preloader.hide();
    },
    async updateMeasuringFrequency(e) {
      const newMeasuringFrequency = e.value;
      if (newMeasuringFrequency === 'DAILY') {
        this.indexOfRange = 1;
      }

      this.setSelectedMeasuringFrequency(newMeasuringFrequency);

      this.$f7.preloader.show();

      const selectedFrequency = this.selectedMeasuringFrequency === 'DAILY' ? 'DAY' : '';
      if (!this.searchInitDate || !this.searchEndDate) {
        await this.requestData(moment(new Date().setDate(new Date().getDate() - 7)).format('YYYY-MM-DD HH:mm:ss'), moment(new Date().setDate(new Date().getDate() + 7)).format('YYYY-MM-DD HH:mm:ss'), selectedFrequency);
      } else {
        await this.requestData(moment(this.searchInitDate).format('YYYY-MM-DD HH:mm:ss'), moment(this.searchEndDate).format('YYYY-MM-DD HH:mm:ss'), selectedFrequency);
      }

      this.$f7.preloader.hide();
    },
    displayTranslatedMeasuringFrequency(measuringFrequency) {
      if (measuringFrequency === null) {
        return '';
      }
      return `${this.$t(`MeasuringFrequency.${measuringFrequency}`)}`;
    },
    ...mapActions('weather', ['getClimateData']),
    ...mapActions('weatherFarm', [
      'setSelectedMeasuringFrequency',
    ]),
  },

};
</script>

<style lang="scss" scoped>
@import './weatherGraph.styles.scss';
</style>

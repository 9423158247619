import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    taskDefinitions: [],
    taskDefinitionDetail: {},
    taskDefinitionId: '',
    activities: [],
  },

  actions: {
    async getTaskDefinitionDetail({ commit, state }) {
      try {
        const xhr = await Api.getTaskDefinitionById(state.taskDefinitionId);
        const taskDetail = JSON.parse(xhr.response);
        commit('getTaskDefinitionDetail', taskDetail);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createTaskDefinition(_, newTask) {
      try {
        await Api.createTaskDefinition(newTask);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchActivities({ commit }) {
      try {
        const xhr = await Api.fetchActivities();
        const activities = JSON.parse(xhr.response).data;
        commit('fetchActivities', activities);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getTaskDefinitions({ commit }) {
      try {
        const xhr = await Api.getAllTasksDefinition();
        const tasks = JSON.parse(xhr.response).data;
        const tasksFormatted = [];
        for (const task of tasks) {
          tasksFormatted.push({
            id: task.id,
            activity: task.activity,
            name: task.name,
          });
        }
        commit('GET_TASKSDEFINITIONS', tasksFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getTaskDefinitionsConfig({ commit }) {
      try {
        const xhr = await Api.getAllTasksDefinitionConfig();
        const tasks = JSON.parse(xhr.response).data;
        const tasksFormatted = [];
        for (const task of tasks) {
          tasksFormatted.push({
            id: task.id,
            activity: task.activity,
            name: task.name,
          });
        }
        commit('GET_TASKSDEFINITIONS', tasksFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async editTaskDefinition({ commit, state }) {
      try {
        await Api.updateTaskDefinition(state.taskDefinitionId, state.taskDefinitionDetail);
        commit('initStore');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async deleteTaskDefinition(_, id) {
      try {
        await Api.deleteTaskDefinition(id);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },

    setName({ commit }, name) {
      commit('setName', name);
    },
    setActivity({ commit }, activity) {
      commit('setActivity', activity);
    },
    setTaskDefinitionId({ commit }, id) {
      commit('setTaskDefinitionId', id);
    },
    setTaskDefinitionDetail({ commit }, value) {
      commit('setTaskDefinitionDetail', value);
    },
    initStore({ commit }) {
      commit('initStore');
    },
  },
  mutations: {

    GET_TASKSDEFINITIONS(state, value) {
      state.taskDefinitions = value;
    },
    getTaskDefinitionDetail(state, value) {
      state.taskDefinitionDetail = value;
    },
    setTaskDefinitionId(state, value) {
      state.taskDefinitionId = value;
    },
    setTaskDefinitionDetail(state, value) {
      state.taskDefinitionDetail = value;
    },
    fetchActivities(state, value) {
      state.activities = value;
    },
    setName(state, value) {
      state.taskDefinitionDetail.name = value;
    },
    setActivity(state, value) {
      state.taskDefinitionDetail.activity = value;
    },
    initStore(state) {
      state.taskDefinitionId = '';
      state.taskDefinitionDetail = {
        name: '',
        activity: '',
      };
    },

  },
};

import i18n from '../i18n.config';

export function getTimeValues(dateStart, dateEnd, period, isReferencePlan = false, isExist = false) {
    const timeValues = [];
    const timeValuesIrrigation = [];
    const initDateMonthName = dateStart.format('MMMM');
    
    const arrayIndex = timeValues.length;
    let counterPeriod = 1;
    switch (period) {
        case 1: // Label: Semanal
        case 'SEMANAL':
        let monthName;
        let isFirstTime = true;
    
        // Iterar sobre cada semana entre dateStart y dateEnd
        let currentWeekStart = dateStart.clone().startOf('isoWeek');
        const lastWeekEnd = dateEnd.clone().endOf('isoWeek');
    
        while (currentWeekStart.isBefore(lastWeekEnd)) {
            const year = currentWeekStart.year();
            const weekNumber = currentWeekStart.isoWeek();
    
            // Obtener el nombre del mes correspondiente al inicio de la semana
            if (isFirstTime) {
                monthName = initDateMonthName;
                isFirstTime = false;
            } else {
                monthName = currentWeekStart.format('MMMM');
            }

            let varName = '';

            if (isReferencePlan) {
                varName = `${counterPeriod}º ${i18n.t('weekFullName')} ${i18n.t(`monthFull.${monthName}`)}`;
                counterPeriod += 1;
            } else {
                varName = `${i18n.t('weekFirstLetter')}${weekNumber} ${year} ${i18n.t(`monthFull.${monthName}`)}`;
            }
            // Si ya existe porque se esta editando entonces se lo añadimos a timeValues
            // Si no se inicializa a 0
            if (isExist) {
              timeValues.push(varName);
            } else {
                if (!timeValues[arrayIndex]) {
                    timeValues.push({ [varName]: 0 });
                    timeValuesIrrigation.push({
                        [varName]: {
                        periods_dose: 0,
                        periods_product_irrigation: 0,
                        }
                    });
                } else {
                    timeValues[arrayIndex][varName] = 0;
                    timeValuesIrrigation[arrayIndex][varName] = {
                        periods_dose: 0,
                        periods_product_irrigation: 0,
                    };
                  }
            }
            // Avanzar al inicio de la siguiente semana
            currentWeekStart.add(1, 'week');
        }
        break;
        
        case 0: // Label: Mensual
        case 'MENSUAL':
        default:
        // Clonamos los objetos de dateStart y dateEnd para que se pueda modificar dentro del bucle
        const currentStart = dateStart.clone();
        const currentEnd = dateEnd.clone();
        while (currentEnd > currentStart || currentStart.format('M') === currentEnd.format('M')) {
            const monthFull = i18n.t(`monthFull.${currentStart.format('MMMM')}`);
            const varName = `${monthFull.split('.')[0]} - ${currentStart.format('yy')}`;
            // Si ya existe porque se esta editando entonces se lo añadimos a timeValues
            // Si no se inicializa a 0
            if (isExist) {
                timeValues.push(varName);
              }
              else {
                if (!timeValues[arrayIndex]) {
                    timeValues.push({ [varName]: 0 });
                    timeValuesIrrigation.push({
                        [varName]: {
                        periods_dose: 0,
                        periods_product_irrigation: 0,
                        }
                    });
                } else {
                    timeValues[arrayIndex][varName] = 0;
                    timeValuesIrrigation[arrayIndex][varName] = {
                        periods_dose: 0,
                        periods_product_irrigation: 0,
                    };
                }
            }
            currentStart.add(1, 'month');
        }
        break;
    }
    
  return { timeValues, timeValuesIrrigation };
}
<template>
  <div>
    <DxPopup
      id="attach-file-popup"
      :title="$t('Searcher.observations.attach_file_title')"
      :visible.sync="popupShow"
      :show-close-button="true"
      :on-hidden="onHidden"
      :close-on-outside-click="false"
      height="auto"
      :drag-enabled="false"
      :position="{my: 'bottom', at: 'center'}"
      width="auto"
    >
      <DxForm :show-colon-after-label="false">
        <DxGroupItem>
          <DxSimpleItem
            :data-field="$t('Searcher.observations.description')"
            editor-type="dxTextArea"
            :editor-options="{
              value: fileDescription,
              onValueChanged: onChangeDescription,
              placeholder: $t('Searcher.observations.description'),
              maxLength: 256,
              height: 65,
              width: 350,
            }"
          >
            <DxRequiredRule message="" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('Searcher.observations.attach_file')"
            template="attachFile"
          > 
           <DxRequiredRule message="" />
          </DxSimpleItem>
        </DxGroupItem>
        <template #attachFile>
          <div
            class="fileuploader-container width-350"
          >
            <attachAFile
              :pop-up-is-visible="popupShow"
              @setExtension="setExtensionData"
            />
          </div>
        </template>
      </DxForm>

      <f7-row>
        <f7-col />
        <f7-col class="justify-content-end">
          <f7-button
            class="dx-btn-save btn-width-100 margin-right-5 margin-bottom-5"
            raised
            styling-mode="outlined"
            name="ButtonSave"
            @click="savePopup"
          >
            {{ $t('Button_save_text') }}
          </f7-button>
        </f7-col>
      </f7-row>
    </DxPopup>
    <f7-row>
      <f7-col width="55" />
      <f7-col width="45">
        <f7-button
          class="dx-btn-save"
          raised
          style="width: 50px;"
          type="default"
          styling-mode="outlined"
          @click="openPopup"
        >
          <f7-icon
            md="f7:arrow_up_doc"
            style="color: white"
          />
        </f7-button>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>

import { DxPopup } from 'devextreme-vue/popup';
import moment from 'moment';
import DxTextArea from 'devextreme-vue/text-area';

import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,

} from 'devextreme-vue/form';
import { mapActions, mapState } from 'vuex';
import attachAFile from './attachAFile.vue';

export default {
  name: 'AttachFilePopup',
  components: {
    DxPopup,
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    attachAFile,
    DxTextArea,
  },
  data() {
    return {
      popupShow: false,
      extension: '',
      fileUploaded: [],
    };
  },
  computed: {
    ...mapState('SearcherObservations', ['file', 'fileDescription']),
  },
  methods: {
    setExtensionData(value) {
      this.extension = value;
    },
    openPopup() {
      this.popupShow = true;
    },
    onHidden() {
      this.extension = 0;
      this.fileUploaded = [];
      this.setFile('');
      this.setFileDescription('');
    },
    savePopup() {
      if (this.fileDescription !== '' && this.file) {
        this.addMultimedia({
          tempName: moment().format('DDMMYYYY_HHmmss'),
          description: this.fileDescription,
          attachment: this.file,
          extension: this.extension,
        });
        this.popupShow = false;
      } else {
        this.$notifyDX(
          {
            message: this.$t('Fields_Required'),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    onChangeDescription(e) {
      this.setFileDescription(e.value);
    },

    ...mapActions('SearcherObservations', ['addMultimedia', 'setFile', 'setFileDescription']),

  },
};
</script>
<style lang="scss" scoped>
@import './AttachFilePopup.styles.scss';
</style>

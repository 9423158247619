<template>
  <section>
    <div
      class="content-devx margin-half"
      style="padding: 0"
    >
      <div style="padding: 4px 4px 0 4px">
        <div class="main-title-content">
          APLICACIÓN DE PRODUCTOS EN CADA PERIODO KG-L/HA
        </div>
      </div>

      <div style="padding: 0 4px">
        <div class="content-separatos">
          <DxDataGrid
            class="grid-application-efficiency-per-period"
            :data-source="dataSource"
            :show-borders="true"
            :row-alternation-enabled="true"
            :column-auto-width="true"
            :show-row-lines="true"
            :show-column-lines="true"
          >
            <DxColumn
              data-field="name"
              caption="Producto"
              :allow-sorting="false"
              :width="200"
              :fixed="true"
              fixed-position="left"
            />
            <DxColumn
              v-for="(period, index) in getPeriods"
              :key="index"
              :data-field="period.dataField"
              :caption="period.name"
              alignment="right"
              :allow-sorting="false"
              :min-width="100"
            />
          </DxDataGrid>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import moment from 'moment';
import { getTimeValues } from '../../../js/utils/timeValuesUtils';


export default {
  name: 'ProductResumeTable',
  components: {
    DxDataGrid,
    DxColumn,
  },
  props: {
    fertilization: {
      type: Object,
      default: () => {},
    },
    isReferencePlan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataSource: [],
    };
  },
  computed: {
    getPeriods() {
      let index = -1;

      const { timeValues } = getTimeValues(
        moment(this.fertilization.init_date),
        moment(this.fertilization.end_date),
        this.fertilization.period,
        this.isReferencePlan
      );

      return Object.entries(timeValues[0]).map(([name, value]) => {
        index += 1;
        return {
          dataField: index.toString(),
          name,
        };
      });
    },
  },
  async mounted() {
    const products = [];

    this.fertilization.contribution_products.forEach((product) => {
      const isLiquid = product.presentation === 'LIQUIDO';
      const unit = isLiquid ? 'l/ha' : 'kg/ha';
      const name = `${product.product_name} (${unit})`;

      const productDose = { name };

      product.periods.forEach(async (period, index) => {
        productDose[index] = period != null ? parseInt(period, 10) : 0;
      });

      products.push(productDose);
    });
    this.dataSource = products;
  },
};
</script>

<style scoped>
.grid-application-efficiency-per-period::v-deep .dx-header-row td[role="columnheader"] {
  text-transform: none;
}
</style>

<template>
  <section class="dosage-product">
    <PopupProductAnnotation
      v-if="showProductAnnotation"
      :product="selectedProductAnnotation"
      @save="saveProductAnnotation"
      @close="closeProductAnnotation"
    />
    <div class="main-container">
      <div class="content-devx treatment-order-form">
        <div class="card-header">
          {{ $t('treatment.treatmentPlan.treatmentCreatePlan.titleRegister') }}
        </div>
        <div class="card-body">
          <section>
            <f7-row class="row-items-center padding-half">
              <f7-col>
                <div class="dx-field">
                  <div
                    class="dx-field-label"
                    style="white-space: normal !important;"
                  >
                    {{
                      $t(
                        'treatment.treatmentPlan.treatmentCreatePlan.description',
                      )
                    }}
                  </div>
                  <div class="dx-field-value-static">
                    <DxTextBox
                      v-model="treatmentPlan.description"
                      :max-length="100"
                    />
                  </div>
                </div>
              </f7-col>
              <f7-col>
                <div class="dx-field">
                  <div
                    class="dx-field-label"
                    style="white-space: normal !important;"
                  >
                    {{
                      $t(
                        'treatment.treatmentPlan.treatmentCreatePlan.temporalFrame',
                      )
                    }}
                  </div>
                  <div class="dx-field-value">
                    <DxSelectBox
                      :items="temporalFrameItems"
                      :no-data-text="$t('No_Data')"
                      :value="temporalFrameId"
                      display-expr="label"
                      value-expr="id"
                      width="auto"
                      :disabled="this.isCopying || this.treatmentPlan.id !== ''"
                      @value-changed="resetDosageProducts"
                    />
                  </div>
                </div>
              </f7-col>

              <f7-col>
                <div class="dx-field">
                  <div class="dx-field-label">
                    {{ $t('dates') }}
                  </div>
                  <div class="dx-field-value">
                    <f7-input
                      v-if="refreshDatePicker"
                      type="datepicker"
                      class="double-date-picker"
                      :placeholder="
                        $t('PlanningComponent_datepicker_multiple_placeholder')
                      "
                      :clear-button="true"
                      :close-on-select="true"
                      :calendar-params="{
                        value: [treatmentInit, treatmentEnd],
                        closeOnSelect: true,
                        rangePicker: true,
                        monthPicker: true,
                        dateFormat: getDateFormat,
                        disabled: disabledDates,
                      }"
                      @calendar:change="calendarChange"
                    />
                  </div>
                </div>
              </f7-col>
            </f7-row>
          </section>
        </div>
      </div>
      <div class="content-devx treatment-order-product-list">
        <div class="card-header">
          {{ $t('treatment.treatmentPlan.treatmentCreatePlan.productDosage') }}
        </div>
        <div class="card-body">
          <section class="search">
            <div class="dx-field" style="margin: 0">
              <div class="dx-field-value-static">
                <DxSelectBox
                  v-model="treatmentPlan.agent"
                  :data-source="PlagueFilterPaginated"
                  :search-enabled="true"
                  :no-data-text="$t('No_Data')"
                  :on-value-changed="onChangePlague"
                  width="200"
                  :placeholder="$t('treatment.treatmentPlan.treatmentCreatePlan.plagueAgent')"
                />
              </div>
              <div class="dx-field-value-static">
                <DxSelectBox
                  v-model="currentDoseType"
                  :items="dosages"
                  width="150"
                  :placeholder="$t('treatment.treatmentPlan.treatmentCreatePlan.dosage')"
                />
              </div>
              <div
                class="dx-field-value-static"
              >
                <DxSelectBox
                  v-if="currentAgent === 'TIMAC'"
                  :key="KeySelectBoxTimac"
                  :data-source="productVademecumsPaginated"
                  :search-enabled="true"
                  value-expr="id"
                  display-expr="nameComposition"
                  :search-expr="['name', 'composition']"
                  :placeholder="
                    $t('treatment.treatmentEditOrder.products.typeToSearch')
                  "
                  :no-data-text="$t('No_Data')"
                  width="auto"
                  item-template="product-name"
                  @value-changed="selectProductItem"
                >
                  <template #product-name="{ data }">
                    <span>
                      <span class="text-color-gray">{{ data.code }}</span> -
                      {{ data.name }}
                    </span>
                  </template>
                </DxSelectBox>
                <DxSelectBox
                  v-else
                  :key="KeySelectBoxOtherAgents"
                  :data-source="productVademecumsPaginated"
                  :search-enabled="true"
                  value-expr="id"
                  display-expr="nameComposition"
                  :search-expr="['name', 'composition']"
                  :placeholder="
                    $t('treatment.treatmentEditOrder.products.typeToSearch')
                  "
                  :no-data-text="$t('No_Data')"
                  width="500"
                  @value-changed="selectProductItem"
                />
              </div>           
            </div>
            <div class="button-add">
              <DxButton
                :text="$t('treatment.treatmentPlan.treatmentCreatePlan.add')"
                type="default"
                @click="addProductFertilizer"
              />
            </div>
          </section>
          <section class="product-table">
            <DxDataGrid
              class="data-grid-products"
              :data-source="treatmentPlan.products"
              :show-borders="true"
              @cellHoverChanged="cellHoverChanged"
            >
              <DxColumn
                data-field="name"
                :caption="
                  $t('treatment.treatmentPlan.treatmentCreatePlan.product')
                "
                :allow-sorting="false"
                :allow-editing="false"
                cell-template="treatmentPlanProductName"
              />
              <DxColumn
                data-field="agent"
                :caption="
                  $t('treatment.treatmentPlan.treatmentCreatePlan.plagueAgent')
                "
                :allow-sorting="false"
                :allow-editing="false"
              />
              <DxColumn
                data-field="dose_type"
                :caption="
                  $t('treatment.treatmentPlan.treatmentCreatePlan.dosage')
                "
                :allow-sorting="false"
                :allow-editing="false"
              />
              <template #treatmentPlanProductName="{ data }">
                <div
                  v-if="data.data.product_deleted"
                >
                  <span :id="'alert-' + data.rowIndex">
                    <img
                      :src="getIconPath + 'alert.svg'"
                      alt="Alert icon"
                      @mouseenter="togglePopUpInfoDotsVisible"
                      @mouseleave="togglePopUpInfoDotsVisible"
                    >
                    <span>{{ data.data.name }}</span>
                  </span>

                  <DxPopover
                    :visible="visible && currentRowIndex === data.rowIndex"
                    :animation="animationConfig"
                    :target="'#alert-' + data.rowIndex"
                    :position="position"
                  >
                    <div class="padding-10">
                      {{ $t('productDeleted') }}
                    </div>
                  </DxPopover>
                </div>
                <div v-else>
                  <div
                    :id="`product-name-${data.rowIndex}`"
                    class="product-name-popup"
                    @mouseenter="togglePopUpDosageRange"
                    @mouseleave="togglePopUpDosageRange"
                  >
                    <div>
                      <svg
                        class="icon"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span>{{ data.data.name }}</span>
                  </div>

                  <DxPopover
                    v-if="data.data.dosage_range !== '' || data.data.noApplications !== null"
                    :visible="visibleDosageRange && currentRowIndex === data.rowIndex"
                    :animation="animationConfig"
                    :target="`#product-name-${data.rowIndex}`"
                    :position="position"
                  >
                    <div class="padding-10">
                      <div v-if="data.data.dosage_range !== ''">
                        {{ $t('treatment.treatmentPlan.treatmentCreatePlan.dosageRange') + ': ' + data.data.dosage_range }}
                      </div>
                      <div v-if="data.data.noApplications !== null">
                        {{ $t('treatment.treatmentPlan.treatmentCreatePlan.maxApplications') + ': ' + data.data.noApplications }}
                      </div>
                    </div>
                  </DxPopover>
                </div>
              </template>
            </DxDataGrid>
            <DxDataGrid
              v-if="showTimeValuesDataGrid"
              class="data-grid-time-values"
              :data-source="treatmentPlan.timeValues"
              :column-min-width="50"
              :column-auto-width="true"
              :show-borders="true"
              @row-updated="onRowUpdatedTimeValues"
            >
              <DxSorting mode="none" />
              <DxScrolling column-rendering-mode="virtual" />
              <DxPaging :enabled="false" />
              <DxEditing
                :allow-updating="true"
                mode="cell"
              />
             <!-- Sacamos todas las columnas dinamicamente de treatmentPlan.timeValues -->
              <template v-for="(value, key) in treatmentPlan.timeValues[0]">
                  <!-- Ocultamos la columna id -->
                  <DxColumn v-if="key !== 'id'" :key="key" :data-field="key" :caption="key" />
              </template>
            </DxDataGrid>
            <DxDataGrid
              class="data-grid-dosages"
              :data-source="treatmentPlan.dosages"
              :show-borders="true"
            >
              <DxColumn
                data-field="dosage"
                :caption="
                  $t('treatment.treatmentPlan.treatmentCreatePlan.totalDosage')
                "
                :allow-sorting="false"
              />
            </DxDataGrid>

            <DxDataGrid
              class="data-grid-price"
              :data-source="treatmentPlanPrices"
              :show-borders="true"
              @row-updated="onRowUpdatedPrices"
            >
              <DxColumn
                data-field="price"
                :caption="
                  $t(
                    'treatment.treatmentPlan.treatmentDetailPlan.products.price',
                  )
                "
                :allow-sorting="false"
              />
              <DxEditing
                :allow-updating="true"
                mode="cell"
              />

              <DxColumn
                cell-template="buttonsColumnTemplate"
                alignment="center"
              />

              <template #buttonsColumnTemplate="{ data }">
                <div class="column-buttons">
                  <DxButton
                    styling-mode="text"
                    type="success"
                    icon="find"
                    @click="viewProduct(data)"
                  />

                  <DxButton
                    styling-mode="text"
                    class="dx-button dx-widget dx-button-has-icon dx-button-mode-text button-edit"
                    icon="edit"
                    @click="addObservation(data)"
                  />

                  <DxButton
                    styling-mode="text"
                    type="danger"
                    icon="trash"
                    @click="deleteItem(data)"
                  />
                </div>
              </template>
            </DxDataGrid>
          </section>
          <section class="margin">
            <f7-row>
              <f7-col>
                <div class="dx-field">
                  <div class="dx-field-label">
                    <span>{{ $t("treatment.treatmentPlan.treatmentCreatePlan.dosageCuba") }}</span>
                  </div>
                  <div
                    class="dx-field-value"
                    style="width: auto !important;"
                  >
                    <DxSwitch
                      :value="dosagePerVat"
                      @valueChanged="toggleDosagePerVat"
                    />
                  </div>
                </div>
              </f7-col>
              <f7-col>
                <div
                  v-if="dosagePerVat"
                  class="dx-field field-vat-volume"
                >
                  <div class="dx-field-label">
                    <span>{{ $t("treatment.treatmentPlan.treatmentCreatePlan.volumeCuba") }}</span>
                  </div>
                  <div class="dx-field-value">
                    <DxNumberBox
                      v-model="treatmentPlan.vat_volume"
                      :disabled="!dosagePerVat"
                      :placeholder="$t('treatment.treatmentPlan.treatmentCreatePlan.volume')"
                    />
                  </div>
                  <div class="padding-left-half margin-right">
                    L
                  </div>
                </div>
              </f7-col>
              <f7-col>
                <div class="dx-field field-forward-speed">
                  <div class="dx-field-label">
                    <span>{{ $t("treatment.treatmentPlan.treatmentCreatePlan.speedCuba") }}</span>
                  </div>
                  <div class="dx-field-value">
                    <DxNumberBox
                      v-model="treatmentPlan.forward_speed"
                      :placeholder="$t('treatment.treatmentPlan.treatmentCreatePlan.speed')"
                    />
                  </div>
                  <div class="padding-left-half">
                    km/h
                  </div>
                </div>
              </f7-col>
            </f7-row>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { DxDateBox, DxCalendarOptions } from 'devextreme-vue/date-box';
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import { DxAutocomplete } from 'devextreme-vue/autocomplete';
import { mapState, mapActions, mapGetters } from 'vuex';
import { DxSimpleItem } from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxSummary,
  DxTotalItem,
  DxEditing,
  DxSorting,
  DxScrolling,
  DxPaging,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import moment from 'moment';
import { DxPopover } from 'devextreme-vue/popover';
import { Device } from 'framework7/framework7-lite.esm.bundle';
import { DxSwitch } from 'devextreme-vue/switch';
import DataSource from 'devextreme/data/data_source';
import PopupProductAnnotation from '../PopupProductAnnotation/index.vue';
import { getTimeValues } from '../../../js/utils/timeValuesUtils';

export default {
  name: 'DosageProduct_treatment',
  components: {
    DxDateBox,
    DxCalendarOptions,
    DxSelectBox,
    DxTextBox,
    DxNumberBox,
    DxAutocomplete,
    DxDataGrid,
    DxColumn,
    DxButton,
    DxSummary,
    DxTotalItem,
    DxEditing,
    DxScrolling,
    DxPaging,
    DxSorting,
    DxPopover,
    DxSimpleItem,
    PopupProductAnnotation,
    DxSwitch,
  },
  props: ['isEditing'],
  data() {
    return {
      visible: false,
      visibleDosageRange: false,
      environment: process.env.NODE_ENV,
      animationConfig: {
        show: {
          type: 'pop',
          from: {
            scale: 0,
          },
          to: {
            scale: 1,
          },
        },
        hide: {
          type: 'fade',
          from: 1,
          to: 0,
        },
      },
      position: 'top',
      productVademecums: [],
      productResume: [],
      temporalFrameId: 0,
      temporalFrameItems: [
        {
          id: 0,
          label: this.$t('Utilities.stepperForm.monthly'),
          value: 'MENSUAL',
        },
        {
          id: 1,
          label: this.$t('Utilities.stepperForm.weekly'),
          value: 'SEMANAL',
        },
      ],
      ownerAgent: 'TIMAC',
      ownerAgentAll: 'TODOS',
      dosages: ['kG-L/1000L', 'kG-L/HA'],
      productVademecumSelected: '',
      productVademecumSelectedValue: '',
      currentAgent: '',
      currentDoseType: '',
      currentRowIndex: 0,
      treatmentInit: null,
      treatmentEnd: null,
      showProductAnnotation: false,
      selectedProductAnnotation: null,
      dosagePerVat: false,
      showTimeValuesDataGrid: true,
      refreshDatePicker: true,
      KeySelectBoxTimac: 'KeySelectBoxTimac',
      KeySelectBoxOtherAgents: 'KeySelectBoxOtherAgents',
    };
  },
  computed: {
    getIconPath() {
      if (Device.cordova) {
        return this.environment === 'development'
          ? '../../../static/img/'
          : 'static/img/';
      }
      return this.environment === 'development'
        ? '../../../static/img/'
        : './static/img/';
    },
    ...mapState('treatmentStepper', [
      'treatmentPlan',
      'treatmentProducts',
      'treatmentProduct',
      'treatmentError',
      'currentStep',
      'isCopying',
      'copyingStepData',
    ]),
    ...mapState('Vademecum', [
      'optionsPlagueFilter',
      'phytosanitaryProductDetail',
      'nutritionalProductDetail',
    ]),
    ...mapState('Plantation', ['currentPlantation']),
    treatmentPlanPrices() {
      return this.treatmentPlan?.periods[0]?.products.map((item, index) => ({ index, price: item.price })) || [];
    },
    PlagueFilterPaginated() {
      return new DataSource({
        store: this.optionsPlagueFilter,
        paginate: true,
        pageSize: 20,
      });
    },
    productVademecumsPaginated() {
      return new DataSource({
        store: this.formattedProductVademecums,
        paginate: true,
        pageSize: 100,
      });
    },
    // hacemos un mapeo añadiendo propiedad nueva nameComposition para nombre y composition ponerlo juntos
    formattedProductVademecums() {
      return this.productVademecums.map(product => {
        return {
          ...product,
          nameComposition: `${product.name} - ${product.composition}`
        };
      });
    },
    getDateFormat() {
      return ['mm/yyyy', 'dd/mm/yyyy'][this.temporalFrameId];
    },
  },
  async mounted() {
    try {
      this.$f7.preloader.show();
      await this.getOptionsPlagueFilter(this.currentPlantation.species);
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.$f7.preloader.hide();
    }

    if (this.treatmentPlan.id && this.isEditing === undefined) {
      this.$notifyDX(
        {
          message: this.$t('treatment.treatmentPlan.changesMessage'),
          width: 550,
        },
        'error',
        3000,
      );
    }

    this.refreshTimeValues();
  },
  created() {
    if (this.isCopying) {
      this.treatmentPlan.init_date = moment(this.copyingStepData.initDate, 'YYYY-MM-DD');
      this.treatmentPlan.end_date = moment(this.copyingStepData.endDate, 'YYYY-MM-DD');
    }

    if (!this.treatmentPlan.init_date || !moment(this.treatmentPlan.init_date).isValid()) {
      this.treatmentPlan.init_date = moment(this.currentPlantation.initDate, 'YYYY-MM-DD');
    }

    if (!this.treatmentPlan.end_date || !moment(this.treatmentPlan.end_date).isValid()) {
      this.treatmentPlan.end_date = moment(this.currentPlantation.endDate, 'YYYY-MM-DD');
    }

    this.treatmentInit = this.treatmentPlan.init_date;
    this.treatmentEnd = this.treatmentPlan.end_date;

    if (this.treatmentPlan.agent) {
      this.updatePlagueProduct(this.treatmentPlan.agent);
    }

    this.temporalFrameId = this.temporalFrameItems.find(
      (item) => item.value === this.treatmentPlan.period,
    )?.id ?? 0;

    this.dosagePerVat = this.treatmentPlan.default_apply_unit.includes('Kg-L / cuva');

    if (this.isEditing) {
      this.next();
      this.$f7.views.main.router.navigate(this.currentStep.page, {
        reloadCurrent: true,
      });
    }
  },
  methods: {
    onRowUpdatedTimeValues(e) {
      const index = e.key.id;

      this.treatmentPlan.periods.forEach((period, periodIndex) => {
        period.products[index].dose_volume = Object.values(this.treatmentPlan.timeValues[index])[periodIndex] ?? 0;
        if (period.products[index].dose_volume > 0) {
          period.products[index].active = true;
        }
      });
    },
    onRowUpdatedPrices(e) {
      const { index } = e.data;

      this.treatmentPlan.periods.forEach((period) => {
        period.products[index].price = e.data.price ?? 0;
      });
    },
    addObservation(event) {
      const index = event.row.rowIndex;
      this.showProductAnnotation = true;
      this.selectedProductAnnotation = { index, name: this.treatmentPlan.periods[0].products[index].product, observation: this.treatmentPlan.periods[0].products[index].observation ?? '' };
    },
    saveProductAnnotation(e) {
      this.treatmentPlan.periods.forEach((period) => {
        period.products[e.index].observation = e.observation;
      });
      // this.treatmentPlan.products[e.index].observation = e.observation;
    },
    closeProductAnnotation(e) {
      this.showProductAnnotation = false;
    },
    cellHoverChanged(e) {
      this.currentRowIndex = e.rowIndex;
    },
    calendarChange(e) {
      if (e.length === 0) {
        this.treatmentInit = null;
        this.treatmentEnd = null;
      }

      if (e.length === 2) {
        this.treatmentInit = moment(e[0], 'YYYY/MM/DD').format(
          'YYYY-MM-DDT00:00:00',
        );
        this.treatmentEnd = moment(e[1], 'YYYY/MM/DD').format(
          'YYYY-MM-DDT00:00:00',
        );
      }

      this.treatmentPlan.init_date = moment(this.treatmentInit, 'YYYY-MM-DD');
      this.treatmentPlan.end_date = moment(this.treatmentEnd, 'YYYY-MM-DD');

      if (e.length === 2) {
        this.resetTimeValues();
      }
    },
    refreshTimeValues() {
      
      const { timeValues } = getTimeValues(
        moment(this.treatmentPlan.init_date, 'YYYY/MM/DD'),
        moment(this.treatmentPlan.end_date, 'YYYY/MM/DD'),
        this.treatmentPlan.period,
        this.isReferencePlan
      );

      const allTimeValuesKeys = Object.keys(timeValues[0]);
      const numberOfPeriods = allTimeValuesKeys.length;
      const numberOfProducts = this.treatmentPlan?.periods?.[0]?.products?.length ?? 0;

      if (numberOfProducts === 0) return;

      const timeValuesFinal = new Array(numberOfProducts).fill().map((u) => ({ ...timeValues[0] }));

      for (let i = 0; i < numberOfPeriods; i++) {
        const products = this.treatmentPlan.periods[i]?.products ?? [];

        products.forEach(({
          dose_volume,
        }, indexProduct) => timeValuesFinal[indexProduct][allTimeValuesKeys[i]] = dose_volume);
      }

      this.treatmentPlan.timeValues = timeValuesFinal;
      // Añadimos el id a timeValues para que en el caso que el objeto sea exactamente igual no haya problemas
      // y podamos identificarlo por su id unico para que en la funcion onRowUpdatedTimeValues utilicemos el id
      // para saber la celda a editar
      this.treatmentPlan.timeValues = this.treatmentPlan.timeValues.map((item, index) => ({...item, id: index}))
    },
    resetTimeValues() {
      const dateStart = moment(this.treatmentPlan.init_date);
      const dateEnd = moment(this.treatmentPlan.end_date);

      const { timeValues } = getTimeValues(dateStart, dateEnd, this.treatmentPlan.period);

      const numberOfNewPeriods = Object.keys(timeValues[0]).length;
      const numberOfOldPeriods = this.treatmentPlan.periods.length;

      if (numberOfOldPeriods === 0) {
        return;
      }

      if (numberOfNewPeriods > numberOfOldPeriods) {
        const diff = numberOfNewPeriods - numberOfOldPeriods;
        const products = this.treatmentPlan.periods[0].products.slice().map((product) => ({ ...product, dose_volume: 0, active: true }));
        const newPeriods = [];
        for (let i = 0; i < diff; i++) {
          const newProducts = [];

          products.forEach((product) => {
            newProducts.push({ ...product, dose_volume: 0, active: true });
          });

          newPeriods.push({
            products: [...newProducts],
            vats_number: 0,
          });
        }
        const default_apply_unit = this.treatmentPlan.default_apply_unit.includes('Kg-L / cuva');
        const newApplyUnit = new Array(diff).fill().map((u) => (default_apply_unit ? 'Kg-L / cuva' : 'default'));
        this.treatmentPlan.periods.push(...newPeriods);
        this.treatmentPlan.default_apply_unit.push(...newApplyUnit);

        this.treatmentPlan.timeValues.map((product, indexProduct) => {
          const newTimeValues = { ...timeValues[0] };

          Object.keys(newTimeValues).forEach((key, index) => {
            newTimeValues[key] = Object.values(product)[index] ?? 0;
          });

          this.treatmentPlan.timeValues.splice(indexProduct, 1, newTimeValues);
        });
      }

      if (numberOfNewPeriods < numberOfOldPeriods) {
        const diff = numberOfOldPeriods - numberOfNewPeriods;
        this.treatmentPlan.periods = this.treatmentPlan.periods.slice(0, -diff);
        this.treatmentPlan.default_apply_unit = this.treatmentPlan.default_apply_unit.slice(0, -diff);
        this.treatmentPlan.timeValues.map((product, indexProduct) => {
          const newTimeValues = { ...timeValues[0] };

          Object.keys(newTimeValues).forEach((key, index) => {
            newTimeValues[key] = Object.values(product)[index];
          });

          this.treatmentPlan.timeValues.splice(indexProduct, 1, newTimeValues);
        });
      }

      // DataGrid de timeValues no se actualiza.
      this.showTimeValuesDataGrid = false;
      setTimeout(() => {
        this.showTimeValuesDataGrid = true;
      }, 10);
    },
    async viewProduct(item) {
      const product = this.treatmentPlan.products[item.rowIndex];

      if (product.agent === this.ownerAgent) {
        this.$emit('owner', this.ownerAgent);

        await this.getNutritionalProductDetail(product.id);
      } else if (product.agent === this.ownerAgentAll) {
        this.$emit('owner', this.ownerAgentAll);

        await this.getPhytosanitaryProductDetail({
          id: product.id,
          crop: this.currentPlantation.species,
        });
      } else {
        this.$emit('owner', '');

        await this.getPhytosanitaryProductDetail({
          id: product.id,
          crop: this.currentPlantation.species,
          agent: product.agent,
        });
      }

      this.setDisplayProductDetailPopup(true);
    },
    togglePopUpInfoDotsVisible() {
      this.visible = !this.visible;
    },
    togglePopUpDosageRange() {
      this.visibleDosageRange = !this.visibleDosageRange;
    },
    disabledDates(date) {
      const initDatePlantation = new Date(this.currentPlantation.initDate);
      const endDatePlantation = new Date(this.currentPlantation.endDate);

      if (
        moment(date).isBetween(
          initDatePlantation,
          endDatePlantation,
          'days',
          '[]',
        )
      ) {
        return false;
      }

      return true;
    },
    selectProductItem(e) {
      if (e.value === '' || e.value === null) {
        this.productVademecumSelected = '';
        return;
      }

      const product = this.productVademecums.find(
        (item) => item.id === e.value,
      );

      if (product === undefined) return;

      this.productVademecumSelected = product;
      this.productVademecumSelectedValue = product.name;
    },
    async addProductFertilizer() {
      try {
        if (this.currentAgent === '') {
          this.$notifyDX(
            {
              message: this.$t('treatment.treatmentPlan.treatmentCreatePlan.agent_validation'),
              width: 550,
            },
            'error',
            3000,
          );
          Promise.resolve();
          return;
        }
        if (this.currentDoseType === '') {
          this.$notifyDX(
            {
              message: this.$t('treatment.treatmentPlan.treatmentCreatePlan.dose_type_validation'),
              width: 550,
            },
            'error',
            3000,
          );
          Promise.resolve();
          return;
        }
        if (this.productVademecumSelected === '') {
          this.$notifyDX(
            {
              message: this.$t('blank_agent'),
              width: 550,
            },
            'error',
            3000,
          );
          Promise.resolve();
          return;
        }
        this.$f7.preloader.show();
        if (this.productVademecumSelected.id == null || this.productVademecumSelected.id === '') {
          return;
        }
        await this.fetchTreatmentProduct({
          id: this.productVademecumSelected.id,
          crop: this.currentPlantation.species,
          agent: this.currentAgent,
        });
        let dosageRange = '';
        if (this.treatmentProduct.dose !== null) {
          dosageRange = `${parseFloat(this.treatmentProduct.dose.min).toFixed(
            2,
          )} - ${parseFloat(this.treatmentProduct.dose.max).toFixed(2)} (${
            this.treatmentProduct.dose.unit
          })`;
        }
        let noApplications = 0;
        if (this.treatmentProduct.application_num !== null) {
          noApplications = this.treatmentProduct.application_num.max;
        }

        if (!this.treatmentPlan.init_date.isValid()) {
          this.$notifyDX(
            {
              message: this.$t('treatment.treatmentCreateOrder.form.date_validation'),
              width: 550,
            },
            'error',
            3000,
          );
          return;
        }

        const dateStart = moment(this.treatmentPlan.init_date);
        const dateEnd = moment(this.treatmentPlan.end_date);

        const { timeValues } = getTimeValues(dateStart, dateEnd, this.treatmentPlan.period);

        const numberOfPeriods = Object.keys(timeValues[0]).length;

        if (this.treatmentPlan.periods.length === 0) {
          this.treatmentPlan.periods = new Array(numberOfPeriods).fill().map((u) => ({ products: [], vats_number: 0 }));
          this.treatmentPlan.default_apply_unit = new Array(numberOfPeriods).fill(this.dosagePerVat ? 'Kg-L / cuva' : 'default');
        }

        if (this.treatmentProduct.id == null || this.treatmentProduct.id === '') {
          return;
        }

        for (let i = 0; i < this.treatmentPlan.periods.length; i++) {
          const brothVolume = this.treatmentPlan.periods[i]?.products[0]?.broth_volume ?? 0;

          this.treatmentPlan.periods[i].products.push({
            product: this.treatmentProduct.name,
            product_id: this.treatmentProduct.id,
            dosage_range: dosageRange,
            dose_volume: 0,
            dose_type: this.currentDoseType,
            agent: this.currentAgent,
            volumen: 0,
            broth_volume: brothVolume,
            price: 0,
            active: true,
            treatment_type: 'FOLIAR',
            observation: '',
            cost_per_ha: 0,
          });
        }

        this.treatmentPlan.totalApplications.push({ total: 0 });
        this.treatmentPlan.dosages.push({ dosage: 0 });
        this.treatmentPlan.timeValues = this.treatmentPlan.timeValues.concat(timeValues[0]);
        // Añadimos el id a timeValues para que en el caso que el objeto sea exactamente igual no haya problemas
        // y podamos identificarlo por su id unico para que en la funcion onRowUpdatedTimeValues utilicemos el id
        // para saber la celda a editar
        this.treatmentPlan.timeValues = this.treatmentPlan.timeValues.map((item, index) => ({...item, id: index}))

        // this.treatmentPlan.timeValues.push(timeValues[0])

        this.treatmentPlan.products.push({
          name: this.treatmentProduct.name,
          id: this.treatmentProduct.id,
          dosage_range: dosageRange,
          dose: this.treatmentProduct.dose
            ? {
              min: this.treatmentProduct.dose.min,
              max: this.treatmentProduct.dose.max,
            }
            : null,
          noApplications,
          agent: this.currentAgent,
          dose_type: this.currentDoseType,
        });

      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async onChangePlague(e) {
      this.KeySelectBoxTimac = `KeySelectBoxTimac-${Math.floor(Math.random() * 1000000)}`;
      this.KeySelectBoxOtherAgents = `KeySelectBoxOtherAgents-${Math.floor(Math.random() * 1000000)}`;
      await this.updatePlagueProduct(e.value);
    },
    async updatePlagueProduct(agent) {
      try {
        this.$f7.preloader.show();
        await this.fetchTreatmentProducts({
          crop: this.currentPlantation.species,
          agent,
        });
        this.productVademecums = this.treatmentProducts.filter((product) => product.status);
        this.productVademecumSelected = '';
        this.productVademecumSelectedValue = '';
        this.currentAgent = agent;
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    }, 
    calcTotalBalance(array) {
      array.forEach((item, index) => {
          let totalProps = 0;
          for (const prop in item) {
              // Excluye la propiedad "id" al sumar las columnas ya que solo queremos las
              // columnas que sean distinta de la columna id
              if (prop !== 'id') {
                  totalProps += item[prop];
              }
          }
          this.treatmentPlan.dosages[index].dosage = totalProps;
      });
    },
    calcTotalApplications(array) {
        array.forEach((item, index) => {
            let totalProps = 0;
            for (const prop in item) {
                // Excluimos la propiedad "id" al contar
                if (prop !== 'id') {
                    if (item[prop] > 0) {
                        totalProps += 1;
                    }
                }
            }

            this.treatmentPlan.totalApplications[index].total = totalProps;
        });
    },
    deleteItem(item) {
      this.$f7.dialog.confirm(
        this.$t('dosageProduct.deleteDialogMessage', {
          product: this.treatmentPlan.products[item.row.rowIndex].name,
        }),
        this.$t('dosageProduct.deleteDialogTitle'),
        () => {
          this.treatmentPlan.products.splice(item.row.rowIndex, 1);
          this.treatmentPlan.dosages.splice(item.row.rowIndex, 1);
          this.treatmentPlan.timeValues.splice(item.row.rowIndex, 1);
          this.treatmentPlan.periods.forEach((period) => {
            period.products.splice(item.row.rowIndex, 1);
          });

          if (this.treatmentError?.products.length > 0) {
            this.deleteFromTreatmentError(item.row.rowIndex);
          }
        },
      );
    },
    resetDosageProducts(e) {
      this.temporalFrameId = e.value;
      this.treatmentPlan.products = [];
      this.treatmentPlan.dosages = [];
      this.treatmentPlan.timeValues = [];
      this.treatmentPlan.periods = [];

      this.treatmentPlan.period = this.temporalFrameItems[this.temporalFrameId].value;

      this.showTimeValuesDataGrid = false;
      this.refreshDatePicker = false;
      setTimeout(() => {
        this.refreshDatePicker = true;
        this.showTimeValuesDataGrid = true;
      }, 10);
    },
    toggleDosagePerVat(data) {
      const { value } = data;

      if (value) {
        this.treatmentPlan.vat_volume = 0;

        this.treatmentPlan.periods.forEach((period) => {
          period.vats_number = 0;
        });

        this.treatmentPlan.default_apply_unit.forEach((element, index) => {
          this.treatmentPlan.default_apply_unit[index] = 'Kg-L / cuva';
        });
      } else {
        this.treatmentPlan.default_apply_unit.forEach((element, index) => {
          this.treatmentPlan.default_apply_unit[index] = 'default';
        });
      }

      // Force re-render
      this.treatmentPlan.default_apply_unit.push('');
      this.treatmentPlan.default_apply_unit.pop();

      this.dosagePerVat = value;
    },
    ...mapActions('treatmentStepper', [
      'fetchTreatmentProducts',
      'fetchTreatmentProduct',
      'deleteFromTreatmentError',
      'next',
    ]),
    ...mapActions('Vademecum', [
      'getOptionsPlagueFilter',
      'getPhytosanitaryProductDetail',
      'getNutritionalProductDetail',
    ]),
    ...mapActions('treatment', ['setDisplayProductDetailPopup']),
  },
  watch: {
    'treatmentPlan.timeValues': {
      handler(val) {
        if (val.length === 0) {
          return;
        }
        this.calcTotalBalance(val);
        this.calcTotalApplications(val);
      },
      deep: true,
    },
  },
};
</script>
<style src="./dosageProduct.scss" scoped lang="scss"></style>

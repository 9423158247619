<template>
  <section class="treatment-detail-plan">
    <ProductPopupDetail
      v-if="displayProductDetailPopup"
      :owner="owner"
    />
    <PopupSelectObservationsReport
      v-if="showSelectObservationsReport"
      :type-report="typeReport"
      :type-action="typeAction"
      hide-filter
      @close="closeSelectObservationsReport"
      @download="downloadReport"
      @share="shareReport"
    />
    <ReportNotify
      v-show="reportMessage.length > 0"
      :message="reportMessage"
    />
    <ModalCopyReferencePlan
      :id="id"
      :visible="ShowModal"
      type="treatment"
      @close="ShowModal = false"
      @copy="copy"
    />
    <div class="main-container">
      <div
        v-if="!hideDetail"
        class="content-devx margin-half treatment-order-form"
      >
        <div class="card-header">
          <div>
            {{ $t("treatment.treatmentPlan.treatmentDetailPlan.title") }}
          </div>
          <div>
            <contextMenu :items="items" />
          </div>
        </div>
        <div class="card-body main">
          <div class="field">
            <span class="field-label">{{
              $t("treatment.treatmentPlan.treatmentDetailPlan.description")
            }}</span>
            <span class="field-value">{{ treatment.description }}</span>
          </div>
          <div class="field">
            <span class="field-label">{{
              $t("treatment.treatmentPlan.treatmentDetailPlan.initDate")
            }}</span>
            <span
              class="field-value"
            >{{ formatingDate(treatment.init_date) }} -
              {{ formatingDate(treatment.end_date) }}</span>
          </div>
          <div class="field">
            <span class="field-label">{{ $t("area") }}</span>
            <span class="field-value">{{ areaTreatment }} ha</span>
          </div>
          <div class="field">
            <span class="field-label">{{
              $t("treatment.treatmentPlan.treatmentDetailPlan.status")
            }}</span>
            <span class="field-value text-uppercase">
              <span :class="`label ${getClassStatus(treatment.status)}`">{{
                getStatus
              }}</span>
            </span>
          </div>
          <div class="field" />
        </div>
      </div>
      <f7-row no-gap>
        <f7-col style="align-self: stretch">
          <section style="display: flex; height: 100%">
            <div class="content-devx margin-half treatment-order-product-list">
              <div class="card-header">
                {{
                  $t(
                    "treatment.treatmentPlan.treatmentDetailPlan.products.title"
                  )
                }}
              </div>
              <div class="card-body">
                <section class="product-table">
                  <DxDataGrid
                    class="data-grid-balance-value"
                    :data-source="getProducts"
                    :show-borders="true"
                  >
                    <DxColumn
                      data-field="product"
                      :caption="
                        $t(
                          'treatment.treatmentPlan.treatmentDetailPlan.products.product'
                        )
                      "
                      :allow-sorting="false"
                      :allow-editing="false"
                      cell-template="cellTemplate"
                    />
                    <template #cellTemplate="{ data }">
                      <div>
                        <span v-if="data.data.product_deleted">
                          <span :id="'alert-' + data.rowIndex">
                            <img
                              :src="getIconPath + 'alert.svg'"
                              alt="Alert icon"
                            >
                            <span>{{ data.data.name }}</span>
                          </span>
                        </span>
                        <span v-else>
                          {{ data.data.name }}
                        </span>
                      </div>
                    </template>

                    <DxColumn
                      data-field="agent"
                      :caption="
                        $t(
                          'treatment.treatmentPlan.treatmentDetailPlan.plagueAgent'
                        )
                      "
                      :allow-sorting="false"
                      :allow-editing="false"
                    />
                    <DxColumn
                      data-field="dosage_total"
                      :caption="
                        $t(
                          'treatment.treatmentPlan.treatmentDetailPlan.doseTotal'
                        )
                      "
                      :allow-sorting="false"
                      :allow-editing="false"
                    />
                  </DxDataGrid>
                </section>
              </div>
            </div>
          </section>
        </f7-col>
        <f7-col style="align-self: stretch">
          <section style="display: flex; height: 100%">
            <div
              class="content-devx margin-half treatment-plan-product-chart"
              style="width: 100%"
            >
              <div class="card-header">
                {{
                  $t("treatment.treatmentPlan.treatmentDetailPlan.chartTitle")
                }}
              </div>
              <div>
                <section class="product-table">
                  <DxChart
                    palette="SoftPastel"
                    :data-source="chartSeries"
                  >
                    <DxSeriesTemplate name-field="productName" />

                    <DxTooltip
                      :enabled="true"
                      :z-index="999999"
                      :customize-tooltip="customizeTooltip"
                    />

                    <DxCommonSeriesSettings
                      argument-field="monthWeek"
                      value-field="value"
                      type="stackedbar"
                    />
                    <DxLegend
                      vertical-alignment="top"
                      horizontal-alignment="center"
                      item-text-position="right"
                    />
                    <DxValueAxis>
                      <DxTitle text="Kg-L" />
                    </DxValueAxis>
                    <DxSize :height="300" />
                  </DxChart>
                </section>
              </div>
            </div>
          </section>
        </f7-col>
      </f7-row>

      <div class="content-devx">
        <div class="card-header">
          {{
            $t("treatment.treatmentPlan.treatmentCreatePlan.totalApplications")
          }}
        </div>
        <DxTabPanel
          :data-source="getPeriods"
          :selected-index.sync="selectedIndex"
          :loop="false"
          :animation-enabled="false"
          :swipe-enabled="false"
        >
          <template #title="{ data: period }">
            <span> {{ period.title }} </span>
          </template>
          <template #item="{ data: period }">
            <div>
              <TreatmentStepTwoFormDefault
                v-if="period.default_apply_unit === 'default'"
                :period="period"
                :selected-index="selectedIndex"
                read-only
              />
              <TreatmentStepTwoFormPlant
                v-if="period.default_apply_unit === 'Kg-L / planta'"
                :period="period"
                :selected-index="selectedIndex"
                read-only
              />
              <TreatmentStepTwoFormVat
                v-if="period.default_apply_unit === 'Kg-L / cuva'"
                :period="period"
                read-only
              />

              <TreatmentStepTwoTableDefault
                v-if="period.default_apply_unit === 'default'"
                :period="period"
                :selected-index="selectedIndex"
                read-only
                @owner="setOwner"
              />
              <TreatmentStepTwoTablePlant
                v-if="period.default_apply_unit === 'Kg-L / planta'"
                :period="period"
                :selected-index="selectedIndex"
                read-only
                @owner="setOwner"
              />
              <TreatmentStepTwoTableVat
                v-if="period.default_apply_unit === 'Kg-L / cuva'"
                :period="period"
                :selected-index="selectedIndex"
                read-only
                @owner="setOwner"
              />
            </div>
          </template>
        </DxTabPanel>
      </div>

      <div>
        <section>
          <div class="content-devx padding-half">
            <div class="card-header-title margin-left-half margin-bottom">
              {{
                $t("treatment.treatmentPlan.treatmentCreatePlan.observations")
              }}
            </div>
            <f7-block>
              <f7-row>
                <f7-col>
                  {{ getObservations }}
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col>
                  <div class="margin-top">
                    <div
                      v-for="(annotation, index) of productsAnnotations"
                      :key="index"
                      class="margin-bottom-half"
                    >
                      <div style="font-weight: 500">
                        • {{ annotation.name }}
                      </div>
                      <div class="padding-left">
                        {{ annotation.annotation }}
                      </div>
                    </div>
                  </div>
                </f7-col>
              </f7-row>
            </f7-block>
          </div>
        </section>
      </div>
      <section
        v-if="!hideBackButton"
        class="buttons"
      >
        <f7-button
          class="dx-btn-cancel btnBack"
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          style="margin-bottom: 10px"
          @click="cancel"
        >
          {{ $t("Button_go_to_back") }}
        </f7-button>
      </section>
    </div>
  </section>
</template>

<script>
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import {
  DxChart,
  DxCommonSeriesSettings,
  DxLegend,
  DxSeriesTemplate,
  DxTooltip,
  DxSize,
  DxValueAxis,
  DxTitle,
} from 'devextreme-vue/chart';
import {
  DxDataGrid,
  DxColumn,
} from 'devextreme-vue/data-grid';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import { Device } from 'framework7/framework7-lite.esm.bundle';
import treatmentPlanService, { treatmentPlanStatus } from '@/services/treatmentPlanService';

import contextMenu from '@/components/contextMenu/index.vue';
import ReportNotify from '@/components/reportNotify/index.vue';
import PopupSelectObservationsReport from '@/components/reports/popupSelectObservationsReport.vue';

import TreatmentStepTwoFormVat from '../TreatmentStepTwoForms/vat.vue';
import TreatmentStepTwoFormDefault from '../TreatmentStepTwoForms/default.vue';
import TreatmentStepTwoFormPlant from '../TreatmentStepTwoForms/plant.vue';

import TreatmentStepTwoTableVat from '../treatmentStepTwoTables/vat.vue';
import TreatmentStepTwoTableDefault from '../treatmentStepTwoTables/default.vue';
import TreatmentStepTwoTablePlant from '../treatmentStepTwoTables/plant.vue';
import ProductPopupDetail from '../productDetail/index.vue';

import PDFMaker from '../../../js/pdf-maker-plans.js';
import Api from '../../../services/Api';

import ModalCopyReferencePlan from '@/components/utilities/referencePlans/detail/ModalCopyReferencePlan.vue';
import { getTimeValues } from '../../../js/utils/timeValuesUtils.js';

export default {
  name: 'TreatmentDetailPlan',
  components: {
    DxDataGrid,
    DxColumn,
    DxChart,
    DxValueAxis,
    DxTitle,
    DxCommonSeriesSettings,
    DxLegend,
    DxSeriesTemplate,
    DxTooltip,
    contextMenu,
    DxSize,
    DxTabPanel,
    TreatmentStepTwoTableVat,
    TreatmentStepTwoTableDefault,
    TreatmentStepTwoTablePlant,
    TreatmentStepTwoFormVat,
    TreatmentStepTwoFormDefault,
    TreatmentStepTwoFormPlant,
    ProductPopupDetail,
    PopupSelectObservationsReport,
    ReportNotify,
    ModalCopyReferencePlan,
  },
  props: {
    id: { type: String, default: '' },
    hideBackButton: { type: Boolean, default: false },
    hideDetail: { type: Boolean, default: false },
    hideReportButton: { type: Boolean, default: false },
    calledFromViewPlan: { type: Boolean, default: false },
  },
  data() {
    return {
      treatment: {},
      chartSeries: [],
      selectedIndex: 0,
      owner: '',
      showSelectObservationsReport: false,
      pdfmaker: null,
      typeAction: 'download',
      reportMessage: '',
      downloading: false,
      ShowModal: false,
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Plans', ['currentPlan']),
    ...mapState('treatmentStepper', ['treatmentPlan']),
    ...mapState('treatment', ['displayProductDetailPopup']),
    ...mapState('authentication', ['user']),
    ...mapState('RegisterPlantation', ['actualPlantation']),
    items() {
      if (this.hideReportButton) {
        return [
          {
            icon: 'copy',
            text: this.$t('ContextMenu.Copy'),
            onItemClick: () => {
              this.ShowModal = true;
            },
          },
          {
            icon: 'edit',
            text: this.$t('ContextMenu.Edit'),
            onItemClick: this.editItem,
          },
        ];
      }

      return [
        {
          icon: 'share',
          text: this.$t('ContextMenu.Share'),
          onItemClick: this.viewReportShare,
        },
        {
          icon: 'download',
          text: this.$t('ContextMenu.Download'),
          onItemClick: this.viewReportDownload,
        },
        {
          icon: 'copy',
          text: this.$t('ContextMenu.Copy'),
          onItemClick: () => {
            this.ShowModal = true;
          },
        },
        {
          icon: 'edit',
          text: this.$t('ContextMenu.Edit'),
          onItemClick: this.editItem,
        },
      ];
    },
    getProducts() {
      return this.treatmentPlan?.products.map((item, index) => {
        let total = 0;
        this.treatmentPlan.periods.forEach((period) => {
          const dose_volume = this.getDoseVolumeValueFirstType(period.products[index]);

          const result = dose_volume * this.currentPlantation.area;

          const fixedResult = Number(result.toFixed(2));

          if (!Number.isNaN(fixedResult) || Number.isFinite(fixedResult)) {
            total += fixedResult;
          }
        });
        item.dosage_total = Number(total.toFixed(2));
        return item;
      }) ?? [];
    },
    getObservations() {
      return this.treatment.observation?.length > 0
        ? this.treatment.observation
        : this.$t('No_Observation');
    },
    productsAnnotations() {
      return this.getProducts.map((item) => ({
        name: item.name,
        annotation: item.observation ?? '',
      })).filter((item) => item.annotation.length > 0);
    },
    getPeriods() {
      const { timeValues } = getTimeValues(moment(this.treatment.init_date), moment(this.treatment.end_date), this.treatment.period);

      return this.treatment?.periods
        ?.map((period, index) => ({
          ...period,
          title: Object.keys(timeValues[0])[index],
          default_apply_unit: this.treatment.default_apply_unit[index],
        }))
        .filter((period) => {
          const doseVolumeNotEmpty = period.products.some(
            ({ dose_volume, active }) => dose_volume > 0 && active,
          );

          return doseVolumeNotEmpty;
        });
    },
    getStatus() {
      return this.$t(this.treatment?.status?.toUpperCase()) ?? '';
    },
    getIconPath() {
      if (Device.cordova) {
        return this.environment === 'development'
          ? '../../../static/img/'
          : 'static/img/';
      }
      return this.environment === 'development'
        ? '../../../static/img/'
        : './static/img/';
    },
    typeReport() {
      const isVat = this.getPeriods.some(
        (period) => period.default_apply_unit === 'Kg-L / cuva',
      );

      if (isVat) return 'Cuba';

      const someDefault = this.getPeriods.some(
        (period) => period.default_apply_unit === 'default',
      );

      const somePlant = this.getPeriods.some(
        (period) => period.default_apply_unit === 'Kg-L / planta',
      );

      if (someDefault && somePlant) return 'Defecto';
      if (someDefault) return 'Defecto';
      if (somePlant) return 'Planta';

      return '';
    },
    areaTreatment() {
      return this.treatment?.area?.toFixed(2) ?? this.treatment?.area;
    },
  },
  async mounted() {
    try {
      this.$f7.preloader.show();
      this.treatment = await treatmentPlanService.getById(
        this.id,
        this.currentPlantation.companyId,
      );

      this.treatment.species = this.currentPlantation.species;
      await this.updateTreatmentPlan(this.treatment);

      const { timeValues } = getTimeValues(moment(this.treatment.init_date), moment(this.treatment.end_date), this.treatment.period);

      Object.entries(timeValues[0]).forEach(([key, value], index) => {
        this.treatment.periods[index].products.forEach((item) => {
          if (item.dose_volume > 0 && item.active) {
            let chartValue = 0;
            const doseVolume = this.getDoseVolumeValueFirstType(item);
            const result = doseVolume * this.currentPlantation.area;
            const fixedResult = Number(result.toFixed(2));

            if (!Number.isNaN(fixedResult) || Number.isFinite(fixedResult)) {
              chartValue = fixedResult;
            }

            this.chartSeries.push({
              productName: item.product,
              monthWeek: key,
              value: chartValue,
            });
          }
        });
      });
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    async copy(date) {
      const planDuration = moment(this.treatment.end_date).diff(moment(this.treatment.init_date), 'days') || 0;

      const xhr = await Api.getPlantationById(this.treatment.company_id, this.treatment.plantation);
      const plantation = JSON.parse(xhr.response);

      this.$f7router.navigate({
        name: 'treatment-plans-copy',
        params: {
          id: this.id,
        },
        query: {
          date,
          duration: planDuration,
          species: plantation.species,
        },
      });
    },
    getClassStatus(status) {
      switch (status) {
        case treatmentPlanStatus.ACTIVE:
          return 'label-success';
        case treatmentPlanStatus.CANCELLED:
          return 'label-danger';
        case treatmentPlanStatus.PLANNED:
        default:
          return 'label-primary';
      }
    },
    customizeTooltip(point) {
      return {
        html: `<div><div class='tooltip-header'>${
          point.argumentText
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('product')}: ${point.seriesName} </span>
          </div>
          <span class="bold"> ${this.$t('Dose')}: ${
  point.value
} KG-L</span> `,
      };
    },
    goToViewCopyTreatmentPlan() {
      this.$f7.views.main.router.navigate('/viewCopyTreatmentPlan/', {
        reloadCurrent: true,
      });
    },
    cancel() {
      this.$f7.views.main.router.navigate('/searcher/treatment/', {
        reloadCurrent: true,
      });
    },
    formatingDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    viewReportDownload() {
      if (this.downloading) return;
      this.typeAction = 'download';
      this.openSelectObservationsReport();
    },
    viewReportShare() {
      if (this.downloading) return;
      this.typeAction = 'share';
      this.openSelectObservationsReport();
    },
    getPeriodTitle(period) {
      if (this.treatment.period === 'MENSUAL') {
        const periodTitleSplit = period.title.split(' - ');
        return `${periodTitleSplit[0]} - ${periodTitleSplit[1]}`;
      }
      return period.title;
    },
    async generatePDF(selectedObservations) {
      const AtcName = `${this.user.user.contact.name} ${this.user.user.contact.surname}`;
      const headerDefault = [
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.product'),
        // this.$t('treatment.treatmentPlan.treatmentCreatePlan.plagueAgent'),
        // this.$t('treatment.treatmentPlan.treatmentCreatePlan.dosage'),
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.application'),
        this.$t(
          'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeL',
        ),
        this.$t(
          'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeHa',
        ),
        this.$t(
          'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothVolumeHa',
        ),
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceL'),
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costHa'),
        this.$t(
          'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.totalDose',
        ),
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costTotal'),
        this.$t(
          'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothTotal',
        ),
      ];
      const headerPlant = [
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.product'),
        // this.$t('treatment.treatmentPlan.treatmentCreatePlan.plagueAgent'),
        // this.$t('treatment.treatmentPlan.treatmentCreatePlan.dosage'),
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.application'),
        // this.$t(
        //   'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeHa',
        // ),
        // this.$t(
        //   'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeL',
        // ),
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTree'),
        // this.$t(
        //   'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothVolumeHa',
        // ),
        this.$t(
          'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothVolumeTree',
        ),
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceL'),
        this.$t(
          'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceTree',
        ),
        this.$t(
          'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTotal',
        ),
        this.$t(
          'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costTotal',
        ),
        this.$t(
          'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothTotal',
        ),
      ];
      const headerCuva = [
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.product'),
        // this.$t('treatment.treatmentPlan.treatmentCreatePlan.plagueAgent'),
        // this.$t('treatment.treatmentPlan.treatmentCreatePlan.dosage'),
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.application'),
        // this.$t(
        //   'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeHa',
        // ),
        // this.$t(
        //   'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeL',
        // ),
        // this.$t(
        //   'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothVolumeHa',
        // ),
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceL'),
        // this.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costHa'),
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVat'),
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceVat'),
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTotal'),
        this.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.cost'),
      ];

      this.pdfmaker.insertReportTitle('PLAN DE TRATAMIENTOS');

      this.pdfmaker.insertTableWithoutBorders([
        [
          {
            title: this.$t('DxClients_client'),
            text: this.currentPlantation.company,
          },
          {
            title: this.$t('searchEngine_farm'),
            text: this.currentPlantation.explotation,
          },
          {
            title: this.$t('Parcel'),
            text: this.currentPlantation.parcel,
          },
        ],
        [
          {
            title: this.$t('Sector'),
            text: this.currentPlantation.sector,
          },
          {
            title: this.$t('Plantation'),
            text: this.currentPlantation.name,
          },
          {
            title: this.$t('nutritionDetail.report.area'),
            text: `${this.treatment.area} ha`,
          },
        ],
        [
          // { title: this.$t('nutritionDetail.report.client'), text: AtcName },
          {
            title: this.$t('nutritionDetail.report.description'),
            text: this.treatment.description,
          },
          {
            title: this.$t('nutritionDetail.report.cropType'),
            text: this.currentPlantation.species,
          },
          {
            title: this.$t('nutritionDetail.report.variety'),
            text: this.currentPlantation.variety,
          },
        ],
        [
          {
            title: this.$t('nutritionDetail.report.date'),
            text: `${moment(this.treatment.init_date).format('DD/MM/YYYY')} - ${moment(this.treatment.end_date).format('DD/MM/YYYY')}`,
          },
          {
            title: '',
            text: '',
          },
          {
            title: '',
            text: '',
          },
        ],
      ]);

      const products = [];
      this.getProducts.forEach((product) => {
        products.push([product.name, product.agent, product.observation]);
      });

      this.pdfmaker.insertTableGeneric('RESUMEN', [
        [
          this.$t(
            'treatment.treatmentPlan.treatmentDetailPlan.products.title',
          ),
          this.$t('treatment.treatmentPlan.treatmentDetailPlan.plagueAgent'),
          this.$t('treatment.treatmentPlan.treatmentDetailPlan.annotation'),
        ],
        ...products,
      ]);

      this.getPeriods.forEach((period, index) => {
        const products = [];

        period.products.forEach((product) => {
          if (
            this.getDoseVolumeValueFirstType(product) > 0
            || this.getDoseVolumeValueSecondType(product) > 0
          ) {
            if (this.treatment.default_apply_unit[index] === 'default') {
              products.push([
                product.product,
                // product.agent,
                // product.dose_type,
                product.treatment_type,
                this.getDoseVolumeValueSecondType(product),
                this.getDoseVolumeValueFirstType(product),
                product.broth_volume,
                product.price,
                this.getCostHaValue(product),
                this.getDoseTotal(product),
                this.getCostValue(product),
                this.getTotalBrothVolume(product),
              ]);
            } else if (
              this.treatment.default_apply_unit[index] === 'Kg-L / planta'
            ) {
              products.push([
                product.product,
                // product.agent,
                // product.dose_type,
                product.treatment_type,
                // this.getDoseVolumeValueFirstType(product),
                // this.getDoseVolumeValueSecondType(product),
                this.getDoseTree(product),
                // product.broth_volume,
                this.getBrothTree(product),
                product.price,
                this.getPriceTree(product),
                this.getDoseTotal(product),
                this.getCostTotal(product),
                this.getTotalBroth(product),
              ]);
            } else if (
              this.treatment.default_apply_unit[index] === 'Kg-L / cuva'
            ) {
              products.push([
                product.product,
                // product.agent,
                // product.dose_type,
                product.treatment_type,
                // this.getDoseVolumeValueFirstType(product),
                // this.getDoseVolumeValueSecondType(product),
                // product.broth_volume,
                product.price,
                // this.getCostHaValue(product),
                this.getDoseVat(product),
                this.getPriceBroth(product),
                this.getDoseTotal(product),
                this.getCostValue(product),
              ]);
            }
          }
        });

        if (this.treatment.default_apply_unit[index] === 'default') {
          const periodTitle = this.getPeriodTitle(period);
          this.pdfmaker.insertTableTreatmentTable(
            periodTitle.toUpperCase(),
            [headerDefault, ...products],
            [
              [
                this.$t(
                  'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.speed',
                ),
                this.treatment?.forward_speed
                  ? `${parseFloat(parseFloat(this.treatment?.forward_speed).toFixed(1))} km/h`
                  : '',
              ],
            ],
            'default',
          );
        } else if (
          this.treatment.default_apply_unit[index] === 'Kg-L / planta'
        ) {
          const periodTitle = this.getPeriodTitle(period);
          this.pdfmaker.insertTableTreatmentTable(
            periodTitle.toUpperCase(),
            [headerPlant, ...products],
            [
              [
                this.$t('nutritionDetail.report.plantsHa'),
                this.actualPlantation?.plants_density ?? '',
              ],
              [
                this.$t(
                  'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.speed',
                ),
                this.treatment?.forward_speed
                  ? `${parseFloat(parseFloat(this.treatment?.forward_speed).toFixed(1))} km/h`
                  : '',
              ],
            ],
            'planta',
          );
        } else if (this.treatment.default_apply_unit[index] === 'Kg-L / cuva') {
          const periodTitle = this.getPeriodTitle(period);
          this.pdfmaker.insertTableTreatmentTable(
            periodTitle.toUpperCase(),
            [headerCuva, ...products],
            [
              [
                this.$t(
                  'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.vatVolume',
                ),
                this.treatment?.vat_volume
                  ? `${parseFloat(parseFloat(this.treatment?.vat_volume).toFixed(2))} L`
                  : '',
              ],
              [
                this.$t(
                  'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.vatNumberHa',
                ),
                this.getNumberVatsHa(period).toFixed(2),
              ],
              [
                this.$t(
                  'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.vatNumber',
                ),
                this.getNumberVats(period).toFixed(2),
              ],
              [
                this.$t(
                  'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.speed',
                ),
                this.treatment?.forward_speed
                  ? `${parseFloat(parseFloat(this.treatment?.forward_speed).toFixed(1))} km/h`
                  : '',
              ],
            ],
            'cuba',
          );
        }
      });

      const isAllDefault = this.getPeriods.every(
        (unit) => unit.default_apply_unit === 'default',
      );

      const isVat = this.getPeriods.some(
        (unit) => unit.default_apply_unit === 'Kg-L / cuva',
      );

      const isAllPlant = this.getPeriods.every(
        (unit) => unit.default_apply_unit === 'Kg-L / planta',
      );

      let totalProductsRow = [];
      let totalProductsFormatted = [];
      let totalProductsHeader = [];

      if (isAllDefault || (!isVat && !isAllPlant && !isAllDefault)) {
        const totalProducts = {};

        this.getPeriods.forEach((period) => {
          period.products.forEach((product, index) => {
            if (totalProducts[index] == null) {
            // this.getDoseTotal(product),
            //     this.getCostValue(product),
              totalProducts[index] = {
                name: product.product,
                dose: this.getDoseTotal(product),
                cost: this.getCostValue(product),
              };
            } else {
              totalProducts[index].dose += this.getDoseTotal(product);
              totalProducts[index].cost += this.getCostValue(product);
            }
          });
        });

        totalProductsRow = Object.values(totalProducts).reduce((acc, cur) => {
          acc[1] += cur.dose;
          acc[2] += cur.cost;
          return acc;
        }, ['TOTAL', 0, 0]);

        totalProductsRow[1] = parseFloat(totalProductsRow[1].toFixed(3));
        totalProductsRow[2] = parseFloat(totalProductsRow[2].toFixed(3));

        totalProductsFormatted = Object.values(totalProducts).map((item) => [
          item.name,
          parseFloat(item.dose.toFixed(3)) ?? 0,
          parseFloat(item.cost.toFixed(3)) ?? 0,
        ]);

        totalProductsHeader = [
          this.$t('treatment.treatmentPlan.treatmentCreatePlan.product'),
          this.$t(
            'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTotal',
          ),
          this.$t(
            'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costTotal',
          ),
        ];
      } else if (isVat) {
        const totalProducts = {};

        this.getPeriods.forEach((period) => {
          period.products.forEach((product, index) => {
            if (totalProducts[index] == null) {
            // this.getDoseTotal(product),
            //     this.getCostValue(product),
              totalProducts[index] = {
                name: product.product,
                dose_vat: this.getDoseTotal(product),
                // Precio (€/cuba) * Nº de cubas
                cost_broth: this.getCostValue(product),
              };
            } else {
              totalProducts[index].dose_vat += this.getDoseTotal(product);
              totalProducts[index].cost_broth += this.getCostValue(product);
            }
          });
        });

        totalProductsRow = Object.values(totalProducts).reduce((acc, cur) => {
          acc[2] += cur.cost_broth;
          return acc;
        }, ['TOTAL', '', 0]);

        totalProductsRow[2] = parseFloat(totalProductsRow[2].toFixed(2));

        totalProductsFormatted = Object.values(totalProducts).map((item) => [
          item.name,
          parseFloat(item.dose_vat.toFixed(2)) ?? 0,
          parseFloat(item.cost_broth.toFixed(2)) ?? 0,
        ]);

        totalProductsHeader = [
          this.$t('treatment.treatmentPlan.treatmentCreatePlan.product'),
          this.$t(
            'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseKgL',
          ),
          this.$t(
            'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.cost',
          ),
        ];
      } else if (isAllPlant) {
        const totalProducts = {};

        this.getPeriods.forEach((period) => {
          const isTree = period.default_apply_unit === 'Kg-L / planta';

          period.products.forEach((product, index) => {
            if (totalProducts[index] == null) {
              totalProducts[index] = {
                name: product.product,
                dose: this.getDoseTotal(product),
                cost: this.getCostValue(product),
                doseTree: isTree ? this.getDoseTree(product) : 0,
                priceTree: isTree ? this.getPriceTree(product) : 0,
              };
            } else {
              totalProducts[index].dose += this.getDoseTotal(product);
              totalProducts[index].cost += this.getCostValue(product);
              totalProducts[index].doseTree += isTree ? this.getDoseTree(product) : 0;
              totalProducts[index].priceTree += isTree ? this.getPriceTree(product) : 0;
            }
          });
        });

        totalProductsRow = Object.values(totalProducts).reduce((acc, cur) => {
          acc[1] += cur.dose;
          acc[2] += cur.cost;
          acc[3] += cur.doseTree;
          acc[4] += cur.priceTree;
          return acc;
        }, ['TOTAL', 0, 0, 0, 0]);

        totalProductsRow[1] = parseFloat(totalProductsRow[1].toFixed(3));
        totalProductsRow[2] = parseFloat(totalProductsRow[2].toFixed(3));
        totalProductsRow[3] = parseFloat(totalProductsRow[3].toFixed(3));
        totalProductsRow[4] = parseFloat(totalProductsRow[4].toFixed(3));

        totalProductsFormatted = Object.values(totalProducts).map((item) => [
          item.name,
          parseFloat(item.dose.toFixed(3)) ?? 0,
          parseFloat(item.cost.toFixed(3)) ?? 0,
          parseFloat(item.doseTree.toFixed(3)) ?? 0,
          parseFloat(item.priceTree.toFixed(3)) ?? 0,
        ]);

        totalProductsHeader = [
          this.$t('treatment.treatmentPlan.treatmentCreatePlan.product'),
          this.$t(
            'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTotal',
          ),
          this.$t(
            'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costTotal',
          ),
          this.$t(
            'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTreeTotal',
          ),
          this.$t(
            'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costTotalTree',
          ),
        ];
      } else {
        const totalProducts = {};

        this.getPeriods.forEach((period) => {
          const isTree = period.default_apply_unit === 'Kg-L / planta';

          period.products.forEach((product, index) => {
            if (totalProducts[index] == null) {
              totalProducts[index] = {
                name: product.product,
                doseTree: isTree ? this.getDoseTree(product) : 0,
                priceTree: isTree ? this.getPriceTree(product) : 0,
              };
            } else {
              totalProducts[index].doseTree += isTree ? this.getDoseTree(product) : 0;
              totalProducts[index].priceTree += isTree ? this.getPriceTree(product) : 0;
            }
          });
        });

        totalProductsRow = Object.values(totalProducts).reduce((acc, cur) => {
          acc[1] += cur.doseTree;
          acc[2] += cur.priceTree;
          return acc;
        }, ['TOTAL', 0, 0]);

        totalProductsRow[1] = parseFloat(totalProductsRow[1].toFixed(3));
        totalProductsRow[2] = parseFloat(totalProductsRow[2].toFixed(3));

        totalProductsFormatted = Object.values(totalProducts).map((item) => [
          item.name,
          parseFloat(item.doseTree.toFixed(3)) ?? 0,
          parseFloat(item.priceTree.toFixed(3)) ?? 0,
        ]);

        totalProductsHeader = [
          this.$t('treatment.treatmentPlan.treatmentCreatePlan.product'),
          this.$t(
            'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTreeTotal',
          ),
          this.$t(
            'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costTotalTree',
          ),
        ];
      }

      this.pdfmaker.insertTableGeneric('TOTAL', [
        totalProductsHeader,
        ...totalProductsFormatted,
        totalProductsRow,
      ], true, 'TOTAL PLANTACIÓN Y AÑO');

      const observations = await Promise.all(
        selectedObservations.map(async (item, index) => {
          const xhr = await Api.getObservationDetail(
            this.currentPlantation.companyId,
            item.id,
          );
          const observation = JSON.parse(xhr.response);

          const comment = [];

          observation.template_form.forEach((element) => {
            if (observation[element.field] !== null && observation[element.field]?.length > 0) {
              comment.push([element.description, observation[element.field]]);
            }
          });

          return {
            id: observation.id,
            title: `Observación ${index + 1}:`,
            date: observation.date,
            multimedia: await Promise.all(observation.multimedia.filter(
              (extension) => extension.extension === '.jpg' || extension.extension === '.png'
              || extension.extension === '.JPG' || extension.extension === '.PNG',
            ).map(async (image) => {
              image.base64 = await this.imageToDataUrl(image.attachment);
              return image;
            })),
            comment,
          };
        }),
      );

      if (
        observations.length > 0
        || (this.treatment.observation !== ''
          && this.treatment.observation != null)
      ) {
        this.pdfmaker.insertObservations(
          this.treatment.observation,
          [],
          [...observations],
        );
      }

      this.pdfmaker.insertTableBottomSignature([
        [
          ['Nombre del ATC', AtcName],
          ['Fecha', moment().format('DD/MM/YYYY')],
          ['Firmado', ''],
        ],
      ]);
    },
    async downloadReport({ observations: selectedObservations, filters }) {
      this.closeSelectObservationsReport();
      this.reportMessage = this.$t('generatingPDF');
      this.downloading = true;
      this.pdfmaker = new PDFMaker();

      const random = moment().format('YYMMDDHHmmss');

      const title = `${this.currentPlantation.name} - ${this.treatment.description} - ${moment().format('DD-MM-YYYY')} (${random})`;

      await this.generatePDF(selectedObservations);

      this.reportMessage = this.$t('downloadingPDF');

      if (Device.desktop) {
        await this.pdfmaker.buildAndDownload(title);
        this.reportMessage = '';
      } else {
        await new Promise((resolve) => {
          const pdfdata = this.pdfmaker.buildForMobile();

          pdfdata.getBase64((base64) => {
            const path = `${cordova.file.externalRootDirectory}Download/`;
            const fileExtension = '.pdf';

            this.savebase64AsPDF(
              path,
              title + fileExtension,
              base64,
              'application/pdf',
            );

            resolve();
          });
        });
      }

      this.downloading = false;
    },
    async shareReport({ observations: selectedObservations, filters }) {
      this.downloading = true;
      this.reportMessage = this.$t('generatingPDFShare');

      this.closeSelectObservationsReport();

      this.pdfmaker = new PDFMaker();

      await this.generatePDF(selectedObservations);

      const random = moment().format('YYMMDDHHmmss');

      const title = `${this.currentPlantation.name} - ${this.treatment.description} - ${moment().format('DD-MM-YYYY')} (${random})`;

      this.$f7.dialog.confirm(this.$t('confirmSharePdf'), async () => {
      const buffer = await this.pdfmaker.getPdfAsBuffer();

      const pdf = new File([buffer], `${title}.pdf`, { type: 'application/pdf' });
      const files = [pdf];
      if (navigator.canShare && Device.desktop) {
            try {
              await navigator.share({
                files,
                title,
                text: '',
              });
              this.reportMessage = '';
            } catch (error) {
              this.reportMessage = '';
              this.$notifyDX(
                {
                  message: error.message,
                  width: 550,
                },
                'error',
                3000,
              );
            }
      } else if (Device.desktop && !navigator.canShare) {
            this.$notifyDX(
              {
                message: this.$t('nutritionDetail.nutritionDetail.shareError'),
                width: 550,
              },
              'error',
              3000,
            ); 
        } else {
            try {
                await new Promise((resolve) => {
                  const pdfdata = this.pdfmaker.buildForMobile();
                  pdfdata.getBase64((base64) => {
                    const path = `${cordova.file.externalRootDirectory}Download/`;
                    const fileExtension = '.pdf';
                    this.savebase64AsPDFAndShare(
                      path,
                      title + fileExtension,
                      base64,
                      'application/pdf',
                      title,
                    );

                    resolve();
                  });
                });
            } catch (error) {
              this.reportMessage = '';
              this.$notifyDX(
                {
                  message: error.message,
                  width: 550,
                },
                'error',
                3000,
              );
            }                 
          }
      });
      this.downloading = false;
      this.reportMessage = '';
    },
    editItem() {
      this.setEditingFromContextMenu(this.id);
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_treatment_treatment-step-one',
        params: { id: this.id },
      });
      this.$f7.views.main.router.navigate(url, { reloadCurrent: true });
    },
    setOwner(data) {
      this.owner = data;
    },
    async imageToDataUrl(path) {
      const blob = await fetch(path).then((res) => res.blob());
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    savebase64AsPDF(folderpath, filename, content, contentType) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter(
            (fileWriter) => {
              fileWriter.write(DataBlob);

              this.reportMessage = this.$t('FileDownloadedInDownloads');

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
            () => {
              this.reportMessage = this.$t('SheetExport_sheet_export_error');

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
          );
        });
      });
    },
    savebase64AsPDFAndShare(folderpath, filename, content, contentType, message) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter(
            (fileWriter) => {
              fileWriter.write(DataBlob);
              const fileName = folderpath + filename;

              window.plugins.socialsharing.shareWithOptions({
                message,
                files: [fileName],
              });

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
            () => {
              this.reportMessage = this.$t('SheetExport_sheet_export_error');

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
          );
        });
      });
    },
    openSelectObservationsReport() {
      this.showSelectObservationsReport = true;
    },
    closeSelectObservationsReport() {
      this.showSelectObservationsReport = false;
    },
    getNumberVatsHa(period) {
      const broth_volume = period.products[0]?.broth_volume ?? 0;

      return this.calculateFormula(11, {
        broth_volume,
        vat_volume: this.treatmentPlan.vat_volume,
      });
    },
    getNumberVats(period) {
      const number_vats_ha = this.getNumberVatsHa(period);

      return this.calculateFormula(12, {
        number_vats_ha,
        area: this.currentPlantation.area,
      });
    },
    getDoseVolumeValueFirstType(data) {
      const type = 'kG-L/HA';

      if (data.dose_type === type) {
        return data.dose_volume;
      }

      return this.calculateFormula(2, data);
    },
    getDoseVolumeValueSecondType(data) {
      const type = 'kG-L/1000L';

      if (data.dose_type === type) {
        return data.dose_volume;
      }

      return '';
    },
    getCostHaValue(data) {
      const dose_volume = this.getDoseVolumeValueFirstType(data);

      return this.calculateFormula(3, {
        dose_volume,
        price: data.price,
      });
    },
    getCostValue(data) {
      const cost_ha = this.getCostHaValue(data);

      return this.calculateFormula(4, {
        cost_ha,
        area: this.currentPlantation.area,
      });
    },
    getTotalBrothVolume(data) {
      return this.calculateFormula(5, {
        broth_volume: data.broth_volume,
        area: this.currentPlantation.area,
      });
    },
    getDoseTotal(data) {
      return this.calculateFormula(10, {
        dose_volume: this.getDoseVolumeValueFirstType(data),
        area: this.currentPlantation.area,
      });
    },
    getDoseTree(data) {
      const dose_volume = this.getDoseVolumeValueFirstType(data);

      return this.calculateFormula(13, {
        dose_volume,
        plants_density: this.actualPlantation.plants_density,
      });
    },
    getBrothTree(data) {
      return this.calculateFormula(14, {
        broth_volume: data.broth_volume,
        plants_density: this.actualPlantation.plants_density,
      });
    },
    getCostTotal(data) {
      const dose_total = this.getDoseTotal(data);

      return this.calculateFormula(15, {
        price: data.price,
        dose_total,
      });
    },
    getTotalBroth(data) {
      return this.calculateFormula(5, {
        broth_volume: data.broth_volume,
        area: this.currentPlantation.area,
      });
    },
    getPriceTree(data) {
      const dose_tree = this.getDoseTree(data);

      return this.calculateFormula(16, {
        price: data.price,
        dose_tree,
      });
    },
    getDoseVat(data) {
      const dose_volume = this.getDoseVolumeValueFirstType(data);
      const vat_volume = data.broth_volume / this.treatment.vat_volume;

      return this.calculateFormula(17, {
        dose_volume,
        vat_volume,
      });
    },
    getPriceBroth(data) {
      const dose_volume_cuba = this.getDoseVat(data);

      return this.calculateFormula(18, {
        dose_volume_cuba,
        price: data.price,
      });
    },
    calculateFormula(type, data) {
      let result = 0;
      // Dosis (kg-L/1000L)= Dosis (kg-L/ha)/(Vol. Caldo (l/ha)/1000)
      if (type === 1) {
        result = data.dose_volume / (data.broth_volume / 1000);
      }

      // Dosis (kg-L/ha) =Dosis (kg-L/1000L) * (Vol. Caldo (l/ha)/1000)
      if (type === 2) {
        result = data.dose_volume * (data.broth_volume / 1000);
      }

      // Coste/ha = Dosis kg-L/ha * Precio €/Kg-L
      if (type === 3) {
        result = data.dose_volume * data.price;
      }

      // Coste = coste (€/ha) * superficie (ha)
      if (type === 4) {
        result = data.cost_ha * data.area;
      }

      // Vol caldo total = Vol caldo (L/ha) *  Superficie (ha)
      if (type === 5) {
        result = data.broth_volume * data.area;
      }

      // Dosis kg-L Total = Dosis (kg-l/ha) * Superficie (ha)
      if (type === 10) {
        result = data.dose_volume * data.area;
      }

      // Nº de cubas/ha = Volumen de caldo (L/ha) / Volumen de cuba (L)
      if (type === 11) {
        result = data.broth_volume / data.vat_volume;
      }

      // Nº de cubas = Nº de cubas/ha * Superficie (ha)
      if (type === 12) {
        result = data.number_vats_ha * data.area;
      }

      // Dosis (g-ml)/árbol = Dosis (kg-L/ha) / nº plantas (ha) *1000
      if (type === 13) {
        result = (data.dose_volume / data.plants_density) * 1000;
      }

      // Vol caldo (L/árbol) = Vol. caldo (l/ha) / nº plantas (ha)
      if (type === 14) {
        result = data.broth_volume / data.plants_density;
      }

      // Coste total = Precio (€/kg-L) * Dosis kg-L Total
      if (type === 15) {
        result = data.price * data.dose_total;
      }

      // Precio/árbol = Precio (€/kg-L) * Dosis (g-ml)/árbol /1000
      if (type === 16) {
        result = (data.price * data.dose_tree) / 1000;
      }

      // Dosis (kg-L/Cuba) = Dosis (kg-l/ha) / Vol.Caldo (l/ha)
      // Dosis (kg-L/Cuba) = Dosis (kg-l/ha) / Vol.cuba
      if (type === 17) {
        result = data.dose_volume / data.vat_volume;
      }

      // Precio (€/cuba) = precio (€/kg-l) / dosis (kg-l/cuba)
      // Precio (€/cuba) = precio (€/kg-l) * dosis (kg-l/cuba)
      if (type === 18) {
        result = data.price * data.dose_volume_cuba;
      }

      const fixedResult = Number(result.toFixed(2));

      if (isNaN(fixedResult) || !isFinite(fixedResult)) {
        return 0;
      }
      return fixedResult;
    },
    ...mapActions('Plans', ['getPlanById']),
    ...mapActions('treatmentStepper', ['updateTreatmentPlan']),
    ...mapActions('treatment', ['setEditingFromContextMenu']),
  },
};
</script>
<style src="./treatmentDetailPlan.scss" scoped lang="scss"></style>

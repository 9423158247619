<template>
  <div>
    <div class="content-devx">
      <f7-block>
        <DxForm>
          <DxGroupItem :col-count="3">
            <DxSimpleItem
              :data-field="$t('DxSimpleItem_PopUpRegister_campania')"
              :caption="$t('DxSimpleItem_PopUpRegister_campania')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: listExplotation,
                value: actual_Plot,
                displayExpr: 'name',
                valueExpr: 'id',
                onValueChanged: onChangePlot,
              }"
            >
              <DxRequiredRule :message="$t('registerPlantation.required_farm')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('registerPlantation.popUpRegisterPlantation_parcels')"
              :caption="$t('registerPlantation.popUpRegisterPlantation_parcel')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: parcels,
                value: parcelSelected,
                displayExpr: 'name',
                valueExpr: 'id',
                onValueChanged: onChangeParcel,
              }"
            >
              <DxRequiredRule :message="$t('registerPlantation.required_parcel')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('registerPlantation.popUpRegisterPlantation_sectors')"
              :caption="$t('registerPlantation.popUpRegisterPlantation_sector')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: sectors,
                value: sectorSelected,
                displayExpr: 'name',
                valueExpr: 'id',
                onValueChanged: onChangeSector,
              }"
            >
              <DxRequiredRule :message="$t('registerPlantation.required_sector')" />
            </DxSimpleItem>
          </DxGroupItem>
        </DxForm>
      </f7-block>
    </div>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'RegisterPlantation',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['parcels', 'sectors', 'sectorSelected', 'parcelSelected']),
    ...mapState('Campania', ['listExplotation', 'actual_Plot']),
    ...mapState('Explotaciones', ['actualUser', 'actualLocation']),
  },
  methods: {
    async onChangeParcel(e) {
      this.setParcelSelected(e.value);
      const data = {
        companyId: this.actualUser.id,
        parcel: e.value,
      };
      try {
        await this.getSectorByParcel(data);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$notifyDX(
            {
              message: message.split('_').pop(),
              width: 550,
            },
            'error',
            3000,
          );
        } else if (!message.includes('404')) {
          this.$notifyDX(
            {
              message: this.$t(message),
              width: 550,
            },
            'error',
            3000,
          );
        }
      }
    },
    onChangeSector(e) {
      for (const sector of this.sectors) {
        if (sector.id === e.value) {
          this.setSectorArea(sector.area);
        }
      }
      this.setSectorSelected(e.value);
    },
    async onChangePlot(e) {
      if (e.value?.length < 1 || e.value == null) return;
      this.actualPlot = e.value;
      this.setActualPlot(e.value);
      if (e.value !== '') {
        const data = {
          companyId: this.actualUser.id,
          explotation: this.actual_Plot,
        };
        try {
          await this.getParcelByExplotation(data);
        } catch (error) {
          const message = this.$helpers.getFilteredErrorMessage(error);
          if (message.includes('default_') && !message.includes('404')) {
            this.$notifyDX(
              {
                message: message.split('_').pop(),
                width: 550,
              },
              'error',
              3000,
            );
          } else if (!message.includes('404')) {
            this.$notifyDX(
              {
                message: this.$t(message),
                width: 550,
              },
              'error',
              3000,
            );
          }
        }
      }
    },
    ...mapActions('RegisterPlantation', ['setParcelSelected', 'setSectorSelected', 'getSectorByParcel',
      'createPlantation', 'getParcelByExplotation', 'setSectorArea', 'setInitDate', 'setEndDate']),
    ...mapActions('Campania', ['setActualPlot']),
  },
};
</script>

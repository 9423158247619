<template>
  <div
    class="content-devx reference-plan-detail-info-header"
    style="padding: 0"
  >
    <div style="display: flex; justify-content: space-between; padding: 4px">
      <div
        class="main-title-content"
        style="padding: 9px"
      >
        {{ $t('referencePlan.titleDetail') }}
      </div>
      <div>
        <ContextMenu
          :items="items"
        />
      </div>
    </div>

    <div style="width: 100%; height: 1px; background: #e4e6ed" />

    <div class="card-body main content-separatos">
      <div class="field">
        <span class="field-label">
          {{ $t('nutritionDetail.nutritionDetail.description') }}
        </span>
        <span class="field-value">{{ plan.description }}</span>
      </div>
      <div class="field">
        <span class="field-label">
          {{ $t('nutritionDetail.nutritionDetail.expectedProduction') }}</span>
        <span class="field-value">
          {{ plan.production }} t/ha
        </span>
      </div>
      <div class="field">
        <span class="field-label">{{ $t('referencePlan.duration') }}</span>
        <span class="field-value">{{ plan.duration }} días</span>
      </div>
      <div class="field">
        <span class="field-label">{{ $t('specie') }}</span>
        <span class="field-value">{{ plan.plantation_specie }}</span>
      </div>
      <div class="field">
        <span class="field-label">{{ $t('variety') }}</span>
        <span class="field-value">{{ plan.plantation_variety }}</span>
      </div>
      <div class="field">
        <span class="field-label">pH</span>
        <span class="field-value">{{ plan.ph }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ContextMenu from '../../../contextMenu/index.vue';

export default {
  name: 'InfoHeader',
  components: {
    ContextMenu,
  },
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      items: [
        {
          text: 'Copiar',
          icon: 'copy',
          onItemClick: () => {
            this.$emit('open-copy-modal');
          },
        },
      ],
    };
  },
  computed: {
    durationPlan() {
      return 70;
    },
    ...mapGetters('authentication', ['isAdmin']),
  },
};
</script>

<style lang="scss" scoped>
@import './referencePlansDetail.styles.scss';
</style>

import { fertilizationService } from '../../services/fertilizationService';

// to handle state
const state = {
  fertilization: [],
  currentFertilizationId: '',
};

// to handle state
const getters = {
  fertilization: (stateL) => stateL.fertilization,
};

// to handle actions
const actions = {
  getFertilization({ commit }, data) {
    return new Promise((resolve, reject) => {
      fertilizationService.getAll(data.companyId, data.id)
        .then((response) => {
          commit('SET_FERTILIZATION', response.data);
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  setCurrentFertilizationId({ commit }, id) {
    commit('setCurrentFertilizationId', id);
  },
};

// to handle mutations
const mutations = {
  SET_FERTILIZATION(stateL, fertilization) {
    stateL.fertilization = fertilization;
  },
  setCurrentFertilizationId(state, value) {
    state.currentFertilizationId = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

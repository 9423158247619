// Funcion que genera un id unico pasando como parametro la longitud de caracteres
export function generateRandomId(length) {
    const chars = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    const charsLength = chars.length;
    const array = new Uint8Array(length); // Usamos Uint8Array para una mejor eficiencia
    crypto.getRandomValues(array);
    let id = '';

    for (let i = 0; i < length; i++) {
        id += chars.charAt(array[i] % charsLength);
    }
    return id;
}

<template>
  <f7-block class="content-devx-margin-block no-margin-horizontal margin-bottom">
    <f7-row>
      <f7-col width="100">
        <div v-if="!loadedGIS || (currentIndexes[`${mainMap}Left`] === '' && currentIndexes[`${mainMap}Right`] === '')">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </div>
        <div v-else>
          <GISWMSSwipeMap
            :zoom="zoom"
            :name-map="mainMap"
            :options-left-key="`${mainMap}Left`"
            :options-right-key="`${mainMap}Right`"
            :tree-locations="treeLocations"
            :layer="layer"
            :indexes="[['CNDVI'], ['CNDVI']]"
            :center="center"
            :bbox="bbox"
            :height="'450px'"
            :companyId="currentPlantation.companyId"
          />
        </div>
      </f7-col>
    </f7-row>
    <f7-row class="margin-bottom-less-5 container">
      <f7-col
        v-if="loadedGIS"
        width="50"
      >
        <GISLayerOptionsComparator
          :key-id="`${mainMap}Left`"
          :default-index="'NDVI'"
          :dates-list="currentDatesList"
          :one-column="true"
          side="left"
          @date-changed="handleDateChange"
        />
        <CloudCoverage
          :cloudsData="cloudsDataScanningLeft"
        />
      </f7-col>
      <f7-col
        v-if="loadedGIS"
        width="50"
      >
        <GISLayerOptionsComparator
          :key-id="`${mainMap}Right`"
          :default-index="'NDVI'"
          :dates-list="currentDatesList"
          :one-column="true"
          side="right"
          @date-changed="handleDateChange"
        />
        <CloudCoverage
          :cloudsData="cloudsDataScanningRight"
        />
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GISWMSSwipeMap from '../../../Gis/GISWMSSwipeMap/index.vue';
import GISLayerOptionsComparator from '../../../Gis/LayerOptionsComparator/index.vue';
import Api from '../../../../services/Api';
import eventBus from '../../../../js/event-bus';
import moment from 'moment';
import CloudCoverage from '../../../Gis/CloudCoverage/CloudCoverage.vue';

export default {
  name: 'GisScanning',
  components: {
    GISWMSSwipeMap,
    GISLayerOptionsComparator,
    CloudCoverage
  },
  data() {
    return {
      loadedGIS: false,
      zoom: 17,
      mainMap: 'gisScanning',
      currentDatesList: {},
      layer: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpace(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      cloudsDataScanningLeft: null,
      cloudsDataScanningRight: null,
      center: {},
      ws: Api.getGeoServerWorkspace(),
      bbox: {},
    };
  },
  computed: {
    ...mapState('Plantation', ['treeLocations', 'currentPlantation']),
    ...mapState('Gis', ['currentIndexes', 'datesList', 'currentBoundsWMSLayer', 'currentGisScanningLeft', 'currentGisScanningRight']),
  },
  created() {
    eventBus.$on('lateral-panel-toggle', () => {
      window.dispatchEvent(new Event('resize'));
    });
  },
  async beforeMount() {
    try {
      await this.initializeMap();
      this.loadedGIS = true;
    } catch (error) {
      this.handleError(error);
    } finally {
      await this.fetchCloudData();
    }
  },
  methods: {
    handleError(error) {
      console.error('Error:', error);
      const errorMessage = this.$helpers.getFilteredErrorMessage(`${error.message} wms`);
      this.$notifyDX({
        message: this.$t(errorMessage),
        width: 550,
      }, 'warning', 3000);
    },
    async initializeMap() {
      this.layer.options.layers = `${this.ws}:${this.currentPlantation.sectorId}`;
      this.center = { ...this.currentPlantation.coordinates };
      await this.fetchLocationsByPlantation();
      await this.boundsWMSLayer(`timacagro:${this.currentPlantation.sectorId}`);
      await this.fetchDatesLayer({ layer: this.currentPlantation.sectorId, companyId: this.currentPlantation.companyId });
      this.bbox = { ...this.currentBoundsWMSLayer.coverage.latLonBoundingBox };
      this.center = { ...this.currentPlantation.coordinates };
      this.currentDatesList = this.datesList;
    },
    async fetchCloudData() {
      try {
        let formattedCloud = null;
        const dateCloud = this.getDateForHistogram();
        const result = await Api.getGeoServerClouds(this.currentPlantation.sectorId, dateCloud);
        formattedCloud = JSON.parse(result.response);
        this.cloudsDataScanningLeft = Number(formattedCloud.clouds);
        this.cloudsDataScanningRight = Number(formattedCloud.clouds);
        this.setCurrentGisScanningLeft(this.cloudsDataScanningLeft);
        this.setCurrentGisScanningRight(this.cloudsDataScanningRight);
      } catch (error) {
        console.error('Error fetching cloud data:', error);
        this.setCurrentGisScanningLeft(0);
        this.setCurrentGisScanningRight(0);
        this.cloudsDataScanningLeft = 0;
        this.cloudsDataScanningRight = 0;
      }
    },
    getDateForHistogram() {
      const availableDates = this.currentDatesList.avaiable_dates || this.currentDatesList;
      return moment(availableDates[availableDates.length - 1]).format('YYYY-MM-DD');
    },
    handleCloudData(cloudData, side) {
      if (cloudData) {
        const cloudsDataScanning = Number(cloudData);
        if (side === 'left') {
            this.cloudsDataScanningLeft = cloudsDataScanning;
            this.setCurrentGisScanningLeft(this.cloudsDataScanningLeft);
        } else if (side === 'right') {
            this.cloudsDataScanningRight = cloudsDataScanning;
            this.setCurrentGisScanningRight(this.cloudsDataScanningRight);
        }
      } else {
        if (side === 'left') {
            this.cloudsDataScanningLeft = 0;
            this.setCurrentGisScanningLeft(0);
        } else if (side === 'right') {
            this.cloudsDataScanningRight = 0;
            this.setCurrentGisScanningRight(0);
        }
      }
    },
    async handleDateChange(data) {
      const { date, side } = data;
      try {
        let formattedCloud = null;
        const dateInit = moment(date, 'YYYY/MM/DD').format('YYYY-MM-DD');
        const result = await Api.getGeoServerClouds(this.currentPlantation.sectorId, dateInit);
        formattedCloud = JSON.parse(result.response);
        const cloudData = formattedCloud.clouds;
        this.handleCloudData(cloudData, side);
      } catch (error) {
        this.handleCloudData(null, side);
        console.error('Error fetching cloud data:', error);
      }
    },
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
    ...mapActions('Gis', ['fetchDatesLayer', 'resetCurrentIntervalDates', 'boundsWMSLayer', 'setCurrentGisScanningRight', 'setCurrentGisScanningLeft']),
  },
};
</script>

<style lang="scss" scoped>
@import './GisScanning.styles.scss';
</style>

<template>
  <div>
    <DxPopup
      :visible="showPopUp"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :on-hidden="onHidden"
      :show-title="true"
      :width="500"
      height="auto"
      :title="$t('registerPlantation.register_plantation_complete')"
    >
      <template>
        <div class="title">
          <span>{{ $t('registerPlantation.plantation_register') }}</span>
        </div>
      </template>
      <f7-block-footer
        class="content-action-botton"
      >
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="success"
          styling-mode="contained"
          @click="createNewPlantation"
        >
          {{ $t("End_Campaign") }}
        </f7-button>
      </f7-block-footer>
    </DxPopup>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';

export default {
  name: 'PopUpRegisterParcelSectorPlantation',
  components: {
    DxPopup,
  },
  props: {
    parcels: { type: Array, default: () => [] },
    sectors: { type: Array, default: () => [] },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['showPopUp', 'parcelSelected', 'isEdit', 'actualPlantation']),
    ...mapState('Explotaciones', ['actualUser']),
    ...mapState('Campania', ['campaignId', 'isFromCampaign']),
    ...mapState('Explotaciones', ['actualUser']),
  },
  methods: {
    onHidden() {
      this.setShowPopUp(false);
    },
    async createNewPlantation() {
      this.setIsPlantationCreated(true);
      this.onHidden();
      if (this.isEdit) {
        if (this.isFromCampaign) {
          this.setIsView(false);
        } else {
          this.setIsView('');
        }
      } else if (this.isFromCampaign) {
        this.setIsView(false);
      } else {
        this.setIsView(true);
      }

      const data = {
        companyId: this.actualUser.id,
        plantationId: this.actualPlantation.id,
      };
      await this.getPlantationById(data);

      this.$f7.views.main.router.navigate('/viewPlantation/', { reloadCurrent: true });
    },
    async onChangeParcel(e) {
      this.setParcelSelected(e.value);
      const data = {
        companyId: this.actualUser.id,
        parcel: e.value,
      };
      try {
        await this.getSectorByParcel(data);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$notifyDX(
            {
              message: message.split('_').pop(),
              width: 550,
            },
            'error',
            3000,
          );
        } else if (!message.includes('404')) {
          this.$notifyDX(
            {
              message: this.$t(message),
              width: 550,
            },
            'error',
            3000,
          );
        }
      }
    },
    onChangeSector(e) {
      this.setSectorSelected(e.value);
    },
    ...mapActions('RegisterPlantation', ['setShowPopUp', 'setParcelSelected', 'setSectorSelected', 'getSectorByParcel',
      'createPlantation', 'getPlantationById', 'setIsPlantationCreated']),
    ...mapActions('Campania', ['setIsView']),
  },
};
</script>

<style>
.title {
  margin: 1rem;
  padding: 1rem;
  /* IMPORTANTE */
  text-align: center;
}
.finishedRegister {
    margin-left: 55%;
}
.center{
  padding-top: 3%;
  font-weight: bold;
}
</style>

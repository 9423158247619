<template>
  <f7-block>
    <f7-row no-gap>
      <f7-col width="50">
        <f7-block v-if="!loaded">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </f7-block>
        <f7-block v-else>
          <WeatherForecast
            :title="''"
            wind-units="m/s"
            :current-day="currentDay"
            :forecast="forecast"
          />
        </f7-block>
      </f7-col>
      <f7-col width="50">
        <f7-block v-if="!loadedGIS">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </f7-block>
        <f7-block v-else>
          <GISGeoJsonMap
            :zoom="zoom"
            :name-map="mainMap"
            :tree-locations="treeLocations"
            selected-min-height="361px"
          />
        </f7-block>
      </f7-col>
    </f7-row>
    <f7-row
      no-gap
      class="margin-top-half"
    >
      <f7-col>
        <div>
          <HomeObservations />
        </div>
      </f7-col>
      <f7-col>
        <LastNutritionPlanActive />
        <LastTreatmentPlan />
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import WeatherForecast from './weather/forecastWeather/index.vue';
import GISGeoJsonMap from '../../Gis/GISGeoJsonMap/index.vue';
import NutritionPlanList from '../../wizardNutrition/nutritionPlanList/index.vue';
import RegistersResume from './registersResume/index.vue';
import HomeObservations from './observations/index.vue';
import LastNutritionPlanActive from './LastNutritionPlanActive/index.vue';
import LastTreatmentPlan from './LastTreatmentPlan/index.vue';

export default {
  name: 'Home',
  components: {
    WeatherForecast,
    GISGeoJsonMap,
    NutritionPlanList,
    RegistersResume,
    HomeObservations,
    LastNutritionPlanActive,
    LastTreatmentPlan,
  },
  data() {
    return {
      loadedGIS: false,
      zoom: 17,
      mainMap: 'Home',
      currentDay: {
      },
      forecast: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation', 'treeLocations']),
    ...mapState('Registers', ['registersResume']),
  },
  async beforeMount() {
    this.$fa.setScreenName('Buscador > Inicio');
    try {
      this.loaded = false;
      await this.fetchLocationsByPlantation();
      this.setSearcherLocation(this.treeLocations[0].children[0].code);
      const data = await this.getWidgetData({
        initDate: moment().format('yyyy-MM-DD HH:mm:ss'),
        endDate: moment(new Date().setDate(new Date().getDate() + 8)).format('yyyy-MM-DD HH:mm:ss'),
        code: this.treeLocations[0].children[0].code,
      });

      this.setClimateData(data);
      this.loaded = true;

      await this.getCompanyById(this.currentPlantation.companyId);
      this.loadedGIS = true;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${this.$options.name} > ${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    }
  },
  methods: {
    setClimateData(data) {
      if (data[0].name.length === 0) return;
      this.currentDay = {
        icon: data[0].name.ICON[0].value.icon,
        code: data[0].name.ICON[0].value.code,
        temp: data[0].name.TEMPERATURE[0].value,
        currentSummary: data[0].name.ICON[0].value.description,
        hourlySummary: '',
        windSpeed: data[0].name.WIND_SPEED[0].value,
      };
      const forecasts = [];

      for (let i = 0; i < 8; i += 1) {
        if (data[0]?.name?.ICON?.[i]?.date === undefined) continue;
        forecasts.push({
          name: new Date(
            data[0].name.ICON[i].date,
          ).toLocaleDateString(this.getLanguage, {
            weekday: 'short',
          }),
          icon: data[0].name.ICON[i].value.icon,
          highTemp: data[0].name.TEMPERATURE_MAX_DAY[i].value,
          lowTemp: data[0].name.TEMPERATURE_MIN_DAY[i].value,
          water: `${data[0].name.PLUVIOMETER[i].value.toFixed(2)} mm`,
          wind: {
            value: data[0].name.WIND_SPEED[i].value,
            direction: data[0].name.WIND_DIRECTION[i].value,
          },
          sun: `${data[0].name.SUN_HOURS[i].value.split(':')[0]}h`,
        });
      }

      this.forecast = forecasts;
    },
    ...mapActions('Plantation', ['fetchLocationsByPlantation', 'setSearcherLocation']),
    ...mapActions('Registers', ['fetchRegistersResume']),
    ...mapActions('Explotaciones', ['getCompanyById']),
    ...mapActions('weather', ['getWidgetData']),
  },
};
</script>

<style lang="scss" scoped>
@import './Home.styles.scss';
</style>

<template>
  <f7-page resizable>
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${$t('Page_subtitle_clients')}`" />
    <DxSystemParcelClients />
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import DxSystemParcelClients from '../../components/systemParcelClient/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'Clients',
  components: {
    DxSystemParcelClients,
    navbar,
  },
  data() {
    return {
      visible: false,
      date: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  async beforeMount() {
    this.$fa.setScreenName('Clientes');
    try {
      this.$f7.preloader.show();
      this.loaded = true;
    } catch (reason) {
      const message = this.$helpers.getFilteredErrorMessage(reason);
      if (message.includes('default_') && !message.includes('404')) {
        this.$notifyDX(
          {
            message: message.split('_').pop(),
            width: 550,
          },
          'error',
          3000,
        );
      } else if (!message.includes('404')) {
        this.$notifyDX(
          {
            message: this.$t(message),
            width: 550,
          },
          'error',
          3000,
        );
      }
    } finally {
      this.$f7.preloader.hide();
    }
  },
};
</script>
<style lang="scss">
@import '../../components/searcher/home/weather/forecastWeather/forecastWeather.styles.scss';

</style>

<template>
  <div
    class="content-devx content-dx-upload-file"
  >
    <div
      class="profile"
    >
      <input
        id="upload"
        ref="inputFile"
        type="file"
        @change="onFileSelected"
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'UploadFile',

  props: {
    popUpIsVisible: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedFile: '',
    };
  },

  computed: {
    ...mapState('SearcherObservations', ['fileDescription']),
  },
  watch: {
    popUpIsVisible(value) {
      if (value) {
        this.$refs.inputFile.value = null;
        this.setFile(null);
        this.selectedFile = null;
      }
    },
  },
  methods: {
    onFileSelected(event) {
      const reader = new FileReader();
      const newFile = event.target.files[0];
      reader.onload = (e) => {
        this.$emit('setExtension', `.${newFile.name.split('.').pop()}`);
        this.selectedFile = `${e.target.result}`;
        this.setFile(this.selectedFile);
      };
      reader.onerror = (error) => {
        this.$notifyDX(
          {
            message: `${this.$t('UploadFile_error')} ${error}`,
            width: 550,
          },
          'error',
          3000,
        );
      };
      reader.readAsDataURL(newFile);
    },
    ...mapActions('SearcherObservations', ['addMultimedia', 'setFile']),

  },
};
</script>

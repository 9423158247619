import { Device } from 'framework7/framework7-lite.esm.bundle';

/**
 * @typedef {object} FirebaseAnalytics
 * @description Capa intermedia que permite la abstracción del uso de librerías y dependencias para el consumo de 
 *  los servicios de Firebase Analytics de manera transparente y unificada independientemente si se la app está
 *  compilada usando Cordova o si es una web app.
 * @property {function} setAnalyticsCollectionEnabled - Activa o desactiva la recopilación de datos de analytics para esta app en este dispositivo.
 * @property {function} isAnalyticsCollectionEnabled - Devuelve si la colección de analytics está habilitada para esta app en este dispositivo.
 * @property {function} logEvent - Loguea un evento de la app usando analytics.
 * @property {function} setScreenName - Setea el nombre de la pantalla actual, que especifica el contexto visual actual.
 * @property {function} setUserId - Setea el ID del usuario. Esta función debe ser usada de acuerdo con la Política de Privacidad de Google.
 * @property {function} setUserProperty - Setea una propiedad de usuario a un valor dado.
 * @see https://github.com/dpa99c/cordova-plugin-firebasex#analytics
 */
export default {
  /**
   * Activa o desactiva la recopilación de datos de analytics para esta app en este dispositivo.
   *
   * @param {boolean} enabled - Si está habilitada o no
   * @example FirebaseAnalytics.setAnalyticsCollectionEnabled(true);
   * @see https://github.com/dpa99c/cordova-plugin-firebasex#setanalyticscollectionenabled
   */
  setAnalyticsCollectionEnabled(enabled) {
    if (Device.cordova) {
      FirebasePlugin.setAnalyticsCollectionEnabled(enabled);
    }
  },
  /**
   * Devuelve si la colección de analytics está habilitada para esta app en este dispositivo.
   * Si la recopilación automática de datos no se desactivó al inicio de la app, esto siempre devolverá true
   * 
   * @returns {Promise<boolean>} Si está habilitada o no
   * @example const enabled = await FirebaseAnalytics.isAnalyticsCollectionEnabled();
   * @see https://github.com/dpa99c/cordova-plugin-firebasex#isanalyticscollectionenabled
   */
  async isAnalyticsCollectionEnabled() {
    return new Promise((resolve, reject) => {
      if (Device.cordova) {
        FirebasePlugin.isAnalyticsCollectionEnabled((enabled) => {
          resolve(enabled);
        });
      } else {
        resolve(false);
      }
    });
  },
  /**
   * Loguea un evento de la app usando analytics.
   *
   * @param {string} eventName - Nombre del evento a loguear en Firebase Analytics
   * - Limitado a 40 caracteres.
   * - Debe comenzar con una letra. Solo letras, números y guiones bajos permitidos. Puntos no permitidos en el nombre.
   * @param {object} eventProperties - Objeto key/value con las propiedades custom de eventos
   * - Estos deben ser valores de tipo string, number o boolean (non-nested)
   * - Límitado a 40 carácteres por clave y 100 carácteres por valor
   * @example FirebaseAnalytics.logEvent('select_content', {content_type: 'page_view', item_id: 'home'});
   * @see https://firebase.google.com/docs/analytics/events?hl=es-419&platform=android
   * @see https://github.com/dpa99c/cordova-plugin-firebasex#logevent
   */
  logEvent(eventName, eventProperties) {
    if (Device.cordova) {
      FirebasePlugin.logEvent(eventName, eventProperties);
    }
  },
  /**
   * Setea el nombre de la pantalla actual, que especifica el contexto visual actual.
   *
   * @param {string} screenName - El nombre de la pantalla
   * @example FirebaseAnalytics.logScreen('login');
   * @see https://github.com/dpa99c/cordova-plugin-firebasex#setscreenname
   */
  setScreenName(screenName) {
    if (Device.cordova) {
      FirebasePlugin.setScreenName(screenName);
    }
  },
  /**
   * Setea el ID del usuario. Esta función debe ser usada de acuerdo con la Política de Privacidad de Google.
   *
   * @param {string} id - El ID del usuario
   * @example FirebaseAnalytics.setUserId('123456789');
   * @see https://github.com/dpa99c/cordova-plugin-firebasex#setuserid
   */
  setUserId(id) {
    if (Device.cordova) {
      FirebasePlugin.setUserId(id);
    }
  },
  /**
   * Setea una propiedad de usuario a un valor dado.
   *
   * @param {string} name - El nombre del usuario
   * @example FirebaseAnalytics.setUserProperty('name', 'John Doe');
   * @see https://support.google.com/firebase/answer/6317486?hl=en&ref_topic=6317484
   * @see https://github.com/dpa99c/cordova-plugin-firebasex#setuserproperty
   */
  setUserProperty(name, value) {
    if (Device.cordova) {
      FirebasePlugin.setUserProperty(name, value);
    }
  },
};
<template>
  <section>
    <div class="content-devx margin-half">
      <div class="main-title-content">
        {{ $t('nutritionDetail.productResume.title') }}
      </div>
      <div class="card-body main">
        <section class="content-separatos product-table">
          <DxDataGrid
            class="data-grid-balance-value"
            :data-source="productResume"
            :row-alternation-enabled="true"
            :word-wrap-enabled="true"
            :show-borders="true"
            @cellPrepared="onCellPrepared"
          >
            <DxColumn
              data-field="product"
              :caption="$t('nutritionDetail.productResume.product')"
              :allow-sorting="false"
              cell-template="cellTemplate"
              :fixed="true"
              fixed-position="left"
              :width="250"
            />
            <template #cellTemplate="{ data }">
              <div>
                <span
                  v-if="!data.data.status && data.data.product !== 'Total'"
                >
                  <span
                    :id="'alert-' + data.rowIndex"
                    style="display: flex; align-items: center;"
                    @mouseenter="data.data.visiblePop = true"
                    @mouseleave="data.data.visiblePop = false"
                  >
                    <img
                      :src="getIconPath + 'alert.svg'"
                      alt="Alert icon"
                    >
                    <span style="margin-left: 0.4rem;">{{ data.data.product }}</span>
                  </span>
                  <DxPopover
                    :width="300"
                    :visible="data.data.visiblePop === true"
                    :target="'#alert-' + data.rowIndex"
                    position="top"
                  >
                    <span style="padding: 1rem; text-align: center; display: flex;">
                      {{ $t('productDeleted') }}
                    </span>
                  </DxPopover>
                </span>
                <span v-else>
                  {{ data.data.product }}
                </span>
              </div>
            </template>
            <DxColumn
              v-if="!isNew"
              :caption="$t('nutritionDetail.productResume.dosageUnit')"
              data-field="dosageUnit"
              alignment="right"
              :allow-sorting="false"
              :min-width="60"
            />
            <DxColumn
              v-if="isNew"
              caption="Dosis unitaria"
              alignment="center"
            >
              <DxColumn
                caption="kg/ha"
                data-field="dosageUnitKg"
                data-type="number"
                alignment="right"
                :allow-editing="false"
                :allow-sorting="false"
                :min-width="60"
              />
              <DxColumn
                caption="l/ha"
                data-field="dosageUnitL"
                name="dosageUnitL"
                data-type="number"
                alignment="right"
                :allow-editing="false"
                :allow-sorting="false"
                :min-width="60"
              />
            </DxColumn>

            <DxColumn
              v-if="!isNew && showPlantColumn"
              :caption="$t('nutritionDetail.productResume.dosagePlant')"
              data-field="dosagePlant"
              alignment="right"
              :allow-sorting="false"
              :min-width="60"
            />
            <DxColumn
              v-if="isNew && showPlantColumn"
              caption="Dosis por planta"
              alignment="center"
            >
              <DxColumn
                caption="g/planta"
                data-field="dosagePlantKg"
                name="dosagePlantKg"
                data-type="number"
                alignment="right"
                :allow-editing="false"
                :allow-sorting="false"
                :min-width="60"
              />
              <DxColumn
                caption="ml/planta"
                data-field="dosagePlantL"
                name="dosagePlantL"
                data-type="number"
                alignment="right"
                :allow-editing="false"
                :allow-sorting="false"
                :min-width="60"
              />
            </DxColumn>

            <DxColumn
              v-if="!isNew"
              :caption="$t('nutritionDetail.productResume.dosageTotal')"
              data-field="dosageTotal"
              alignment="right"
              :allow-sorting="false"
              :min-width="60"
            />
            <DxColumn
              v-if="isNew"
              caption="Dosis Total"
              alignment="center"
            >
              <DxColumn
                caption="kg"
                data-field="dosageTotalKg"
                data-type="number"
                alignment="right"
                :allow-editing="false"
                :allow-sorting="false"
                :min-width="60"
              />
              <DxColumn
                caption="l"
                data-field="dosageTotalL"
                data-type="number"
                alignment="right"
                :allow-editing="false"
                :allow-sorting="false"
                :min-width="60"
              />
            </DxColumn>

            <DxColumn
              v-if="!isNew"
              :caption="$t('nutritionDetail.productResume.price')"
              data-field="price"
              alignment="right"
              :allow-sorting="false"
              :min-width="60"
            />
            <DxColumn
              v-if="isNew"
              caption="Precio"
              alignment="center"
            >
              <DxColumn
                caption="€/kg"
                data-field="priceKg"
                alignment="right"
                data-type="number"
                :allow-sorting="false"
                :min-width="60"
              />
              <DxColumn
                caption="€/l"
                data-field="priceL"
                alignment="right"
                data-type="number"
                :allow-sorting="false"
                :min-width="60"
              />
            </DxColumn>

            <DxColumn
              :caption="$t('nutritionDetail.productResume.costUnit')"
              data-field="costUnit"
              alignment="right"
              :allow-sorting="false"
              :min-width="80"
            />
            <DxColumn
              v-if="showPlantColumn"
              :caption="$t('nutritionDetail.productResume.costPlant')"
              data-field="costPlant"
              alignment="right"
              :allow-sorting="false"
              :min-width="80"
            />
            <DxColumn
              :caption="$t('nutritionDetail.productResume.costTotal')"
              data-field="costTotal"
              alignment="right"
              :allow-sorting="false"
              :min-width="80"
            />
          </DxDataGrid>
        </section>
        <section
          v-if="productsNotInNutritionPlan.length > 0"
          class="content-separatos"
        >
          <f7-row style="margin-left: 8px">
            <f7-col>
              <div class="alert alert-warning alert-white rounded">
                <div class="icon-container">
                  <f7-icon md="material:warning" />
                </div>
                <div>
                  <p>{{ $t('nutritionProductsDeleted') }}</p>
                  <div v-for="prodNotInPlan in productsNotInNutritionPlan">
                    <strong> - {{ prodNotInPlan.name }}</strong>
                  </div>
                </div>
              </div>
            </f7-col>
          </f7-row>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import { DxPopover } from 'devextreme-vue/popover';
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLegend,
  DxSeriesTemplate,
  DxTooltip,
} from 'devextreme-vue/chart';
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import { mapActions, mapState } from 'vuex';
import { Device } from 'framework7/framework7-lite.esm.bundle';
import ProductDataFormatter from '@/pages/wizardNutrition/report-formatter/product-data-formatter';

export default {
  name: 'ProductResume',
  components: {
    DxPopover,
    DxDataGrid,
    DxColumn,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLegend,
    DxSeriesTemplate,
    DxTooltip,
  },
  props: {
    fertilization: {
      type: Array,
      required: true,
      default: () => []
    },
    plantationData: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  data() {
    return {
      productResume: [],
      stackProduct: [],
      plantation: {},
      defaultVisible: false,
    };
  },
  computed: {
    ...mapState('Nutrition', ['productsNotInNutritionPlan']),
    ...mapState('nutritionStepper', ['isNew']),
    ...mapState('Plantation', ['currentPlantation']),
    showPlantColumn() {
      return this.plantationData.crop_type !== 'HERBÁCEO';
    },
    getIconPath() {
      if (Device.cordova) {
        return this.environment === 'development'
          ? '../../../static/img/'
          : 'static/img/';
      }
      return this.environment === 'development'
        ? '../../../static/img/'
        : './static/img/';
    },
  },
  async mounted() {
    this.setProductsNotInNutritionPlan([]);
    const formatter = new ProductDataFormatter(this.fertilization);
    try {
      await formatter.buildProductResumeData();
    } catch (e) {
      const notFoundNutritionProducts = JSON.parse(e.message);
      this.setProductsNotInNutritionPlan(notFoundNutritionProducts);
    }
    this.productResume = formatter.productResume.map((p) => ({
      ...p,
      visiblePop: false,
    }));
    this.stackProduct = formatter.stackProduct;
    this.plantation = formatter.plantation;
  },
  methods: {
    customizeTooltip(point) {
      return {
        html: `<div><div class='tooltip-header'>${
          point.argumentText
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('product')}: ${point.seriesName} </span>
          </div>
          <span class="bold"> ${this.$t('weather_parameter_value')}: ${
  point.value
} kg/ha</span> `,
      };
    },
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        // if (e.values[0] !== 'Total') return;
        if (e.rowIndex !== this.productResume.length - 1) return;

        // if (e.columnIndex !== 0) return;
        // if (e.columnIndex !== 0 && e.rowIndex > 0 && e.rowIndex < 4) return;

        e.cellElement.style.backgroundColor = 'var(--fr-chocolat)';
        e.cellElement.style.borderTop = '2px solid #ddd';
        e.cellElement.style.fontWeight = '600';
      }
    },
    ...mapActions('Nutrition', ['setProductsNotInNutritionPlan']),
  },
};
</script>
<style src="./productResume.scss" scoped lang="scss"></style>

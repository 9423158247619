<template>
  <f7-panel
    right
    resizable
    style="width: 350px"
  >
    <f7-view>
      <f7-page class="mainDiv">
        <div class="title">
          <div class="innerTitle">
            <div>{{ $t("attributes") }}</div>
            <div>
              <f7-link panel-toggle="right">
                <i class="f7-icons">multiply</i>
              </f7-link>
            </div>
          </div>
        </div>

        <SearchEngineFilters />
      </f7-page>
      <div
        class="bottom-filter no-hairline bg-gray"
        style="min-height: 60px"
      >
        <div class="bottom-filter-inner justify-content-flex-end">
          <div />
          <f7-button
            class="button-large button-style button-outline color-gray"
            @click="clearFilters()"
          >
            <span class="text-color-gray">Resetear</span>
          </f7-button>
          <f7-button
            fill
            panel-toggle="right"
            class="
              button-large button-style-blue
              margin-left-half
              padding-horizontal
              blue-button
            "
            @click="applyCurrentFilters"
          >
            <span class="text-color-white"> Aplicar </span>
          </f7-button>
        </div>
      </div>
    </f7-view>
  </f7-panel>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import SearchEngineFilters from './searchEngineFilters/index.vue';

export default {
  components: {
    SearchEngineFilters,
  },
  methods: {
    ...mapActions('Filters', ['clearCurrentFilters', 'applyCurrentFilters']),
    ...mapActions('SearchEngineFilter', ['setSelectedAttribute']),
    clearFilters() {
      this.setSelectedAttribute([]);
      this.clearCurrentFilters();
    },
  },
};
</script>
<style scoped>
.title {
  font-weight: bold;
  margin: -25px;
}
.innerTitle {
  align-items: center;
  border: 1px solid #c0c9ce;
  color: #002035;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 15px 25px 10px;
  text-transform: uppercase;
}
.innerTitle i {
  border-radius: 50px;
  color: #002035;
}
.mainDiv {
  overflow: auto;
}

.bottom-filter {
  background: #f7f7f7;
  bottom: 0;
  min-height: 60px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 9999;
}

.bottom-filter-inner {
  display: flex;
  padding: 0 10px;
}

.bottom-filter .blue-button {
  background: var(--ha-butons-color-blue) !important;
}

.bottom-filter .button-style {
  width: 108px;
  height: 36px;
  line-height: 32px;
}

.bottom-filter .button-style-blue {
  width: 108px;
  height: 36px;
  line-height: 35px;
}
</style>

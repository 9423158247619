<template>
  <div class="content-devx">
    <f7-block-title>
      {{ $t("Searcher.lastTreatmentPlan") }}
    </f7-block-title>
    <div
      v-if="!loaded"
      class="loadingFalse"
      style="height: 36px;"
    >
      <div class="preloader" />
    </div>
    <DxDataGrid
      v-else
      class="data-grid-reference-value"
      :row-alternation-enabled="true"
      :data-source="lastTreatmentPlan"
      :column-auto-width="true"
      :show-borders="true"
    >
      <DxColumn
        data-field="init_date"
        :min-width="200"
        :allow-sorting="false"
        :caption="$t('treatment.treatmentPanel.date')"
        :calculate-display-value="formatingDate"
      />
      <DxColumn
        data-field="description"
        :min-width="200"
        :allow-sorting="false"
        :caption="$t('treatment.treatmentPanel.description')"
      />
      <DxColumn
        data-field="products"
        :allow-sorting="false"
        :caption="$t('treatment.treatmentPanel.product')"
      />
      <DxColumn
        type="buttons"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="viewItem"
        />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>

<script>
import moment from 'moment';

import {
  DxDataGrid,
  DxColumn,
  DxButton,
} from 'devextreme-vue/data-grid';
import { mapActions, mapState } from 'vuex';
import { treatmentPlanService } from '@/services/treatmentPlanService';
import settings from '../../../../js/commonSettings';

export default {
  name: 'LastTreatmentPlan',
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
  },
  data() {
    return {
      loaded: false,
      pageSizes: [5, 10, 20],
      lastTreatmentPlan: [],
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
    gridSettings() {
      return settings.datagrid;
    },
  },
  async beforeMount() {
    try {
      const { data } = await treatmentPlanService.getLast(
        this.currentPlantation.companyId,
        this.currentPlantation.id,
      );

      this.lastTreatmentPlan = data;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 450,
        },
        'error',
        3000,
      );
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('treatmentStepper', ['setCurrentTreatmentId']),
    viewItem(e) {
      this.setCurrentTreatmentId(e.row.data.id);
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_treatment_treatment-detail-plan',
        params: { id: e.row.data.id },
      });
      this.$f7.views.main.router.navigate(url, { reloadCurrent: true });
    },
    formatingDate(e) {
      const initDate = moment(e.init_date).format('DD/MM/YYYY');
      const endDate = moment(e.end_date).format('DD/MM/YYYY');

      return `${initDate}  ~  ${endDate}`;
    },
  },
};
</script>

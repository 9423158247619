<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="`${$t('Utilities_Title')} > ${$t('Nutritional_Products')}`" />
    <nutritionalProducts />
  </f7-page>
</template>

<script>
import navbar from '../../../components/NavBar.vue';
import nutritionalProducts from '../../../components/utilities/vademecum/nutritionalProducts/index.vue';

export default {
  name: 'NutritionalProductsPage',
  components: {
    navbar,
    nutritionalProducts,
  },
  methods: {
    beforein() {
      this.$fa.setScreenName('Utilidades > Productos nutricionales');
    },
  },
};
</script>

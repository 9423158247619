<template>
  <f7-page
    class="main-viewparcel"
  >
    <div v-if="loaded">
      <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} > ${actualLocation.name} > ${currentParcel.name}`" />
      <f7-row no-gap>
        <f7-col>
          <ParcelDetail
            :current-parcel="currentParcel"
            :current-client="actualUser.name"
          />
          <SectorsDataGrid
            :sectors-availables="currentParcel.children"
          />
        </f7-col>
        <f7-col>
          <f7-block class="no-padding-left padding-top-half">
            <GISGeoJsonMap
              :zoom="zoom"
              :name-map="mainMap"
              :tree-locations="treeLocations"
            />
          </f7-block>
        </f7-col>
      </f7-row>
      <div
        class="go-back-button margin-left margin-top-half"
      >
        <f7-button
          class="dx-btn-cancel no-margin padding-horizontal"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t('Button_go_to_back') }}
        </f7-button>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ParcelDetail from '../../components/parcel/parcelDetail/index.vue';
import GISGeoJsonMap from '../../components/Gis/GISGeoJsonMap/index.vue';
import SectorsDataGrid from '../../components/parcel/sectorsDataGrid/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'ViewParcel',
  components: {
    ParcelDetail,
    GISGeoJsonMap,
    SectorsDataGrid,
    navbar,
  },
  data() {
    return {
      zoom: 17,
      mainMap: 'parcelMainMap',
      loaded: false,
    };
  },
  computed: {
    ...mapState('Parcel', ['currentParcelId', 'currentParcel']),
    ...mapState('Explotaciones', ['actualLocation', 'actualUser']),
    ...mapState('Plantation', ['treeLocations']),
  },
  async mounted() {
    this.$f7.preloader.show();
    try {
      await this.getParcelById({ companyId: this.actualUser.id, currentParcelId: this.currentParcelId });
      await this.fetchTreeLocationById({ companyId: this.actualUser.id, locationId: this.currentParcelId });
      this.setCentroides();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    setCentroides() {
      this.setParcelCentroide(this.treeLocations[0].children[0].coordinates);
    },
    goToBack() {
      this.$f7.views.main.router.navigate('/viewLocation/', { reloadCurrent: true });
    },
    ...mapActions('Parcel', ['getParcelById']),
    ...mapActions('Plantation', ['fetchTreeLocationById']),
    ...mapActions('Gis', ['setParcelCentroide']),

  },
};
</script>

<style lang="scss">
.main-viewparcel{
  .go-back-button{
    margin: 0;
    padding: 0;
  }
}
</style>

<template>
  <div class="margin-top-half">
    <div
      class="content-devx no-padding-bottom"
    >
      <DxDataGrid
        id="observationsFormsDatabase-datagrid"
        :data-source="observationsTemplates"
        :column-min-width="70"
        :column-auto-width="true"
        :allow-column-resizing="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        @toolbar-preparing="onToolbarPreparing($event)"
        @RowRemoved="deleteForm"
        @exporting="onExporting"
      >
        <DxSelection
          mode="single"
        />
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="observationsFormsDatabase"
        />
        <DxHeaderFilter :visible="true" />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="gridSettings.pageSize" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          info-text="{2} items"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxEditing
          :allow-updating="true"
          :allow-deleting="true"
          :confirm-delete="true"
          mode="popup"
        >
          <DxTexts
            :confirm-delete-message="$t('Confirm_Delete_Text')"
            :confirm-delete-title="$t('Confirm_Delete_Title')"
          />
        </DxEditing>
        <DxColumn
          data-field="subcategory"
          :caption="$t('Configuration.ObservationsForms.formName')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field="count"
          :caption="$t('Configuration.ObservationsForms.attributes')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          type="buttons"
          :fixed="gridSettings.fixed"
          :fixed-position="gridSettings.fixedPosition"
        >
          <DxButton
            name="find"
            icon="find"
            :on-click="viewForm"
          />
          <DxButton
            name="edit"
            icon="edit"
            :on-click="editForm"
          />
          <DxButton
            name="delete"
            icon="trash"
          />
        </DxColumn>
        <template #titleObservationsForms>
          {{ $t('Configuration.ObservationsForms.title') }}
        </template>
      </DxDataGrid>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
  DxEditing,
  DxTexts,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import settings from '../../../../js/commonSettings';

export default {
  name: 'ObservationsFormsDatabase',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
    DxEditing,
    DxTexts,
  },
  props: {
    observationsTemplates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
  },
  methods: {
    viewForm(e) {
      this.initCurrentObservationTemplate();
      this.setCurrentObservationTemplateId(e.row.data.id);
      this.$f7.views.main.router.navigate('/viewObservationTemplate/', { reloadCurrent: true });
    },
    editForm(e) {
      this.initCurrentObservationTemplate();
      this.setCurrentObservationTemplateId(e.row.data.id);
      this.$f7.views.main.router.navigate('/editObservationTemplate/', { reloadCurrent: true });
    },
    async deleteForm(rowSelected) {
      if (typeof rowSelected.data.id !== 'undefined' && rowSelected.data.id !== null && rowSelected.data.id !== '') {
        try {
          await this.deleteObservationTemplateById(rowSelected.data.id);
        } catch (error) {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000,
          );
        }
      } else {
        this.$notifyDX(
          {
            message: this.$t('Configuration.Delete_Validation'),
            width: 550,
          },
          'info',
          3000,
        );
      }
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.initCurrentObservationTemplate();
            this.$f7.views.main.router.navigate('/createObservationTemplate/', { reloadCurrent: true });
          },
        },
      });
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ObservationsTemplatesDatabase');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ObservationsTemplatesDatabase.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Observations', ['setCurrentObservationTemplateId', 'deleteObservationTemplateById', 'initCurrentObservationTemplate']),
  },
};
</script>
<style lang="scss" scoped>
@import './ObservationsFormsDatabase.styles.scss';
</style>

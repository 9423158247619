<template>
  <div>
    <div
      class="content-devx"
    >
      <div class="card-header no-padding-horizontal margin-bottom">
        {{ $t('Product_Detail_Title') }}
      </div>
      <f7-block>
        <f7-row class="margin-bottom">
          <f7-col>
            {{ $t('DxProduct_nameCommercial') }}
          </f7-col>
          <f7-col>
            {{ $t('DxProduct_tipology') }}
          </f7-col>
          <f7-col>
            {{ $t('DxProduct_presentation') }}
          </f7-col>
          <f7-col>
            {{ $t('competence') }}
          </f7-col>
          <f7-col>
            {{ $t('DxProduct_ecology') }}
          </f7-col>
          <f7-col v-if="productDetail.presentation === 'LIQUIDO'">
            {{ $t('DxProduct_density_no_unit') }}
          </f7-col>
          <f7-col>
            {{ $t('Atc_Propietario') }}
          </f7-col>
        </f7-row>
        <f7-row class="bold margin-bottom">
          <f7-col>
            {{ productDetail.name }}
          </f7-col>
          <f7-col>
            {{ getTipology(productDetail.tipology) }}
          </f7-col>
          <f7-col>
            {{ productDetail.presentation }}
          </f7-col>
          <f7-col>
            <span v-if="productDetail.tipology === 4">{{ $t('sector.autoSector.yes') }}</span>
            <span v-else>{{ $t('sector.autoSector.no') }}</span>
          </f7-col>
          <f7-col>
            {{ productDetail.ecologic }}
          </f7-col>
          <f7-col v-if="productDetail.presentation === 'LIQUIDO'">
            {{ productDetail.density }}
          </f7-col>
          <f7-col>
            {{ productDetail.owner }}
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
    <div
      class="content-devx"
    >
      <f7-block-title>
        <f7-row>
          <f7-col width="20">
            {{ $t('Application') }}
          </f7-col>
          <f7-col width="80" />
        </f7-row>
      </f7-block-title>
      <f7-block>
        <f7-row>
          <f7-col width="25">
            <f7-row class="margin-bottom">
              {{ $t('Main_Elements') }}
            </f7-row>
            <DxDataGrid
              id="mainElements-grid"
              :data-source="[productDetail]"
              :column-auto-width="true"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
            >
              <DxColumn
                data-field="NT"
                :caption="'N ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="P2015_Total"
                :caption="'P₂O₅ ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="K2O"
                :caption="'K₂O ' + unit"
                :allow-header-filtering="true"
                alignment="left"
              />
            </Dxdatagrid>
          </f7-col>
          <f7-col width="25">
            <f7-row class="margin-bottom">
              {{ $t('Secondary_Elements') }}
            </f7-row>
            <DxDataGrid
              id="secondaryElements-grid"
              :data-source="[productDetail]"
              :column-auto-width="true"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
            >
              <DxColumn
                data-field="CaO"
                :caption="'CaO ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="MgO"
                :caption="'MgO ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="SO3"
                :caption="'SO₃ ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
            </Dxdatagrid>
          </f7-col>
          <f7-col width="50">
            <f7-row class="margin-bottom">
              {{ $t('Microelements') }}
            </f7-row>
            <DxDataGrid
              id="microelements-grid"
              :data-source="[productDetail]"
              :column-auto-width="true"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
            >
              <DxColumn
                data-field="Cu"
                :caption="'Cu ' + unit"
                :allow-header-filtering="true"
                alignment="left"
              />
              <DxColumn
                data-field="Fe"
                :caption="'Fe ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="Mn"
                :caption="'Mn ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="Zn"
                :caption="'Zn ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="Se"
                :caption="'Se ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="MO"
                :caption="'Mo ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="I"
                :caption="'I ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
            </Dxdatagrid>
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
} from 'devextreme-vue/data-grid';

export default {
  name: 'ProductDetail',
  components: {
    DxDataGrid,
    DxColumn,
  },
  props: {
    productDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      unit: '(% p/p)',
    };
  },
  methods: {
    getTipology(id) {
      return [
        { id: 1, name: 'Especial' },
        { id: 2, name: 'Otro artículo Timac' },
        { id: 3, name: 'Innovabio' },
        { id: 4, name: 'Fertilizante Complejo Competencia' },
        { id: 5, name: 'Fertilizante Nitrogenado Competencia' },
        { id: 6, name: 'Fitosanitario / Bioestimulante' },
      ][id - 1].name || id;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './ProductDetail.styles.scss';
</style>
